import React, { useCallback } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import {
    useModalQueryParams,
    useSelectedRoundId,
    useSelectedSimulation,
} from "@/hooks";
import {
    useDiscardActiveContentBlock,
    useGetActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { BehaviorEditor } from "../behavior-editor/BehaviorEditor";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

function BehaviorModalContent({ currentPageId }: { currentPageId?: string }) {
    const { selectedRoundId } = useSelectedRoundId();
    const { selectedSimulation } = useSelectedSimulation();

    const { data: activeContentBlock } = useGetActiveContentBlock();
    const {  closeModal } = useModalQueryParams();

    const save = useCallback(() => {
        SapienInertia.put(
            "creator.design.content-blocks.update",
            {
                ...activeContentBlock,
                theme: JSON.stringify(activeContentBlock.theme),
            } as unknown as (typeof LaravelRequestBodyShapes)["creator.design.content-blocks.update"],
            {
                simulationSlug: selectedSimulation.slug,
                roundId: selectedRoundId,
                contentBlock: activeContentBlock.id,
            },
            {
                preserveState: true,
            },
        );
    }, [activeContentBlock]);

    return (
        <>
            <div
                className="flex flex-col space-y-2"
                data-testid="behavior-modal"
            >
                <BehaviorEditor />
            </div>
            <div className="grid w-full gap-6 border-t border-[#374151] p-6 md:grid-cols-2">
                <div className="flex items-center justify-center">
                    <button
                        onClick={() => {
                            closeModal();
                        }}
                        data-testid="close-behavior-modal"
                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                        Cancel
                    </button>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        onClick={() => {
                            save();
                        }}
                        className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-75"
                    >
                        Save
                    </button>
                </div>
            </div>
        </>
    );
}

export default function BehaviorModal({
    currentPageId,
}: {
    currentPageId?: string;
}) {
    const { isAtModalUrl, closeModal } = useModalQueryParams("BehaviorModal");
    const { discardActiveContentBlock } = useDiscardActiveContentBlock();

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => {
                discardActiveContentBlock();
                closeModal();
            }}
            size="m"
            backgroundColor="#111928"
        >
            {isAtModalUrl && (
                <BehaviorModalContent currentPageId={currentPageId} />
            )}
        </ModalContainer>
    );
}
