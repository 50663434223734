import React from "react";
import { Simulation } from "../../models";
import { Link } from "@inertiajs/react";
import {
    ArrowUpRightIcon,
    DocumentDuplicateIcon,
    PencilIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import { sapienRoute } from "@/inertia-utils/hooks";

interface SimulationCardProps {
    simulation: Simulation;
    setEditedSimulation?: () => void;
    setClonedSimulation?: () => void;
    deleteSimulation: (simulationId: string) => void;
}

function secondsToDuration(sec: number): string {
    const roundedSeconds = Math.round(sec / 900) * 900; // 900 seconds = 15 minutes
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds - hours * 3600) / 60);

    let duration = "";

    if (hours > 0) {
        duration += hours + "h ";
    }

    if (minutes > 0 || duration === "") {
        // always display minutes if no hours are present
        duration += minutes + "m";
    }

    return duration.trim();
}
export const SimulationCard = ({
    simulation,
    setEditedSimulation,
    deleteSimulation,
    setClonedSimulation,
}: SimulationCardProps) => {
    const backgroundImage =
        simulation?.theme?.image_url ||
        "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/noun-image-5275349.png";

    return (
        <div className="flex flex-col justify-between rounded-md border bg-white p-4 shadow-md">
            <div className="flex flex-col space-y-3 pb-4">
                <div
                    className={`h-72 w-full rounded-md xl:h-56 ${
                        !!simulation?.theme?.image_url
                            ? "bg-cover bg-center bg-no-repeat shadow-lg"
                            : "border-2 bg-contain bg-center bg-no-repeat"
                    }`}
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                ></div>
                <div className="flex text-sm text-[#6B7280]">
                    <p>
                        {simulation.rounds_count} Episode
                        {simulation.rounds_count > 1 && "s"}
                    </p>
                    {!!simulation.estimated_time && (
                        <>
                            <p className="mx-2">•</p>
                            <p>
                                Approx{" "}
                                {secondsToDuration(simulation.estimated_time)}
                            </p>
                        </>
                    )}
                </div>
                <h2 className="text-lg font-bold">{simulation.title}</h2>{" "}
            </div>
            <div className="flex justify-between">
                <Link
                    data-testid={`launch_${simulation.title}`}
                    href={sapienRoute("simulation.projects.show", {
                        project: simulation.slug,
                    })}
                    className="flex items-center rounded-full border border-black px-4 py-1 text-sm
                        transition-all hover:bg-black hover:text-white"
                >
                    <span>Launch</span>
                    <ArrowUpRightIcon className="ml-1 h-4 w-4" />
                </Link>
                <div className="flex space-x-2">
                    <button
                        onClick={() => {
                            setEditedSimulation();
                        }}
                        className="rounded-full transition-all hover:text-blue-600"
                    >
                        <PencilIcon className="h-4 w-4" />
                    </button>
                    <button
                        onClick={() => {
                            setClonedSimulation();
                        }}
                        className="rounded-full transition-all hover:text-blue-600"
                    >
                        <DocumentDuplicateIcon className="h-4 w-4" />
                    </button>
                    <button
                        onClick={() => {
                            deleteSimulation(simulation.id);
                        }}
                        className="rounded-full transition-all hover:text-red-600"
                    >
                        <TrashIcon className="h-4 w-4" />
                    </button>
                </div>
            </div>
        </div>
    );
};
