import React, { useMemo, useState } from "react";
import {
    ModelVariable,
    ModelVariableDataType,
    ModelVariableType,
    TimeHorizon,
} from "../../models";
import { IconButton } from "../../modules/shared";
import { edit2 } from "react-icons-kit/feather/edit2";
import { groupBy } from "lodash";
import { useFormatVariableValue } from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import {
    PencilIcon,
    Cog6ToothIcon,
    CheckIcon,
    ArrowTopRightOnSquareIcon,
    BeakerIcon,
    StarIcon as KeyMetricOutlineIcon,
    BookmarkIcon as DesignOutlineIcon,
    ChartBarIcon as FacilitationOutlineIcon,
} from "@heroicons/react/24/outline";
import {
    StarIcon as KeyMetricSolidIcon,
    BookmarkIcon as DesignSolidIcon,
    ChartBarIcon as FacilitationSolidIcon,
} from "@heroicons/react/24/solid";

const saveBasicVariableProperties = async (
    modelVariableId: string,
    label: string,
    exposeToFacilitator: boolean,
    exposeToDesigner: boolean,
    isKeyMetric: boolean,
    description?: string,
) => {
    return SapienInertia.post(
        "model-builder.model-variables.simple-save",
        {
            id: modelVariableId,
            label: label,
            expose_to_facilitator: exposeToFacilitator,
            expose_to_designer: exposeToDesigner,
            is_key_metric: isKeyMetric,
            description: description,
        },
        {},
        { preserveScroll: true },
    );
};

const NestedModelVariableDisplayComponent = ({
    modelVariable,
    showAllValues,
    timeHorizons,
    setActiveModelVariable,
    variableDepth,
    isInteractive,
    children,
}: {
    modelVariable: ModelVariable;
    showAllValues?: boolean;
    timeHorizons: TimeHorizon[];
    setActiveModelVariable: (modelVariable: ModelVariable) => void;
    variableDepth: number;
    isInteractive?: boolean;
    children: React.ReactNode;
}) => {
    const formatVariableValue = useFormatVariableValue();

    const [editedLabel, setEditedLabel] = useState<string>();
    const [editedDescription, setEditedDescription] = useState<string>();

    const variableValuesByTimeHorizonId = useMemo(() => {
        if (!!modelVariable && !!modelVariable.baseVariableValues?.length) {
            return groupBy(modelVariable.baseVariableValues, "time_horizon_id");
        } else {
            return {};
        }
    }, [modelVariable?.baseVariableValues]);

    return (
        <tr className={"table-row border odd:bg-[#f8f9fe] even:bg-[#fcfdfe]"}>
            {!!modelVariable.baseVariableValues &&
            !!modelVariable.baseVariableValues.length ? (
                <>
                    <th className={"table-col px-1 py-1"}>
                        <span>
                            {editedLabel != undefined ? (
                                <textarea
                                    rows={1}
                                    className="block min-h-[54px] w-full min-w-[200px] rounded-lg border border-gray-300
                                        bg-gray-50 p-2 text-xs text-gray-700 focus:border-blue-500 focus:ring-blue-500"
                                    value={editedLabel}
                                    onChange={(e) => {
                                        setEditedLabel(e.target.value);
                                    }}
                                />
                            ) : (
                                <span
                                    className={`text-xs ${
                                        !!isInteractive &&
                                        !modelVariable.resample_function &&
                                        (variableDepth > 0 ||
                                            modelVariable.data_type ===
                                                ModelVariableDataType.Boolean)
                                            ? "text-red-600"
                                            : ""
                                    }`}
                                >
                                    {modelVariable.label}
                                </span>
                            )}
                            {editedDescription != undefined && (
                                <>
                                    <span className="text-xs font-light">
                                        {"Description"}
                                    </span>
                                    <textarea
                                        rows={1}
                                        className="block min-h-[54px] w-full min-w-[200px] rounded-lg border border-gray-300
                                            bg-gray-50 p-2 text-xs font-normal text-gray-700 focus:border-blue-500
                                            focus:ring-blue-500"
                                        value={editedDescription}
                                        onChange={(e) => {
                                            setEditedDescription(
                                                e.target.value,
                                            );
                                        }}
                                    />
                                </>
                            )}
                        </span>
                    </th>
                    <td className="px-1 py-1">
                        <span className="flex items-center">
                            <span>
                                <button
                                    title="Label Editor"
                                    type="button"
                                    className="inline-flex items-center rounded p-1.5 text-center text-sm font-medium
                                        text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-0"
                                    disabled={false}
                                    onClick={async () => {
                                        if (!!editedLabel) {
                                            await saveBasicVariableProperties(
                                                modelVariable.id,
                                                editedLabel,
                                                modelVariable.expose_to_facilitator,
                                                modelVariable.expose_to_designer,
                                                modelVariable.is_key_metric,
                                                isInteractive
                                                    ? editedDescription
                                                    : modelVariable.description,
                                            );
                                            setEditedLabel(undefined);
                                            setEditedDescription(undefined);
                                        } else {
                                            setEditedLabel(modelVariable.label);
                                            if (isInteractive) {
                                                setEditedDescription(
                                                    modelVariable.description ??
                                                        "",
                                                );
                                            }
                                        }
                                    }}
                                >
                                    {!!editedLabel ? (
                                        <CheckIcon className="h-4 w-4" />
                                    ) : (
                                        <PencilIcon className="h-4 w-4" />
                                    )}
                                </button>
                            </span>
                            {modelVariable.variable_type !==
                                ModelVariableType["Selection Data"] && (
                                <>
                                    <button
                                        title="Modal Editor"
                                        type="button"
                                        className="inline-flex items-center rounded p-1.5 text-center text-sm font-medium
                                            text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-0"
                                        disabled={false}
                                        onClick={() =>
                                            setActiveModelVariable(
                                                modelVariable,
                                            )
                                        }
                                    >
                                        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        title="Key Metric"
                                        type="button"
                                        className="inline-flex items-center rounded p-1 text-center text-sm font-medium
                                            text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-0"
                                        disabled={
                                            isInteractive
                                                ? modelVariable.expose_to_designer
                                                : false
                                        }
                                        onClick={async () =>
                                            await saveBasicVariableProperties(
                                                modelVariable.id,
                                                modelVariable.label,
                                                modelVariable.expose_to_facilitator,
                                                modelVariable.expose_to_designer,
                                                !modelVariable.is_key_metric,
                                                modelVariable.description,
                                            )
                                        }
                                    >
                                        {modelVariable.is_key_metric ? (
                                            <KeyMetricSolidIcon className="h-4 w-4 text-indigo-700" />
                                        ) : (
                                            <KeyMetricOutlineIcon className="h-4 w-4 text-gray-300" />
                                        )}
                                    </button>
                                    <button
                                        data-testid={`${modelVariable.label}_design_button`}
                                        title="Design"
                                        type="button"
                                        className="inline-flex items-center rounded p-1 text-center text-sm font-medium
                                            text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-0"
                                        disabled={
                                            isInteractive
                                                ? variableDepth > 0 ||
                                                  modelVariable.is_key_metric
                                                : false
                                        }
                                        onClick={async () =>
                                            await saveBasicVariableProperties(
                                                modelVariable.id,
                                                modelVariable.label,
                                                modelVariable.expose_to_facilitator,
                                                !modelVariable.expose_to_designer,
                                                modelVariable.is_key_metric,
                                                modelVariable.description,
                                            )
                                        }
                                    >
                                        {modelVariable.expose_to_designer ? (
                                            <DesignSolidIcon className="h-4 w-4 text-indigo-700" />
                                        ) : (
                                            <DesignOutlineIcon className="h-4 w-4 text-gray-300" />
                                        )}
                                    </button>
                                </>
                            )}
                            <button
                                title="Facilitation"
                                type="button"
                                className="inline-flex items-center rounded p-1 text-center text-sm font-medium
                                    text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-0"
                                disabled={false}
                                onClick={async () =>
                                    await saveBasicVariableProperties(
                                        modelVariable.id,
                                        modelVariable.label,
                                        !modelVariable.expose_to_facilitator,
                                        modelVariable.expose_to_designer,
                                        modelVariable.is_key_metric,
                                        modelVariable.description,
                                    )
                                }
                            >
                                {modelVariable.expose_to_facilitator ? (
                                    <FacilitationSolidIcon className="h-4 w-4 text-indigo-700" />
                                ) : (
                                    <FacilitationOutlineIcon className="h-4 w-4 text-gray-300" />
                                )}
                            </button>
                            {children}
                            {/* <button
                                type="button"
                                className={`inline-flex items-center p-1.5 rounded text-sm font-medium text-center ${
                                    isSelectedAsSourceVariable
                                        ? "text-gray-200 bg-gray-600 hover:bg-gray-500"
                                        : "text-gray-950 hover:bg-gray-100"
                                } focus:ring-0 focus:outline-none`}
                                disabled={!toggleSourceVariable}
                                onClick={() =>
                                    !!toggleSourceVariable &&
                                    toggleSourceVariable()
                                }
                            >
                                <BeakerIcon className="h-4 w-4" />
                            </button>
                            {modelVariable.variable_type !==
                                ModelVariableType["Selection Data"] &&
                                modelVariable.variable_type !==
                                    ModelVariableType.Independent && (
                                    <button
                                        type="button"
                                        className={`inline-flex items-center p-1.5 rounded text-sm font-medium text-center ${
                                            isSelectedAsTargetVariable
                                                ? "text-gray-200 bg-gray-600 hover:bg-gray-500"
                                                : "text-gray-950 hover:bg-gray-100"
                                        } focus:ring-0 focus:outline-none`}
                                        disabled={!toggleTargetVariable}
                                        onClick={() =>
                                            !!toggleTargetVariable &&
                                            toggleTargetVariable()
                                        }
                                    >
                                        <Cog6ToothIcon className="h-4 w-4" />
                                    </button>
                                )} */}
                        </span>
                    </td>
                    <td className="px-1 py-1">
                        <span
                            style={{
                                letterSpacing: "0.5px",
                                textTransform: "uppercase",
                            }}
                        >
                            {modelVariable.variable_type.replace("_", " ")}
                        </span>
                    </td>
                    <td className="px-1 py-1">
                        <span>{`${variableDepth}`}</span>
                    </td>
                    <td className="px-1 py-1">
                        <span
                            style={{
                                letterSpacing: "0.5px",
                                textTransform: "uppercase",
                            }}
                        >
                            {modelVariable.scope}
                        </span>
                    </td>
                    {!modelVariable.uses_time || !showAllValues ? (
                        <td
                            className="border-l px-1 py-1"
                            colSpan={timeHorizons?.length || 1}
                        >
                            {modelVariable.data_type ===
                            ModelVariableDataType.Number
                                ? formatVariableValue(
                                      modelVariable.unit,
                                      modelVariable.baseVariableValues[0]
                                          .numerical_value,
                                      modelVariable.is_integer,
                                  )
                                : modelVariable.baseVariableValues[0].boolean_value.toString()}
                        </td>
                    ) : (
                        <>
                            {!!modelVariable.uses_time &&
                                !!variableValuesByTimeHorizonId &&
                                !!Object.keys(variableValuesByTimeHorizonId)
                                    .length &&
                                timeHorizons.map((timeHorizon) => (
                                    <td
                                        className="border-l px-1 py-1 text-right"
                                        key={timeHorizon.id}
                                    >
                                        {!!variableValuesByTimeHorizonId[
                                            timeHorizon.id
                                        ] && (
                                            <span>
                                                {/* {`(${timeHorizon.time_index}) `} */}
                                                {modelVariable.data_type ===
                                                ModelVariableDataType.Number
                                                    ? formatVariableValue(
                                                          modelVariable.unit,
                                                          variableValuesByTimeHorizonId[
                                                              timeHorizon.id
                                                          ][0].numerical_value,
                                                          modelVariable.is_integer,
                                                      )
                                                    : variableValuesByTimeHorizonId[
                                                          timeHorizon.id
                                                      ][0].boolean_value.toString()}
                                            </span>
                                        )}
                                    </td>
                                ))}
                        </>
                    )}
                </>
            ) : (
                <th className={"table-col px-1 py-1"}>
                    <span>{modelVariable.label}</span>
                    <span>
                        {modelVariable.variable_type !==
                            ModelVariableType["Selection Data"] && (
                            <IconButton
                                icon={edit2}
                                handler={() =>
                                    setActiveModelVariable(modelVariable)
                                }
                                noMargin={true}
                            />
                        )}
                    </span>
                </th>
            )}
        </tr>
    );
};

export const NestedModelVariableWorkspaceButtons = ({
    showTargetButton,
    toggleSourceVariable,
    toggleTargetVariable,
    isSelectedAsSourceVariable,
    isSelectedAsTargetVariable,
}: {
    showTargetButton: boolean;
    toggleSourceVariable?: () => void;
    toggleTargetVariable?: () => void;
    isSelectedAsSourceVariable?: boolean;
    isSelectedAsTargetVariable?: boolean;
}) => {
    return (
        <>
            <button
                type="button"
                className={`inline-flex items-center rounded p-1.5 text-center text-sm font-medium ${
                    isSelectedAsSourceVariable
                        ? "bg-gray-600 text-gray-200 hover:bg-gray-500"
                        : "text-gray-950 hover:bg-gray-100"
                } focus:outline-none focus:ring-0`}
                disabled={!toggleSourceVariable}
                onClick={() => !!toggleSourceVariable && toggleSourceVariable()}
            >
                <BeakerIcon className="h-4 w-4" />
            </button>
            {showTargetButton && (
                <button
                    type="button"
                    className={`inline-flex items-center rounded p-1.5 text-center text-sm font-medium ${
                        isSelectedAsTargetVariable
                            ? "bg-gray-600 text-gray-200 hover:bg-gray-500"
                            : "text-gray-950 hover:bg-gray-100"
                    } focus:outline-none focus:ring-0`}
                    disabled={!toggleTargetVariable}
                    onClick={() =>
                        !!toggleTargetVariable && toggleTargetVariable()
                    }
                >
                    <Cog6ToothIcon className="h-4 w-4" />
                </button>
            )}
        </>
    );
};

export const NestedModelVariableDisplay = React.memo(
    NestedModelVariableDisplayComponent,
);
