import React from "react";
import { Course, Lesson } from "../../types";
import LessonLink from "../LessonLink";
import { Transition } from "@headlessui/react";
import LessonSideNavHeader from "./LessonSideNavHeader";

type Props = {
    course: Course;
    currentLesson: Lesson;
    isInAdminContext: boolean;
    sidebarState: "open" | "closed";
    setSidebarState: React.Dispatch<React.SetStateAction<"open" | "closed">>;
};

export default function LessonSideNav({
    course,
    currentLesson,
    isInAdminContext,
    sidebarState,
    setSidebarState,
}: Props) {
    const link = isInAdminContext
        ? "e-learning.preview.show"
        : "e-learning.participant.lesson.show";

    return (
        <aside
            className="lesson-side-nav relative z-[9999] mt-16 transition-all data-[state='closed']:w-0
                data-[state='open']:w-80"
            data-state={sidebarState}
        >
            <Transition
                show={sidebarState === "open"}
                enter="transition ease-in-out delay-75 duration-500 sm:duration-700"
                enterFrom="opacity-0"
                enterTo="opacity-100 thingus"
                leave="transition opacity-0"
                leaveFrom="opacity-0 transition-opacity"
                leaveTo="opacity-0"
            >
                <div
                    data-state={sidebarState}
                    className="fixed h-full overflow-y-auto bg-white pb-16 scrollbar scrollbar-thumb-gray-300
                        scrollbar-thumb-rounded-full scrollbar-w-2 data-[state='closed']:w-0
                        data-[state='open']:w-80
                        data-[state='open']:shadow-[rgba(0,0,15,0.15)_3px_1px_10px_0px]"
                >
                    <LessonSideNavHeader
                        course={course}
                        lessonId={currentLesson.id}
                    />
                    {course.lessons.map((lesson) => (
                        <LessonLink
                            lesson={lesson}
                            currentLesson={currentLesson}
                            route={link}
                            weight={lesson.weight}
                            currentWeight={currentLesson.weight}
                        />
                    ))}
                </div>
            </Transition>
            {/* <button
                data-state={sidebarState}
                className="fixed top-2 mt-16 rounded-md bg-white p-1 text-slate-800 shadow-sm
                    transition-all duration-500 data-[state='closed']:left-2
                    data-[state='open']:left-[20rem] data-[state='open']:ml-2 hover:text-slate-900"
                onClick={() =>
                    setSidebarState((sidebarState) =>
                        sidebarState === "open" ? "closed" : "open",
                    )
                }
            >
                <AlignJustify className="h-6 w-6" />
            </button> */}
            {
                <button
                    data-state={sidebarState}
                    className="button-one fixed top-2 mt-16 flex items-center justify-center rounded-md
                        bg-white p-1 text-sapien-blue shadow-sm transition-all duration-500
                        data-[state='closed']:left-2 data-[state='open']:left-80
                        data-[state='open']:ml-2 hover:text-slate-900"
                    onClick={() =>
                        setSidebarState((sidebarState) =>
                            sidebarState === "open" ? "closed" : "open",
                        )
                    }
                    aria-controls="primary-navigation"
                    aria-expanded={sidebarState === "open" ? "true" : "false"}
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        className="hamburger"
                        viewBox="0 0 100 100"
                        width="30"
                    >
                        <rect
                            className="line top"
                            width="80"
                            height="10"
                            x="10"
                            y="25"
                            rx="5"
                        ></rect>
                        <rect
                            className="line middle"
                            width="80"
                            height="10"
                            x="10"
                            y="45"
                            rx="5"
                        ></rect>
                        <rect
                            className="line bottom"
                            width="80"
                            height="10"
                            x="10"
                            y="65"
                            rx="5"
                        ></rect>
                    </svg>
                </button>
            }
        </aside>
    );
}
