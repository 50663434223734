import React, { useEffect, useMemo, useState } from "react";
import { QuestionDisplay } from "./QuestionDisplay";
import { SapienInertia, useModelBuilderPage } from "@/inertia-utils/hooks";
import { ModelConnections } from "./ModelConnections";
import {
    createNewQuestionSubmoduleData,
    QuestionSubmoduleData,
} from "./QuestionSubmoduleForm";
import { PromptType, Round } from "@/models";

export const QuestionList = (props: {
    simulationId: string;
    selectedBuilderRoundId: string;
    setSelectedBuilderRoundId: (roundId: string) => void;
    setQuestionSubmoduleData: (
        questionSubmoduleData: QuestionSubmoduleData
    ) => void;
}) => {
    const {
        simulationId,
        selectedBuilderRoundId,
        setSelectedBuilderRoundId,
        setQuestionSubmoduleData,
    } = props;

    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { rounds } = modelBuilderPageProps;

    const roundsWithFilteredPrompts = useMemo(() => {
        return rounds.map(
            (round, i) =>
                ({
                    ...round,
                    prompts: round.prompts.filter(
                        (prompt) =>
                            prompt.prompt_type !== PromptType["Short Text"] &&
                            prompt.prompt_type !== PromptType["Long Text"]
                    ),
                } as Round)
        );
    }, [rounds]);

    const [hiddenPromptAccordionKeys, setHiddenPromptAccordionKeys] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        setHiddenPromptAccordionKeys({});
    }, [selectedBuilderRoundId]);

    const connectQuestion = async (promptId: string) => {
        return SapienInertia.post(
            "model-builder.connect-question",
            {
                prompt_id: promptId,
            },
            {},
            { preserveScroll: true }
        );
    };

    const reorderPrompts = async (
        promptId: string,
        destinationIndex: number
    ) => {
        return SapienInertia.post(
            "model-builder.reorder-prompts",
            {
                prompt_id: promptId,
                destination_index: destinationIndex,
            },
            {},
            { preserveScroll: true }
        );
    };

    return (
        <div>
            <div className="mt-2 mb-4 text-xl">Questions by Round</div>
            <div className="mb-4 mx-4">
                <span className="flex items-center">
                    <label className="font-medium">Round</label>
                    <select
                        className="ml-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={selectedBuilderRoundId}
                        onChange={(e) => {
                            setSelectedBuilderRoundId(e.target.value);
                        }}
                    >
                        <option key="" value="" />
                        {roundsWithFilteredPrompts.map((round) => (
                            <option
                                key={round.id}
                                value={round.id}
                                disabled={!round.prompts?.length}
                            >
                                {round.title}
                            </option>
                        ))}
                    </select>
                </span>
            </div>
            <div className="mb-4">
                {!!selectedBuilderRoundId &&
                    roundsWithFilteredPrompts
                        ?.filter((round) => round.id === selectedBuilderRoundId)
                        .map((round) => (
                            <div key={round.id}>
                                {round.prompts !== undefined &&
                                    round.prompts.length > 0 && (
                                        <>
                                            <div className="pb-4 text-xs text-center">
                                                <span
                                                    className="mr-2 cursor-pointer hover:underline"
                                                    onClick={() =>
                                                        setHiddenPromptAccordionKeys(
                                                            {}
                                                        )
                                                    }
                                                >
                                                    Expand
                                                </span>
                                                <span
                                                    className="cursor-pointer hover:underline"
                                                    onClick={() =>
                                                        setHiddenPromptAccordionKeys(
                                                            round.prompts.reduce(
                                                                (
                                                                    map,
                                                                    prompt
                                                                ) => ({
                                                                    ...map,
                                                                    [prompt.id]:
                                                                        true,
                                                                }),
                                                                {}
                                                            )
                                                        )
                                                    }
                                                >
                                                    Collapse
                                                </span>
                                            </div>
                                            {round.prompts.map((prompt) => (
                                                <QuestionDisplay
                                                    key={prompt.id}
                                                    simulationId={simulationId}
                                                    prompt={prompt}
                                                    connectQuestion={
                                                        connectQuestion
                                                    }
                                                    reorderPrompts={
                                                        reorderPrompts
                                                    }
                                                    isExpanded={
                                                        !hiddenPromptAccordionKeys[
                                                            prompt.id
                                                        ]
                                                    }
                                                    toggleExpanded={() =>
                                                        setHiddenPromptAccordionKeys(
                                                            {
                                                                ...hiddenPromptAccordionKeys,
                                                                [prompt.id]:
                                                                    !hiddenPromptAccordionKeys[
                                                                        prompt
                                                                            .id
                                                                    ],
                                                            }
                                                        )
                                                    }
                                                    handleSetQuestionSubmoduleData={() =>
                                                        setQuestionSubmoduleData(
                                                            createNewQuestionSubmoduleData(
                                                                {
                                                                    simulationId,
                                                                    roundId:
                                                                        round.id,
                                                                    prompt,
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            ))}
                                        </>
                                    )}
                            </div>
                        ))}
            </div>
            <div>
                {!!selectedBuilderRoundId &&
                    roundsWithFilteredPrompts
                        ?.filter((round) => round.id === selectedBuilderRoundId)
                        .map((round) => (
                            <React.Fragment key={round.id}>
                                <div className="mt-10 mb-4 text-xl">
                                    Variables by Question
                                </div>
                                <ModelConnections round={round} />
                            </React.Fragment>
                        ))}
            </div>
        </div>
    );
};
