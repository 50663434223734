import { RichText } from "@/models";
import { Editor, EditorContent } from "@tiptap/react";
import React, { useCallback } from "react";
import { useDebouncedEditor } from "@/modules/text-editor/useDebouncedEditor";
import RichTextMenuButton from "@/components/rich-text/RichTextMenuButton";
import {
    ALargeSmall,
    BoldIcon,
    ItalicIcon,
    ListIcon,
    ListOrdered,
    Redo,
    UnderlineIcon,
    Undo,
} from "lucide-react";

type MenuProps = { editor: Editor };
function Menu({ editor }: MenuProps) {
    const optionallySelectAll = (editor: Editor) => {
        const currentSelectionIsEmpty = editor.view.state.selection.empty;
        if (currentSelectionIsEmpty) {
            editor.chain().focus().selectAll().run();
        }
    };

    return (
        <div className="relative flex w-full flex-row gap-1">
            <RichTextMenuButton
                onClick={() => {
                    optionallySelectAll(editor);
                    editor.getAttributes("textStyle")?.fontSize === "24"
                        ? editor.chain().focus().setFontSize("14").run()
                        : editor.chain().focus().setFontSize("24").run();
                }}
                isActive={editor.getAttributes("textStyle")?.fontSize === "24"}
            >
                <ALargeSmall className="h-4 w-4" />
            </RichTextMenuButton>
            <RichTextMenuButton
                onClick={() => {
                    optionallySelectAll(editor);
                    editor.chain().focus().toggleBold().run();
                }}
                isActive={editor.isActive("bold")}
            >
                <BoldIcon className="h-4 w-4" />
            </RichTextMenuButton>
            <RichTextMenuButton
                onClick={() => {
                    optionallySelectAll(editor);
                    editor.chain().focus().toggleItalic().run();
                }}
                isActive={editor.isActive("italic")}
            >
                <ItalicIcon className="h-4 w-4" />
            </RichTextMenuButton>
            <RichTextMenuButton
                onClick={() => {
                    optionallySelectAll(editor);
                    editor.chain().focus().toggleUnderline().run();
                }}
                isActive={editor.isActive("underline")}
            >
                <UnderlineIcon className="h-4 w-4" />
            </RichTextMenuButton>
            <RichTextMenuButton
                onClick={() => {
                    optionallySelectAll(editor);
                    editor.chain().focus().toggleBulletList().run();
                }}
                isActive={editor.isActive("bulletList")}
            >
                <ListIcon className="h-4 w-4" />
            </RichTextMenuButton>
            <RichTextMenuButton
                onClick={() => {
                    optionallySelectAll(editor);
                    editor.chain().focus().toggleOrderedList().run();
                }}
                isActive={editor.isActive("orderedList")}
            >
                {" "}
                <ListOrdered className="h-4 w-4" />
            </RichTextMenuButton>

            <RichTextMenuButton
                onClick={() => editor.chain().focus().undo().run()}
                isActive={false}
            >
                <Undo className="h-4 w-4" />
            </RichTextMenuButton>
            <RichTextMenuButton
                onClick={() => editor.chain().focus().redo().run()}
                isActive={false}
            >
                <Redo className="h-4 w-4" />
            </RichTextMenuButton>
        </div>
    );
}

type Props = {
    editable: boolean;
    content: App.Data.RichTextData;
    saveOverride?: (rich_text: RichText | App.Data.RichTextData) => void;
    parentObject: {
        id?: string;
        rich_text: App.Data.RichTextData;
        updated_at: string;
    };
    placeHolder?: string;
    onUpdate?: (e: RichText | App.Data.RichTextData) => void;
    hideButtons?: boolean;
};

const ForumRichText = React.memo(function ForumRichText({
    editable = false,
    content,
    saveOverride,
    parentObject,
    placeHolder = "Add some text...",
    onUpdate,
    hideButtons = false,
}: Props) {
    const [hasChanges, setHasChanges] = React.useState(false);

    const editor = useDebouncedEditor(
        content,
        editable,
        onUpdate,
        undefined,
        0,
        placeHolder,
    );

    const handleSave = useCallback(
        (rich_text: RichText | App.Data.RichTextData) => {
            // mutateAsync({ ...contentBlock, rich_text });
            saveOverride?.(rich_text);
            setHasChanges(true);
            editor.commands.clearContent();
        },
        [parentObject?.id, parentObject.updated_at, editable, editor],
    );

    return (
        <div
            className="group flex w-full flex-col space-y-2 selection:bg-gray-100 focus-within:py-1
                data-[is-editable=true]:rounded-md data-[is-editable=true]:border
                data-[is-editable=true]:p-4 data-[is-editable=false]:px-0
                data-[is-editable=false]:py-0"
            data-is-editable={editable}
        >
            {!!editor && editable && <Menu editor={editor} />}

            <EditorContent editor={editor} className="w-full" />

            <div className="hidden justify-end space-x-2 group-focus-within:flex">
                {editable && !hideButtons &&(
                    <>
                        <button
                            className="rounded-full bg-gray-100 px-2 py-1 text-sm text-gray-800"
                            onClick={() => {}}
                        >
                            Cancel
                        </button>
                        <button
                            className="rounded-full bg-sapien-blue px-2 py-1 text-sm text-white"
                            onClick={() =>
                                handleSave(editor.getJSON() as RichText)
                            }
                        >
                            Reply
                        </button>
                    </>
                )}
            </div>
        </div>
    );
});

export default ForumRichText;
