import React from "react";
import { WizardVariableSettingsDisplay } from "./WizardVariableSettingsDisplay";
import { useFormatVariableValue } from "@/hooks";
import { AccordionCardWrapper } from "../shared";
import { SettingsDisplayProps } from "./props";
import { useAuthStore } from "@/hooks/store";
import { useArchetypes } from "../state";
import { ArchetypeInputComparison } from "./ArchetypeInputComparison";

const AccordionCardSettingsDisplayComponent = ({
    modelBlocks,
    modelVariables,
    inputDataObject,
    setInputDataItem,
    showSettingsCharts,
    modelBlockDisplayMap,
    setModelBlockDisplayMap,
}: SettingsDisplayProps) => {
    const { isNotParticipant } = useAuthStore();
    const archetypes = useArchetypes();
    const formatVariableValue = useFormatVariableValue();

    return (
        <div className="space-y-6">
            {!!modelVariables &&
                modelBlocks?.length > 0 &&
                modelBlocks
                    .filter((block) => !!modelVariables[block.id])
                    .map((block) => (
                        <AccordionCardWrapper
                            key={block.id}
                            modelBlockLabel={block.label}
                            handleSetModelBlockDisplayMap={() =>
                                setModelBlockDisplayMap({
                                    ...modelBlockDisplayMap,
                                    [block.id]: !modelBlockDisplayMap[block.id],
                                })
                            }
                            isExpanded={!!modelBlockDisplayMap[block.id]}
                        >
                            {!!modelBlockDisplayMap[block.id] && (
                                <div className="p-4">
                                    <div className="flex flex-col space-y-4">
                                        {!!block.description?.length && (
                                            <div className="pb-4 text-slate-600 dark:text-slate-400">
                                                {block.description}
                                            </div>
                                        )}
                                        {modelVariables[block.id]
                                            ?.filter(
                                                (modelVariable) =>
                                                    !!inputDataObject[
                                                        modelVariable.id
                                                    ],
                                            )
                                            .map((modelVariable, i) => (
                                                <WizardVariableSettingsDisplay
                                                    key={modelVariable.id}
                                                    modelVariable={
                                                        modelVariable
                                                    }
                                                    inputDataItem={
                                                        inputDataObject[
                                                            modelVariable.id
                                                        ]
                                                    }
                                                    setInputDataItem={
                                                        setInputDataItem
                                                    }
                                                    showSettingsCharts={
                                                        showSettingsCharts
                                                    }
                                                    index={i}
                                                    formatVariableValue={
                                                        formatVariableValue
                                                    }
                                                    fullChartIconMenu={false}
                                                >
                                                    {!!isNotParticipant && (
                                                        <ArchetypeInputComparison
                                                            modelVariable={
                                                                modelVariable
                                                            }
                                                            archetypes={
                                                                archetypes
                                                            }
                                                            formatVariableValue={
                                                                formatVariableValue
                                                            }
                                                        />
                                                    )}
                                                </WizardVariableSettingsDisplay>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </AccordionCardWrapper>
                    ))}
        </div>
    );
};

export const AccordionCardSettingsDisplay = React.memo(
    AccordionCardSettingsDisplayComponent,
);
