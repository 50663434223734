import React, { useMemo } from "react";
import {
    CardWidgetWrapper,
    ChartDisplay,
    DashboardWidgetWrapper,
    DefaultTableWrapper,
    FavoriteToggleIcon,
} from "../shared";
import {
    useModelTimespan,
    usePythonValuesYearly,
    useVariableWatchlist,
    useWatchlistVariables,
} from "../state";
import {
    ChartBlock,
    FormattedChartValue,
    InteractiveModuleChartType,
} from "../types";
import { useFormatVariableValue } from "@/hooks";
import { getChartValues } from "../utils";
import { DefaultVariableDataTableRow } from "../data/DefaultVariableDataTableRow";
import { ModelVariableDataType } from "@/models";

const InteractiveSectionFavoritesComponent = ({}: {}) => {
    const watchlist = useVariableWatchlist();
    const watchlistVariables = useWatchlistVariables();
    const modelTimespan = useModelTimespan();
    const formatVariableValue = useFormatVariableValue();
    const pythonValuesYearly = usePythonValuesYearly();

    const chartBlock = useMemo<ChartBlock>(() => {
        return {
            id: "",
            label: "",
            chart_type: InteractiveModuleChartType.LINE,
            time_index: modelTimespan,
            time_increment: "year",
            stacked: false,
            interpolation: "linear",
            model_block_id: "",
            variableIds: [],
            weight: 0,
        };
    }, [modelTimespan]);

    const watchlistChartValuesMap = useMemo<
        Record<string, Record<string, FormattedChartValue[]>>
    >(() => {
        return !!watchlistVariables && !!pythonValuesYearly
            ? watchlistVariables
                  ?.filter(
                      (modelVariable) =>
                          modelVariable.data_type ===
                          ModelVariableDataType.Number,
                  )
                  .reduce(
                      (map, variable) => ({
                          ...map,
                          [variable.id]: getChartValues(
                              [variable],
                              chartBlock,
                              {
                                  [variable.id]:
                                      pythonValuesYearly[variable.id] ?? [],
                              },
                              formatVariableValue,
                          ),
                      }),
                      {},
                  )
            : {};
    }, [watchlistVariables, pythonValuesYearly, chartBlock]);

    return (
        <div className="flex w-full flex-col space-y-6">
            <div className="flex items-center justify-between space-x-2">
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Watchlist"}
                </h1>
            </div>

            {!!watchlist &&
                !!watchlistVariables &&
                watchlistVariables?.length > 0 &&
                !!pythonValuesYearly &&
                Object.keys(pythonValuesYearly)?.length > 0 && (
                    <CardWidgetWrapper>
                        <div>
                            <DefaultTableWrapper
                                header={"variable"}
                                columnLabels={
                                    Object.values(pythonValuesYearly)[0]?.map(
                                        (_, index) => (index + 1).toString(),
                                    ) || []
                                }
                            >
                                {watchlistVariables?.map((modelVariable, i) => (
                                    <DefaultVariableDataTableRow
                                        key={modelVariable.id}
                                        modelVariable={modelVariable}
                                        variableValues={
                                            pythonValuesYearly[
                                                modelVariable.id
                                            ] || []
                                        }
                                        index={i}
                                        showResampleFunction={true}
                                        formatVariableValue={
                                            formatVariableValue
                                        }
                                    />
                                ))}
                            </DefaultTableWrapper>
                        </div>
                    </CardWidgetWrapper>
                )}

            {!!watchlist &&
                !!watchlistVariables &&
                watchlistVariables?.length > 0 &&
                !!watchlistChartValuesMap &&
                Object.keys(watchlistChartValuesMap)?.length > 0 &&
                !!pythonValuesYearly &&
                Object.keys(pythonValuesYearly)?.length > 0 && (
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                        {watchlistVariables
                            ?.filter(
                                (modelVariable) =>
                                    modelVariable.data_type ===
                                    ModelVariableDataType.Number,
                            )
                            .map((modelVariable) => (
                                <DashboardWidgetWrapper key={modelVariable.id}>
                                    <div className="relative">
                                        <ChartDisplay
                                            modelTimespan={modelTimespan}
                                            chartBlock={{
                                                ...chartBlock,
                                                label: modelVariable.label,
                                            }}
                                            chartValues={
                                                watchlistChartValuesMap[
                                                    modelVariable.id
                                                ] ?? {}
                                            }
                                            setChartTimeIndex={(
                                                timeIndex: number,
                                            ) => null}
                                        />
                                        <span className="absolute right-0 top-0 p-2">
                                            <FavoriteToggleIcon
                                                modelVariableId={
                                                    modelVariable.id
                                                }
                                            />
                                        </span>
                                    </div>
                                </DashboardWidgetWrapper>
                            ))}
                    </div>
                )}
        </div>
    );
};

export const InteractiveSectionFavorites = React.memo(
    InteractiveSectionFavoritesComponent,
);
