import React from "react";
import { DiscoveryMenuButton } from "./DiscoveryMenuButton";

export const DiscoveryMenuHeader = ({
    isDropdownMenuOpen,
    setIsDropdownMenuOpen,
    dropdownMenuSection,
    setDropdownMenuSection,
}: {
    isDropdownMenuOpen: boolean;
    setIsDropdownMenuOpen: (isDropdownMenuOpen: boolean) => void;
    dropdownMenuSection: string;
    setDropdownMenuSection: (dropdownMenuSection: string) => void;
}) => {
    return (
        <div
            className={`discovery-middle border-box flex h-10 cursor-pointer flex-row items-center
            justify-center border-b border-slate-200 bg-slate-200/50 px-3 text-sm
            hover:bg-slate-200/75 ${isDropdownMenuOpen ? "bg-slate-200/90" : ""}`}
            onClick={(e) => {
                e.stopPropagation();
                setIsDropdownMenuOpen(!isDropdownMenuOpen);
            }}
        >
            <DiscoveryMenuButton
                label={`Source Filters`}
                isSelected={
                    dropdownMenuSection == "source" && isDropdownMenuOpen
                }
                handleClick={() => {
                    if (dropdownMenuSection !== "source") {
                        setIsDropdownMenuOpen(true);
                        setDropdownMenuSection("source");
                    } else {
                        setIsDropdownMenuOpen(!isDropdownMenuOpen);
                    }
                }}
            />
            <DiscoveryMenuButton
                label={`Data Filters`}
                isSelected={dropdownMenuSection == "data" && isDropdownMenuOpen}
                handleClick={() => {
                    if (dropdownMenuSection !== "data") {
                        setIsDropdownMenuOpen(true);
                        setDropdownMenuSection("data");
                    } else {
                        setIsDropdownMenuOpen(!isDropdownMenuOpen);
                    }
                }}
            />
            <DiscoveryMenuButton
                label={`Display Options`}
                isSelected={
                    dropdownMenuSection == "display" && isDropdownMenuOpen
                }
                handleClick={() => {
                    if (dropdownMenuSection !== "display") {
                        setIsDropdownMenuOpen(true);
                        setDropdownMenuSection("display");
                    } else {
                        setIsDropdownMenuOpen(!isDropdownMenuOpen);
                    }
                }}
            />
        </div>
    );
};
