import React, { useCallback } from "react";
import { InputDataItem, InputDataSettingType } from "../types";
import {
    InputChartIconMenu,
    SimpleInputChartIconMenu,
    useInputSettingKey,
} from "./InputChartIconMenu";
import { VariableSettingsDisplayProps } from "./props";
import { useInteractiveVariableSetting } from "./useInteractiveVariableSetting";
import {
    getSettingsVariableChartProps,
    SettingsVariableChart,
} from "./SettingsVariableChart";
import {
    FavoriteToggleIcon,
    FormSwitch,
    NestedCardWidgetWrapper,
    ScrollableWrapper,
} from "../shared";
import { adjustNumericalInputValue } from "../utils";
import { useColorModeClass } from "../useInterfaceState";
import { WizardNumericVariableDisplay } from "./WizardNumericVariableDisplay";
import { ModelVariable } from "@/models";
import { VariableValueFormatFunction } from "@/hooks";

const useBooleanVariableSettingFunctions = ({
    inputDataItem,
    setInputDataItem,
}: {
    inputDataItem: InputDataItem;
    setInputDataItem: (inputDataItem: InputDataItem) => Promise<void>;
}) => {
    const handleToggleBooleanSettingType = useCallback(() => {
        setInputDataItem({
            ...inputDataItem,
            setting_type:
                inputDataItem.setting_type ==
                InputDataSettingType["boolean constant"]
                    ? InputDataSettingType["boolean series"]
                    : InputDataSettingType["boolean constant"],
        });
    }, [inputDataItem, setInputDataItem]);

    const handleToggleBooleanValue = useCallback(
        (key: string, index?: number) => {
            return () => {
                if (index == undefined) {
                    setInputDataItem({
                        ...inputDataItem,
                        [key]: inputDataItem[key] === 1 ? 0 : 1,
                    });
                } else {
                    setInputDataItem({
                        ...inputDataItem,
                        [key]: (inputDataItem[key] as number[]).map(
                            (item, i) =>
                                i === index ? (item === 1 ? 0 : 1) : item,
                        ),
                    });
                }
            };
        },
        [inputDataItem, setInputDataItem],
    );

    return {
        handleToggleBooleanSettingType,
        handleToggleBooleanValue,
    };
};

const useNumericVariableSettingFunctions = ({
    inputDataItem,
    setInputDataItem,
    modelVariable,
    editedInputDataItem,
    setEditedInputDataItem,
    rangeStep,
    setActiveNumberInputKey,
    formatVariableValue,
}: {
    inputDataItem: InputDataItem;
    setInputDataItem: (inputDataItem: InputDataItem) => Promise<void>;
    modelVariable: ModelVariable;
    editedInputDataItem: InputDataItem;
    setEditedInputDataItem: (inputDataItem: InputDataItem) => void;
    rangeStep: number;
    setActiveNumberInputKey: (key: string) => void;
    formatVariableValue: VariableValueFormatFunction;
}) => {
    const getValueByKeyAndIndex = useCallback(
        (key: string, index?: number) => {
            if (index == undefined) {
                const value = !!editedInputDataItem
                    ? editedInputDataItem[key]
                    : inputDataItem[key];
                return value !== null ? Number(value) : null;
            } else {
                const value = !!editedInputDataItem
                    ? editedInputDataItem[key][index]
                    : inputDataItem[key][index];
                return value !== null ? Number(value) : null;
            }
        },
        [inputDataItem, editedInputDataItem],
    );

    const getFormattedValue = useCallback(
        (value: number) => {
            return formatVariableValue(
                modelVariable.unit,
                value,
                modelVariable.is_integer,
            );
        },
        [modelVariable],
    );

    const handleOnChange = useCallback(
        (key: string, index?: number) => {
            if (index == undefined) {
                return (value: number) =>
                    setEditedInputDataItem({
                        ...inputDataItem,
                        [key]: value,
                    });
            } else {
                return (value: number) =>
                    setEditedInputDataItem({
                        ...inputDataItem,
                        [key]: (inputDataItem[key] as number[]).map(
                            (item, i) => (i === index ? value : item),
                        ),
                    });
            }
        },
        [inputDataItem],
    );

    const handleOnClick = useCallback(() => {
        if (!!editedInputDataItem) {
            setInputDataItem(editedInputDataItem);
        }
    }, [editedInputDataItem, setInputDataItem]);

    const handleOnBlur = useCallback(
        (key: string, index?: number) => {
            if (index == undefined) {
                return () => {
                    if (
                        !!editedInputDataItem &&
                        editedInputDataItem[key] !== inputDataItem[key] &&
                        editedInputDataItem[key] !== null
                    ) {
                        const newValue = adjustNumericalInputValue(
                            modelVariable.minimum,
                            modelVariable.maximum,
                            rangeStep,
                            Number(editedInputDataItem[key]),
                        );
                        setInputDataItem({
                            ...editedInputDataItem,
                            [key]: newValue,
                        });
                    } else {
                        setEditedInputDataItem(undefined);
                        setActiveNumberInputKey(undefined);
                    }
                };
            } else {
                return () => {
                    if (
                        !!editedInputDataItem &&
                        editedInputDataItem[key][index] !==
                            inputDataItem[key][index] &&
                        editedInputDataItem[key][index] !== null
                    ) {
                        const newValue = adjustNumericalInputValue(
                            modelVariable.minimum,
                            modelVariable.maximum,
                            rangeStep,
                            Number(editedInputDataItem[key][index]),
                        );
                        setInputDataItem({
                            ...editedInputDataItem,
                            [key]: (editedInputDataItem[key] as number[]).map(
                                (item, i) => (i === index ? newValue : item),
                            ),
                        });
                    } else {
                        setEditedInputDataItem(undefined);
                        setActiveNumberInputKey(undefined);
                    }
                };
            }
        },
        [
            inputDataItem,
            editedInputDataItem,
            modelVariable,
            rangeStep,
            setInputDataItem,
        ],
    );

    return {
        handleOnBlur,
        handleOnChange,
        handleOnClick,
        getValueByKeyAndIndex,
        getFormattedValue,
    };
};

const WizardVariableSettingsDisplayComponent = ({
    modelVariable,
    inputDataItem,
    setInputDataItem,
    showSettingsCharts,
    formatVariableValue,
    fullChartIconMenu,
    children,
}: VariableSettingsDisplayProps & {
    fullChartIconMenu?: boolean;
    children?: React.ReactNode;
}) => {
    const colorModeClass = useColorModeClass();

    const {
        rangeStep,
        editedInputDataItem,
        setEditedInputDataItem,
        isBusy,
        setIsBusy,
        settingType,
        showNumericalFormFields,
        formFieldKeys,
        activeNumberInputKey,
        setActiveNumberInputKey,
    } = useInteractiveVariableSetting({ modelVariable, inputDataItem });

    const handleSetInputDataItem = useCallback(
        async (newInputDataItem: InputDataItem) => {
            setIsBusy(true);
            setEditedInputDataItem(newInputDataItem);
            await setInputDataItem(newInputDataItem);
        },
        [setInputDataItem],
    );

    const chartProps = getSettingsVariableChartProps({
        modelVariableMin: modelVariable.minimum,
        modelVariableMax: modelVariable.maximum,
        inputDataItem,
        settingType,
    });

    const { inputSettingKey, handleSetInputKey } = useInputSettingKey(
        !!editedInputDataItem ? editedInputDataItem : inputDataItem,
        handleSetInputDataItem,
    );

    const { handleToggleBooleanSettingType, handleToggleBooleanValue } =
        useBooleanVariableSettingFunctions({
            inputDataItem,
            setInputDataItem: handleSetInputDataItem,
        });

    const {
        handleOnBlur,
        handleOnChange,
        handleOnClick,
        getValueByKeyAndIndex,
        getFormattedValue,
    } = useNumericVariableSettingFunctions({
        inputDataItem,
        setInputDataItem: handleSetInputDataItem,
        modelVariable,
        editedInputDataItem,
        setEditedInputDataItem,
        rangeStep,
        setActiveNumberInputKey,
        formatVariableValue,
    });

    return (
        <NestedCardWidgetWrapper>
            <div className="space-y-2 px-4 pt-4 text-slate-600 dark:text-slate-400">
                <div className="relative flex items-center justify-between text-base font-semibold">
                    <span className="flex items-center">
                        {modelVariable.label}
                        <span className="flex pl-2">
                            <FavoriteToggleIcon
                                modelVariableId={modelVariable.id}
                            />
                        </span>
                    </span>
                    {children}
                </div>
                {!!modelVariable.description?.length && (
                    <div className="text-sm text-slate-500 dark:text-slate-500">
                        {modelVariable.description}
                    </div>
                )}
            </div>
            <ScrollableWrapper>
                <div className="p-4 text-slate-600 dark:text-slate-400">
                    <div
                        className={`flex flex-col space-x-0 space-y-4 text-sm md:flex-row md:justify-between
                        md:space-x-4 md:space-y-0 ${showSettingsCharts ? "" : ""}`}
                    >
                        <div
                            className={
                                "flex flex-col space-y-3 md:min-w-[500px]"
                            }
                        >
                            <div className="font-bold text-slate-700 dark:text-slate-300">
                                Values
                            </div>
                            <div className="space-y-3">
                                {!!showNumericalFormFields
                                    ? formFieldKeys?.map((key) => (
                                          <React.Fragment key={key}>
                                              {settingType !==
                                              InputDataSettingType[
                                                  "step interval"
                                              ] ? (
                                                  <WizardNumericVariableDisplay
                                                      key={key}
                                                      label={
                                                          key !==
                                                          "numerical_value"
                                                              ? key
                                                              : "value"
                                                      }
                                                      formattedValue={getFormattedValue(
                                                          getValueByKeyAndIndex(
                                                              key,
                                                          ),
                                                      )}
                                                      isBusy={isBusy}
                                                      isActive={
                                                          activeNumberInputKey ==
                                                          key
                                                      }
                                                      rangeMin={
                                                          modelVariable.minimum
                                                      }
                                                      rangeMax={
                                                          modelVariable.maximum
                                                      }
                                                      rangeStep={rangeStep}
                                                      isDisabled={
                                                          isBusy ||
                                                          activeNumberInputKey !==
                                                              undefined
                                                      }
                                                      value={getValueByKeyAndIndex(
                                                          key,
                                                      )}
                                                      handleOnChange={handleOnChange(
                                                          key,
                                                      )}
                                                      handleOnClick={
                                                          handleOnClick
                                                      }
                                                      handleOnBlur={handleOnBlur(
                                                          key,
                                                      )}
                                                      handleSetActive={() => {
                                                          if (
                                                              activeNumberInputKey ==
                                                              undefined
                                                          ) {
                                                              setEditedInputDataItem(
                                                                  inputDataItem,
                                                              );
                                                              setActiveNumberInputKey(
                                                                  key,
                                                              );
                                                          }
                                                      }}
                                                  />
                                              ) : (
                                                  (
                                                      inputDataItem[
                                                          key
                                                      ] as number[]
                                                  )?.map((stepValue, index) => (
                                                      <WizardNumericVariableDisplay
                                                          key={`${key}-${index}`}
                                                          label={`Year ${index + 1}`}
                                                          formattedValue={getFormattedValue(
                                                              getValueByKeyAndIndex(
                                                                  key,
                                                                  index,
                                                              ),
                                                          )}
                                                          isBusy={isBusy}
                                                          isActive={
                                                              activeNumberInputKey ==
                                                              `${key}-${index}`
                                                          }
                                                          rangeMin={
                                                              modelVariable.minimum
                                                          }
                                                          rangeMax={
                                                              modelVariable.maximum
                                                          }
                                                          rangeStep={rangeStep}
                                                          isDisabled={
                                                              isBusy ||
                                                              activeNumberInputKey !==
                                                                  undefined
                                                          }
                                                          value={getValueByKeyAndIndex(
                                                              key,
                                                              index,
                                                          )}
                                                          handleOnChange={handleOnChange(
                                                              key,
                                                              index,
                                                          )}
                                                          handleOnClick={
                                                              handleOnClick
                                                          }
                                                          handleOnBlur={handleOnBlur(
                                                              key,
                                                              index,
                                                          )}
                                                          handleSetActive={() => {
                                                              if (
                                                                  activeNumberInputKey ==
                                                                  undefined
                                                              ) {
                                                                  setEditedInputDataItem(
                                                                      inputDataItem,
                                                                  );
                                                                  setActiveNumberInputKey(
                                                                      `${key}-${index}`,
                                                                  );
                                                              }
                                                          }}
                                                      />
                                                  ))
                                              )}
                                          </React.Fragment>
                                      ))
                                    : formFieldKeys?.map((key) => (
                                          <React.Fragment key={key}>
                                              {settingType ==
                                              InputDataSettingType[
                                                  "boolean constant"
                                              ] ? (
                                                  <div className="flex items-center space-x-2">
                                                      <div className="w-12 min-w-12 font-normal">
                                                          {"Value"}
                                                      </div>
                                                      <div className="flex items-center pl-8">
                                                          <FormSwitch
                                                              label={""}
                                                              isChecked={
                                                                  inputDataItem[
                                                                      key
                                                                  ] === 1
                                                              }
                                                              isDisabled={
                                                                  isBusy
                                                              }
                                                              handleToggle={handleToggleBooleanValue(
                                                                  key,
                                                              )}
                                                          />
                                                      </div>
                                                  </div>
                                              ) : (
                                                  <div
                                                      className={
                                                          "flex flex-row space-x-2 space-y-0 md:flex-col md:space-x-0 md:space-y-3"
                                                      }
                                                  >
                                                      {(
                                                          inputDataItem[
                                                              key
                                                          ] as number[]
                                                      )?.map(
                                                          (
                                                              stepValue,
                                                              index,
                                                          ) => (
                                                              <div
                                                                  key={`${key}-${index}`}
                                                                  className="flex items-center space-x-2 md:space-x-6"
                                                                  title={`time horizon ${index + 1}`}
                                                              >
                                                                  <div className="hidden font-normal md:block md:w-14 md:min-w-14">{`Year ${index + 1}`}</div>
                                                                  <FormSwitch
                                                                      label={""}
                                                                      isChecked={
                                                                          inputDataItem[
                                                                              key
                                                                          ][
                                                                              index
                                                                          ] ===
                                                                          1
                                                                      }
                                                                      isDisabled={
                                                                          isBusy
                                                                      }
                                                                      handleToggle={handleToggleBooleanValue(
                                                                          key,
                                                                          index,
                                                                      )}
                                                                  />
                                                              </div>
                                                          ),
                                                      )}
                                                  </div>
                                              )}
                                          </React.Fragment>
                                      ))}
                            </div>
                        </div>

                        <div className="flex w-full justify-between space-x-2 md:justify-evenly">
                            <div className={"flex flex-col space-y-3"}>
                                <div className="whitespace-nowrap font-bold text-slate-700 dark:text-slate-300">
                                    Change Pattern
                                </div>
                                {!!showNumericalFormFields ? (
                                    !fullChartIconMenu ? (
                                        <SimpleInputChartIconMenu
                                            inputSettingKey={inputSettingKey}
                                            handleSetInputKey={
                                                handleSetInputKey
                                            }
                                            isDisabled={
                                                isBusy ||
                                                activeNumberInputKey !==
                                                    undefined
                                            }
                                        />
                                    ) : (
                                        <InputChartIconMenu
                                            inputSettingKey={inputSettingKey}
                                            handleSetInputKey={
                                                handleSetInputKey
                                            }
                                            isDisabled={
                                                isBusy ||
                                                activeNumberInputKey !==
                                                    undefined
                                            }
                                        />
                                    )
                                ) : (
                                    <div
                                        className={`${!!fullChartIconMenu ? "min-w-52" : ""}`}
                                    >
                                        <FormSwitch
                                            label={"Series"}
                                            isChecked={
                                                settingType ==
                                                InputDataSettingType[
                                                    "boolean series"
                                                ]
                                            }
                                            isDisabled={isBusy}
                                            handleToggle={
                                                handleToggleBooleanSettingType
                                            }
                                        />
                                    </div>
                                )}
                            </div>

                            {showSettingsCharts && (
                                <div className={""}>
                                    <div
                                        className={`min-w-64 max-w-64 rounded-md ${!fullChartIconMenu ? "min-w-72 max-w-72" : ""}`}
                                    >
                                        {!!showNumericalFormFields && (
                                            <SettingsVariableChart
                                                {...chartProps}
                                                colorModeClass={colorModeClass}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ScrollableWrapper>
        </NestedCardWidgetWrapper>
    );
};

export const WizardVariableSettingsDisplay = React.memo(
    WizardVariableSettingsDisplayComponent,
);
