import DropdownAvatar from "@/Layouts/DropdownAvatar";
import ApplicationLogo from "@/components/ApplicationLogo";
import NavLink from "@/components/NavLink";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { Auth } from "@/inertia-utils/types";
import React from "react";
import { Course } from "../types";
import { ChevronLeft } from "lucide-react";

type Props = {
    auth: Auth;
    children: React.ReactNode | React.ReactNode[];
    course: Course;
};

export default function StudentHeader({ auth, children, course }: Props) {
    const { props } = usePage();

    return (
        <>
            <div
                className="fixed top-0 z-[99999] flex h-16 w-full items-center justify-between border-b
                    border-gray-200 bg-white pl-2 pr-8 shadow-md"
            >
                <div className="hidden h-16 space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <div className="flex shrink-0 items-center">
                        <NavLink
                            href={sapienRoute("dashboard")}
                            active={isCurrent("dashboard", props.route)}
                        >
                            <ApplicationLogo className="block h-9 w-auto text-gray-500" />
                        </NavLink>
                    </div>
                    <div className="hidden space-x-8 sm:-my-px sm:flex">
                        {isCurrent(
                            props.route,
                            "e-learning.participant.lesson.show",
                        ) && (
                            <NavLink
                                active={false}
                                href={sapienRoute(
                                    "e-learning.participant.course.show",
                                    {
                                        course: course.slug,
                                    },
                                )}
                            >
                                <span className="flex items-center space-x-1">
                                    <span>
                                        <ChevronLeft className="h-6 w-6" />
                                    </span>
                                    <span>
                                        {course.courseMetadata?.course_title ||
                                            course.title}
                                    </span>
                                </span>
                            </NavLink>
                        )}
                        <NavLink
                            href={sapienRoute("dashboard")}
                            active={isCurrent("dashboard", props.route)}
                        >
                            Dashboard
                        </NavLink>
                    </div>
                </div>
                <div>{children}</div>
                <div>
                    <DropdownAvatar
                        auth={auth}
                        logoutRoute="e-learning.participant.logout"
                    />
                </div>
            </div>
        </>
    );
}
