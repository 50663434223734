import React from "react";
import { Course } from "../types";
import { Link } from "@inertiajs/react";
import {
    ArrowUpRightIcon,
    DocumentDuplicateIcon,
    PencilIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";

interface CourseCardProps {
    course: Course;
}

function secondsToDuration(sec: number): string {
    const roundedSeconds = Math.round(sec / 900) * 900; // 900 seconds = 15 minutes
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds - hours * 3600) / 60);

    let duration = "";

    if (hours > 0) {
        duration += hours + "h ";
    }

    if (minutes > 0 || duration === "") {
        // always display minutes if no hours are present
        duration += minutes + "m";
    }

    return duration.trim();
}
export const CourseCard = ({ course }: CourseCardProps) => {
    const backgroundImage =
        course?.theme?.image_url ||
        "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/noun-image-5275349.png";

    function deleteCourse(course: string) {
        if (confirm("Are you sure you want to delete this course?")) {
            SapienInertia.delete(
                sapienRoute("e-learning.courses.destroy", { course: course }),
            );
        }
    }

    return (
        <Link
            href={sapienRoute("e-learning.courses.edit", {
                course: course.id,
            })}
            data-testid={`edit-${course.slug}`}
            className="flex items-center justify-between rounded-md bg-slate-50 p-4 text-sm shadow-none
                transition-all duration-300 hover:bg-white hover:shadow-lg"
        >
            <div className="flex w-full items-center space-x-4">
                <div
                    className={`h-16 w-full max-w-20 rounded-md ${
                        !!course?.theme?.image_url
                            ? "bg-cover bg-center bg-no-repeat shadow-lg"
                            : "border-2 bg-contain bg-center bg-no-repeat"
                    }`}
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                    }}
                ></div>
                <div className="flex flex-col space-y-2">
                    <h2 className="text-lg font-bold">{course.courseMetadata?.course_title || course.title}</h2>{" "}
                    <div className="flex text-sm text-[#6B7280]">
                        {!!course.lessons_count && (
                            <p>
                                {course.lessons_count} Lesson
                                {course.lessons_count > 1 && "s"}
                            </p>
                        )}
                        {!!course.estimated_time && (
                            <>
                                <p className="mx-2">•</p>
                                <p>
                                    Approx{" "}
                                    {secondsToDuration(course.estimated_time)}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex space-x-2 p-4">
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setClonedCourse();
                    }}
                    className="rounded-full p-4 transition-all hover:text-blue-600 hover:shadow-md"
                >
                    <DocumentDuplicateIcon className="h-6 w-6" />
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteCourse(course.id);
                    }}
                    className="rounded-full p-4 transition-all hover:text-red-600 hover:shadow-md"
                >
                    <TrashIcon className="h-6 w-6" />
                </button>
            </div>
        </Link>
    );
};
