import { useImageIsText } from "@/util/useImageIsText";
import React from "react";

type Props = {
    children: JSX.Element;
    imageUrl: string;
    isBeingRequired: boolean;
    setRequiredId: (g) => void;
    unsetRequiredId: () => void;
    requirement: App.Data.PrerequisiteData | null;
};
export default function DashboardCard({
    children,
    imageUrl,
    isBeingRequired,
    requirement,
    setRequiredId,
    unsetRequiredId,
}: Props) {
    const imageIsText = useImageIsText({ imageUrl });
    const hasRequirment = !!requirement;

    return (
        <li
            className="group/has-requirement relative list-none overflow-hidden rounded-lg
                border-gray-100 bg-white bg-center bg-no-repeat p-6 pt-48 shadow
                data-[is-required=true]:bg-blue-100 data-[is-required=true]:before:bg-blue-600
                md:col-span-1 lg:pt-60"
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: imageIsText ? "contain" : "cover",
            }}
            data-is-required={isBeingRequired}
            data-has-requiment={hasRequirment}
        >
            {isBeingRequired && (
                <div className="absolute inset-0 z-[99999] flex items-center justify-center bg-blue-600/75">
                    <div className="text-center text-white">
                        <h2 className="text-2xl font-semibold">Required</h2>
                        <p className="text-sm">
                            This item is required to continue
                        </p>
                    </div>
                </div>
            )}
            {hasRequirment && (
                <div
                    className="absolute inset-0 z-[99999] flex cursor-pointer items-center justify-center
                        bg-blue-600/75 opacity-0 group-hover/has-requirement:opacity-100"
                    onMouseEnter={setRequiredId}
                    onMouseLeave={unsetRequiredId}
                >
                    <div className="text-center text-white">
                        <h2 className="text-2xl font-semibold">Requirement</h2>
                        <p className="text-sm">
                            You must first complete {requirement.required.title}
                        </p>
                    </div>
                </div>
            )}
            {children}
        </li>
    );
}
