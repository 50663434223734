import React, { FC, ReactNode } from "react";
import { Link } from "@inertiajs/react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { Icon } from "react-icons-kit";
import { layout } from "react-icons-kit/feather/layout";
import { pieChart } from "react-icons-kit/feather/pieChart";
import { box } from "react-icons-kit/feather/box";
import { clipboard } from "react-icons-kit/feather/clipboard";
import { sliders } from "react-icons-kit/feather/sliders";
import { compass } from "react-icons-kit/feather/compass";
import { ic_lightbulb_outline } from "react-icons-kit/md/ic_lightbulb_outline";

function SubNavLink({ href, active, children }) {
    return (
        <Link
            href={href}
            className={
                active
                    ? `inline-flex items-center text-nowrap rounded-md bg-indigo-400 bg-opacity-15 px-2
                        py-1 text-indigo-800 transition duration-150 ease-in-out hover:bg-indigo-400
                        hover:bg-opacity-10 focus:outline-none focus:ring-0`
                    : `inline-flex items-center text-nowrap rounded-md px-2 py-1 text-slate-500
                        transition duration-150 ease-in-out hover:bg-indigo-400 hover:bg-opacity-10
                        hover:text-indigo-800 focus:outline-none focus:ring-0`
            }
        >
            {children}
        </Link>
    );
}

export const SimBuilderTopNav: FC<{
    title: string;
    slug: string;
    id: string;
    isCourse: boolean;
    children?: ReactNode;
}> = ({ title, slug, id, isCourse, children }) => {
    const { props } = usePage();
    return (
        <div className="flex flex-col items-center gap-4 md:flex-row md:gap-8">
            {!isCourse && (
                <>
                    <div>
                        {isCurrent("simulation.projects.show", props.route) ? (
                            <span className="text-nowrap text-xl font-semibold text-slate-900">
                                {title}
                            </span>
                        ) : (
                            <Link
                                href={sapienRoute("simulation.projects.show", {
                                    project: slug,
                                })}
                            >
                                <div className="text-nowrap text-xl font-semibold text-slate-900 hover:underline">
                                    {title}
                                </div>
                            </Link>
                        )}
                    </div>
                    <div className="flex flex-wrap gap-1 text-sm font-semibold">
                        <SubNavLink
                            href={sapienRoute("simulation.projects.detail", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "simulation.projects.detail",
                                props.route,
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={layout}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Detail</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("facilitation.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "facilitation.index",
                                props.route,
                                ["facilitation.show"],
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={clipboard}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Facilitation</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("discovery.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent("discovery.index", props.route, [
                                "discovery.show-data",
                                "discovery.show-statistics",
                            ])}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={ic_lightbulb_outline}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Discovery</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("interactive.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent("interactive.index", props.route)}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={compass}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Interactive</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("model-builder.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "model-builder.index",
                                props.route,
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={pieChart}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Model Builder</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("model-builder.structure.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "model-builder.structure.index",
                                props.route,
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={sliders}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Structure</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("model-builder.wizard.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "model-builder.wizard.index",
                                props.route,
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={box}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Wizard</span>
                        </SubNavLink>
                    </div>
                </>
            )}
            {!!isCourse && (
                <>
                    <div>
                        <Link
                            href={sapienRoute("e-learning.courses.edit", {
                                course: id,
                            })}
                        >
                            <div className="text-nowrap text-xl font-semibold text-slate-900 hover:underline">
                                {title}
                            </div>
                        </Link>
                    </div>
                    <div className="flex flex-wrap gap-1 text-sm font-semibold">
                        <SubNavLink
                            href={sapienRoute("facilitation.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "facilitation.index",
                                props.route,
                                ["facilitation.show"],
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={clipboard}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Facilitation</span>
                        </SubNavLink>
                    </div>
                </>
            )}
        </div>
    );
};
