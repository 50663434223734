import React, { useCallback, useMemo } from "react";
import { RegressionChart } from "./Charts";
import { StatisticsTable } from "./StatisticsTable";
import {
    defaultStatisticsObject,
    RegressionProps,
    RegressionType,
} from "./types";
import { SubsectionWrapper, VariableMultiSelect } from "./SharedComponents";
import _ from "lodash";

const singleInputRegressionTypes = {
    LINEAR_SIMPLE: true,
    LINEAR_MULTIPLE: false,
    LINEAR_MULTIVARIATE: false,
    POLYNOMIAL: true,
};
const singleOutputRegressionTypes = {
    LINEAR_SIMPLE: true,
    LINEAR_MULTIPLE: true,
    LINEAR_MULTIVARIATE: false,
    POLYNOMIAL: true,
};

function generateEquationHtml(
    regression_type: RegressionType,
    x_variables: string[],
    y_variables: string[],
    coefficients: number[][],
    intercepts: number[],
    degree?: number,
): string[] {
    if (regression_type == RegressionType.polynomial) {
        let polynomialEquation = `<b>${y_variables[0].replace(/ /g, "&nbsp;")}</b> &equals; ${intercepts[0].toFixed(2)}`;
        for (let i = 0; i < degree; i++) {
            polynomialEquation += ` &plus; (${coefficients[0][i + 1] < 0.01 ? coefficients[0][i + 1].toFixed(4) : coefficients[0][i + 1].toFixed(2)})&nbsp;&times;&nbsp;<b>${x_variables[0].replace(/ /g, "&nbsp;")}</b><sup>${i > 0 ? i + 1 : ""}</sup>`;
        }
        return [polynomialEquation];
    } else {
        let equations = [];
        for (let yIndex = 0; yIndex < y_variables.length; yIndex++) {
            let equation = `<b>${y_variables[yIndex].replace(/ /g, "&nbsp;")}</b> &equals; ${intercepts[yIndex].toFixed(2)}`;
            for (let xIndex = 0; xIndex < x_variables.length; xIndex++) {
                equation += ` &plus; (${coefficients[yIndex][xIndex].toFixed(2)}) &times; <b>${x_variables[xIndex].replace(/ /g, "&nbsp;")}</b>`;
            }
            equations = [...equations, equation];
        }
        return equations;
    }
}

const RegressionVariableMenu = ({
    label,
    isSingleSelect,
    handleSetVariables,
    variables,
    selectedVariables,
    isDisabled,
}: {
    label: string;
    isSingleSelect: boolean;
    handleSetVariables: (selectedVariables: string[]) => void;
    variables: string[];
    selectedVariables: string[];
    isDisabled: boolean;
}) => {
    return !!isSingleSelect ? (
        <>
            <div>{`${label} Variable`}</div>
            <select
                className="rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm text-blue-700
                    marker:border focus:border-slate-300 focus:outline-none focus:ring-0
                    dark:border-slate-800 dark:bg-slate-800/20 dark:text-blue-700
                    dark:focus:border-slate-700"
                value={selectedVariables[0]}
                disabled={isDisabled}
                onChange={(e) => {
                    e.stopPropagation();
                    handleSetVariables([e.target.value]);
                }}
            >
                {variables?.map((variable) => (
                    <option
                        key={variable}
                        value={variable}
                        className="dark:bg-slate-800"
                    >
                        {variable}
                    </option>
                ))}
            </select>
        </>
    ) : (
        <>
            <div>{`${label} Variables (1-3)`}</div>
            <VariableMultiSelect
                variables={variables}
                selectedVariables={selectedVariables}
                handleSetVariables={handleSetVariables}
                isDisabled={isDisabled}
            />
            <div className="text-blue-700 dark:text-blue-700">
                {selectedVariables?.map((variable) => (
                    <div key={variable}>{variable}</div>
                ))}
            </div>
        </>
    );
};

const RegressionDisplayComponent = ({
    variables,
    regressionParameters,
    setRegressionParameters,
    regressionResponse,
}: {
    variables: string[];
} & RegressionProps) => {
    const { regressionData, regressionSummary, regressionDataPoints } =
        regressionResponse;

    const isPending = useMemo(() => {
        const isSynced =
            !!regressionParameters &&
            !!regressionSummary &&
            Object.keys(regressionParameters)?.length > 0 &&
            Object.keys(regressionSummary)?.length > 0 &&
            regressionParameters.regression_type ==
                regressionSummary.regression_type &&
            regressionParameters.degree == regressionSummary.degree &&
            _.isEqual(
                regressionParameters.x_variables,
                regressionSummary.x_variables,
            ) &&
            _.isEqual(
                regressionParameters.y_variables,
                regressionSummary.y_variables,
            );
        return !isSynced;
    }, [regressionParameters, regressionSummary]);

    const handleSetVariablesByKey = useCallback(
        (selectedVariables: string[], key: "x_variables" | "y_variables") => {
            if (
                isPending ||
                selectedVariables.length < 1 ||
                selectedVariables.length > 3
            ) {
                return;
            }

            const checkKey =
                key == "x_variables" ? "y_variables" : "x_variables";
            if (
                selectedVariables.some((variable) =>
                    regressionParameters[checkKey].includes(variable),
                )
            ) {
                return;
            }

            const orderedSelectedVariables = variables.filter((variable) =>
                selectedVariables.includes(variable),
            );

            setRegressionParameters({
                ...regressionParameters,
                [key]: orderedSelectedVariables,
            });
        },
        [variables, regressionParameters, isPending],
    );

    return (
        <>
            <SubsectionWrapper title={"Settings"}>
                <div className="grid grid-cols-1 gap-4 text-sm md:grid-cols-2">
                    <div className="">
                        <div className="flex h-full w-full flex-col justify-center text-slate-700 dark:text-slate-300">
                            <RegressionChart
                                predictionDataObject={
                                    Object.keys(regressionSummary)?.length > 0
                                        ? regressionSummary?.prediction_data[0]
                                        : {
                                              x: "",
                                              y: "",
                                              points: [
                                                  { x: 0, y: 0 },
                                                  { x: 0, y: 0 },
                                              ],
                                          }
                                }
                                regressionDataPoints={
                                    regressionDataPoints || []
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <span className="text-base">Parameters</span>
                        <div className="space-y-2">
                            <div>{`Type`}</div>
                            <div className="flex space-x-2">
                                <select
                                    className="min-w-[250px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                                        text-blue-700 marker:border focus:border-slate-300 focus:outline-none
                                        focus:ring-0 dark:border-slate-800 dark:bg-slate-800/20 dark:text-blue-700
                                        dark:focus:border-slate-700"
                                    value={
                                        regressionParameters?.regression_type
                                    }
                                    disabled={isPending}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setRegressionParameters({
                                            regression_type: e.target
                                                .value as RegressionType,
                                            x_variables:
                                                singleInputRegressionTypes[
                                                    e.target.value
                                                ]
                                                    ? [
                                                          regressionParameters
                                                              .x_variables[0],
                                                      ]
                                                    : regressionParameters.x_variables,
                                            y_variables:
                                                singleOutputRegressionTypes[
                                                    e.target.value
                                                ]
                                                    ? [
                                                          regressionParameters
                                                              .y_variables[0],
                                                      ]
                                                    : regressionParameters.y_variables,
                                            degree: regressionParameters.degree,
                                        });
                                    }}
                                >
                                    {Object.keys(RegressionType)
                                        .filter(
                                            (key) => true,
                                            // key !== "multiple linear" &&
                                            // key !== "multivariate linear",
                                        )
                                        .map((key) => (
                                            <option
                                                key={key}
                                                value={RegressionType[key]}
                                                className="dark:bg-slate-800"
                                            >
                                                {`${key} regression `}
                                            </option>
                                        ))}
                                </select>
                                {regressionParameters?.regression_type ==
                                    RegressionType.polynomial && (
                                    <select
                                        className="min-w-[125px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                                            text-blue-700 marker:border focus:border-slate-300 focus:outline-none
                                            focus:ring-0 dark:border-slate-800 dark:bg-slate-800/20 dark:text-blue-700
                                            dark:focus:border-slate-700"
                                        value={regressionParameters?.degree}
                                        disabled={isPending}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setRegressionParameters({
                                                ...regressionParameters,
                                                degree: Number(e.target.value),
                                            });
                                        }}
                                    >
                                        {[2, 3, 4].map((key) => (
                                            <option
                                                key={key}
                                                value={key}
                                                className="dark:bg-slate-800"
                                            >
                                                {`degree ${key}`}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                            <div className="flex flex-col space-y-2">
                                <RegressionVariableMenu
                                    label={"Independent"}
                                    isSingleSelect={
                                        singleInputRegressionTypes[
                                            regressionParameters
                                                ?.regression_type
                                        ]
                                    }
                                    handleSetVariables={(
                                        selectedVariables: string[],
                                    ) =>
                                        handleSetVariablesByKey(
                                            selectedVariables,
                                            "x_variables",
                                        )
                                    }
                                    selectedVariables={
                                        regressionParameters?.x_variables || []
                                    }
                                    variables={variables || []}
                                    isDisabled={isPending}
                                />
                            </div>
                            <div className="flex flex-col space-y-2">
                                <RegressionVariableMenu
                                    label={"Dependent"}
                                    isSingleSelect={
                                        singleOutputRegressionTypes[
                                            regressionParameters
                                                ?.regression_type
                                        ]
                                    }
                                    handleSetVariables={(
                                        selectedVariables: string[],
                                    ) =>
                                        handleSetVariablesByKey(
                                            selectedVariables,
                                            "y_variables",
                                        )
                                    }
                                    selectedVariables={
                                        regressionParameters?.y_variables || []
                                    }
                                    variables={variables || []}
                                    isDisabled={isPending}
                                />
                            </div>
                        </div>
                        <div className="space-y-1">
                            {/* <span className="flex items-center">
                                {`Coefficient(s): ${
                                    (Object.keys(regressionSummary)?.length >
                                        0 &&
                                        regressionSummary?.coefficients[0]?.map(
                                            (val) => ` ${val.toFixed(2)}`,
                                        )) ||
                                    "---"
                                }`}
                            </span>
                            <span className="flex items-center">
                                {`Intercept(s): ${(Object.keys(regressionSummary)?.length > 0 && regressionSummary?.intercepts?.map((val) => ` ${val.toFixed(2)}`)) || "---"}`}
                            </span> */}
                            <span className="flex items-center">
                                {`R2 score(s): ${(Object.keys(regressionSummary)?.length > 0 && regressionSummary?.r2_scores?.map((val) => ` ${val.toFixed(4)}`)) || "---"}`}
                            </span>
                            <span className="flex items-center">
                                {`Outliers removed: ${(Object.keys(regressionSummary)?.length > 0 && regressionSummary?.outliers) || "---"}`}
                            </span>
                            <span className="flex items-center">
                                {`Scatter plot sample: ${(Object.keys(regressionSummary)?.length > 0 && regressionDataPoints?.length) || "---"} of ${regressionSummary?.count || "---"} points`}
                            </span>
                        </div>

                        {(regressionParameters?.x_variables?.length > 1 ||
                            regressionParameters?.y_variables?.length > 1) && (
                            <div className="text-center font-light tracking-wide">
                                See all charts below
                            </div>
                        )}
                    </div>
                    <div className="col-span-1 items-center md:col-span-2">
                        <div className="flex flex-col items-center text-base">
                            {Object.keys(regressionSummary)?.length > 0 &&
                                generateEquationHtml(
                                    regressionSummary.regression_type,
                                    regressionSummary.x_variables,
                                    regressionSummary.y_variables,
                                    regressionSummary.coefficients,
                                    regressionSummary.intercepts,
                                    regressionSummary.degree,
                                )?.map((equation, i) => (
                                    <span
                                        key={i}
                                        dangerouslySetInnerHTML={{
                                            __html: equation,
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </SubsectionWrapper>
            <SubsectionWrapper title={"Statistical Summary"}>
                <div className="pt-2">
                    <StatisticsTable
                        data={
                            !!regressionData &&
                            Object.keys(regressionData)?.length > 0
                                ? regressionData
                                : [
                                      ...regressionParameters.x_variables,
                                      ...regressionParameters.y_variables,
                                  ]?.reduce(
                                      (map, variable) => ({
                                          ...map,
                                          [variable]: defaultStatisticsObject,
                                      }),
                                      {},
                                  ) || {}
                        }
                    />
                </div>
            </SubsectionWrapper>
            {(regressionParameters?.x_variables?.length > 1 ||
                regressionParameters?.y_variables?.length > 1) && (
                <SubsectionWrapper title={"Charts"}>
                    <div
                        className="interactive-widget relative h-full w-full overflow-auto scrollbar-thin
                            scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                            scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                            scrollbar-corner-rounded-full dark:scrollbar-track-slate-800
                            dark:scrollbar-thumb-slate-700/75"
                    >
                        <div
                            className={`w-full ${
                                regressionParameters?.x_variables?.length > 1
                                    ? "min-w-[896px]"
                                    : "max-w-[50%]"
                            }`}
                        >
                            <div
                                className={`grid grid-cols-${regressionParameters?.x_variables?.length}`}
                            >
                                {regressionParameters?.y_variables?.map(
                                    (yVar, i) =>
                                        regressionParameters?.x_variables?.map(
                                            (xVar, j) => (
                                                <RegressionChart
                                                    key={`${yVar}-${xVar}`}
                                                    predictionDataObject={
                                                        Object.keys(
                                                            regressionSummary,
                                                        )?.length > 0 &&
                                                        regressionSummary
                                                            ?.prediction_data
                                                            ?.length ==
                                                            regressionParameters
                                                                ?.x_variables
                                                                ?.length *
                                                                regressionParameters
                                                                    ?.y_variables
                                                                    ?.length &&
                                                        regressionSummary
                                                            .prediction_data[
                                                            i *
                                                                regressionParameters
                                                                    ?.x_variables
                                                                    ?.length +
                                                                j
                                                        ]?.y == yVar &&
                                                        regressionSummary
                                                            .prediction_data[
                                                            i *
                                                                regressionParameters
                                                                    ?.x_variables
                                                                    ?.length +
                                                                j
                                                        ]?.x == xVar
                                                            ? regressionSummary
                                                                  .prediction_data[
                                                                  i *
                                                                      regressionParameters
                                                                          ?.x_variables
                                                                          ?.length +
                                                                      j
                                                              ]
                                                            : {
                                                                  x: xVar,
                                                                  y: yVar,
                                                                  points: [
                                                                      {
                                                                          x: 0,
                                                                          y: 0,
                                                                      },
                                                                      {
                                                                          x: 0,
                                                                          y: 0,
                                                                      },
                                                                  ],
                                                              }
                                                    }
                                                    regressionDataPoints={
                                                        regressionDataPoints ||
                                                        []
                                                    }
                                                />
                                            ),
                                        ),
                                )}
                            </div>
                        </div>
                    </div>
                </SubsectionWrapper>
            )}
        </>
    );
};

export const RegressionDisplay = React.memo(RegressionDisplayComponent);
