import React, { useCallback, useMemo } from "react";
import { ModelBlock, ModelVariable } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";
import {
    Items,
    SortableMultiContainersTree,
} from "./SortableMultiContainerTree";
import { StoredModelBlock } from "@/redux-state/schema";

const flattenModelBlocksIntoContainerDataMap = (
    modelBlocks: ModelBlock[],
    depth = 0
) => {
    return modelBlocks.reduce((map, modelBlock) => {
        return {
            ...map,
            [modelBlock.id]: { label: modelBlock.label, depth: depth },
            ...flattenModelBlocksIntoContainerDataMap(
                modelBlock.modelBlocks || [],
                depth + 1
            ),
        };
    }, {});
};

export const SortableModelVariableTree = ({
    modelBlocks,
    modelBlockArray,
    modelVariables,
}: {
    modelBlocks: { [index: string]: ModelBlock };
    modelBlockArray: StoredModelBlock[];
    modelVariables: { [index: string]: ModelVariable };
}) => {
    const itemMap: { [index: string]: string[] } = useMemo(() => {
        return modelBlockArray
            .filter((modelBlock) => !modelBlock.has_connections)
            .reduce((map, modelBlock) => {
                return { ...map, [modelBlock.id]: modelBlock.modelVariables };
            }, {});
    }, [modelBlockArray]);

    const selectionVariableItemMap: { [index: string]: string[] } =
        useMemo(() => {
            return modelBlockArray
                .filter((modelBlock) => modelBlock.has_connections)
                .reduce((map, modelBlock) => {
                    return {
                        ...map,
                        [modelBlock.id]: modelBlock.modelVariables,
                    };
                }, {});
        }, [modelBlockArray]);

    const itemLabelMap: { [index: string]: string } = useMemo(() => {
        return Object.values(modelVariables).reduce((map, modelVariable) => {
            return { ...map, [modelVariable.id]: modelVariable.label };
        }, {});
    }, [modelVariables]);

    const containerDataMap = useMemo(() => {
        return flattenModelBlocksIntoContainerDataMap(
            Object.values(modelBlocks).filter(
                (modelBlock) => !modelBlock.has_connections
            )
        );
    }, [modelBlocks]);

    const selectionVariableContainerDataMap = useMemo(() => {
        const selectionVariableModelBlock = Object.values(modelBlocks).find(
            (modelBlock) => modelBlock.has_connections
        );
        if (!selectionVariableModelBlock) {
            return {};
        } else {
            return {
                [selectionVariableModelBlock.id]: {
                    label: selectionVariableModelBlock.label,
                    depth: 0,
                },
            };
        }
    }, [modelBlocks]);

    const reorganizeModelVariables = async (
        modelVariablesToSave: ModelVariable[]
    ) => {
        await SapienInertia.post(
            "model-builder.model-variables.reorganize",
            {
                modelVariables: modelVariablesToSave,
            } as any,
            {},
            { preserveScroll: true }
        );
    };

    const getHandleUpdate = useCallback(
        (isConnectionBlock?: boolean) => {
            const handle = (items: Items) => {
                let modelVariablesToSave: ModelVariable[] = [];

                Object.keys(containerDataMap).forEach((modelBlockId) => {
                    const orderedVariableIds = !isConnectionBlock
                        ? items[modelBlockId]
                        : itemMap[modelBlockId];

                    const currentCount = modelVariablesToSave.length;

                    const modelVariableArray: ModelVariable[] =
                        orderedVariableIds.map((variableId, index) => ({
                            ...modelVariables[variableId],
                            model_block_id: modelBlockId,
                            weight: currentCount + index,
                        }));

                    modelVariablesToSave = [
                        ...modelVariablesToSave,
                        ...modelVariableArray,
                    ];
                });

                Object.keys(selectionVariableContainerDataMap).forEach(
                    (modelBlockId) => {
                        const orderedVariableIds = isConnectionBlock
                            ? items[modelBlockId]
                            : selectionVariableItemMap[modelBlockId];

                        const currentCount = modelVariablesToSave.length;

                        const modelVariableArray: ModelVariable[] =
                            orderedVariableIds.map((variableId, index) => ({
                                ...modelVariables[variableId],
                                model_block_id: modelBlockId,
                                weight: currentCount + index,
                            }));

                        modelVariablesToSave = [
                            ...modelVariablesToSave,
                            ...modelVariableArray,
                        ];
                    }
                );

                reorganizeModelVariables(modelVariablesToSave);
            };

            return handle;
        },
        [
            modelVariables,
            containerDataMap,
            selectionVariableContainerDataMap,
            itemMap,
            selectionVariableItemMap,
        ]
    );

    return (
        <div>
            <div className="mt-2 mb-4 text-xl">Organize Variables</div>
            <SortableMultiContainersTree
                items={itemMap}
                itemLabels={itemLabelMap}
                containerDataMap={containerDataMap}
                handleUpdate={getHandleUpdate()}
            />
            <div className="mt-10 mb-4 text-xl">
                Organize Selection Data Variables
            </div>
            <SortableMultiContainersTree
                items={selectionVariableItemMap}
                itemLabels={itemLabelMap}
                containerDataMap={selectionVariableContainerDataMap}
                handleUpdate={getHandleUpdate(true)}
            />
        </div>
    );
};
