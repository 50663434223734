import React from "react";
import { useAreAllScoredPromptsComplete } from "../shared-state/useSelections";
import { useLessonScore } from "../LessonDisplay/useLessonScore";
import { CSSProperties } from "styled-components";

export default function FeedbackDrawer({
    sidebarState,
}: {
    sidebarState: "open" | "closed";
}) {
    const complete = useAreAllScoredPromptsComplete();
    const { hasPassedQuiz, lessonScore } = useLessonScore();
    const isSidebarOpen = sidebarState === "open";

    return (
        <div
            id="bottomDrawer"
            className="fixed inset-x-0 bottom-0 z-[999] w-full
                shadow-[0_-5px_5px_-5px_#0000003d] transition-all duration-500 delay-500 transform
                data-[is-open=false]:translate-y-full data-[is-open=true]:translate-y-0"
            data-is-open={complete}
            style={
                {
                    "--nav-width": isSidebarOpen ? "20rem" : "0rem",
                } as CSSProperties
            }
        >
            {!!hasPassedQuiz ? (
                <div className=" flex h-20 justify-center pl-[var(--nav-width)] bg-green-50 text-green-700 transition-all">
                    {/* Drawer content */}
                    <div className="flex flex-none items-center gap-x-5">
                        You've completed the quiz and can advance to the next
                        lesson!
                    </div>
                </div>
            ) : (
                <div className=" flex h-20 justify-center bg-red-50 text-red-800 transition-all">
                    {/* Drawer content */}
                    <div className="flex flex-none items-center gap-x-5">
                        You must score{" "}
                        {lessonScore.required_score < 100 ? " at least" : ""}{" "}
                        {lessonScore.required_score}% on the quiz to advance
                        past this lesson. Your current score is{" "}
                        {Math.round(lessonScore.current_score)}%.
                    </div>
                </div>
            )}
        </div>
    );
}
