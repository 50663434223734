import React, { useCallback } from "react";
import { ModelVariable } from "@/models";
import { CheckIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
    ChartBlock,
    FormattedChartValue,
    InteractiveModuleChartType,
} from "../types";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { debounce } from "@/hooks";
import { ChartDisplay, DashboardWidgetWrapper, RenderChart } from "../shared";
import { useSaveChartBlock } from "./useMutateCharts";
import PublishSwitch from "./PublishSwitch";

const InteractiveDesignChartComponent = ({
    availableVariables,
    selectedVariables,
    modelTimespan,
    index,
    chartBlock,
    setChartBlock,
    deleteChartBlock,
    showChartControls,
    isNotParticipant,
    chartValues,
}: {
    availableVariables: ModelVariable[];
    selectedVariables: ModelVariable[];
    modelTimespan: number;
    index: number;
    chartBlock: ChartBlock;
    setChartBlock: (chartBlock: ChartBlock) => void;
    deleteChartBlock: () => void;
    showChartControls: boolean;
    isNotParticipant: boolean;
    chartValues: Record<string, FormattedChartValue[]>;
}) => {
    const { mutateAsync, isPending } = useSaveChartBlock();

    const setSelectedVariableIds = useCallback(
        async (variableIds: string[]) => {
            setChartBlock({ ...chartBlock, variableIds: variableIds });

            if (isNotParticipant) {
                await mutateAsync({ ...chartBlock, variableIds: variableIds });
            }
        },
        [chartBlock, setChartBlock, mutateAsync, isNotParticipant],
    );

    const update = useCallback(async (chart: ChartBlock) => {
        setChartBlock(chart);

        if (isNotParticipant) {
            await mutateAsync(chart);
        }
    }, []);

    const handleUpdateChartBlock = debounce(update, 500);

    const showControls =
        showChartControls && (isNotParticipant || !chartBlock.created_at);

    return !showControls ? (
        <DashboardWidgetWrapper>
            {!!chartBlock.published_at && (
                <span title="Published" className="relative">
                    <CheckIcon className="absolute left-2 top-2 h-5 w-5 text-blue-600" />
                </span>
            )}
            <ChartDisplay
                modelTimespan={modelTimespan}
                chartBlock={{
                    ...chartBlock,
                    time_index: modelTimespan > 0 ? modelTimespan - 1 : 0,
                }}
                setChartTimeIndex={(timeIndex) => {
                    // handleUpdateChartBlock({
                    //     ...chartBlock,
                    //     time_index: timeIndex,
                    // });
                }}
                chartValues={chartValues}
            />
        </DashboardWidgetWrapper>
    ) : (
        <div className="interactive-widget relative col-span-2 mb-2">
            {isPending && (
                <svg
                    className="absolute right-2 top-2 h-4 w-4 animate-spin text-sapien-blue"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            )}
            <div
                className="flex w-full items-center justify-between gap-1 bg-slate-200 bg-opacity-50 p-2
                    text-slate-700 dark:border-slate-700 dark:bg-slate-800 dark:bg-opacity-75
                    dark:text-slate-300"
            >
                <span className="px-2 font-bold">{`${index + 1}`}</span>
                <input
                    className="w-full border border-slate-100 bg-white bg-opacity-50 py-1 text-sm
                        text-slate-700 focus:border-slate-200 focus:outline-none focus:ring-0
                        dark:border-slate-700 dark:bg-opacity-5 dark:text-slate-300
                        dark:focus:border-slate-600"
                    type="text"
                    defaultValue={chartBlock.label}
                    onChange={(e) => {
                        handleUpdateChartBlock({
                            ...chartBlock,
                            label: e.target.value,
                        });
                    }}
                />
                <select
                    className="min-w-[100px] border-slate-100 bg-white bg-opacity-50 py-1 pr-1 text-sm
                        text-slate-700 marker:border focus:border-slate-200 focus:outline-none
                        focus:ring-0 dark:border-slate-700 dark:bg-opacity-5 dark:text-slate-300
                        dark:focus:border-slate-600"
                    value={chartBlock?.chart_type || ""}
                    onChange={(e) => {
                        e.stopPropagation();
                        handleUpdateChartBlock({
                            ...chartBlock,
                            chart_type: e.target
                                .value as InteractiveModuleChartType,
                        });
                    }}
                >
                    <option key={""} value={""} disabled>
                        chart type
                    </option>
                    {Object.keys(InteractiveModuleChartType).map((key) => (
                        <option
                            key={key}
                            value={InteractiveModuleChartType[key]}
                        >
                            {InteractiveModuleChartType[key]}
                        </option>
                    ))}
                </select>
                {chartBlock.chart_type === InteractiveModuleChartType.LINE && (
                    <select
                        className="min-w-[100px] border-slate-100 bg-white bg-opacity-50 py-1 pr-1 text-sm
                            text-slate-700 marker:border focus:border-slate-200 focus:outline-none
                            focus:ring-0 dark:border-slate-700 dark:bg-opacity-5 dark:text-slate-300
                            dark:focus:border-slate-600"
                        value={chartBlock.interpolation}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleUpdateChartBlock({
                                ...chartBlock,
                                interpolation: e.target.value,
                            });
                        }}
                    >
                        <option key={""} value={""} disabled>
                            interpolation
                        </option>
                        {["linear", "natural"].map((key, i) => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                    </select>
                )}
                {/* {!!modelTimespan &&
                    chartBlock.chart_type ===
                        InteractiveModuleChartType.PIE && (
                        <select
                            className="min-w-[100px] border-slate-100 bg-white bg-opacity-50 py-1 pr-1 text-sm
                                text-slate-700 marker:border focus:border-slate-200 focus:outline-none
                                focus:ring-0 dark:border-slate-700 dark:bg-opacity-5 dark:text-slate-300
                                dark:focus:border-slate-600"
                            value={chartBlock.time_index}
                            onChange={(e) => {
                                e.stopPropagation();
                                handleUpdateChartBlock({
                                    ...chartBlock,
                                    time_index: Number(e.target.value),
                                });
                            }}
                        >
                            <option key={""} value={""} disabled>
                                time
                            </option>
                            {Array(modelTimespan)
                                .fill(0)
                                .map((_, i) => (
                                    <option key={i} value={i}>
                                        {`${chartBlock.time_increment} ${i + 1}`}
                                    </option>
                                ))}
                        </select>
                    )} */}
                {(chartBlock.chart_type === InteractiveModuleChartType.AREA ||
                    chartBlock.chart_type ===
                        InteractiveModuleChartType.BAR) && (
                    <select
                        className="min-w-[100px] border-slate-100 bg-white bg-opacity-50 py-1 pr-1 text-sm
                            text-slate-700 marker:border focus:border-slate-200 focus:outline-none
                            focus:ring-0 dark:border-slate-700 dark:bg-opacity-5 dark:text-slate-300
                            dark:focus:border-slate-600"
                        value={chartBlock.stacked ? "stacked" : "flat"}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleUpdateChartBlock({
                                ...chartBlock,
                                stacked: e.target.value == "stacked",
                            });
                        }}
                    >
                        <option key={""} value={""} disabled>
                            grouping
                        </option>
                        {["flat", "stacked"].map((key, i) => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                    </select>
                )}
                {isNotParticipant && (
                    <PublishSwitch
                        chartBlock={chartBlock}
                        updateBlock={setChartBlock}
                    />
                )}

                <button
                    type="button"
                    className="m-1 inline-flex items-center rounded-md bg-transparent p-1 text-center text-sm
                        font-medium text-slate-700 hover:text-slate-900 focus:outline-none focus:ring-0
                        dark:text-slate-300 dark:hover:text-slate-100"
                    onClick={(e) => {
                        e.stopPropagation();
                        const confirmMessage = !!chartBlock.created_at
                            ? `Are you sure you want to delete ${chartBlock.label} chart for everyone?`
                            : `Are you sure you want to delete ${chartBlock.label}?`;
                        if (window.confirm(confirmMessage)) {
                            deleteChartBlock();
                        }
                    }}
                >
                    <XMarkIcon className="h-4 w-4" />
                </button>
            </div>
            <div
                className="grid w-full grid-cols-1 bg-slate-200 bg-opacity-20 p-4 text-slate-700
                    md:grid-cols-2 dark:border-slate-700 dark:bg-slate-800 dark:bg-opacity-50
                    dark:text-slate-300"
            >
                <div className="">
                    <div className="flex py-4">
                        <Listbox
                            value={chartBlock.variableIds}
                            onChange={setSelectedVariableIds}
                            multiple
                        >
                            <div
                                className="flex items-center rounded-md border border-slate-200 bg-opacity-50
                                    hover:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600"
                            >
                                <ListboxButton
                                    className="h-full rounded-md bg-slate-100 bg-opacity-30 p-4 hover:bg-opacity-50
                                        dark:bg-slate-700 dark:bg-opacity-30 dark:hover:bg-opacity-50"
                                >
                                    <div className="text-md font-medium">
                                        Variables
                                    </div>
                                </ListboxButton>
                                {selectedVariables?.length > 0 && (
                                    <span className="px-4 text-sm">
                                        {selectedVariables.map((variable) => (
                                            <span
                                                key={variable.id}
                                                className="flex items-center font-light"
                                            >
                                                {variable.label}
                                                <button
                                                    type="button"
                                                    className="m-1 inline-flex items-center rounded-md bg-transparent p-1 text-center text-sm
                                                        font-medium text-slate-700 hover:text-slate-900 focus:outline-none focus:ring-0
                                                        dark:text-slate-300 dark:hover:text-slate-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedVariableIds(
                                                            chartBlock.variableIds.filter(
                                                                (variableId) =>
                                                                    variableId !==
                                                                    variable.id,
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    <XMarkIcon className="h-4 w-4" />
                                                </button>
                                            </span>
                                        ))}
                                    </span>
                                )}
                            </div>
                            <ListboxOptions
                                anchor="bottom start"
                                className="[--anchor-gap:4px]"
                            >
                                {availableVariables.map((variable) => (
                                    <ListboxOption
                                        key={variable.id}
                                        value={variable.id}
                                        className="group flex gap-3 bg-slate-900 p-2 text-sm text-white data-[focus]:bg-slate-700"
                                    >
                                        <CheckIcon className="invisible size-5 text-blue-600 group-data-[selected]:visible" />
                                        {variable.label}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Listbox>
                    </div>
                </div>
                <div className="max-w-2xl text-slate-700 dark:text-slate-300">
                    {Object.keys(chartValues).length > 0 && (
                        <RenderChart
                            chartValues={chartValues}
                            chartType={chartBlock.chart_type}
                            stacked={chartBlock.stacked}
                            interpolation={chartBlock.interpolation}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export const InteractiveDesignChart = React.memo(
    InteractiveDesignChartComponent,
);
