import React, { FC, useState } from "react";
import { FormContainer } from "@/forms";
import {
    ModelVariable,
    ModelVariableDataType,
    Option,
    Prompt,
    PromptType,
} from "@/models";
import { QuestionContentBlockFormField } from "@/styles/themes/Question/QuestionContentBlockFormField";
import {
    BoltIcon,
    UserIcon,
    UserGroupIcon,
    PencilIcon,
    CheckIcon,
} from "@heroicons/react/24/outline";
import { BoltIcon as SolidBoltIcon } from "@heroicons/react/24/solid";
import { displayPromptType } from "@/util";
import { useFormatVariableValue } from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import { plus } from "react-icons-kit/feather/plus";
import { PrimaryButtonOutline } from "@/components/admin-components";

const saveBasicVariableProperties = async (
    modelVariableId: string,
    label: string,
    exposeToFacilitator: boolean,
    exposeToDesigner: boolean,
    isKeyMetric: boolean,
) => {
    return SapienInertia.post(
        "model-builder.model-variables.simple-save",
        {
            id: modelVariableId,
            label: label,
            expose_to_facilitator: exposeToFacilitator,
            expose_to_designer: exposeToDesigner,
            is_key_metric: isKeyMetric,
        },
        {},
        { preserveScroll: true },
    );
};

export const QuestionDisplay = (props: {
    simulationId: string;
    prompt: Prompt;
    connectQuestion?: (promptId: string) => Promise<void>;
    reorderPrompts?: (
        promptId: string,
        destinationIndex: number,
    ) => Promise<void>;
    isExpanded: boolean;
    toggleExpanded: () => void;
    handleSetQuestionSubmoduleData?: () => void;
}) => {
    const {
        prompt,
        connectQuestion,
        isExpanded,
        toggleExpanded,
        handleSetQuestionSubmoduleData,
    } = props;

    return (
        <div className="w-full pb-1 text-sm">
            <div
                className={`mb-2 mt-2 cursor-pointer py-0.5 ${
                    isExpanded
                        ? "bg-[rgba(28,78,216,0.05)]"
                        : "bg-[rgba(28,78,216,0.03)]"
                }`}
                onClick={() => toggleExpanded()}
            >
                <div className="mr-2 flex items-center justify-between">
                    <div className="flex w-[50%] max-w-[50%] items-center">
                        <svg
                            className={`-mr-0.5 ml-2 h-5 w-5 min-w-[20px] transition-all ${
                                isExpanded ? "rotate-0" : "-rotate-90"
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <div className="block overflow-hidden px-1.5 py-1.5">
                            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                {prompt.content}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-[50%] max-w-[50%] items-center justify-end">
                        <div className="text-xs font-bold uppercase">
                            {displayPromptType(prompt.prompt_type)}
                        </div>
                        <span className="ml-3">
                            {prompt.scope === "Participant" ? (
                                <UserIcon className="h-5 w-5 text-neutral-700" />
                            ) : (
                                <UserGroupIcon className="h-5 w-5 text-neutral-700" />
                            )}
                        </span>
                        <div className="ml-1">
                            <button
                                type="button"
                                className="inline-flex items-center rounded-full p-2 hover:bg-[rgba(28,78,216,0.03)]
                                    focus:outline-none focus:ring-0"
                                disabled={false}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    connectQuestion(prompt.id);
                                }}
                            >
                                {prompt.has_model_effects ? (
                                    <SolidBoltIcon className="h-5 w-5 text-neutral-600" />
                                ) : (
                                    <BoltIcon className="h-5 w-5 text-neutral-400" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {!!isExpanded && (
                <div className="border-box -mt-2 mb-2 border border-[rgba(28,78,216,0.09)] p-2">
                    <FormContainer defaultValues={{}}>
                        <div className="p-4">
                            <div style={{ marginBottom: "1rem" }}>
                                {prompt.content}
                            </div>
                            <QuestionContentBlockFormField
                                key={prompt.id}
                                promptId={prompt.id}
                                prompt={prompt}
                                optionRenderMap={{}}
                                isInDesignContext={true}
                            />
                        </div>
                    </FormContainer>
                    {prompt.has_model_effects && (
                        <div className="relative mt-4 overflow-x-auto">
                            <table
                                className="h-full w-full table-auto text-left text-sm text-gray-500"
                                style={{
                                    fontSize: "0.8rem",
                                }}
                            >
                                <thead className="text-xs uppercase text-gray-700">
                                    <tr className="border">
                                        <th className="px-1 py-2">variable</th>
                                        <th className="px-1 py-2"></th>
                                        <th className="px-1 py-2">scope</th>
                                        <th className="px-1 py-2">value</th>
                                        {prompt.prompt_type !==
                                            PromptType["Numerical Input"] &&
                                            prompt.prompt_type !==
                                                PromptType[
                                                    "Numerical Slider"
                                                ] && (
                                                <th className="px-1 py-2">
                                                    option
                                                </th>
                                            )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {prompt.options.map((option) => (
                                        <tr
                                            key={option.id}
                                            className={
                                                "table-row h-full border"
                                            } // odd:bg-neutral-100 even:bg-neutral-50`}
                                        >
                                            {!!prompt.selectionDataModelVariables.find(
                                                (modelVariable) =>
                                                    modelVariable.option_id ===
                                                    option.id,
                                            ) ? (
                                                prompt.selectionDataModelVariables
                                                    .filter(
                                                        (modelVariable) =>
                                                            modelVariable.option_id ===
                                                            option.id,
                                                    )
                                                    .map((modelVariable) => (
                                                        <QuestionOptionRow
                                                            key={
                                                                modelVariable.id
                                                            }
                                                            modelVariable={
                                                                modelVariable
                                                            }
                                                            prompt={prompt}
                                                            option={option}
                                                        />
                                                    ))
                                            ) : (
                                                <>
                                                    <td className="px-1 py-1">
                                                        No variable
                                                    </td>
                                                    <td className="px-1 py-1"></td>
                                                    <td className="px-1 py-1"></td>
                                                    {prompt.prompt_type !==
                                                        PromptType[
                                                            "Numerical Input"
                                                        ] &&
                                                        prompt.prompt_type !==
                                                            PromptType[
                                                                "Numerical Slider"
                                                            ] && (
                                                            <td className="px-1 py-1">
                                                                {option.content}
                                                            </td>
                                                        )}
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {prompt.has_model_effects &&
                        prompt.selectionDataModelVariables.length ===
                            prompt.options.length && (
                            <div className="mt-4 flex justify-center">
                                <PrimaryButtonOutline
                                    text={"Create Submodule"}
                                    icon={plus}
                                    handler={handleSetQuestionSubmoduleData}
                                />
                            </div>
                        )}
                </div>
            )}
        </div>
    );
};

const QuestionOptionRow: FC<{
    modelVariable?: ModelVariable;
    prompt?: Prompt;
    option?: Option;
}> = ({ modelVariable, prompt, option }) => {
    const formatVariableValue = useFormatVariableValue();

    const [editedLabel, setEditedLabel] = useState<string>();

    return (
        <>
            <th className={"table-col h-full px-1 py-1"}>
                {!!editedLabel ? (
                    <div className="h-full">
                        <textarea
                            className="block h-full min-h-[54px] w-full min-w-[200px] rounded-lg border border-gray-300
                                bg-gray-50 p-0.5 text-xs text-gray-700 focus:border-blue-500 focus:ring-blue-500"
                            value={editedLabel}
                            onChange={(e) => {
                                setEditedLabel(e.target.value);
                            }}
                        />
                    </div>
                ) : (
                    <span className="text-xs">{modelVariable.label}</span>
                )}
            </th>
            <td className="px-1 py-1">
                <span className="flex items-center">
                    <span>
                        <button
                            type="button"
                            className="inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-0"
                            disabled={false}
                            onClick={async () => {
                                if (!!editedLabel) {
                                    await saveBasicVariableProperties(
                                        modelVariable.id,
                                        editedLabel,
                                        modelVariable.expose_to_facilitator,
                                        modelVariable.expose_to_designer,
                                        modelVariable.is_key_metric,
                                    );
                                    setEditedLabel(undefined);
                                } else {
                                    setEditedLabel(modelVariable.label);
                                }
                            }}
                        >
                            {!!editedLabel ? (
                                <CheckIcon className="h-4 w-4" />
                            ) : (
                                <PencilIcon className="h-4 w-4" />
                            )}
                        </button>
                    </span>
                </span>
            </td>
            <td className="px-1 py-1">
                <span
                    style={{
                        letterSpacing: "0.5px",
                        textTransform: "uppercase",
                    }}
                >
                    {modelVariable.scope}
                </span>
            </td>
            <td className="px-1 py-1">
                {modelVariable.data_type === ModelVariableDataType.Number
                    ? formatVariableValue(
                          modelVariable.unit,
                          modelVariable.default_numerical_value,
                          modelVariable.is_integer,
                      )
                    : modelVariable.default_boolean_value.toString()}
            </td>
            {prompt.prompt_type !== PromptType["Numerical Input"] &&
                prompt.prompt_type !== PromptType["Numerical Slider"] && (
                    <td className="px-1 py-1">{option.content}</td>
                )}
        </>
    );
};
