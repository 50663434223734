import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/popover/Popover";
import {
    Cohort,
    PromptType,
    Round,
    SelectionShape,
    Simulation,
    SimulationShape,
    Team,
} from "@/models";
import {
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";
import {
    ChartBarIcon,
    ChevronDownIcon,
    TableCellsIcon,
} from "@heroicons/react/24/solid";
import { DiscoveryQuestionDisplay } from "../discovery/DiscoveryQuestionDisplay";
import { DiscoverySelectionsByType } from "../discovery/DiscoverySelectionsByType";
import { DiscoverySelectionsByPeople } from "../discovery/DiscoverySelectionsByPeople";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";
import { useDiscoveryStatistics } from "@/hooks/useDiscoveryStatistics";
import { DiscoveryMenuItemCheckbox } from "../discovery/DiscoveryMenuItemCheckbox";
import { displayPromptType } from "@/util";
import { DiscoveryWidgetWrapper } from "../discovery/DiscoveryWidgetWrapper";

const promptTypesToDisplay = [
    PromptType["Multiple Choice"],
    PromptType["Multiple Select"],
    PromptType["Dropdown List"],
    PromptType["Numerical Slider"],
];

const displayOptions: { [index: string]: string } = {
    question: "Rounds",
    type: "Type and Scope",
    people: "Tables",
};

export const CohortQuestionStatistics = ({
    simulation,
    rounds: originalRounds,
    cohort,
    teams,
    selections,
    simpleDisplay = true,
    setSimpleDisplay,
}: {
    simulation: Simulation | SimulationShape;
    rounds: Round[];
    cohort: Cohort;
    teams: (Team | TeamWithProgressData)[];
    selections: SelectionShape[];
    simpleDisplay?: boolean;
    setSimpleDisplay?: (isSimple: boolean) => void;
}) => {
    const rounds = useMemo(() => {
        return (
            originalRounds
                ?.map((round) => {
                    const promptsFiltered =
                        round?.prompts?.filter((prompt) =>
                            promptTypesToDisplay.includes(prompt.prompt_type),
                        ) || [];
                    return {
                        ...round,
                        prompts_count: promptsFiltered.length,
                        prompts: promptsFiltered,
                    } as Round;
                })
                .filter((round) => round.prompts_count > 0) || []
        );
    }, [originalRounds]);

    const discoveryStatisticsObject = useDiscoveryStatistics(
        rounds,
        teams,
        selections,
    );

    const {
        selectionMap,
        promptMap,
        teamCount,
        participantCount,
        // selectedPromptIds,
        selectedRoundIds,
        togglePromptId,
        toggleRoundId,
        teamMap,
        participantMap,
        promptTypeAndScopeMap,
        // promptScopeAndTypeMap,
        selectionsGrouping,
        setSelectionsGrouping,
        // teamsHaveMultipleParticipants,
        promptScopes,
        promptTypes,
        selectedPromptScopes,
        togglePromptScope,
        selectedPromptTypes,
        togglePromptType,
        disabledPrompts,
    } = discoveryStatisticsObject;

    const [questionDisplay, setQuestionDisplay] = useState<
        "tables" | "charts" | ""
    >("");

    useEffect(() => {
        if (questionDisplay !== "") {
            setQuestionDisplay("");
        }
    }, [questionDisplay]);

    const [displayTypeScopeMap, setDisplayTypeScopeMap] = useState<{
        [promptType: string]: {
            [promptScope: string]: boolean;
        };
    }>(
        Object.keys(promptTypeAndScopeMap).reduce(
            (tMap, promptType) => ({
                ...tMap,
                [promptType]: Object.keys(
                    promptTypeAndScopeMap[promptType],
                ).reduce(
                    (sMap, promptScope) => ({ ...sMap, [promptScope]: true }),
                    {},
                ),
            }),
            {},
        ),
    );

    const handleSetDisplayTypeScopeMap = useCallback(
        (promptType: string, promptScope: string) => {
            setDisplayTypeScopeMap({
                ...displayTypeScopeMap,
                [promptType]: {
                    ...(displayTypeScopeMap[promptType] || {}),
                    [promptScope]:
                        !!displayTypeScopeMap[promptType] &&
                        displayTypeScopeMap[promptType][promptScope] !==
                            undefined
                            ? !displayTypeScopeMap[promptType][promptScope]
                            : false,
                },
            });
        },
        [displayTypeScopeMap],
    );

    const [displayRoundMap, setDisplayRoundMap] = useState<{
        [roundId: string]: boolean;
    }>(
        rounds?.reduce(
            (map, round) => ({
                ...map,
                [round.id]: true,
            }),
            {},
        ),
    );

    return (
        !!cohort && (
            <div className="space-y-4">
                <div className="text-sm font-light">
                    <div>{`Teams: ${teamCount}`}</div>
                    <div>{`Participants: ${participantCount}`}</div>
                </div>
                <div className="flex items-center">
                    <label htmlFor={"stats-display"}>
                        <span className="flex items-center text-xs">
                            <input
                                id={"stats-display"}
                                type={"checkbox"}
                                checked={simpleDisplay}
                                className={`text-primary-600 focus:ring-primary-500 mr-2 h-4 w-4 rounded border-gray-300
                                    bg-gray-100 focus:ring-1`}
                                onChange={() => {
                                    setSimpleDisplay(!simpleDisplay);
                                }}
                            />
                            {`Simple Display`}
                        </span>
                    </label>
                </div>
                <div className={"flex items-center"}>
                    <span className="text-md font-semibold">Data Display</span>
                    {Object.keys(displayOptions)
                        .filter((key) => key !== "people" || !simpleDisplay)
                        .map((key) => (
                            <div
                                key={key}
                                className="ml-4 flex items-center font-medium text-gray-900"
                            >
                                <DiscoveryMenuItemCheckbox
                                    key={key}
                                    itemKey={key}
                                    label={displayOptions[key]}
                                    checked={selectionsGrouping === key}
                                    handleOnChange={() => {
                                        setSelectionsGrouping(
                                            key as
                                                | "question"
                                                | "people"
                                                | "type",
                                        );
                                    }}
                                    inputType={"radio"}
                                />
                            </div>
                        ))}
                </div>
                <div className="flex flex-row items-center space-x-2">
                    <span className="text-md pr-2 font-semibold">Filters</span>
                    <Popover>
                        <PopoverTrigger
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div
                                className={`relative flex w-full items-center rounded-md border border-neutral-200
                                bg-neutral-50 bg-opacity-50 px-2 py-1 text-center text-sm text-neutral-800
                                hover:bg-neutral-100 ${
                                    rounds?.filter(
                                        (round) => !!round.prompts?.length,
                                    ).length >
                                    Object.keys(selectedRoundIds).length
                                        ? "border-neutral-700"
                                        : ""
                                }`}
                            >
                                <span>Rounds</span>
                                <ChevronDownIcon className="ml-2 h-4 w-4" />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent
                            align={"start"}
                            className="max-h-[50vh] w-full overflow-auto bg-white p-4"
                        >
                            <div className="">
                                <div className="mb-1 flex items-center font-medium text-gray-900">
                                    <DiscoveryMenuItemCheckbox
                                        key={"selections"}
                                        itemKey={"selections"}
                                        label={`All Rounds`}
                                        checked={
                                            // all rounds are selected
                                            rounds?.filter(
                                                (round) =>
                                                    !!round.prompts?.length,
                                            ).length ===
                                            Object.keys(selectedRoundIds).length
                                        }
                                        handleOnChange={() => {
                                            if (
                                                rounds?.filter(
                                                    (round) =>
                                                        !!round.prompts?.length,
                                                ).length ===
                                                Object.keys(selectedRoundIds)
                                                    .length
                                            ) {
                                                // if all rounds are selected, deselect all
                                                toggleRoundId();
                                            } else {
                                                // otherwise select all rounds that aren't
                                                toggleRoundId("ALL");
                                            }
                                        }}
                                    />
                                </div>
                                {!!rounds?.length && (
                                    <RoundAndQuestionCheckboxList
                                        rounds={rounds}
                                        selectedRoundIds={selectedRoundIds}
                                        selectedPromptIds={promptMap}
                                        toggleRoundId={toggleRoundId}
                                        togglePromptId={togglePromptId}
                                        disabledPrompts={disabledPrompts}
                                    />
                                )}
                            </div>
                        </PopoverContent>
                    </Popover>
                    {promptScopes?.length > 1 && (
                        <Popover>
                            <PopoverTrigger
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className={`relative flex w-full items-center rounded-md border border-neutral-200
                                    bg-neutral-50 bg-opacity-50 px-2 py-1 text-center text-sm text-neutral-800
                                    hover:bg-neutral-100 ${
                                        selectedPromptScopes?.length <
                                        promptScopes?.length
                                            ? "border-neutral-700"
                                            : ""
                                    }`}
                                >
                                    <span>Question Scope</span>
                                    <ChevronDownIcon className="ml-2 h-4 w-4" />
                                </div>
                            </PopoverTrigger>
                            <PopoverContent
                                align={"start"}
                                className="w-full bg-white p-4"
                            >
                                <div className="">
                                    <div className="mb-1 flex items-center font-medium text-gray-900">
                                        <DiscoveryMenuItemCheckbox
                                            key={"scope"}
                                            itemKey={"scope"}
                                            label={`All Scopes`}
                                            checked={
                                                selectedPromptScopes?.length ===
                                                promptScopes?.length
                                            }
                                            handleOnChange={() => {
                                                if (
                                                    selectedPromptScopes?.length ===
                                                    promptScopes?.length
                                                ) {
                                                    togglePromptScope("");
                                                } else {
                                                    togglePromptScope("ALL");
                                                }
                                            }}
                                        />
                                    </div>
                                    <ul className="space-y-1 text-sm">
                                        <li
                                            key={"Participant"}
                                            className="flex items-center font-semibold text-gray-800"
                                        >
                                            <DiscoveryMenuItemCheckbox
                                                key={"Participant"}
                                                itemKey={"Participant"}
                                                label={"Participant"}
                                                checked={selectedPromptScopes.includes(
                                                    "Participant",
                                                )}
                                                handleOnChange={() =>
                                                    togglePromptScope(
                                                        "Participant",
                                                    )
                                                }
                                            />
                                        </li>
                                        <li
                                            key={"Team"}
                                            className="flex items-center font-semibold text-gray-800"
                                        >
                                            <DiscoveryMenuItemCheckbox
                                                key={"Team"}
                                                itemKey={"Team"}
                                                label={"Team"}
                                                checked={selectedPromptScopes.includes(
                                                    "Team",
                                                )}
                                                handleOnChange={() =>
                                                    togglePromptScope("Team")
                                                }
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </PopoverContent>
                        </Popover>
                    )}
                    {promptTypes?.length > 1 && (
                        <Popover>
                            <PopoverTrigger
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className={`relative flex w-full items-center rounded-md border border-neutral-200
                                    bg-neutral-50 bg-opacity-50 px-2 py-1 text-center text-sm text-neutral-800
                                    hover:bg-neutral-100 ${
                                        selectedPromptTypes?.length <
                                        promptTypes?.length
                                            ? "border-neutral-700"
                                            : ""
                                    }`}
                                >
                                    <span>Question Type</span>
                                    <ChevronDownIcon className="ml-2 h-4 w-4" />
                                </div>
                            </PopoverTrigger>
                            <PopoverContent
                                align={"start"}
                                className="w-full bg-white p-4"
                            >
                                <div className="">
                                    <div className="mb-1 flex items-center font-medium text-gray-900">
                                        <DiscoveryMenuItemCheckbox
                                            key={"question-type"}
                                            itemKey={"question-type"}
                                            label={`All Types`}
                                            checked={
                                                selectedPromptTypes?.length ===
                                                promptTypes?.length
                                            }
                                            handleOnChange={() => {
                                                if (
                                                    selectedPromptTypes?.length ===
                                                    promptTypes?.length
                                                ) {
                                                    togglePromptType("");
                                                } else {
                                                    togglePromptType("ALL");
                                                }
                                            }}
                                        />
                                    </div>
                                    <ul className="space-y-1 text-sm">
                                        {promptTypes.map((key) => (
                                            <li
                                                key={key}
                                                className="flex items-center font-semibold text-gray-800"
                                            >
                                                <DiscoveryMenuItemCheckbox
                                                    key={key}
                                                    itemKey={key}
                                                    label={key}
                                                    checked={selectedPromptTypes.includes(
                                                        key,
                                                    )}
                                                    handleOnChange={() =>
                                                        togglePromptType(key)
                                                    }
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </PopoverContent>
                        </Popover>
                    )}
                </div>
                <div className="discovery-container">
                    <div className="space-y-2">
                        <div className="flex items-center justify-between px-1">
                            <div className="text-base">{"Selections"}</div>
                            {!simpleDisplay && (
                                <div className="ml-3 flex items-center">
                                    {selectionsGrouping === "question" && (
                                        <>
                                            <SmallButton
                                                onClick={() =>
                                                    setQuestionDisplay("charts")
                                                }
                                            >
                                                <ChartBarIcon className="mr-2 h-4 w-4" />
                                                <span className="text-nowrap">
                                                    Charts
                                                </span>
                                            </SmallButton>
                                            <SmallButton
                                                onClick={() =>
                                                    setQuestionDisplay("tables")
                                                }
                                            >
                                                <TableCellsIcon className="mr-2 h-4 w-4" />
                                                <span className="text-nowrap">
                                                    Tables
                                                </span>
                                            </SmallButton>
                                        </>
                                    )}
                                    <SmallButton
                                        onClick={() => toggleRoundId("ALL")}
                                    >
                                        <span className="text-nowrap">
                                            Show All Rounds
                                        </span>
                                        <ChevronDoubleDownIcon className="ml-2 h-3 w-3" />
                                    </SmallButton>
                                    <SmallButton
                                        onClick={() => toggleRoundId()}
                                    >
                                        <span className="text-nowrap">
                                            Hide All Rounds
                                        </span>
                                        <ChevronDoubleUpIcon className="ml-2 h-3 w-3" />
                                    </SmallButton>
                                </div>
                            )}
                        </div>
                        <div className="">
                            {selectionsGrouping === "question" &&
                                !!rounds?.length &&
                                !!selectionMap &&
                                rounds
                                    .filter(
                                        (round) =>
                                            !!round.prompts &&
                                            round.prompts_count > 0 &&
                                            round.prompts.some(
                                                (prompt) =>
                                                    !!promptMap[prompt.id] &&
                                                    !!selectionMap[prompt.id]
                                                        ?.length,
                                            ),
                                    )
                                    .map((round) => (
                                        <DiscoveryWidgetWrapper
                                            key={round.id}
                                            className="mb-4 border-indigo-100 bg-indigo-50/80"
                                        >
                                            <div
                                                className="-m-4 mb-0 flex cursor-pointer items-center justify-between rounded-md
                                                    bg-indigo-100/50 p-4 text-sm font-semibold hover:bg-indigo-100"
                                                onClick={() =>
                                                    setDisplayRoundMap({
                                                        ...displayRoundMap,
                                                        [round.id]:
                                                            !displayRoundMap[
                                                                round.id
                                                            ],
                                                    })
                                                }
                                            >
                                                {round.title}
                                            </div>
                                            {!!displayRoundMap[round.id] && (
                                                <div className="flex flex-col space-y-2">
                                                    {round.prompts
                                                        .filter(
                                                            (prompt) =>
                                                                !!promptMap[
                                                                    prompt.id
                                                                ] &&
                                                                !!selectionMap[
                                                                    prompt.id
                                                                ]?.length,
                                                        )
                                                        .map((prompt) => (
                                                            <div
                                                                key={prompt.id}
                                                                className="mt-2 border-t border-neutral-300 pt-2"
                                                            >
                                                                <DiscoveryQuestionDisplay
                                                                    key={
                                                                        prompt.id
                                                                    }
                                                                    prompt={
                                                                        prompt
                                                                    }
                                                                    round={
                                                                        round
                                                                    }
                                                                    discoveryLayerObject={
                                                                        discoveryStatisticsObject
                                                                    }
                                                                    teams={
                                                                        teams as Team[]
                                                                    }
                                                                    questionDisplay={
                                                                        questionDisplay
                                                                    }
                                                                    defaultToCharts={
                                                                        true
                                                                    }
                                                                    simpleDisplay={
                                                                        simpleDisplay
                                                                    }
                                                                />
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </DiscoveryWidgetWrapper>
                                    ))}
                            {selectionsGrouping === "type" &&
                                !!rounds?.length &&
                                !!promptMap &&
                                !!Object.keys(promptMap).length && (
                                    <DiscoverySelectionsByType
                                        discoveryLayerObject={
                                            discoveryStatisticsObject
                                        }
                                        hideCategoryQuestionScopeGrouping={true}
                                        rounds={rounds}
                                        timeHorizons={simulation.timeHorizons}
                                        displayTypeScopeMap={
                                            displayTypeScopeMap
                                        }
                                        handleDisplayTypeScopeMap={
                                            handleSetDisplayTypeScopeMap
                                        }
                                    />
                                )}
                            {selectionsGrouping === "people" &&
                                !!rounds?.length &&
                                !!selectionMap &&
                                !!promptMap &&
                                !!Object.keys(promptMap).length &&
                                // !!promptMap &&
                                !!participantMap &&
                                !!teamMap && (
                                    <DiscoverySelectionsByPeople
                                        discoveryLayerObject={
                                            discoveryStatisticsObject
                                        }
                                        rounds={rounds}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

const SmallButton: React.FC<{
    onClick?: () => void;
    children?: React.ReactNode;
}> = ({ onClick, children }) => {
    return (
        <button
            type="button"
            className={`relative mx-1 flex w-full items-center rounded-md border border-neutral-200
                bg-neutral-50 bg-opacity-50 px-2 py-1 text-center text-xs text-neutral-800
                hover:bg-neutral-100`}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            {children}
        </button>
    );
};

const RoundAndQuestionCheckboxList = ({
    rounds,
    selectedRoundIds,
    selectedPromptIds,
    toggleRoundId,
    togglePromptId,
    disabledPrompts,
}: {
    rounds: Round[];
    selectedRoundIds: {};
    selectedPromptIds: {};
    toggleRoundId: (roundId?: string) => void;
    togglePromptId: (promptId?: string) => void;
    disabledPrompts: {};
}) => {
    return (
        <ul className="space-y-1 text-sm">
            {rounds
                .filter((round) => !!round.prompts?.length)
                .map((round) => (
                    <React.Fragment key={round.id}>
                        <li
                            key={round.id}
                            className="flex items-center font-semibold text-gray-800"
                        >
                            <DiscoveryMenuItemCheckbox
                                key={round.id}
                                itemKey={round.id}
                                label={round.title}
                                checked={!!selectedRoundIds[round.id]}
                                handleOnChange={() => toggleRoundId(round.id)}
                            />
                        </li>
                        {round.prompts.map((prompt) => (
                            <li
                                key={prompt.id}
                                className="flex items-center pl-7 font-medium text-gray-600"
                            >
                                <DiscoveryMenuItemCheckbox
                                    key={prompt.id}
                                    itemKey={prompt.id}
                                    label={
                                        prompt.content.length < 40
                                            ? prompt.content
                                            : prompt.content.substring(0, 37) +
                                              "..."
                                    }
                                    checked={!!selectedPromptIds[prompt.id]}
                                    handleOnChange={() =>
                                        togglePromptId(prompt.id)
                                    }
                                    disabled={!!disabledPrompts[prompt.id]}
                                    subLabel={`${prompt.scope} — ${displayPromptType(
                                        prompt.prompt_type,
                                    )}`}
                                />
                            </li>
                        ))}
                    </React.Fragment>
                ))}
        </ul>
    );
};
