import { VariableValueFormatFunction } from "@/hooks";
import { ModelVariable, ModelVariableDataType, VariableValue } from "@/models";
import {
    ChartBlock,
    FormattedChartValue,
    InputDataItem,
    InputDataSettingType,
    InteractiveModuleChartType,
    LocalStorageInteractiveModelObject,
} from "./types";

export const getIsModelTimeVariable = (variableLabel: string) => {
    return variableLabel.toLowerCase().includes("model years");
};

const getDefaultInputDataItem = (
    variable: ModelVariable,
    modelTimespan: number,
) => {
    const defaultValue =
        variable.data_type === ModelVariableDataType.Number
            ? Number(variable.baseVariableValues[0]?.numerical_value) ||
              Number(variable.default_numerical_value)
            : Number(
                  !!variable.baseVariableValues[0]
                      ? variable.baseVariableValues[0]?.boolean_value
                      : variable.default_boolean_value,
              );
    return {
        model_variable_id: variable.id,
        label: variable.label,
        numerical_value: defaultValue,
        initial: Number(variable.minimum),
        final: Number(variable.maximum),
        setting_type: getIsModelTimeVariable(variable.label)
            ? InputDataSettingType.timespan
            : variable.data_type === ModelVariableDataType.Number
              ? InputDataSettingType.constant
              : InputDataSettingType["boolean constant"],
        exponent: 2,
        step_values: Array(modelTimespan || 0).fill(defaultValue),
    } as InputDataItem;
};

const getResetInputDataObject = (
    modelVariables: ModelVariable[],
    modelTimespan?: number,
) => {
    let defaultModelTimespan = modelTimespan;
    if (!defaultModelTimespan) {
        const modelTimeVariable =
            modelVariables?.find((variable) =>
                getIsModelTimeVariable(variable.label),
            ) ?? undefined;

        defaultModelTimespan =
            modelTimeVariable?.baseVariableValues[0]?.numerical_value ||
            modelTimeVariable?.default_numerical_value ||
            0;
    }

    const newInputDataObject = modelVariables
        ?.filter((variable) => variable.expose_to_designer)
        .reduce(
            (map, variable) => {
                return {
                    ...map,
                    [variable.id]: getDefaultInputDataItem(
                        variable,
                        defaultModelTimespan,
                    ),
                };
            },
            {} as { [index: string]: InputDataItem },
        );

    return newInputDataObject;
};

export const getNewInputDataObjectOnTimespanChange = (
    inputDataObject: {
        [model_variable_id: string]: InputDataItem;
    },
    modelTimespan: number,
) => {
    const newInputDataObject = Object.values(inputDataObject).reduce(
        (map, inputDataItem) => {
            // to do: handle step interval case differently
            const newStepValues =
                inputDataItem.setting_type !=
                InputDataSettingType["step interval"]
                    ? Array(modelTimespan || 0).fill(
                          inputDataItem.numerical_value || 0,
                      )
                    : inputDataItem.step_values.length < modelTimespan
                      ? [
                            ...inputDataItem.step_values,
                            ...Array(
                                modelTimespan -
                                    inputDataItem.step_values.length,
                            ).fill(inputDataItem.numerical_value || 0),
                        ]
                      : [...inputDataItem.step_values].slice(0, modelTimespan);
            const newNumericalValue =
                inputDataItem.setting_type != InputDataSettingType.timespan
                    ? inputDataItem.numerical_value
                    : modelTimespan;
            return {
                ...map,
                [inputDataItem.model_variable_id]: {
                    ...inputDataItem,
                    step_values: newStepValues.map((value) => Number(value)),
                    numerical_value: newNumericalValue,
                },
            };
        },
        {} as { [index: string]: InputDataItem },
    );
    return newInputDataObject;
};

export const getNewChartBlocksOnTimespanChange = (
    chartBlocks: {
        [index: string]: ChartBlock;
    },
    modelTimespan: number,
) => {
    const newChartBlocks = Object.values(chartBlocks).reduce(
        (map, chartBlock) => ({
            ...map,
            [chartBlock.id]: {
                ...chartBlock,
                time_index: modelTimespan > 0 ? modelTimespan - 1 : 0, // Math.min(modelTimespan - 1, chartBlock.time_index),
            },
        }),
        {} as { [index: string]: ChartBlock },
    );
    return newChartBlocks;
};

export const getChartValues = (
    variables: ModelVariable[],
    chartBlock: ChartBlock,
    valuesMap: {
        [index: string]: VariableValue[];
    },
    formatVariableValue: VariableValueFormatFunction,
): Record<string, FormattedChartValue[]> => {
    return (
        variables?.reduce((carry, variable, i) => {
            const values =
                !!valuesMap[variable.id] && valuesMap[variable.id]?.length > 0
                    ? chartBlock.chart_type === InteractiveModuleChartType.PIE
                        ? [
                              valuesMap[variable.id][
                                  chartBlock.time_index >= 0 &&
                                  chartBlock.time_index <=
                                      valuesMap[variable.id].length - 1
                                      ? chartBlock.time_index
                                      : valuesMap[variable.id].length - 1
                              ],
                          ]
                        : valuesMap[variable.id]
                    : [];

            return {
                ...carry,
                [variable.id]: values.map((variableValue, i) => {
                    return {
                        x: variableValue.time_index + 1,
                        y: variableValue.numerical_value,
                        label: variable.label,
                        formattedValue: formatVariableValue(
                            variable.unit,
                            variableValue.numerical_value,
                            variable.is_integer,
                        ),
                    } as FormattedChartValue;
                }),
            };
        }, {}) || {}
    );
};

export const tickFormat = (tick) => {
    if (tick >= 1000000) {
        return `${tick / 1000000}m`;
    } else if (tick >= 1000) {
        return `${tick / 1000}k`;
    }
    return tick;
};

export function powspace(
    start: number,
    stop: number,
    power: number,
    num: number,
): number[] {
    const startRoot = Math.pow(start, 1 / power);
    const stopRoot = Math.pow(stop, 1 / power);
    const linspace: number[] = Array.from(
        { length: num },
        (_, i) => startRoot + (i * (stopRoot - startRoot)) / (num - 1),
    );
    return linspace.map((val) => Math.pow(val, power));
}

// export function filterValuesMap(
//     variableIds: string[],
//     valuesMap: { [variableId: string]: VariableValue[] },
// ): { [variableId: string]: VariableValue[] } {
//     return variableIds.reduce(
//         (filteredMap, id) => {
//             if (!!valuesMap[id]) {
//                 filteredMap = { ...filteredMap, [id]: valuesMap[id] };
//             }
//             return filteredMap;
//         },
//         {} as { [variableId: string]: VariableValue[] },
//     );
// }

// export function calculatePercentChange(
//     values: VariableValue[],
//     timespan: number,
// ): number | null {
//     if (!values || values?.length === 0 || !(timespan > 0)) {
//         return null;
//     }
//     let firstNonZeroIndex = -1;
//     let lastIndex = values.length - 1;
//     for (let i = 0; i < values.length; i++) {
//         if (values[i].numerical_value !== 0) {
//             firstNonZeroIndex = i;
//             break;
//         }
//     }
//     if (firstNonZeroIndex === -1) {
//         return null;
//     }
//     const firstValue = values[firstNonZeroIndex].numerical_value;
//     const lastValue = values[lastIndex].numerical_value;
//     // CAGR = (FV / PV) ^ (1 / n) – 1
//     const percentChange =
//         (Math.pow(lastValue / firstValue, 1 / timespan) - 1) * 100;
//     return Math.round(percentChange);
// }

export const calculateRangeIncrement = (
    min: number,
    max: number,
    isInteger: boolean,
): number => {
    const range = max - min;
    if (range <= 10) {
        return isInteger ? 1 : 0.1;
    }

    if (range <= 200) {
        return 1;
    }

    const powerOfTen = Math.floor(Math.log10(range));
    let increment = Math.pow(10, powerOfTen - 1);

    const divisors = [2, 1, 5, 10];

    for (const divisor of divisors) {
        if (range % (increment / divisor) === 0) {
            return increment / divisor;
        }
    }
    return 1;
};

export const adjustNumericalInputValue = (
    min: number,
    max: number,
    step: number,
    value: number,
) => {
    let newValue = Math.round(Math.min(max, Math.max(min, value)) * 100) / 100;
    const remainder = Math.round((newValue * 100) % (step * 100)) / 100;
    if (remainder !== 0) {
        newValue = Math.round((newValue - remainder) * 100) / 100;
    }
    return newValue;
};

export const getLocalStorageInteractiveModelObject = (modelBlockId: string) => {
    if (!!modelBlockId) {
        const localStorageItem = localStorage.getItem(
            `interactive-model-${modelBlockId}`,
        );
        const localStorageObject: LocalStorageInteractiveModelObject =
            !!localStorageItem ? JSON.parse(localStorageItem) : {};
        return localStorageObject;
    } else {
        return undefined;
    }
};

export const setLocalStorageInteractiveModelObject = (
    modelBlockId: string,
    chartBlocks: {
        [index: string]: ChartBlock;
    },
    variableWatchlist: Record<string, boolean>,
    archetypeChartVariables: Record<string, boolean>,
) => {
    localStorage.setItem(
        `interactive-model-${modelBlockId}`,
        JSON.stringify({
            settings: {},
            chartBlocks:
                Object.values(chartBlocks)
                    ?.filter((block) => !block.created_at)
                    .reduce(
                        (carry, block) => ({ ...carry, [block.id]: block }),
                        {},
                    ) ?? {},
            variableWatchlist: variableWatchlist,
            archetypeChartVariables: archetypeChartVariables,
        } as LocalStorageInteractiveModelObject),
    );
};

const getIsInputDataObjectUnsynced = (
    inputDataObject: Record<string, InputDataItem>,
    modelVariables: ModelVariable[],
) => {
    return modelVariables?.some(
        (variable) =>
            (variable.expose_to_designer && !inputDataObject[variable.id]) ||
            (!variable.expose_to_designer && !!inputDataObject[variable.id]),
    );
};

export const processInputDataObject = (
    modelVariables: ModelVariable[],
    inputDataObject: Record<string, InputDataItem>,
    defaultInputDataObject?: Record<string, InputDataItem>,
) => {
    if (!Object.keys(inputDataObject)?.length) {
        if (!!defaultInputDataObject) {
            inputDataObject = defaultInputDataObject;
        } else {
            inputDataObject = getResetInputDataObject(modelVariables);
        }
    }
    if (getIsInputDataObjectUnsynced(inputDataObject, modelVariables)) {
        let timespan =
            Object.values(inputDataObject)?.find(
                (item) => item.setting_type == InputDataSettingType.timespan,
            )?.numerical_value ?? undefined;

        const filteredInputDataObject = modelVariables
            ?.filter(
                (variable) =>
                    variable.expose_to_designer &&
                    !!inputDataObject[variable.id],
            )
            .reduce(
                (map, variable) => {
                    return {
                        ...map,
                        [variable.id]: inputDataObject[variable.id],
                    };
                },
                {} as { [index: string]: InputDataItem },
            );

        inputDataObject = {
            ...getResetInputDataObject(modelVariables, timespan),
            ...filteredInputDataObject,
        };
    }
    return inputDataObject;
};

export const getFullVariableWatchlist = (
    newVariableWatchlist: Record<string, boolean>,
    modelVariables: ModelVariable[],
) => {
    if (
        !Object.keys(newVariableWatchlist)?.length ||
        modelVariables.some(
            (variable) => newVariableWatchlist[variable.id] === undefined,
        )
    ) {
        newVariableWatchlist = modelVariables.reduce(
            (map, variable) => {
                return {
                    ...map,
                    [variable.id]:
                        newVariableWatchlist[variable.id] !== undefined
                            ? newVariableWatchlist[variable.id]
                            : getIsModelTimeVariable(variable.label)
                              ? false
                              : variable.expose_to_facilitator &&
                                variable.data_type ===
                                    ModelVariableDataType.Number,
                };
            },
            {} as { [index: string]: boolean },
        );
    }
    return newVariableWatchlist;
};

export const getTimespanFromInputDataObject = (
    modelTimeVariableId: string,
    inputDataObject: Record<string, InputDataItem>,
) => {
    if (
        Object.keys(inputDataObject)?.length > 0 &&
        !!modelTimeVariableId &&
        !!inputDataObject[modelTimeVariableId]
    ) {
        return inputDataObject[modelTimeVariableId].numerical_value;
    } else {
        return undefined;
    }
};
