import React, { useEffect, useMemo, useState } from "react";
import {
    ModelVariableUnit,
    Prompt,
    PromptType,
    VariableRelationshipOperation,
} from "@/models";
import { FormInputSmall, H4ThinSpan } from "@/components";
import { IconButton, ModalContainer } from "../../modules/shared";
import { x } from "react-icons-kit/feather/x";
import { SapienInertia, useModelBuilderPage } from "@/inertia-utils/hooks";
import { useModelBlockArray } from "@/hooks/store";
import { SparklesIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import {
    displayPromptType,
    displayVariableRelationshipOperation,
} from "@/util";

export type QuestionSubmoduleData = {
    simulation_id: string;
    round_id: string;
    prompt_id: string;
    unit: ModelVariableUnit;
    modelBlockLabel: string;
    parent_model_block_id: string;
    optionIds: string[];
    independentVariableLabels: string[];
    dependentVariableLabels: string[];
    independentVariableNumericalValues: number[];
    comparisonVariableNumericalValues: number[];
};

export const createNewQuestionSubmoduleData = ({
    simulationId,
    roundId,
    prompt,
}: {
    simulationId: string;
    roundId: string;
    prompt: Prompt;
}): QuestionSubmoduleData => {
    return {
        simulation_id: simulationId,
        round_id: roundId,
        prompt_id: prompt.id,
        unit: null,
        modelBlockLabel: "",
        parent_model_block_id: null,
        optionIds: prompt.options.map((option) => option.id),
        independentVariableLabels: prompt.options.map(() => ""),
        dependentVariableLabels: [""],
        independentVariableNumericalValues: prompt.options.map(() => 0),
        comparisonVariableNumericalValues: [],
    };
};

type UtilityObject = {
    remove: string;
    prepend: string;
    append: string;
};
const initialUtilityMap = {
    independent: {
        remove: "",
        prepend: "",
        append: "",
    },
    dependent: {
        remove: "",
        prepend: "",
        append: "",
    },
};

export const QuestionSubmoduleForm = ({
    questionSubmoduleData,
    setQuestionSubmoduleData,
}: {
    questionSubmoduleData: QuestionSubmoduleData;
    setQuestionSubmoduleData: (
        questionSubmoduleData: QuestionSubmoduleData,
    ) => void;
}) => {
    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { rounds } = modelBuilderPageProps;

    const { modelBlockArray } = useModelBlockArray();

    const createQuestionSubmodule = async (
        submoduleData: QuestionSubmoduleData,
    ) => {
        return SapienInertia.post(
            "model-builder.create-question-submodule",
            submoduleData,
            {},
            { preserveScroll: true },
        );
    };

    const [prompt, setPrompt] = useState<Prompt>();

    const [optionMap, setOptionMap] = useState<{
        [index: string]: {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            dependentVariableLabel: string;
        };
    }>();

    const [segmentMap, setSegmentMap] = useState<
        {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            rightValue: number;
        }[]
    >();

    const [isBusy, setIsBusy] = useState(false);

    const [showWizard, setShowWizard] = useState(false);

    const [utilityMap, setUtilityMap] = useState<{
        independent: UtilityObject;
        dependent: UtilityObject;
    }>(initialUtilityMap);

    // to do: utility for more visual presentation of results
    // const [numericalResultConstraints, setNumericalResultConstraints] =
    //     useState<{
    //         min: number;
    //         max: number;
    //         increment: number;
    //     }>({ min: 0, max: 10, increment: 0 });

    useEffect(() => {
        if (
            !!questionSubmoduleData?.prompt_id &&
            !!questionSubmoduleData?.round_id &&
            !!rounds?.length &&
            !!rounds.find(
                (round) => round.id === questionSubmoduleData.round_id,
            ) &&
            prompt?.id !== questionSubmoduleData.prompt_id
        ) {
            const newPrompt = rounds
                .find((round) => round.id === questionSubmoduleData.round_id)
                .prompts?.find(
                    (prompt) => prompt.id === questionSubmoduleData.prompt_id,
                );
            setPrompt(newPrompt);
        } else {
            setPrompt(undefined);
            setShowWizard(false);
            // setUtilityMap(initialUtilityMap);
        }
    }, [questionSubmoduleData?.prompt_id]);

    const isSingleResultVariableQuestion = useMemo(() => {
        return (
            !!prompt &&
            prompt.prompt_type !== PromptType["Multiple Select"] &&
            prompt.prompt_type !== PromptType["Drag and Drop"] &&
            prompt.prompt_type !== PromptType.Timeline
        );
    }, [prompt]);

    const isNumericalQuestion = useMemo(() => {
        return (
            !!prompt &&
            (prompt.prompt_type === PromptType["Numerical Input"] ||
                prompt.prompt_type === PromptType["Numerical Slider"])
        );
    }, [prompt]);

    const potentialSelectionNumericalValuesCount = useMemo(() => {
        return !!prompt &&
            (prompt.prompt_type === PromptType["Numerical Input"] ||
                prompt.prompt_type === PromptType["Numerical Slider"]) &&
            prompt.max > prompt.min &&
            prompt.increment !== 0
            ? (prompt.max - prompt.min) / prompt.increment + 1
            : undefined;
    }, [prompt]);

    // set initial optionMap or segmentMap based on prompt
    useEffect(() => {
        if (!!prompt) {
            setOptionMap(
                prompt.options.reduce((map, option, i) => {
                    return {
                        ...map,
                        [option.id]: {
                            independentVariableLabel: "",
                            independentVariableNumericalValue:
                                prompt.prompt_type === PromptType["Rank Order"]
                                    ? i + 1
                                    : 0,
                            dependentVariableLabel: "",
                        },
                    };
                }, {}),
            );
            if (
                prompt.prompt_type === PromptType["Numerical Input"] ||
                prompt.prompt_type === PromptType["Numerical Slider"]
            ) {
                if (
                    prompt.max > prompt.min &&
                    prompt.increment !== 0 &&
                    (prompt.max - prompt.min) / prompt.increment + 1 <= 6
                ) {
                    let newSegmentMap = [];
                    for (
                        let i = prompt.min;
                        i <= prompt.max;
                        i += prompt.increment
                    ) {
                        newSegmentMap = [
                            ...newSegmentMap,
                            {
                                independentVariableLabel: "",
                                independentVariableNumericalValue: 0,
                                rightValue: i,
                            },
                        ];
                    }
                    setSegmentMap(newSegmentMap);
                } else {
                    setSegmentMap([
                        {
                            independentVariableLabel: "",
                            independentVariableNumericalValue: 0,
                            rightValue: prompt.min + prompt.increment,
                        },
                        {
                            independentVariableLabel: "",
                            independentVariableNumericalValue: 0,
                            rightValue: prompt.min + prompt.increment,
                        },
                    ]);
                }
            }
        } else {
            setOptionMap(undefined);
            setSegmentMap(undefined);
        }
    }, [prompt?.id]);

    // update questionSubmoduleData when optionMap changes for non-numerical questions
    useEffect(() => {
        if (
            !!optionMap &&
            !!questionSubmoduleData &&
            !!prompt &&
            prompt.prompt_type !== PromptType["Numerical Input"] &&
            prompt.prompt_type !== PromptType["Numerical Slider"]
        ) {
            let independentVariableLabels = [];
            let dependentVariableLabels = [];
            let independentVariableNumericalValues = [];
            let optionIds = [];

            prompt.options.forEach((option) => {
                if (!!optionMap[option.id]) {
                    independentVariableLabels = [
                        ...independentVariableLabels,
                        optionMap[option.id].independentVariableLabel,
                    ];
                    independentVariableNumericalValues = [
                        ...independentVariableNumericalValues,
                        optionMap[option.id].independentVariableNumericalValue,
                    ];
                    optionIds = [...optionIds, option.id];

                    if (!isSingleResultVariableQuestion) {
                        dependentVariableLabels = [
                            ...dependentVariableLabels,
                            optionMap[option.id].dependentVariableLabel,
                        ];
                    }
                }
            });

            const updatedData = {
                ...questionSubmoduleData,
                independentVariableLabels,
                independentVariableNumericalValues,
                optionIds,
                dependentVariableLabels: isSingleResultVariableQuestion
                    ? questionSubmoduleData.dependentVariableLabels
                    : dependentVariableLabels,
            };
            setQuestionSubmoduleData(updatedData as QuestionSubmoduleData);
        }
    }, [optionMap]);

    // update questionSubmoduleData when segmentMap changes for numerical questions
    useEffect(() => {
        if (
            !!segmentMap &&
            !!questionSubmoduleData &&
            !!prompt &&
            (prompt.prompt_type === PromptType["Numerical Input"] ||
                prompt.prompt_type === PromptType["Numerical Slider"])
        ) {
            let independentVariableLabels = segmentMap.map(
                (segment) => segment.independentVariableLabel,
            );
            let independentVariableNumericalValues = segmentMap.map(
                (segment) => segment.independentVariableNumericalValue,
            );
            let comparisonVariableNumericalValues = segmentMap
                .map((segment) => segment.rightValue)
                .slice(0, segmentMap.length - 1);

            const updatedData = {
                ...questionSubmoduleData,
                independentVariableLabels,
                independentVariableNumericalValues,
                comparisonVariableNumericalValues,
            };
            setQuestionSubmoduleData(updatedData as QuestionSubmoduleData);
        }
    }, [segmentMap]);

    const updateQuestionSubmoduleData = (
        prop: keyof QuestionSubmoduleData,
        value: any,
    ) => {
        const updatedData = {
            ...questionSubmoduleData,
            ...{ [prop]: value },
        };
        setQuestionSubmoduleData(updatedData as QuestionSubmoduleData);
    };

    const updateUtilityMap = (
        mapKey: "independent" | "dependent",
        objectProperty: keyof UtilityObject,
        value: string,
    ) => {
        const updatedMap = {
            ...utilityMap,
            ...{
                [mapKey]: {
                    ...utilityMap[mapKey],
                    [objectProperty]: value,
                } as UtilityObject,
            },
        };
        setUtilityMap(updatedMap);
    };

    const generateLabels = (mapKey: "independent" | "dependent") => {
        if (
            !!prompt?.selectionDataModelVariables &&
            !!utilityMap[mapKey] // &&
            // (!!utilityMap[mapKey].remove.length ||
            //     !!utilityMap[mapKey].prepend.length ||
            //     !!utilityMap[mapKey].append.length)
        ) {
            if (!isNumericalQuestion) {
                setOptionMap(
                    prompt.selectionDataModelVariables.reduce(
                        (map, selectionDataModelVariable) => {
                            if (
                                !!optionMap[
                                    selectionDataModelVariable.option_id
                                ]
                            ) {
                                const newLabel = `${
                                    utilityMap[mapKey].prepend || ""
                                }${selectionDataModelVariable.label.replace(
                                    utilityMap[mapKey].remove || "",
                                    "",
                                )}${utilityMap[mapKey].append || ""}`;
                                return {
                                    ...map,
                                    [selectionDataModelVariable.option_id]: {
                                        ...optionMap[
                                            selectionDataModelVariable.option_id
                                        ],
                                        [mapKey == "independent"
                                            ? "independentVariableLabel"
                                            : "dependentVariableLabel"]:
                                            newLabel,
                                    },
                                };
                            } else {
                                return map;
                            }
                        },
                        {},
                    ),
                );
            } else {
                setSegmentMap(
                    [...segmentMap].map((segment, i) => {
                        const newLabel = `${
                            utilityMap[mapKey].prepend || ""
                        }${prompt?.selectionDataModelVariables[0]?.label.replace(
                            utilityMap[mapKey].remove || "",
                            "",
                        )}${utilityMap[mapKey].append || ""}`;

                        return {
                            ...segment,
                            independentVariableLabel: newLabel,
                        };
                    }),
                );
            }
        }
    };

    return (
        <ModalContainer
            isModalOpen={!!questionSubmoduleData}
            setIsModalOpen={() => setQuestionSubmoduleData(undefined)}
            styles={{}}
            size={"xl"}
        >
            <div className="px-4">
                <div className="pb-4 pt-4">
                    <div className="flex flex-row items-center justify-between">
                        <H4ThinSpan>{`Create Question Submodule`}</H4ThinSpan>
                        <span>
                            <IconButton
                                icon={x}
                                handler={() => {
                                    setQuestionSubmoduleData(undefined);
                                }}
                                isDisabled={false}
                            />
                        </span>
                    </div>
                </div>

                <div
                    className="mb-5 flex flex-row items-center justify-between space-x-2 border-b
                        border-gray-200"
                >
                    <div className="text-sm">{prompt?.content}</div>
                    <div className="text-xs uppercase text-neutral-600">
                        {displayPromptType(prompt?.prompt_type)}
                    </div>
                </div>

                <div className="mb-4">
                    <div className="mb-2 text-sm font-semibold text-gray-900">
                        Model Block Label
                    </div>
                    <input
                        type="text"
                        placeholder=""
                        value={questionSubmoduleData.modelBlockLabel}
                        onChange={(e) => {
                            updateQuestionSubmoduleData(
                                "modelBlockLabel",
                                e.target.value,
                            );
                        }}
                        className="w-full rounded-md border-gray-300 bg-transparent focus:border-blue-500
                            focus:ring-blue-500 sm:text-sm"
                    />
                </div>

                <div className="mb-4">
                    <div className="mb-2 text-sm font-semibold text-gray-900">
                        Model Block Parent
                    </div>
                    <select
                        onChange={(e) =>
                            updateQuestionSubmoduleData(
                                "parent_model_block_id",
                                e.target.value,
                            )
                        }
                        value={
                            questionSubmoduleData.parent_model_block_id ||
                            undefined
                        }
                        className="w-full rounded-md border-gray-300 bg-transparent focus:border-blue-500
                            focus:ring-blue-500 sm:text-sm"
                    >
                        <option>---</option>
                        {modelBlockArray
                            .filter((modelBlock) => !modelBlock.has_connections)
                            .map((modelBlock) => (
                                <option
                                    value={modelBlock.id}
                                    key={modelBlock.id}
                                >
                                    {modelBlock.label}
                                </option>
                            ))}
                    </select>
                </div>

                {isSingleResultVariableQuestion && (
                    <div className="mb-4">
                        <div className="mb-2 text-sm font-semibold text-gray-900">
                            Result Variable Label
                        </div>
                        <input
                            type="text"
                            placeholder=""
                            value={
                                questionSubmoduleData.dependentVariableLabels[0]
                            }
                            onChange={(e) => {
                                updateQuestionSubmoduleData(
                                    "dependentVariableLabels",
                                    [e.target.value],
                                );
                            }}
                            className="w-full rounded-md border-gray-300 bg-transparent focus:border-blue-500
                                focus:ring-blue-500 sm:text-sm"
                        />
                    </div>
                )}

                {(prompt?.prompt_type !== PromptType["Rank Order"] ||
                    !!questionSubmoduleData.comparisonVariableNumericalValues
                        ?.length) && (
                    <UnitFormField
                        questionSubmoduleData={questionSubmoduleData}
                        updateQuestionSubmoduleData={
                            updateQuestionSubmoduleData
                        }
                    />
                )}

                <div
                    className={`mb-4 rounded-md border ${showWizard ? "border-blue-700" : "border-white"}`}
                >
                    <div className="">
                        <button
                            type="button"
                            className={`inline-flex w-full items-center justify-center rounded-md border ${
                                !showWizard ? "border-blue-700" : "border-white"
                            } px-3.5 py-2 text-center text-sm
                            font-medium text-blue-700 hover:bg-blue-50 focus:outline-none focus:ring-0`}
                            disabled={false}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowWizard(!showWizard);
                            }}
                        >
                            <SparklesIcon className="h-5 w-5" />
                            <span className="ml-2">Variable Utilities</span>
                        </button>
                    </div>
                    {showWizard && (
                        <div className={"m-4 text-xs"}>
                            <WizardLabelUtilityForm
                                title={"Generate Independent Variable Labels"}
                                utilityMapKey={"independent"}
                                utilityMap={utilityMap}
                                updateUtilityMap={updateUtilityMap}
                                generateLabels={generateLabels}
                            />
                            {isNumericalQuestion &&
                                !!prompt?.selectionDataModelVariables
                                    ?.length && (
                                    <div className="mb-4 text-gray-900">
                                        <div className="font-semibold">{`Selection Data Variable:`}</div>
                                        <div>{`${prompt.selectionDataModelVariables[0].label}`}</div>
                                    </div>
                                )}
                            {!isSingleResultVariableQuestion && (
                                <WizardLabelUtilityForm
                                    title={
                                        "Generate Conditional Variable Labels"
                                    }
                                    utilityMapKey={"dependent"}
                                    utilityMap={utilityMap}
                                    updateUtilityMap={updateUtilityMap}
                                    generateLabels={generateLabels}
                                />
                            )}
                            {!isNumericalQuestion &&
                                prompt?.prompt_type !==
                                    PromptType["Rank Order"] && (
                                    <div className="flex items-center font-semibold text-gray-900">
                                        <span>Use Option Numerical Values</span>
                                        <button
                                            type="button"
                                            className="ml-2 inline-flex items-center rounded-full p-1 hover:bg-gray-50
                                                focus:outline-none focus:ring-0"
                                            disabled={false}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (!!prompt) {
                                                    setOptionMap(
                                                        prompt.options.reduce(
                                                            (map, option) => {
                                                                if (
                                                                    !!optionMap[
                                                                        option
                                                                            .id
                                                                    ]
                                                                ) {
                                                                    return {
                                                                        ...map,
                                                                        [option.id]:
                                                                            {
                                                                                ...optionMap[
                                                                                    option
                                                                                        .id
                                                                                ],
                                                                                independentVariableNumericalValue:
                                                                                    Number(
                                                                                        option.numerical_value,
                                                                                    ),
                                                                            },
                                                                    };
                                                                } else {
                                                                    return map;
                                                                }
                                                            },
                                                            {},
                                                        ),
                                                    );
                                                }
                                            }}
                                        >
                                            <ArrowPathIcon className="h-4 w-4" />
                                        </button>
                                    </div>
                                )}
                        </div>
                    )}
                </div>

                {prompt?.prompt_type === PromptType["Rank Order"] && (
                    <RankingSubmoduleForm
                        prompt={prompt}
                        optionMap={optionMap}
                        setOptionMap={setOptionMap}
                        questionSubmoduleData={questionSubmoduleData}
                        updateQuestionSubmoduleData={
                            updateQuestionSubmoduleData
                        }
                    />
                )}

                {!!prompt && !!segmentMap && isNumericalQuestion && (
                    <NumericalSubmoduleForm
                        prompt={prompt}
                        segmentMap={segmentMap}
                        setSegmentMap={setSegmentMap}
                        potentialSelectionNumericalValuesCount={
                            potentialSelectionNumericalValuesCount
                        }
                        questionSubmoduleData={questionSubmoduleData}
                        updateQuestionSubmoduleData={
                            updateQuestionSubmoduleData
                        }
                    />
                )}

                {!!prompt && !!optionMap && !isNumericalQuestion && (
                    <NonNumericalSubmoduleTable
                        prompt={prompt}
                        isSingleResultVariableQuestion={
                            isSingleResultVariableQuestion
                        }
                        optionMap={optionMap}
                        setOptionMap={setOptionMap}
                        questionSubmoduleData={questionSubmoduleData}
                    />
                )}

                <div className="pb-2">
                    <button
                        type="submit"
                        className="mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white
                            hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300
                            disabled:bg-blue-800"
                        disabled={isBusy}
                        onClick={async (e) => {
                            e.preventDefault();
                            setIsBusy(true);

                            // basic validation
                            const optionCount =
                                questionSubmoduleData.optionIds.length;
                            if (
                                !!questionSubmoduleData.modelBlockLabel &&
                                questionSubmoduleData.independentVariableLabels.every(
                                    (label) => !!label,
                                ) &&
                                questionSubmoduleData.dependentVariableLabels.every(
                                    (label) => !!label,
                                ) &&
                                ((!isNumericalQuestion &&
                                    optionCount ===
                                        questionSubmoduleData
                                            .independentVariableLabels.length &&
                                    optionCount ===
                                        questionSubmoduleData
                                            .independentVariableNumericalValues
                                            .length &&
                                    (prompt?.prompt_type !==
                                        PromptType["Rank Order"] ||
                                        questionSubmoduleData
                                            .comparisonVariableNumericalValues
                                            .length === 1 ||
                                        (optionCount ===
                                            prompt?.options?.length &&
                                            prompt?.options?.every(
                                                (_option, i) =>
                                                    questionSubmoduleData.independentVariableNumericalValues.indexOf(
                                                        i + 1,
                                                    ) > -1,
                                            )))) ||
                                    (isNumericalQuestion &&
                                        questionSubmoduleData
                                            .comparisonVariableNumericalValues
                                            .length ===
                                            new Set(
                                                questionSubmoduleData.comparisonVariableNumericalValues,
                                            ).size &&
                                        (!potentialSelectionNumericalValuesCount ||
                                            questionSubmoduleData.comparisonVariableNumericalValues.every(
                                                (value, i) =>
                                                    value >= prompt.min &&
                                                    value < prompt.max &&
                                                    value % prompt.increment ===
                                                        0 &&
                                                    (i === 0 ||
                                                        questionSubmoduleData
                                                            .comparisonVariableNumericalValues[
                                                            i - 1
                                                        ] < value),
                                            )) &&
                                        questionSubmoduleData
                                            .comparisonVariableNumericalValues
                                            .length ===
                                            questionSubmoduleData
                                                .independentVariableLabels
                                                .length -
                                                1 &&
                                        questionSubmoduleData
                                            .independentVariableLabels
                                            .length ===
                                            questionSubmoduleData
                                                .independentVariableNumericalValues
                                                .length)) &&
                                ((isSingleResultVariableQuestion &&
                                    questionSubmoduleData
                                        .dependentVariableLabels.length ===
                                        1) ||
                                    (!isSingleResultVariableQuestion &&
                                        optionCount ===
                                            questionSubmoduleData
                                                .dependentVariableLabels
                                                .length))
                            ) {
                                createQuestionSubmodule(questionSubmoduleData);
                                setQuestionSubmoduleData(undefined);
                            } else {
                                alert("Complete all form fields.");
                            }

                            setIsBusy(false);
                        }}
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        className="mb-2 mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium
                            text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4
                            focus:ring-red-300"
                        disabled={isBusy}
                        onClick={(e) => {
                            e.preventDefault();
                            setQuestionSubmoduleData(undefined);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalContainer>
    );
};

const NonNumericalSubmoduleTable = ({
    prompt,
    isSingleResultVariableQuestion,
    optionMap,
    setOptionMap,
    questionSubmoduleData,
}: {
    prompt: Prompt;
    isSingleResultVariableQuestion: boolean;
    optionMap: {
        [index: string]: {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            dependentVariableLabel: string;
        };
    };
    setOptionMap: (optionMap: {
        [index: string]: {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            dependentVariableLabel: string;
        };
    }) => void;
    questionSubmoduleData: QuestionSubmoduleData;
}) => {
    return (
        <div className="relative mb-4 overflow-x-auto">
            <table
                className="h-full w-full table-auto text-left text-xs text-gray-500"
                style={{
                    fontSize: "0.8rem",
                }}
            >
                <thead className="uppercase text-gray-700">
                    <tr className="border">
                        <th className="px-1 py-2"></th>
                        <th className="px-1 py-2">Option</th>
                        <th className="px-1 py-2">Selection Data Variable</th>
                        <th className="px-1 py-2">Independent Variable</th>
                        <th className="px-1 py-2">
                            {prompt?.prompt_type === PromptType["Rank Order"] &&
                            questionSubmoduleData
                                ?.comparisonVariableNumericalValues?.length ===
                                0
                                ? "Ideal Position"
                                : "Numerical Value"}
                        </th>
                        {!isSingleResultVariableQuestion && (
                            <th className="px-1 py-2">Conditional Variable</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {prompt.options.map((option) => (
                        <tr
                            key={option.id}
                            className={`table-row h-full border ${!optionMap[option.id] && "text-gray-300"}`}
                        >
                            <td className="px-1 py-1">
                                <input
                                    type="checkbox"
                                    className="mx-1 h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                        focus:ring-blue-500"
                                    checked={!!optionMap[option.id]}
                                    disabled={
                                        prompt?.prompt_type ===
                                            PromptType["Rank Order"] &&
                                        questionSubmoduleData
                                            ?.comparisonVariableNumericalValues
                                            ?.length === 0
                                    }
                                    onChange={() =>
                                        !!optionMap[option.id]
                                            ? setOptionMap(
                                                  Object.keys(optionMap).reduce(
                                                      (map, optionId) => {
                                                          return optionId ===
                                                              option.id
                                                              ? map
                                                              : {
                                                                    ...map,
                                                                    [optionId]:
                                                                        optionMap[
                                                                            optionId
                                                                        ],
                                                                };
                                                      },
                                                      {},
                                                  ),
                                              )
                                            : setOptionMap({
                                                  ...optionMap,
                                                  [option.id]: {
                                                      independentVariableLabel:
                                                          "",
                                                      independentVariableNumericalValue: 0,
                                                      dependentVariableLabel:
                                                          "",
                                                  },
                                              })
                                    }
                                />
                            </td>
                            <td className="px-1 py-1">{option.content}</td>
                            <td className="px-1 py-1">
                                {
                                    prompt.selectionDataModelVariables.find(
                                        (modelVariable) =>
                                            modelVariable.option_id ===
                                            option.id,
                                    )?.label
                                }
                            </td>
                            <td className="px-1 py-1">
                                <textarea
                                    rows={1}
                                    className="block min-h-[54px] w-full min-w-[200px] rounded-md border border-gray-300
                                        bg-transparent p-2 text-xs text-gray-700 focus:border-blue-500
                                        focus:ring-blue-500"
                                    value={
                                        optionMap[option.id]
                                            ?.independentVariableLabel || ""
                                    }
                                    onChange={(e) => {
                                        setOptionMap({
                                            ...optionMap,
                                            [option.id]: {
                                                ...optionMap[option.id],
                                                independentVariableLabel:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                    disabled={!optionMap[option.id]}
                                />
                            </td>
                            <td className="px-1 py-1">
                                {/* {use dropdown for ranking proximity} */}
                                {prompt?.prompt_type ===
                                    PromptType["Rank Order"] &&
                                questionSubmoduleData
                                    ?.comparisonVariableNumericalValues
                                    ?.length === 0 ? (
                                    <select
                                        onChange={(e) => {
                                            setOptionMap({
                                                ...optionMap,
                                                [option.id]: {
                                                    ...optionMap[option.id],
                                                    independentVariableNumericalValue:
                                                        Number(
                                                            e.target.value,
                                                        ) || 0,
                                                },
                                            });
                                        }}
                                        value={
                                            optionMap[option.id]
                                                ?.independentVariableNumericalValue ||
                                            0
                                        }
                                        className="w-full rounded-md border-gray-300 bg-transparent focus:border-blue-500
                                            focus:ring-blue-500 sm:text-sm"
                                    >
                                        {prompt?.options?.map((option, i) => (
                                            <option
                                                value={i + 1}
                                                key={option.id}
                                            >
                                                {i + 1}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        type="number"
                                        placeholder=""
                                        className="w-full min-w-[120px] rounded-md border-gray-300 bg-transparent
                                            focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        value={
                                            optionMap[option.id]
                                                ?.independentVariableNumericalValue ||
                                            0
                                        }
                                        onChange={(e) => {
                                            setOptionMap({
                                                ...optionMap,
                                                [option.id]: {
                                                    ...optionMap[option.id],
                                                    independentVariableNumericalValue:
                                                        Number(
                                                            e.target.value,
                                                        ) || 0,
                                                },
                                            });
                                        }}
                                        disabled={!optionMap[option.id]}
                                    />
                                )}
                            </td>
                            {!isSingleResultVariableQuestion && (
                                <td className="px-1 py-1">
                                    <textarea
                                        rows={1}
                                        className="block min-h-[54px] w-full min-w-[200px] rounded-md border border-gray-300
                                            bg-transparent p-2 text-xs text-gray-700 focus:border-blue-500
                                            focus:ring-blue-500"
                                        value={
                                            optionMap[option.id]
                                                ?.dependentVariableLabel || ""
                                        }
                                        onChange={(e) => {
                                            setOptionMap({
                                                ...optionMap,
                                                [option.id]: {
                                                    ...optionMap[option.id],
                                                    dependentVariableLabel:
                                                        e.target.value,
                                                },
                                            });
                                        }}
                                        disabled={!optionMap[option.id]}
                                    />
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const NumericalSubmoduleForm = ({
    prompt,
    segmentMap,
    setSegmentMap,
    potentialSelectionNumericalValuesCount,
    questionSubmoduleData,
    updateQuestionSubmoduleData,
}: {
    prompt: Prompt;
    segmentMap: {
        independentVariableLabel: string;
        independentVariableNumericalValue: number;
        rightValue: number;
    }[];
    setSegmentMap: (
        segmentMap: {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            rightValue: number;
        }[],
    ) => void;
    potentialSelectionNumericalValuesCount: number;
    questionSubmoduleData: QuestionSubmoduleData;
    updateQuestionSubmoduleData: (
        prop: keyof QuestionSubmoduleData,
        value: any,
    ) => void;
}) => {
    const updateComparisonValue = (value: number, i: number) => {
        if (
            // value doesn't exist already
            questionSubmoduleData.comparisonVariableNumericalValues.indexOf(
                value,
            ) === -1 &&
            // either there are infinite selection options
            (!potentialSelectionNumericalValuesCount ||
                // or there are finite options (because of min/max/increment) and value is in range
                (!!potentialSelectionNumericalValuesCount &&
                    value < prompt?.max &&
                    value >= prompt?.min))
        ) {
            let newValues = [
                ...questionSubmoduleData.comparisonVariableNumericalValues,
            ];
            newValues[i] = value;
            newValues.sort((a, b) => a - b);

            // map over segmentMap and update rightValue for all but last segment
            let newSegmentMap = [...segmentMap].map((segment, i) => ({
                ...segment,
                rightValue:
                    i < segmentMap.length - 1 ? newValues[i] : newValues[i - 1], // segment.rightValue,
            }));

            setSegmentMap(newSegmentMap);
        }
    };

    return (
        <>
            <div className="mb-4 flex items-center">
                <label className="mr-2 text-sm font-semibold text-gray-900">
                    Numerical Comparison Count
                </label>
                <FormInputSmall
                    className="ml-2 disabled:bg-gray-100"
                    type="number"
                    min="2"
                    max={Math.min(
                        potentialSelectionNumericalValuesCount || 10,
                        10,
                    )}
                    value={segmentMap?.length}
                    onChange={(e) => {
                        const newCount =
                            Number(e.target.value) < 2
                                ? 2
                                : Math.min(
                                      Number(e.target.value),
                                      potentialSelectionNumericalValuesCount ||
                                          10,
                                      10,
                                  );

                        let newSegmentMap = [...segmentMap];

                        if (newCount > segmentMap?.length) {
                            for (
                                let i = 0;
                                i < newCount - segmentMap?.length;
                                i++
                            ) {
                                newSegmentMap = [
                                    ...newSegmentMap,
                                    {
                                        independentVariableLabel: "",
                                        independentVariableNumericalValue: 0,
                                        rightValue:
                                            // to do
                                            newSegmentMap[
                                                segmentMap?.length - 1
                                            ].rightValue,
                                    },
                                ];
                            }
                        } else {
                            newSegmentMap = newSegmentMap.slice(0, newCount);
                        }

                        setSegmentMap(newSegmentMap);
                    }}
                />
                <label className="ml-4 text-sm text-gray-900">
                    {prompt?.min < prompt?.max
                        ? `(prompt range: min ${prompt?.min} to max ${prompt?.max})`
                        : `(prompt range: none)`}
                </label>
            </div>
            <div className="wrapper height-[100px] relative m-4 flex items-center">
                <div className="input-wrapper height-[16px] absolute w-full">
                    {!!questionSubmoduleData.comparisonVariableNumericalValues &&
                        questionSubmoduleData.comparisonVariableNumericalValues.map(
                            (comparisonValue, i) => (
                                <input
                                    key={`key-${i}`}
                                    className="input pointer-events-none absolute w-full cursor-pointer appearance-none
                                        opacity-0 active:cursor-grabbing [&::-webkit-slider-thumb]:pointer-events-auto"
                                    style={{
                                        height: "100%",
                                        zIndex: "3",
                                        padding: "0",
                                    }}
                                    type="range"
                                    value={comparisonValue}
                                    min={
                                        prompt?.min < prompt?.max
                                            ? prompt?.min
                                            : -10000
                                    }
                                    max={
                                        prompt?.min < prompt?.max
                                            ? prompt?.max
                                            : 10000
                                    }
                                    step={prompt?.increment || 1}
                                    onChange={(e) => {
                                        updateComparisonValue(
                                            Number(e.target.value),
                                            i,
                                        );
                                    }}
                                />
                            ),
                        )}
                </div>
                <div className="control-wrapper height-[16px] absolute w-full">
                    <div
                        className="rail"
                        style={{
                            position: "absolute",
                            width: "100%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            height: "6px",
                            borderRadius: "3px",
                            background: "lightgrey",
                        }}
                    />
                    {!!questionSubmoduleData.comparisonVariableNumericalValues &&
                        questionSubmoduleData.comparisonVariableNumericalValues.map(
                            (comparisonValue, i) => (
                                <div
                                    key={`key-${i}`}
                                    className="control absolute top-[50%] ml-[-8px] h-[16px] w-[16px] rounded-full
                                        bg-indigo-700 opacity-50"
                                    style={{
                                        transform: "translate3d(0, -50%, 0)",
                                        zIndex: "2",
                                        left: `${
                                            ((comparisonValue -
                                                (prompt?.min < prompt?.max
                                                    ? prompt?.min
                                                    : -10000)) /
                                                ((prompt?.min < prompt?.max
                                                    ? prompt?.max
                                                    : 10000) -
                                                    (prompt?.min < prompt?.max
                                                        ? prompt?.min
                                                        : -10000))) *
                                            100
                                        }%`,
                                    }}
                                />
                            ),
                        )}
                </div>
            </div>
            <div className="mb-4 flex items-center overflow-auto">
                <label className="mr-2 text-sm font-semibold text-gray-900">
                    Segment Divider Values
                </label>

                {!!questionSubmoduleData.comparisonVariableNumericalValues &&
                    questionSubmoduleData.comparisonVariableNumericalValues.map(
                        (comparisonValue, i) => (
                            <FormInputSmall
                                key={`comparison-value-${i}`}
                                className="ml-2"
                                type="number"
                                min={
                                    prompt?.min < prompt?.max
                                        ? prompt?.min
                                        : -10000
                                }
                                max={
                                    prompt?.min < prompt?.max
                                        ? prompt?.max
                                        : 10000
                                }
                                step={prompt?.increment || 1}
                                value={comparisonValue}
                                onChange={(e) => {
                                    updateComparisonValue(
                                        Number(e.target.value),
                                        i,
                                    );
                                }}
                            />
                        ),
                    )}
            </div>
            <div className="relative mb-4 overflow-x-auto">
                <table
                    className="h-full w-full table-auto text-left text-xs text-gray-500"
                    style={{
                        fontSize: "0.8rem",
                    }}
                >
                    <thead className="uppercase text-gray-700">
                        <tr className="border">
                            <th className="px-1 py-2">Comparison</th>
                            <th className="px-1 py-2">Independent Variable</th>
                            <th className="px-1 py-2">Numerical Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {segmentMap.map((segment, i) => (
                            <tr
                                key={`segment-${i}`}
                                className={"table-row h-full border"}
                            >
                                <td className="flex items-center px-1 py-1">
                                    {i > 0 && (
                                        <FormInputSmall
                                            key={`comparison-value-${i}-left`}
                                            className="mr-2 bg-gray-50"
                                            type="number"
                                            min={
                                                prompt?.min < prompt?.max
                                                    ? prompt?.min
                                                    : -10000
                                            }
                                            max={
                                                prompt?.min < prompt?.max
                                                    ? prompt?.max
                                                    : 10000
                                            }
                                            value={segmentMap[i - 1].rightValue}
                                            onChange={(e) => {}}
                                            disabled={true}
                                        />
                                    )}
                                    <div className="text-md mr-2 flex font-semibold">
                                        {i > 0 && (
                                            <span
                                                className="inline-flex w-full items-center justify-between whitespace-nowrap border
                                                    border-blue-600 bg-blue-50 px-3 py-2 text-blue-600"
                                            >
                                                {displayVariableRelationshipOperation(
                                                    VariableRelationshipOperation[
                                                        "Less Than"
                                                    ],
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <span
                                        className={`font-bold ${i === 0 && "pl-[100px]"}`}
                                    >{`RESPONSE`}</span>
                                    <div className="text-md ml-2 flex font-semibold">
                                        {i < segmentMap.length - 1 && (
                                            <span
                                                className="inline-flex w-full items-center justify-between whitespace-nowrap border
                                                    border-blue-600 bg-blue-50 px-3 py-2 text-blue-600"
                                            >
                                                {displayVariableRelationshipOperation(
                                                    VariableRelationshipOperation[
                                                        "Less Than Or Equal To"
                                                    ],
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    {i < segmentMap.length - 1 && (
                                        <FormInputSmall
                                            key={`comparison-value-${i}-right`}
                                            className="ml-2 bg-gray-50"
                                            type="number"
                                            min={
                                                prompt?.min < prompt?.max
                                                    ? prompt?.min
                                                    : -10000
                                            }
                                            max={
                                                prompt?.min < prompt?.max
                                                    ? prompt?.max
                                                    : 10000
                                            }
                                            value={segment.rightValue}
                                            onChange={(e) => {}}
                                            disabled={true}
                                        />
                                    )}
                                </td>
                                <td className="px-1 py-1">
                                    <textarea
                                        rows={1}
                                        className="block min-h-[54px] w-full min-w-[200px] rounded-md border border-gray-300
                                            bg-transparent p-2 text-xs text-gray-700 focus:border-blue-500
                                            focus:ring-blue-500"
                                        value={segment.independentVariableLabel}
                                        onChange={(e) => {
                                            let newSegmentMap = [...segmentMap];
                                            newSegmentMap[i] = {
                                                ...segmentMap[i],
                                                independentVariableLabel:
                                                    e.target.value,
                                            };
                                            setSegmentMap(newSegmentMap);
                                        }}
                                    />
                                </td>
                                <td className="px-1 py-1">
                                    <input
                                        type="number"
                                        placeholder=""
                                        className="w-full min-w-[120px] rounded-md border-gray-300 bg-transparent
                                            focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        value={
                                            segment.independentVariableNumericalValue
                                        }
                                        onChange={(e) => {
                                            let newSegmentMap = [...segmentMap];
                                            newSegmentMap[i] = {
                                                ...segmentMap[i],
                                                independentVariableNumericalValue:
                                                    Number(e.target.value) || 0,
                                            };
                                            setSegmentMap(newSegmentMap);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

const UnitFormField = ({
    questionSubmoduleData,
    updateQuestionSubmoduleData,
}: {
    questionSubmoduleData: QuestionSubmoduleData;
    updateQuestionSubmoduleData: (
        prop: keyof QuestionSubmoduleData,
        value: any,
    ) => void;
}) => {
    return (
        <div className="mb-4 flex items-center">
            <div className="mr-4 text-sm font-semibold text-gray-900">Unit</div>
            <div
                className="w-full items-center rounded border border-gray-200 bg-white text-xs font-medium
                    text-gray-900 sm:flex"
            >
                <div
                    className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                        !questionSubmoduleData.unit && "bg-blue-50"
                    }`}
                >
                    <div className="w-full px-0">
                        <label className="flex w-full items-center p-3 font-medium text-gray-900">
                            <input
                                type="radio"
                                className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={!questionSubmoduleData.unit}
                                onChange={(e) =>
                                    updateQuestionSubmoduleData("unit", null)
                                }
                            />
                            None
                        </label>
                    </div>
                </div>
                {Object.keys(ModelVariableUnit)
                    .filter((key) => key !== "Custom" && key !== "None")
                    .map((key: any) => (
                        <div
                            key={key}
                            className={`flex w-full items-center border-b border-gray-200 sm:border-b-0 sm:border-r ${
                                questionSubmoduleData.unit ===
                                    ModelVariableUnit[key] && "bg-blue-50"
                            }`}
                        >
                            <div className="w-full px-0">
                                <label className="flex w-full items-center p-3 font-medium text-gray-900">
                                    <input
                                        type="radio"
                                        className="mr-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                            focus:ring-blue-500"
                                        checked={
                                            questionSubmoduleData.unit ===
                                            ModelVariableUnit[key]
                                        }
                                        onChange={(e) =>
                                            updateQuestionSubmoduleData(
                                                "unit",
                                                ModelVariableUnit[key],
                                            )
                                        }
                                    />
                                    {key == "Dollars" ? "Currency" : key}
                                </label>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

const RankingSubmoduleForm = ({
    prompt,
    optionMap,
    setOptionMap,
    questionSubmoduleData,
    updateQuestionSubmoduleData,
}: {
    prompt: Prompt;
    optionMap: {
        [index: string]: {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            dependentVariableLabel: string;
        };
    };
    setOptionMap: (optionMap: {
        [index: string]: {
            independentVariableLabel: string;
            independentVariableNumericalValue: number;
            dependentVariableLabel: string;
        };
    }) => void;
    questionSubmoduleData: QuestionSubmoduleData;
    updateQuestionSubmoduleData: (
        prop: keyof QuestionSubmoduleData,
        value: any,
    ) => void;
}) => {
    return (
        <div className="mb-4 flex items-center">
            <label className="mr-6 flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="radio"
                    className="mr-3 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={
                        !questionSubmoduleData.comparisonVariableNumericalValues
                            ?.length
                    }
                    onChange={() => {
                        updateQuestionSubmoduleData(
                            "comparisonVariableNumericalValues",
                            [],
                        );
                        setOptionMap(
                            prompt?.options?.reduce((map, option, i) => {
                                return {
                                    ...map,
                                    [option.id]: optionMap[option.id]
                                        ? {
                                              ...optionMap[option.id],
                                              independentVariableNumericalValue:
                                                  i + 1,
                                          }
                                        : {
                                              independentVariableLabel: "",
                                              independentVariableNumericalValue:
                                                  i + 1,
                                              dependentVariableLabel: "",
                                          },
                                };
                            }, {}),
                        );
                    }}
                />
                {"Create proximity score"}
            </label>
            <label className="flex items-center text-sm font-semibold text-gray-900">
                <input
                    type="radio"
                    className="mr-3 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                        focus:ring-blue-500"
                    checked={
                        !!questionSubmoduleData
                            .comparisonVariableNumericalValues?.length
                    }
                    onChange={() =>
                        updateQuestionSubmoduleData(
                            "comparisonVariableNumericalValues",
                            [1],
                        )
                    }
                />
                {"Compare selections to specific rank"}
            </label>
            {!!questionSubmoduleData.comparisonVariableNumericalValues && (
                <select
                    className="ml-4 rounded-md border-gray-300 bg-transparent focus:border-blue-500
                        focus:ring-blue-500 disabled:bg-gray-100 sm:text-sm"
                    onChange={(e) => {
                        const value =
                            Number(e.target.value) < 1
                                ? 1
                                : Number(e.target.value) >
                                    prompt?.options?.length
                                  ? prompt?.options?.length
                                  : Number(e.target.value);
                        updateQuestionSubmoduleData(
                            "comparisonVariableNumericalValues",
                            [value],
                        );
                    }}
                    disabled={
                        !questionSubmoduleData.comparisonVariableNumericalValues
                            ?.length
                    }
                    value={
                        questionSubmoduleData
                            .comparisonVariableNumericalValues[0] || ""
                    }
                >
                    <option value={""} key={"none"} disabled />
                    {prompt?.options?.map((option, i) => (
                        <option value={i + 1} key={option.id}>
                            {i + 1}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

const WizardLabelUtilityForm = ({
    title,
    utilityMapKey,
    utilityMap,
    updateUtilityMap,
    generateLabels,
}: {
    title: string;
    utilityMapKey: "independent" | "dependent";
    utilityMap: {
        independent: UtilityObject;
        dependent: UtilityObject;
    };
    updateUtilityMap: (
        mapKey: "independent" | "dependent",
        objectProperty: keyof UtilityObject,
        value: string,
    ) => void;
    generateLabels: (mapKey: "independent" | "dependent") => void;
}) => {
    return (
        <div className="mb-4">
            <div className="mb-2 flex items-center font-semibold text-gray-900">
                <span>{title}</span>
                <button
                    type="button"
                    className="ml-2 inline-flex items-center rounded-full p-1 hover:bg-gray-50
                        focus:outline-none focus:ring-0"
                    disabled={false}
                    onClick={(e) => {
                        e.stopPropagation();
                        generateLabels(utilityMapKey);
                    }}
                >
                    <ArrowPathIcon className="h-4 w-4" />
                </button>
            </div>
            <div>
                <div className="-mx-3 mb-2 flex flex-wrap">
                    {!!utilityMap &&
                        !!utilityMap[utilityMapKey] &&
                        Object.keys(utilityMap[utilityMapKey]).map((key) => (
                            <div
                                key={key}
                                className="mb-6 w-full px-3 md:mb-0 md:w-1/3"
                            >
                                <label className="mb-2 block text-xs uppercase tracking-wide text-gray-700">
                                    {key}
                                </label>
                                <input
                                    className="w-full rounded-md border-gray-300 bg-transparent focus:border-blue-500
                                        focus:ring-blue-500 sm:text-sm"
                                    type="text"
                                    value={utilityMap[utilityMapKey][key] || ""}
                                    onChange={(e) => {
                                        updateUtilityMap(
                                            utilityMapKey,
                                            key as keyof UtilityObject,
                                            e.target.value || "",
                                        );
                                    }}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
