import React, { useCallback, useMemo, useState, useReducer } from "react";
import { ModelDataSelectionForm } from "@/components/admin-components";
import {
    useModalQueryParams,
    useSelectedRoundId,
    useSelectedSimulation,
} from "@/hooks";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";
import { SapienInertia } from "@/inertia-utils/hooks";
import { buildTopBarCard } from "@/model-configs/content-block-layouts/DataDisplayCardLayout";
import { buildTopDrawer } from "@/model-configs/content-block-layouts/TopDrawerLayout";
import {
    ContentBlockShape,
    ContentBlockType,
    ModelVariableDataType,
} from "@/models";
import { ModalContainer } from "@/modules/shared";
import { defaultCardDeckTheme } from "@/styles/themes/CardDeckTheme";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { PlusIcon } from "@heroicons/react/24/solid";
import { flatten } from "lodash";
import {
    CreateContentBlockBody,
    createContentBlock,
} from "../content-block-buttons/create-content-block-button/createContentBlock";

type State = {
    modelDataSources: {
        [index: number]: {
            time_horizon_id: string | null;
            model_variable_id: string;
        };
    };
};

const initialState: State = {
    modelDataSources: {},
};

type Action =
    | {
          type: "SET_MODEL_VARIABLE";
          payload: {
              model_variable_id: string;
              index: number;
          };
      }
    | {
          type: "SET_TIME_HORIZON";
          payload: {
              time_horizon_id: string;
              index: number;
          };
      };

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case "SET_MODEL_VARIABLE":
            if (!state.modelDataSources[action.payload.index]) {
                return {
                    ...state,
                    modelDataSources: {
                        ...state.modelDataSources,
                        [action.payload.index]: {
                            model_variable_id: action.payload.model_variable_id,
                            time_horizon_id: null,
                        },
                    },
                };
            }

            return {
                ...state,
                modelDataSources: {
                    ...state.modelDataSources,
                    [action.payload.index]: {
                        ...state.modelDataSources[action.payload.index],
                        model_variable_id: action.payload.model_variable_id,
                    },
                },
            };
        case "SET_TIME_HORIZON":
            if (!state.modelDataSources[action.payload.index]) {
                return state;
            }
            return {
                ...state,
                modelDataSources: {
                    ...state.modelDataSources,
                    [action.payload.index]: {
                        ...state.modelDataSources[action.payload.index],
                        time_horizon_id: action.payload.time_horizon_id,
                    },
                },
            };
    }
};

function TopDrawerModalContent() {
    const { closeModal } = useModalQueryParams();
    const [{ modelDataSources }, dispatch] = useReducer(reducer, initialState);

    const [numberOfVariables, setNumberOfVariables] = useState(0);

    const { modelVariables } = useModelVariableStore();
    const { timeHorizons } = useTimeHorizonStore();
    const { selectedRoundId } = useSelectedRoundId();
    const { selectedSimulation } = useSelectedSimulation();

    const modelVariablesToUse = useMemo(() => {
        return Object.values(modelVariables).filter(
            (modelVariable) =>
                modelVariable.data_type === ModelVariableDataType.Number,
        );
    }, [modelVariables]);

    const selectedModelVariables = useMemo(() => {
        return Object.values(modelVariables).filter((modelVariable) => {
            return Object.values(modelDataSources).some(
                (modelDataSource) =>
                    modelDataSource.model_variable_id === modelVariable.id,
            );
        });
    }, [modelDataSources]);
    const selectedTimeHorizons = useMemo(() => {
        return timeHorizons.filter((timeHorizon) => {
            return Object.values(modelDataSources).some(
                (modelDataSource) =>
                    modelDataSource.time_horizon_id === timeHorizon.id,
            );
        });
    }, [modelDataSources]);

    const save = useCallback(async () => {
        let contentBlock: ContentBlockShape;
        let childBlocks: ContentBlockShape[] = [];
        if (selectedModelVariables?.length) {
            childBlocks = [
                {
                    content_block_type: ContentBlockType["Card Deck"],
                    theme: defaultCardDeckTheme,
                    contentBlocks: Object.values(modelDataSources).map(
                        (modelDataSource) => {
                            return buildTopBarCard() as ContentBlockShape;
                        },
                    ),
                },
            ];
            contentBlock = buildTopDrawer(childBlocks);
        } else {
            contentBlock = buildTopDrawer();
        }
        // closeModal();
        const { contentBlock: block } = await createContentBlock({
            body: {
                ...(contentBlock as CreateContentBlockBody),
                position: "after",
            },
            selectedSimulation,
        });
        const blocks = flatten(
            block.contentBlocks as ContentBlockShape[],
        ).filter(
            (block) =>
                block.content_block_type ===
                ContentBlockType["Model Value Display"],
        );
        SapienInertia.post(
            "creator.design.content-block-model-data-sources.store",
            {
                timeHorizons: Object.values(selectedTimeHorizons),
                contentBlocks: blocks.map((block, i) => {
                    return {
                        ...block,
                        contentBlockModelDataSources: [modelDataSources[i]],
                    };
                }),
                contentBlockId: block.id,
            } as (typeof LaravelRequestBodyShapes)["creator.design.content-block-model-data-sources.store"],
            {},
            {
                onSuccess: () => {
                    SapienInertia.reload();
                    closeModal();
                },
            },
        );
    }, [selectedModelVariables, selectedTimeHorizons]);
    return (
        <div
            className="flex flex-col space-y-4 p-6 text-white"
            data-testid="top-drawer-modal"
        >
            <div>
                <h3>Create top drawer for this round</h3>
                <p className="text-sm text-gray-100">
                    You can automatically add data display cards to the top bar
                </p>
            </div>
            <div className="flex flex-col space-y-2">
                <div className="flex flex-col">
                    {[...Array(numberOfVariables).keys()].map((_, i) => {
                        const modelDataSource = modelDataSources[i];
                        return (
                            <div
                                className="border-t border-[#37415162]"
                                key={modelDataSource?.model_variable_id || i}
                            >
                                <ModelDataSelectionForm
                                    label=" "
                                    modelVariables={modelVariablesToUse}
                                    timeHorizons={timeHorizons}
                                    selectedModelVariables={selectedModelVariables.filter(
                                        (modelVariable) =>
                                            !!modelDataSource &&
                                            modelVariable.id ===
                                                modelDataSource.model_variable_id,
                                    )}
                                    setSelectedModelVariables={(
                                        modelVariables,
                                    ) => {
                                        dispatch({
                                            type: "SET_MODEL_VARIABLE",
                                            payload: {
                                                model_variable_id:
                                                    modelVariables[0]?.id,
                                                index: i,
                                            },
                                        });
                                    }}
                                    selectedTimeHorizons={selectedTimeHorizons.filter(
                                        (timeHorizon) =>
                                            !!modelDataSource &&
                                            timeHorizon.id ===
                                                modelDataSource.time_horizon_id,
                                    )}
                                    setSelectedTimeHorizons={(timeHorizons) => {
                                        dispatch({
                                            type: "SET_TIME_HORIZON",
                                            payload: {
                                                time_horizon_id:
                                                    timeHorizons[0]?.id,
                                                index: i,
                                            },
                                        });
                                    }}
                                    isSingleModelVariableSelection={true}
                                    isSingleTimeHorizonSelection={true}
                                />
                            </div>
                        );
                    })}
                    <div>
                        <button
                            className={`focus:ring-[rgba(255, 255, 255, 0.65)] flex rounded px-2 py-1 text-center text-xs font-medium text-white focus:outline-none focus:ring-0 ${`hover:bg-gray-800 hover:text-white`}`}
                            onClick={() => {
                                setNumberOfVariables(
                                    (numberOfVariables) =>
                                        numberOfVariables + 1,
                                );
                            }}
                        >
                            <PlusIcon className="mr-1 h-4 w-4" />
                            <span>Add variable</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                <div className="flex items-center justify-center">
                    {" "}
                    <button
                        data-testid="top-drawer-close-button"
                        onClick={() => {
                            closeModal();
                        }}
                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                        Cancel
                    </button>
                </div>{" "}
                <div className="flex items-center justify-center">
                    <button
                        onClick={() => save()}
                        className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-75"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default function TopDrawerModal() {
    const { queryParams, isAtModalUrl: isOpen } =
        useModalQueryParams("TopDrawerModal");

    return (
        <ModalContainer
            size="l"
            isModalOpen={isOpen}
            backgroundColor="#111928"
            classNames="transform-y-1/2"
        >
            {!!isOpen && <TopDrawerModalContent />}
        </ModalContainer>
    );
}
