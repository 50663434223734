import React, { useEffect, useMemo } from "react";
import {
    getAreRelationshipsValid,
    useAvailableVariables,
    useBuilderWorkspaceStore,
    useVariableRelationshipStore,
} from "@/hooks/store";
import { ModelVariableType, VariableRelationshipOperation } from "@/models";
import {
    displayVariableRelationshipOperation,
    getVariableRelationshipOperationKeySubset,
} from "@/util";
import {
    SapienInertia,
    sapienRoute,
    useModelBuilderPage,
} from "@/inertia-utils/hooks";
import { TrashIcon } from "@heroicons/react/24/solid";

export const BuilderWorkspace = ({}: {}) => {
    const { props: modelBuilderPageProps } = useModelBuilderPage();
    const { timeHorizons } = modelBuilderPageProps;

    const {
        targetVariable,
        sourceVariables,
        sourceVariableRelationships,
        modelVariables,
        updateSourceRelationship,
        resetBuilderWorkspace,
        sourceVariableRelationshipMap,
        setAvailableVariablesMap,
    } = useBuilderWorkspaceStore();
    const { relationshipsBySourceId, relationshipsByTargetId } =
        useVariableRelationshipStore();

    const { availableVariablesMap } = useAvailableVariables(
        targetVariable,
        sourceVariableRelationshipMap,
        modelVariables,
        relationshipsBySourceId,
        relationshipsByTargetId,
    );

    useEffect(() => {
        setAvailableVariablesMap(availableVariablesMap);
    }, [availableVariablesMap]);

    const areRelationshipsValid = useMemo<boolean>(() => {
        return getAreRelationshipsValid(
            targetVariable,
            sourceVariableRelationships,
            modelVariables,
        );
    }, [targetVariable, sourceVariableRelationships, modelVariables]);

    return (
        <div className="text-sm">
            <div className="mb-2 flex items-baseline justify-between">
                <div className="font-bold">Target</div>
                <div className="text-xs"></div>
            </div>
            <div>
                {!!targetVariable ? (
                    <div className="border border-neutral-600 p-2 text-xs text-neutral-200">
                        {targetVariable.label}
                    </div>
                ) : (
                    <div className="text-xs italic text-neutral-400">
                        Select an existing target variable to edit, or select
                        source variables to create a new target variable
                    </div>
                )}
            </div>
            <div className="mb-2 mt-4 flex items-baseline justify-between">
                <div className="font-bold">Sources</div>
                <div className="text-xs"></div>
            </div>
            <div>
                {!!sourceVariables && !!Object.keys(sourceVariables).length ? (
                    <div>
                        {/* {Object.values(sourceVariables).map((variable) => (
                            <div
                                key={variable.id}
                                className="text-xs text-neutral-200"
                            >
                                {variable.label}
                            </div>
                        ))} */}
                    </div>
                ) : (
                    <div className="text-xs italic text-neutral-400">
                        None selected
                    </div>
                )}
            </div>
            <div>
                {!!targetVariable &&
                    !!modelVariables &&
                    !!sourceVariableRelationships?.length &&
                    sourceVariableRelationships.map((relationship, index) => (
                        <div
                            className="mb-4 flex items-start justify-between border-t border-neutral-600 pt-2"
                            key={relationship.source_variable_id}
                        >
                            {!!relationship.operation_type &&
                                (!(
                                    targetVariable.variable_type ===
                                    ModelVariableType.Comparison
                                ) ||
                                    index > 0) && (
                                    <>
                                        <div className="flex text-sm font-semibold">
                                            {getVariableRelationshipOperationKeySubset(
                                                targetVariable.variable_type,
                                            ).map((key) => (
                                                <div
                                                    key={
                                                        VariableRelationshipOperation[
                                                            key
                                                        ]
                                                    }
                                                >
                                                    <input
                                                        type="radio"
                                                        className="peer hidden"
                                                        id={`${VariableRelationshipOperation[key]}-${index}`}
                                                        value={`${VariableRelationshipOperation[key]}-${index}`}
                                                        checked={
                                                            relationship.operation_type ===
                                                            VariableRelationshipOperation[
                                                                key
                                                            ]
                                                        }
                                                        onChange={(e) =>
                                                            updateSourceRelationship(
                                                                index,
                                                                "operation_type",
                                                                VariableRelationshipOperation[
                                                                    key
                                                                ],
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="inline-flex w-full cursor-pointer items-center justify-between whitespace-nowrap
                                                            border border-gray-200 bg-white px-1.5 py-1.5 text-gray-500
                                                            peer-checked:border-blue-600 peer-checked:bg-blue-50 peer-checked:text-blue-600
                                                            hover:bg-gray-100 hover:text-gray-600"
                                                        htmlFor={`${VariableRelationshipOperation[key]}-${index}`}
                                                    >
                                                        {displayVariableRelationshipOperation(
                                                            VariableRelationshipOperation[
                                                                key
                                                            ],
                                                        )}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            <>
                                <div className="px-2 text-xs text-neutral-200">
                                    {
                                        sourceVariables[
                                            relationship.source_variable_id
                                        ].label
                                    }
                                </div>
                            </>
                            {!!targetVariable.uses_time &&
                                !!relationship.source_variable_id &&
                                modelVariables[relationship.source_variable_id]
                                    .uses_time === false && (
                                    <>
                                        <select
                                            value={
                                                relationship.target_time_horizon_id ||
                                                ""
                                            }
                                            onChange={(e) =>
                                                updateSourceRelationship(
                                                    index,
                                                    "target_time_horizon_id",
                                                    e.target.value,
                                                )
                                            }
                                            style={{ minWidth: "54px" }}
                                            className="block min-w-[54px] max-w-[54px] rounded border border-gray-300 bg-gray-50 p-1.5
                                                text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                                        >
                                            <option
                                                key={""}
                                                value={undefined}
                                                disabled={true}
                                            />
                                            {timeHorizons?.map(
                                                (timeHorizon) => (
                                                    <option
                                                        key={timeHorizon.id}
                                                        value={timeHorizon.id}
                                                    >
                                                        {timeHorizon.time_index}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                    </>
                                )}
                            {!!relationship.id &&
                                targetVariable.variable_type !==
                                    ModelVariableType.Conditional &&
                                targetVariable.variable_type !==
                                    ModelVariableType["Time Shift"] && (
                                    <button
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Delete variable relationship?",
                                                )
                                            ) {
                                                SapienInertia.delete(
                                                    sapienRoute(
                                                        "model-builder.variable-relationships.destroy",
                                                        {
                                                            id: relationship.id,
                                                        },
                                                    ),
                                                    { preserveScroll: true },
                                                );
                                            }
                                        }}
                                        className="rounded-full pl-1 text-slate-200 transition-all hover:text-red-600"
                                    >
                                        <TrashIcon className="h-3 w-3" />
                                    </button>
                                )}
                        </div>
                    ))}
            </div>
            {!!targetVariable && (
                <div className="pt-3">
                    <button
                        type="submit"
                        className="mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white
                            hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300
                            disabled:bg-blue-800"
                        disabled={!areRelationshipsValid}
                        onClick={async (e) => {
                            e.preventDefault();

                            let variableToSave = targetVariable;

                            if (!!sourceVariableRelationships?.length) {
                                variableToSave = {
                                    ...variableToSave,
                                    sourceVariableRelationships:
                                        sourceVariableRelationships,
                                };
                            }

                            if (variableToSave?.id) {
                                SapienInertia.put(
                                    "model-builder.model-variables.update",
                                    variableToSave,
                                    {
                                        modelVariableId: variableToSave.id,
                                    },
                                    {
                                        preserveState: true,
                                        preserveScroll: true,
                                    },
                                );
                            } else {
                                SapienInertia.post(
                                    "model-builder.model-variables.store",
                                    {
                                        ...variableToSave,
                                        duplicateCount: 0,
                                    },
                                    {},
                                    {
                                        preserveState: true,
                                        preserveScroll: true,
                                    },
                                );
                            }

                            resetBuilderWorkspace();
                        }}
                    >
                        Save
                    </button>
                </div>
            )}
        </div>
    );
};
