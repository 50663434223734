import React, { FC, ReactNode } from "react";
import { Link } from "@inertiajs/react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";

function SubNavLink({ href, active, children }) {
    return (
        <Link
            href={href}
            className={
                active
                    ? `inline-flex items-center text-nowrap rounded-md bg-indigo-400 bg-opacity-15 px-2
                        py-1 text-indigo-800 transition duration-150 ease-in-out hover:bg-indigo-400
                        hover:bg-opacity-10 focus:outline-none focus:ring-0`
                    : `inline-flex items-center text-nowrap rounded-md px-2 py-1 text-slate-500
                        transition duration-150 ease-in-out hover:bg-indigo-400 hover:bg-opacity-10
                        hover:text-indigo-800 focus:outline-none focus:ring-0`
            }
        >
            {children}
        </Link>
    );
}

export const TopNavContainer: FC<{
    header?: string;
    children?: ReactNode;
}> = ({ header, children }) => {
    const { props } = usePage();
    return (
        <div className="flex flex-row items-center space-x-8">
            <div>
                {isCurrent("admin.index", props.route) ? (
                    <span className="text-xl font-semibold text-slate-900">
                        {header}
                    </span>
                ) : (
                    <Link href={sapienRoute("admin.index")}>
                        <div className="text-xl font-semibold text-slate-900 hover:underline">
                            {header}
                        </div>
                    </Link>
                )}
            </div>
            <div className="flex flex-wrap gap-2 text-sm font-semibold">
                <SubNavLink
                    href={sapienRoute("admin.users.index")}
                    active={isCurrent("admin.users.index", props.route)}
                >
                    Users
                </SubNavLink>
                <SubNavLink
                    href={sapienRoute("templates.index")}
                    active={isCurrent("templates.index", props.route, [
                        "templates.edit",
                    ])}
                >
                    Templates
                </SubNavLink>
            </div>
        </div>
    );
};
