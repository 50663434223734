import { sapienRoute } from "@/inertia-utils/hooks";
import { Link } from "@inertiajs/react";
import React from "react";
import { WithId } from "./WithId";
import { useAuthStore } from "@/hooks/store";
import { PlusCircleIcon } from "lucide-react";

type Props = {
    categories: WithId<App.Data.Forum.CategoryData>[];
};

export default function ForumSideNav({ categories }: Props) {
    const { isNotParticipant, user } = useAuthStore();
    return (
        <>
            <div
                className="border-right fixed bottom-0 left-0 top-0 w-full max-w-96 overflow-auto
                    border-sapien-blue bg-white md:hidden"
            ></div>
            <div
                className="border-right border-right top bottom-0 left-0 top-20 -mt-4 hidden
                    h-[calc(100vh-4rem)] w-full max-w-60 flex-col items-center divide-y
                    overflow-auto border-sapien-blue p-6 lg:flex"
            ></div>
            <nav
                className="border-right border-right top fixed bottom-0 left-0 top-20 -mt-4 hidden
                    h-[calc(100vh-4rem)] w-full max-w-60 flex-col items-center overflow-auto
                    border-sapien-blue bg-white p-6 lg:flex"
            >
                {isNotParticipant && (
                    <Link
                        href={sapienRoute("forum.categories.create", {
                            step: "1",
                        })}
                        className="flex w-full items-center space-x-2 rounded-md p-2 text-sm hover:bg-gray-100"
                    >
                        <PlusCircleIcon className="h-4 w-4" />{" "}
                        <span className="truncate">Create A Category </span>
                    </Link>
                )}
                {categories.map((category) => (
                    <Link
                        href={sapienRoute("forum.category", {
                            category: category.slug,
                        })}
                        className="flex w-full rounded-md p-2 text-sm hover:bg-gray-100"
                        key={category.id}
                    >
                        <span className="truncate">{category.title}</span>
                    </Link>
                ))}
            </nav>
        </>
    );
}
