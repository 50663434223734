import React, { useCallback } from "react";
import { MoveUpIcon, MoveDownIcon } from "lucide-react";
import { SapienInertia } from "@/inertia-utils/hooks";

type Props = {
    index: number;
    numberOfBlocks: number;
    contentBlockId: string;
};

export default function ReorderButtons({
    index,
    numberOfBlocks,
    contentBlockId,
}: Props) {
    const reorderContentBlocks = useCallback(
        (indexChange: number) => {
            SapienInertia.post(
                "creator.design.content-blocks.reorder",
                {
                    content_block_id: contentBlockId,
                    destination_index: index + indexChange,
                },
                {},
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: (page) => {
                        console.log(page);
                    },
                },
            );
        },
        [contentBlockId, index],
    );
    return (
        <>
            {index !== 0 && (
                <button
                    onClick={() => reorderContentBlocks(-1)}
                    className="rounded-md p-2 hover:bg-slate-200"
                >
                    <MoveUpIcon className="h-4 w-4" />
                </button>
            )}
            {index !== numberOfBlocks - 1 && (
                <button
                    onClick={() => reorderContentBlocks(1)}
                    className="rounded-md p-2 hover:bg-slate-200"
                >
                    <MoveDownIcon className="h-4 w-4" />
                </button>
            )}
        </>
    );
}
