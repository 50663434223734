import React from "react";
import { Head } from "@inertiajs/react";
import Authenticated from "../Authenticated";
import { Auth } from "@/inertia-utils/types";

export const AdminContainer = ({
    children,
    headTitle,
    auth,
    eLearningContext,
}: {
    children?: React.ReactNode;
    headTitle?: string;
    auth: Auth;
    eLearningContext?: boolean;
}) => {
    return (
        <>
            <Head title={headTitle} />
            <Authenticated auth={auth} eLearningContext={eLearningContext}>
                <>
                    <div className="h-[calc(100vh-64px)] bg-slate-100 text-slate-900">
                        <div className="">{children}</div>
                    </div>
                </>
            </Authenticated>
        </>
    );
};
