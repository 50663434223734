import React, { useCallback } from "react";
import { WithId } from "./WithId";
import { SapienPageProps } from "@/inertia-utils/types";
import Authenticated from "@/Layouts/Authenticated";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import NavLink from "@/components/NavLink";
import { Head } from "@inertiajs/react";
import { ThreadIcon } from "./ThreadPreviewCard";
import ForumRichText from "./ForumRichText";
import QueryClient from "../ELearning/QueryClient/QueryClient";
import ForumSideNav from "./ForumSideNav";
import { TypographyType } from "@/styles";
import { RichText } from "@/models";
import Post from "./Post";
import { useInitAuthFromInertiaProps } from "@/hooks";

type Props = SapienPageProps & {
    thread: WithId<App.Data.ThreadData>;
    categories: WithId<App.Data.Forum.CategoryData>[];
    posts: WithId<App.Data.PostData>[];
};

export const content = {
    type: "doc",
    content: [
        {
            type: "typography",
            attrs: {
                typographyType: TypographyType.Paragraph,
                textAlign: "left",
            },
            content: [
                {
                    marks: [
                        {
                            type: "textStyle",
                            attrs: {
                                color: null,
                                lineHeight: "normal",
                                fontFamily: "Open Sans",
                                indent: 0,
                                fontSize: "14",
                            },
                        },
                    ],
                    type: "text",
                    text: "",
                },
            ],
        },
    ],
};

export default function Thread({
    thread,
    auth,
    impersonating_user,
    categories,
    posts,
}: Props) {
    useInitAuthFromInertiaProps(auth);

    const createReply = useCallback(
        (rich_text: RichText | App.Data.RichTextData) => {
            SapienInertia.post(
                "forum.posts.store",
                {
                    parent_thread_id: thread.id,
                    rich_text: JSON.stringify(rich_text),
                },
                {},
                {
                    only: ["posts"],
                },
            );
        },
        [thread],
    );

    return (
        <Authenticated
            auth={auth}
            errors={[]}
            impersonatingUser={impersonating_user}
            links={
                <NavLink active={false} href={sapienRoute("resources")}>
                    Resources
                </NavLink>
            }
        >
            <QueryClient>
                <Head title="Threads" />
                <div className="flex w-full justify-center">
                    <ForumSideNav categories={categories} />

                    <div className="flex w-full flex-col items-center space-y-2">
                        <div className="flex max-w-4xl flex-col items-center p-6">
                            <div className="flex flex-col space-y-1 rounded-md p-4">
                                <div className="flex items-center space-x-2 text-sm">
                                    <ThreadIcon thread={thread} /> &bull;
                                    <span className="text-xs text-gray-400">
                                        {new Date(
                                            thread.created_at,
                                        ).toLocaleDateString()}
                                    </span>
                                </div>
                                <h2 className="font-bold">{thread.title}</h2>

                                <div className="text-gray-400">
                                    <ForumRichText
                                        content={thread.rich_text}
                                        parentObject={thread}
                                        editable={false}
                                    />
                                </div>
                            </div>
                            <div className="w-full pl-4">
                                <ForumRichText
                                    content={content}
                                    parentObject={{
                                        rich_text: content,
                                        id: "",
                                        updated_at: "",
                                    }}
                                    editable={true}
                                    placeHolder="Add a comment"
                                    saveOverride={createReply}
                                />
                            </div>
                            <div className="mt-6 flex w-full flex-col space-y-2 px-4">
                                {posts.map((post) => (
                                    <Post key={post.id} post={post} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </QueryClient>
        </Authenticated>
    );
}
