import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ChartBlock,
    FormattedChartValue,
    InteractiveModuleArchetype,
    InteractiveModuleChartType,
} from "../types";
import {
    useArchetypeModelBlocks,
    useArchetypeModelVariableMap,
} from "../state";
import {
    ChartDisplay,
    CollapseAllControlButton,
    CompactTableWrapper,
    DashboardWidgetWrapper,
    ModelBlockCarouselMenu,
} from "../shared";
import { useFormatVariableValue, VariableValueFormatFunction } from "@/hooks";
import { ModelVariable, VariableValue, ModelVariableDataType } from "@/models";
import {
    useArchetypeValuesMap,
    useInterfaceStateGuides,
} from "../useInterfaceState";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { ChartBarIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useArchetypeDisplayMapChangeMutation } from "../useCalculateInteractiveModel";
import { ChevronRight } from "lucide-react";
import { getChartValues } from "../utils";

const ArchetypeVariableDataTableRowComponent = ({
    archetypeLabel,
    archetypeTimespan,
    modelVariable,
    variableValues,
    index,
    showResampleFunction,
    formatVariableValue,
    maxTimespan,
}: {
    archetypeLabel: string;
    archetypeTimespan: number;
    modelVariable: ModelVariable;
    variableValues: VariableValue[];
    index: number;
    showResampleFunction?: boolean;
    formatVariableValue: VariableValueFormatFunction;
    maxTimespan: number;
}) => {
    return (
        <tr
            key={modelVariable.id}
            className={`table-row border border-slate-300 bg-slate-50 transition-all
            dark:border-slate-600 dark:bg-slate-900 ${
                index % 2 == 0
                    ? modelVariable.expose_to_designer
                        ? "bg-slate-50 bg-opacity-10 dark:bg-opacity-30"
                        : "bg-slate-100 bg-opacity-20 dark:bg-opacity-40"
                    : "bg-slate-50 bg-opacity-80 dark:bg-opacity-10"
            }
            ${modelVariable.expose_to_designer ? "text-slate-500 dark:text-slate-400" : ""}`}
        >
            <th
                className={`table-col px-1 py-1.5 ${showResampleFunction ? "flex justify-between" : ""}`}
            >
                <span
                    className={`text-nowrap text-sm ${
                        modelVariable.expose_to_designer
                            ? "font-normal"
                            : "font-semibold"
                    }`}
                >
                    {archetypeLabel}
                    {!!showResampleFunction && (
                        <span className="pl-1 text-xs font-thin text-slate-500 dark:text-slate-400">{` (${archetypeTimespan} years)`}</span>
                    )}
                </span>
            </th>
            {variableValues?.map((value, index) => (
                <td
                    key={index}
                    className={`border-l border-slate-300 px-1 py-1 text-right text-sm transition-all
                        dark:border-slate-600`}
                    colSpan={1}
                >
                    {modelVariable.data_type === ModelVariableDataType.Number
                        ? formatVariableValue(
                              modelVariable.unit,
                              value.numerical_value,
                              modelVariable.is_integer,
                          )
                        : value.boolean_value.toString()}
                </td>
            ))}
            {archetypeTimespan < maxTimespan && (
                <td
                    key={"placeholder"}
                    className={`border-l border-slate-300 px-1 py-1 text-right text-sm transition-all
                        dark:border-slate-600`}
                    colSpan={maxTimespan - archetypeTimespan}
                />
            )}
        </tr>
    );
};

const ArchetypeVariableDataTableRow = React.memo(
    ArchetypeVariableDataTableRowComponent,
);

const ArchetypeVariableAccordionTableRowComponent = ({
    label,
    handleSetDisplayMap,
    isExpanded,
    colSpan,
    children,
}: {
    label: string;
    handleSetDisplayMap: () => void;
    isExpanded: boolean;
    colSpan: number;
    children: React.ReactNode;
}) => {
    return (
        <tr
            className="border border-slate-300 bg-slate-200/80 transition-all hover:cursor-pointer
                hover:bg-slate-200 dark:border-slate-600 dark:bg-slate-700/80
                dark:hover:bg-slate-700"
            onClick={() => handleSetDisplayMap()}
        >
            <th
                className="group px-1 py-1 text-base font-semibold text-slate-700 transition-all
                    dark:text-slate-300"
                colSpan={colSpan}
                data-is-shown={isExpanded}
            >
                <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                        <ChevronRight className="h-4 w-4 transition-all group-data-[is-shown='true']:rotate-90" />
                        <span>{label}</span>
                    </div>
                    <div>{children}</div>
                </div>
            </th>
        </tr>
    );
};

const ArchetypeVariableAccordionTableRow = React.memo(
    ArchetypeVariableAccordionTableRowComponent,
);

const ArchetypeComparisonComponent = ({
    archetypes,
    archetypeTimespanMap,
}: {
    archetypes: InteractiveModuleArchetype[];
    archetypeTimespanMap: Record<string, number>;
}) => {
    const formatVariableValue = useFormatVariableValue();
    const {
        selectedGuidesModelBlock,
        setSelectedGuidesModelBlock,
        modelVariableDisplayMapGuides,
        setModelVariableDisplayMapGuides,
        archetypeDisplayMapGuides,
        setArchetypeDisplayMapGuides,
        guidesChartVariablesMap,
        setGuidesChartVariablesMap,
        hasOpenGuidesVariables,
    } = useInterfaceStateGuides();
    const { mutateAsync, isPending } = useArchetypeDisplayMapChangeMutation();
    const archetypeValuesMap = useArchetypeValuesMap();
    const archetypeModelBlocks = useArchetypeModelBlocks();
    const archetypeModelVariableMap = useArchetypeModelVariableMap();

    const [isBusy, setIsBusy] = useState(false);

    const handleSetArchetypeDisplayMapGuides = useCallback(
        async (archetypeIds: string[]) => {
            const unselectedIds = Object.keys(archetypeDisplayMapGuides).filter(
                (id) => !archetypeIds.includes(id),
            );
            const newSelected = archetypeIds.filter(
                (id) => !archetypeDisplayMapGuides[id],
            );
            const newArchetypeDisplayMapGuides = {
                ...unselectedIds.reduce(
                    (map, id) => ({ ...map, [id]: false }),
                    {},
                ),
                ...archetypeIds.reduce(
                    (map, id) => ({ ...map, [id]: true }),
                    {},
                ),
            };

            if (
                newSelected?.length > 0 &&
                !!archetypeValuesMap &&
                (!archetypeValuesMap[newSelected[0]] ||
                    !Object.keys(archetypeValuesMap[newSelected[0]])?.length)
            ) {
                await mutateAsync(newSelected[0]);
            }

            setArchetypeDisplayMapGuides(newArchetypeDisplayMapGuides);
            setIsBusy(false);
        },
        [archetypeDisplayMapGuides, archetypeValuesMap],
    );

    useEffect(() => {
        if (archetypeModelBlocks?.length > 0 && !selectedGuidesModelBlock) {
            setSelectedGuidesModelBlock(archetypeModelBlocks[0]);
        }
    }, [archetypeModelBlocks]);

    useEffect(() => {
        if (!Object.keys(modelVariableDisplayMapGuides)?.length) {
            setModelVariableDisplayMapGuides(
                Object.values(archetypeModelVariableMap)?.reduce(
                    (map, variableArray) => ({
                        ...map,
                        ...variableArray.reduce(
                            (variableMap, variable) => ({
                                ...variableMap,
                                [variable.id]: true,
                            }),
                            {},
                        ),
                    }),
                    {},
                ) || {},
            );
        }
    }, [archetypeModelVariableMap]);

    useEffect(() => {
        if (!Object.keys(guidesChartVariablesMap)?.length) {
            setGuidesChartVariablesMap(
                Object.values(archetypeModelVariableMap)?.reduce(
                    (map, variableArray) => ({
                        ...map,
                        ...variableArray.reduce(
                            (variableMap, variable) => ({
                                ...variableMap,
                                [variable.id]: false,
                            }),
                            {},
                        ),
                    }),
                    {},
                ) || {},
            );
        }
    }, [archetypeModelVariableMap]);

    useEffect(() => {
        if (
            archetypes?.length > 0 &&
            Object.keys(archetypeDisplayMapGuides)?.length !== archetypes.length
        ) {
            const archetypesMissingKeys = archetypes?.filter(
                (archetype) =>
                    archetypeDisplayMapGuides[archetype.id] === undefined,
            );
            if (archetypesMissingKeys?.length > 0) {
                setArchetypeDisplayMapGuides({
                    ...archetypeDisplayMapGuides,
                    ...archetypesMissingKeys.reduce(
                        (map, archetype) => ({
                            ...map,
                            [archetype.id]:
                                Object.keys(archetypeDisplayMapGuides)
                                    ?.length == 0 && archetype.is_default
                                    ? true
                                    : false,
                        }),
                        {},
                    ),
                });
            }
        }
    }, [archetypes]);

    const maxTimespan = useMemo(() => {
        const includedTimespanValues =
            !!archetypeTimespanMap &&
            !!archetypeDisplayMapGuides &&
            Object.keys(archetypeDisplayMapGuides)?.length > 0
                ? Object.keys(archetypeTimespanMap)
                      ?.filter((key) => !!archetypeDisplayMapGuides[key])
                      .map((key) => archetypeTimespanMap[key])
                : [];
        return includedTimespanValues?.length > 0
            ? Math.max(...includedTimespanValues)
            : 0;
    }, [archetypeTimespanMap, archetypeDisplayMapGuides]);

    const blockColSpan = useMemo(() => {
        return 1 + maxTimespan;
    }, [maxTimespan]);

    const columnLabels = useMemo(() => {
        return !!maxTimespan
            ? [...Array(maxTimespan)]?.map((_, index) => (index + 1).toString())
            : [];
    }, [maxTimespan]);

    const visibleArchetypes = useMemo(() => {
        return !!archetypes &&
            !!archetypeDisplayMapGuides &&
            !!archetypeValuesMap
            ? archetypes.filter(
                  (archetype) =>
                      !!archetypeDisplayMapGuides[archetype.id] &&
                      !!archetypeValuesMap[archetype.id] &&
                      !!Object.keys(archetypeValuesMap[archetype.id])?.length,
              )
            : [];
    }, [archetypeDisplayMapGuides, archetypeValuesMap]);

    const chartBlock = useMemo<ChartBlock>(() => {
        return {
            id: "",
            label: "",
            chart_type: InteractiveModuleChartType.LINE,
            time_index: maxTimespan,
            time_increment: "year",
            stacked: false,
            interpolation: "linear",
            model_block_id: "",
            variableIds:
                visibleArchetypes?.map((archetype, i) => archetype.id) ?? [],
            weight: 0,
        };
    }, [maxTimespan, visibleArchetypes]);

    const guidesChartValuesMap = useMemo<
        Record<string, Record<string, FormattedChartValue[]>>
    >(() => {
        return !!guidesChartVariablesMap && !!archetypeModelVariableMap
            ? Object.values(archetypeModelVariableMap)
                  .reduce((array, variables) => [...array, ...variables], [])
                  .filter((variable) => guidesChartVariablesMap[variable.id])
                  .reduce(
                      (map, variable) => ({
                          ...map,
                          [variable.id]: getChartValues(
                              visibleArchetypes?.map((archetype) => ({
                                  ...variable,
                                  id: archetype.id,
                                  label: archetype.name,
                              })) ?? [],
                              chartBlock,
                              visibleArchetypes?.reduce(
                                  (map, archetype) => ({
                                      ...map,
                                      [archetype.id]:
                                          archetypeValuesMap[archetype.id][
                                              variable.id
                                          ] ?? [],
                                  }),
                                  {},
                              ) ?? {},
                              formatVariableValue,
                          ),
                      }),
                      {},
                  )
            : {};
    }, [
        guidesChartVariablesMap,
        archetypeModelVariableMap,
        visibleArchetypes,
        archetypeValuesMap,
    ]);

    return (
        <>
            {archetypes?.length > 0 && !!archetypeDisplayMapGuides && (
                <div className="flex text-slate-700 dark:text-slate-300">
                    <Listbox
                        value={Object.keys(archetypeDisplayMapGuides).filter(
                            (key) => !!archetypeDisplayMapGuides[key],
                        )}
                        onChange={async (archetypeIds) => {
                            setIsBusy(true);
                            await handleSetArchetypeDisplayMapGuides(
                                archetypeIds,
                            );
                        }}
                        disabled={isPending || isBusy}
                        multiple
                    >
                        <div
                            className="flex w-full items-center rounded-md border border-slate-200 bg-opacity-50
                                hover:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600"
                        >
                            <ListboxButton
                                className="h-full rounded-md bg-slate-100 bg-opacity-30 p-4 hover:bg-opacity-50
                                    dark:bg-slate-700 dark:bg-opacity-30 dark:hover:bg-opacity-50"
                            >
                                <div className="text-md font-medium">
                                    Archetypes
                                </div>
                            </ListboxButton>
                            {Object.keys(archetypeDisplayMapGuides).filter(
                                (key) => !!archetypeDisplayMapGuides[key],
                            )?.length > 0 && (
                                <span className="flex flex-wrap px-4 py-1 text-sm">
                                    {archetypes
                                        .filter(
                                            (archetype) =>
                                                !!archetypeDisplayMapGuides[
                                                    archetype.id
                                                ],
                                        )
                                        .map((archetype) => (
                                            <span
                                                key={archetype.id}
                                                className="flex items-center"
                                            >
                                                <button
                                                    type="button"
                                                    className="m-1 flex items-center rounded-md bg-transparent p-1 text-center text-sm
                                                        font-light text-slate-700 hover:text-slate-900 focus:outline-none focus:ring-0
                                                        dark:text-slate-300 dark:hover:text-slate-100"
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        setIsBusy(true);
                                                        await handleSetArchetypeDisplayMapGuides(
                                                            Object.keys(
                                                                archetypeDisplayMapGuides,
                                                            ).filter(
                                                                (key) =>
                                                                    !!archetypeDisplayMapGuides[
                                                                        key
                                                                    ] &&
                                                                    key !==
                                                                        archetype.id,
                                                            ),
                                                        );
                                                    }}
                                                    disabled={
                                                        isPending || isBusy
                                                    }
                                                >
                                                    {archetype.name}
                                                    {` (${archetypeTimespanMap[archetype.id]} years)`}
                                                    <XMarkIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </span>
                                        ))}
                                </span>
                            )}
                        </div>
                        <ListboxOptions
                            anchor="bottom start"
                            className="[--anchor-gap:4px]"
                        >
                            {archetypes.map((archetype) => (
                                <ListboxOption
                                    key={archetype.id}
                                    value={archetype.id}
                                    className="group flex gap-3 bg-slate-900 p-2 text-sm text-white data-[focus]:bg-slate-700"
                                    disabled={isPending || isBusy}
                                >
                                    <CheckIcon className="invisible size-5 text-blue-600 group-data-[selected]:visible" />
                                    {archetype.name}
                                    {` (${archetypeTimespanMap[archetype.id]} years)`}
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </Listbox>
                </div>
            )}

            {!!guidesChartValuesMap &&
                !!guidesChartVariablesMap &&
                !!archetypeModelVariableMap &&
                !!archetypeModelBlocks &&
                Object.keys(guidesChartValuesMap)?.length > 0 && (
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                        {archetypeModelBlocks?.map((block) => (
                            <React.Fragment key={block.id}>
                                {archetypeModelVariableMap[block.id]
                                    ?.filter(
                                        (modelVariable) =>
                                            !!guidesChartVariablesMap[
                                                modelVariable.id
                                            ] &&
                                            !!guidesChartValuesMap[
                                                modelVariable.id
                                            ],
                                    )
                                    .map((modelVariable) => (
                                        <DashboardWidgetWrapper
                                            key={modelVariable.id}
                                        >
                                            <div className="relative">
                                                <ChartDisplay
                                                    modelTimespan={maxTimespan}
                                                    chartBlock={{
                                                        ...chartBlock,
                                                        label: modelVariable.label,
                                                    }}
                                                    chartValues={
                                                        guidesChartValuesMap[
                                                            modelVariable.id
                                                        ]
                                                    }
                                                    setChartTimeIndex={(
                                                        timeIndex: number,
                                                    ) => null}
                                                />
                                                <button
                                                    type="button"
                                                    className="absolute right-0 top-0 p-2 text-blue-600 opacity-15 hover:opacity-100"
                                                    onClick={() =>
                                                        setGuidesChartVariablesMap(
                                                            {
                                                                ...guidesChartVariablesMap,
                                                                [modelVariable.id]:
                                                                    !guidesChartVariablesMap[
                                                                        modelVariable
                                                                            .id
                                                                    ],
                                                            },
                                                        )
                                                    }
                                                >
                                                    <XMarkIcon className="h-5 w-5" />
                                                </button>
                                            </div>
                                        </DashboardWidgetWrapper>
                                    ))}
                            </React.Fragment>
                        ))}
                    </div>
                )}

            {!!selectedGuidesModelBlock && archetypeModelBlocks?.length > 0 && (
                <div
                    className="flex w-full items-center justify-between text-slate-700 transition-all
                        dark:text-slate-300"
                >
                    <span></span>
                    <div className="w-full">
                        <ModelBlockCarouselMenu
                            selectedModelBlock={selectedGuidesModelBlock}
                            setSelectedModelBlock={setSelectedGuidesModelBlock}
                            modelBlocks={archetypeModelBlocks}
                        />
                    </div>
                    <CollapseAllControlButton
                        allAreCollapsed={!hasOpenGuidesVariables}
                        setAllAreCollapsed={(hasOpen) => {
                            setModelVariableDisplayMapGuides(
                                Object.keys(
                                    modelVariableDisplayMapGuides,
                                ).reduce(
                                    (map, key) => ({
                                        ...map,
                                        [key]: !hasOpen,
                                    }),
                                    {},
                                ),
                            );
                        }}
                    />
                </div>
            )}

            <CompactTableWrapper
                header={""}
                columnLabels={columnLabels}
                alignRight={true}
            >
                {!!archetypeModelVariableMap &&
                    !!selectedGuidesModelBlock &&
                    archetypeModelBlocks?.length > 0 &&
                    archetypeModelBlocks
                        .filter(
                            (block) =>
                                !!archetypeModelVariableMap[block.id] &&
                                selectedGuidesModelBlock.id === block.id,
                        )
                        .map((block) => (
                            <React.Fragment key={block.id}>
                                {archetypeModelVariableMap[block.id].map(
                                    (modelVariable) => (
                                        <React.Fragment key={modelVariable.id}>
                                            <ArchetypeVariableAccordionTableRow
                                                key={modelVariable.id}
                                                label={modelVariable.label}
                                                handleSetDisplayMap={() =>
                                                    setModelVariableDisplayMapGuides(
                                                        {
                                                            ...modelVariableDisplayMapGuides,
                                                            [modelVariable.id]:
                                                                !modelVariableDisplayMapGuides[
                                                                    modelVariable
                                                                        .id
                                                                ],
                                                        },
                                                    )
                                                }
                                                isExpanded={
                                                    modelVariableDisplayMapGuides[
                                                        modelVariable.id
                                                    ]
                                                }
                                                colSpan={blockColSpan}
                                            >
                                                {modelVariable.data_type ===
                                                    ModelVariableDataType.Number && (
                                                    <button
                                                        type="button"
                                                        className={`rounded-md border border-blue-600 p-1 ${
                                                            guidesChartVariablesMap[
                                                                modelVariable.id
                                                            ]
                                                                ? "bg-blue-600 text-white dark:bg-blue-600 dark:text-white"
                                                                : "bg-slate-300 text-blue-600 dark:bg-slate-800 dark:text-blue-600"
                                                        }`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setGuidesChartVariablesMap(
                                                                {
                                                                    ...guidesChartVariablesMap,
                                                                    [modelVariable.id]:
                                                                        !guidesChartVariablesMap[
                                                                            modelVariable
                                                                                .id
                                                                        ],
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <ChartBarIcon className="h-4 w-4" />
                                                    </button>
                                                )}
                                            </ArchetypeVariableAccordionTableRow>
                                            {!!modelVariableDisplayMapGuides[
                                                modelVariable.id
                                            ] &&
                                                !!visibleArchetypes &&
                                                visibleArchetypes.map(
                                                    (archetype, i) => (
                                                        <ArchetypeVariableDataTableRow
                                                            key={archetype.id}
                                                            archetypeLabel={
                                                                archetype.name
                                                            }
                                                            archetypeTimespan={
                                                                archetypeTimespanMap[
                                                                    archetype.id
                                                                ]
                                                            }
                                                            modelVariable={
                                                                modelVariable
                                                            }
                                                            variableValues={
                                                                archetypeValuesMap[
                                                                    archetype.id
                                                                ][
                                                                    modelVariable
                                                                        .id
                                                                ] ?? []
                                                            }
                                                            index={i}
                                                            showResampleFunction={
                                                                true
                                                            }
                                                            formatVariableValue={
                                                                formatVariableValue
                                                            }
                                                            maxTimespan={
                                                                maxTimespan
                                                            }
                                                        />
                                                    ),
                                                )}
                                            {/* {!!modelVariableDisplayMapGuides[
                                                modelVariable.id
                                            ] &&
                                                !!guidesChartVariablesMap[
                                                    modelVariable.id
                                                ] &&
                                                guidesChartValuesMap !==
                                                    undefined &&
                                                !!guidesChartValuesMap[
                                                    modelVariable.id
                                                ] &&
                                                chartBlock !== undefined && (
                                                    <tr className="dark:bg-slate-900">
                                                        <td
                                                            colSpan={
                                                                blockColSpan
                                                            }
                                                        >
                                                            <div className="w-full p-4">
                                                                <div className="grid w-full grid-cols-1 lg:grid-cols-2">
                                                                    <DashboardWidgetWrapper>
                                                                        <ChartDisplay
                                                                            modelTimespan={
                                                                                maxTimespan
                                                                            }
                                                                            chartBlock={{
                                                                                ...chartBlock,
                                                                                label: modelVariable.label,
                                                                            }}
                                                                            chartValues={
                                                                                guidesChartValuesMap[
                                                                                    modelVariable
                                                                                        .id
                                                                                ]
                                                                            }
                                                                            setChartTimeIndex={(
                                                                                timeIndex: number,
                                                                            ) =>
                                                                                null
                                                                            }
                                                                        />
                                                                    </DashboardWidgetWrapper>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )} */}
                                        </React.Fragment>
                                    ),
                                )}
                            </React.Fragment>
                        ))}
            </CompactTableWrapper>
        </>
    );
};

export const ArchetypeComparison = React.memo(ArchetypeComparisonComponent);
