import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import Icon from "react-icons-kit";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { TopNavLinkDiv } from "@/components";
import LogoImage from "@/components/LogoImage";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";
import { InteractiveColorModeButton } from "./InteractiveColorModeButton";
import { useAuthStore } from "@/hooks/store";
import { SparklesIcon } from "@heroicons/react/24/solid";

const InteractiveModuleTopNavComponent = ({
    simulationTitle,
    simulationSlug,
    modelBlockLabel,
    colorModeClass,
    setColorModeClass,
}: {
    simulationTitle: string;
    simulationSlug: string;
    modelBlockLabel?: string;
    colorModeClass: "" | "dark";
    setColorModeClass: (colorModeClass: "" | "dark") => void;
}) => {
    const { user, isNotParticipant, isSapienSuperAdmin } = useAuthStore();

    return (
        <div className="interactive-top flex h-11">
            <div className="interactive-top-logo flex h-11 w-11 min-w-11 justify-center bg-slate-950">
                <TopNavLinkDiv style={{ padding: 0 }}>
                    {!!isNotParticipant ? (
                        <InertiaLink
                            href={sapienRoute("simulation.projects.index")}
                        >
                            <LogoImage size={"22"} opacity={"0.9"} />
                        </InertiaLink>
                    ) : (
                        <InertiaLink href={sapienRoute("dashboard")}>
                            <LogoImage size={"22"} opacity={"0.9"} />
                        </InertiaLink>
                    )}
                </TopNavLinkDiv>
            </div>
            <div
                className="interactive-top-center flex h-11 w-[calc(100%-44px)] min-w-[calc(100%-44px)]
                    items-center justify-between bg-slate-950 text-white transition-all"
            >
                <div className="flex flex-row flex-wrap items-center">
                    <div
                        className={`hidden items-center ${!modelBlockLabel ? "sm:flex" : "md:flex"}`}
                    >
                        <TopNavLinkDiv
                            style={{
                                padding: 0,
                            }}
                            className=""
                        >
                            {!!isNotParticipant ? (
                                <InertiaLink
                                    href={
                                        !!simulationSlug &&
                                        sapienRoute(
                                            "simulation.projects.show",
                                            {
                                                project: simulationSlug,
                                            },
                                        )
                                    }
                                >
                                    <div className="px-1 text-sm font-bold text-white sm:px-3">
                                        {`${simulationTitle}`}
                                    </div>
                                </InertiaLink>
                            ) : (
                                <InertiaLink href={sapienRoute("dashboard")}>
                                    <div className="px-1 text-sm font-bold text-white sm:px-3">
                                        {`Dashboard`}
                                    </div>
                                </InertiaLink>
                            )}
                        </TopNavLinkDiv>
                        <div className="">
                            <Icon
                                icon={chevronRight}
                                size={12}
                                className="flex text-white opacity-60"
                            />
                        </div>
                    </div>
                    {!modelBlockLabel && (
                        <div className="px-1 text-sm font-semibold sm:px-3">
                            Interactive Models
                        </div>
                    )}
                    {!!modelBlockLabel && !!isNotParticipant && (
                        <div className="hidden sm:inline-flex">
                            <TopNavLinkDiv
                                style={{
                                    padding: 0,
                                }}
                            >
                                {!!isNotParticipant ? (
                                    <InertiaLink
                                        href={
                                            !!simulationSlug &&
                                            sapienRoute("interactive.index", {
                                                simulationSlug: simulationSlug,
                                            })
                                        }
                                    >
                                        <div className="px-1 text-sm font-semibold text-white sm:px-3">
                                            Interactive Models
                                        </div>
                                    </InertiaLink>
                                ) : (
                                    <div className="px-1 text-sm font-semibold opacity-80 sm:px-3">
                                        Interactive Models
                                    </div>
                                )}
                            </TopNavLinkDiv>
                            <Icon
                                icon={chevronRight}
                                size={12}
                                className="flex text-white opacity-60"
                            />
                        </div>
                    )}
                    {!!modelBlockLabel && (
                        <div className="px-1 text-sm font-normal sm:px-3">
                            {`${modelBlockLabel}`}
                        </div>
                    )}
                </div>
                {!isNotParticipant && (
                    <div
                        className={`hidden items-center text-sm font-thin opacity-80 ${
                            !modelBlockLabel ? "md:flex" : "lg:flex"
                        }`}
                    >
                        {simulationTitle}
                    </div>
                )}
                <div
                    className="discovery-top-right-links flex h-full items-center bg-slate-950 text-white
                        transition-all"
                >
                    {!!isSapienSuperAdmin && !modelBlockLabel && (
                        <TopNavLinkDiv style={{ padding: 0 }}>
                            <InertiaLink
                                href={sapienRoute("data-sandbox.index")}
                            >
                                <SparklesIcon className="mr-2 h-5 w-5" />
                            </InertiaLink>
                        </TopNavLinkDiv>
                    )}
                    <InteractiveColorModeButton
                        colorModeClass={colorModeClass}
                        setColorModeClass={setColorModeClass}
                    />
                    {!!user && <UserNavDropdown userName={user.name} />}
                </div>
            </div>
        </div>
    );
};

export const InteractiveModuleTopNav = React.memo(
    InteractiveModuleTopNavComponent,
);
