import React from "react";
import { Round, RoundShape, Simulation } from "../../models";
import { ButtonSpan, H6Span } from "..";
import { edit2 } from "react-icons-kit/feather/edit2";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { Icon } from "react-icons-kit";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { RoundOutlineDisplay } from "./RoundOutlineDisplay";

export const RoundDisplay = ({
    round,
    index,
    setEditedRound,
    simulation,
    children,
    displayOutline,
}: {
    round: Round | RoundShape;
    index: number;
    setEditedRound?: () => void;
    simulation: Simulation | Partial<Simulation>;
    children?: React.ReactNode;
    backgroundColor?: string;
    displayOutline?: boolean;
}) => {
    return (
        <div className="w-full max-w-full">
            <div className={"mb-4 rounded-md bg-white p-2"}>
                <div className="m-2 flex flex-row justify-between">
                    {children}
                    <div className="mb-0 mr-4 flex max-w-[50%] flex-row items-center">
                        <div className="flex h-full items-center">
                            <img
                                style={{
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                }}
                                src={
                                    simulation?.theme?.image_url ||
                                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png"
                                }
                            />
                        </div>
                    </div>
                    <div className="flex w-[calc(100%-70px)] flex-col">
                        <div className="pb-1 opacity-60">
                            <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-row items-center">
                                    <ButtonSpan
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >{`Round ${index + 1}`}</ButtonSpan>
                                    {!!setEditedRound && (
                                        <div className="ml-1">
                                            <button
                                                type="button"
                                                className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                                    opacity-90 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0
                                                    focus:ring-blue-300"
                                                disabled={false}
                                                onClick={() => setEditedRound()}
                                            >
                                                <Icon
                                                    icon={edit2}
                                                    size={16}
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <InertiaLink
                                    href={sapienRoute(
                                        "simulation.design.show",
                                        {
                                            roundId: round.id,
                                        },
                                    )}
                                    data-testid={`launch_${round.title}`}
                                >
                                    <button
                                        type="button"
                                        className="m-1 inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                                            opacity-90 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
                                        disabled={false}
                                    >
                                        <Icon
                                            icon={chevronRight}
                                            size={16}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </button>
                                </InertiaLink>
                            </div>
                        </div>
                        <div>
                            <H6Span style={{ fontSize: "18px" }}>
                                {round.title}
                            </H6Span>
                        </div>
                    </div>
                </div>
                {displayOutline && !!round.parentContentBlocks?.length && (
                    <div className="m-2 pt-2 text-xs">
                        <RoundOutlineDisplay
                            topLevelContentBlockLayers={
                                round.parentContentBlocks
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
