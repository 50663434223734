import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModelVariable, Simulation, VariableValue } from "@/models";
import { SapienPageProps } from "@/inertia-utils/types";
import { useInitAuthFromInertiaProps, useSelectedSimulation } from "@/hooks";
import { InteractiveModuleMenu } from "./InteractiveModuleMenu";
import { InteractiveModuleWrapper } from "./InteractiveModuleWrapper";
import { InteractiveDetailDisplay } from "./InteractiveDetailDisplay";
import {
    InputDataItem,
    InteractiveModelBlock,
    InteractivePageSection,
} from "./types";
import { useResetInterfaceState } from "./useInterfaceState";

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    modelBlock: InteractiveModelBlock;
    inputDataObject: Record<string, InputDataItem>;
    targetVariables: ModelVariable[];
    valuesMonthly: Record<string, VariableValue[]>;
    valuesYearly: Record<string, VariableValue[]>;
    archetypeValuesYearly: Record<string, VariableValue[]>;
    archetypeId: string;
};

const queryClient = new QueryClient();

export default function InteractiveModuleDetail({
    simulation,
    modelBlock,
    inputDataObject,
    targetVariables,
    valuesMonthly,
    valuesYearly,
    archetypeValuesYearly,
    archetypeId,
    auth,
    tenant,
}: Props) {
    const { isNotParticipant } = useInitAuthFromInertiaProps(
        auth,
        null,
        null,
        tenant,
    );

    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    useResetInterfaceState();

    const [pageSection, setPageSection] =
        useState<InteractivePageSection>("Dashboard");

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        setIsDrawerOpen(false);
    }, [pageSection]);

    return (
        <QueryClientProvider client={queryClient}>
            <InteractiveModuleWrapper
                simulationTitle={simulation?.title}
                simulationSlug={simulation?.slug}
                modelBlockLabel={modelBlock?.label}
            >
                <InteractiveModuleMenu
                    pageSection={pageSection}
                    setPageSection={setPageSection}
                    isNotParticipant={isNotParticipant}
                    archetypeId={archetypeId}
                />
                <InteractiveDetailDisplay
                    pageSection={pageSection}
                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={setIsDrawerOpen}
                    modelBlock={modelBlock}
                    inputDataObject={inputDataObject}
                    targetVariables={targetVariables}
                    valuesMonthly={valuesMonthly}
                    valuesYearly={valuesYearly}
                    archetypeValuesYearly={archetypeValuesYearly}
                    archetypeId={archetypeId}
                    simulationSlug={simulation?.slug}
                />
            </InteractiveModuleWrapper>
        </QueryClientProvider>
    );
}
