import { ArrowRightIcon } from "lucide-react";
import React from "react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { Link } from "@inertiajs/react";

type Props = {
    modelBlock: App.Data.InertactiveModelBlockData;
};

export default function InteractiveModelBlockDashboardCardContents({
    modelBlock,
}: Props) {
    const simulation = modelBlock.simulation;


    return (
        <>

            <div
                className="pointer-events-none absolute inset-0 bg-gradient-to-b from-slate-900/50
                    to-slate-900/60"
            ></div>
            <div className="relative z-[999] flex w-full flex-col items-start space-y-6">
                <h3 className="flex items-center font-bold text-gray-50 ">
                    {simulation?.title}
                </h3>
                <h5 className="!mt-1 text-sm text-gray-100">
                    {modelBlock.label}
                </h5>
                <Link
                    href={sapienRoute("interactive.show", {
                        simulationSlug: simulation?.slug,
                        moduleId: modelBlock.id,
                    })}
                    className="inline-flex w-full items-center justify-center space-x-2 rounded-md
                        bg-indigo-600 px-3.5 py-2 text-center text-sm text-white hover:bg-indigo-700
                        focus:outline-none focus:ring-0 disabled:bg-indigo-700"
                >
                    <span>Go</span>
                    <span>
                        <ArrowRightIcon className="ml-1.5 h-4 w-4" />
                    </span>
                </Link>
            </div>
        </>
    );
}
