import {
    useFindContentBlockMethods,
    useModalQueryParams,
    useSelectedSimulation,
    useUploadStore,
} from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useForm } from "@inertiajs/react";
import React, { useEffect, useMemo } from "react";

function NotificationModalContents() {
    const { selectedSimulation } = useSelectedSimulation();
    const { queryParams, closeModal } =
        useModalQueryParams("NotificationModal");
    const { getContentBlock } = useFindContentBlockMethods();
    const contentBlock = useMemo(() => {
        return getContentBlock(queryParams?.notificationModalContentBlockId);
    }, [queryParams, getContentBlock]);

    const { data, setData } = useForm({
        fromAvatarUrl: contentBlock?.theme?.fromAvatarUrl,
        from: contentBlock?.theme?.from,
        title: contentBlock?.title,
    });

    const { upload, uploading, uploadedFileUrl } = useUploadStore();
    useEffect(() => {
        setData("fromAvatarUrl", uploadedFileUrl);
    }, [uploadedFileUrl]);

    return (
        !!contentBlock && (
            <div className="py-6 text-white">
                <h6 className="p-6">Manage Notification</h6>
                <form
                    className="text-white border-t border-[#374151] p-6 flex flex-col space-y-4"
                    onSubmit={(e) => {
                        e.preventDefault();
                        SapienInertia.put(
                            "creator.design.content-blocks.update",
                            {
                                ...contentBlock,
                                title: data.title,
                                theme: JSON.stringify({
                                    ...contentBlock.theme,
                                    ...{
                                        fromAvatarUrl: data.fromAvatarUrl,
                                        from: data.from,
                                    },
                                }),
                            } as unknown as (typeof LaravelRequestBodyShapes)["creator.design.content-blocks.update"],
                            {
                                simulationSlug: selectedSimulation.slug,
                                contentBlock: contentBlock.id,
                                roundId: contentBlock.round_id,
                            }
                        );
                    }}
                >
                    <div className="flex justify-between">
                        <label>Title</label>
                        <input
                            type="text"
                            value={data.title}
                            onChange={(e) => setData("title", e.target.value)}
                            className="w-full max-w-xl block rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                        />
                    </div>
                    <div className="flex justify-between">
                        <label>From</label>
                        <input
                            type="text"
                            value={data.from}
                            onChange={(e) => setData("from", e.target.value)}
                            className="w-full max-w-xl block rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200"
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <label
                            htmlFor="avatar"
                            className="flex justify-between w-full"
                            aria-label="Avatar"
                        >
                            <div className="flex items-center space-x-4">
                                <span>Avatar</span>
                                <span className="flex h-10 w-10 overflow-hidden rounded-full bg-gray-100">
                                    {!!data?.fromAvatarUrl ? (
                                        <img
                                            className="inline-block h-10 w-10 rounded-full"
                                            src={data.fromAvatarUrl}
                                            alt="Avatar"
                                        />
                                    ) : (
                                        <svg
                                            className="h-full w-full text-gray-300"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    )}
                                </span>
                            </div>
                            <div className="flex w-full max-w-xl rounded-md border-gray-300 overflow-hidden">
                                <span className="inline-flex w-32 items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                    Choose File
                                </span>
                                <input className="w-full max-w-xl block rounded-r-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200 not-sr-only pointer-events-none" />
                                <input
                                    type="file"
                                    name="avatar"
                                    id="avatar"
                                    className="sr-only"
                                    placeholder=""
                                    value={""}
                                    onChange={(event) => {
                                        const files = Array.from(
                                            event.target.files
                                        );

                                        upload(files);
                                    }}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                        <div className="flex justify-center items-center">
                            <button
                                onClick={closeModal}
                                className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="flex justify-center items-center">
                            <button
                                disabled={!data.from || uploading}
                                className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    );
}

export default function NotificationModal() {
    const { isAtModalUrl, closeModal } =
        useModalQueryParams("NotificationModal");

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={closeModal}
            size="m"
            backgroundColor="#111928"
        >
            {isAtModalUrl && <NotificationModalContents />}
        </ModalContainer>
    );
}
