import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { DataSandboxProps } from "./types";
import { useDataSandbox } from "./useDataSandbox";
import { ClusterAnalysisDisplay } from "./ClusterAnalysisDisplay";
import { RegressionDisplay } from "./RegressionDisplay";
import { ForecastDisplay } from "./ForecastDisplay";
import { OverviewDisplay } from "./OverviewDisplay";

const TabContentWrapper = ({
    tabKey,
    children,
}: {
    tabKey: string;
    children: React.ReactNode;
}) => {
    return (
        <Tabs.Content key={tabKey} value={tabKey} style={{}}>
            <div className={"mx-auto my-0 w-full min-w-80 max-w-7xl"}>
                <div
                    className="flex w-full flex-col space-y-8 divide-y-[1px] divide-slate-500 px-6 py-8
                        2xl:px-0 dark:divide-slate-500"
                >
                    <div className="text-lg font-bold text-slate-700 dark:text-slate-300">
                        {tabKey}
                    </div>
                    {children}
                </div>
            </div>
        </Tabs.Content>
    );
};

const DataSandboxDetailComponent = ({
    tabKeys,
    data,
    years,
    variables,
}: { tabKeys: string[] } & DataSandboxProps) => {
    const { regressionProps, clusterAnalysisProps, forecastProps } =
        useDataSandbox();

    return (
        <>
            <TabContentWrapper tabKey={tabKeys[0]}>
                <OverviewDisplay
                    data={data}
                    years={years}
                    variables={variables}
                />
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[1]}>
                <RegressionDisplay variables={variables} {...regressionProps} />
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[2]}>
                <ClusterAnalysisDisplay
                    variables={variables}
                    {...clusterAnalysisProps}
                />
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[3]}>
                <ForecastDisplay
                    variables={variables}
                    years={years}
                    {...forecastProps}
                />
            </TabContentWrapper>
        </>
    );
};

export const DataSandboxDetail = React.memo(DataSandboxDetailComponent);
