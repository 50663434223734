import React from "react";
import { ModelBlockDisplay } from "./ModelBlockDisplay";
import { ModelBlock } from "../../models";
import { plus } from "react-icons-kit/feather/plus";
import { useModelBlockArray, useModelBlockStore } from "../../hooks/store";
import { PrimaryButtonOutline } from "../../components/admin-components";
import { SortableModelBlockTree } from "./SortableModelBlockTree";
import { SapienInertia } from "@/inertia-utils/hooks";

export const ModelBlockList = (props: {
    simulationId: string;
    interactive: boolean;
}) => {
    const { simulationId, interactive } = props;

    const { modelBlocks } = useModelBlockStore();
    const { modelBlockArray } = useModelBlockArray();

    return (
        <div>
            <div className="mb-4">
                <div className="text-l mb-2 font-bold">Blocks</div>
                {Object.values(modelBlocks)
                    .filter((modelBlock) => !modelBlock.has_connections)
                    .map((modelBlock, i) => (
                        <ModelBlockDisplay
                            key={modelBlock.id}
                            modelBlock={modelBlock}
                            styleValue={0}
                            isInteractive={interactive}
                        />
                    ))}
            </div>
            <div className="mb-10">
                {!interactive && (
                    <PrimaryButtonOutline
                        text={"Create Model Block"}
                        icon={plus}
                        handler={() =>
                            SapienInertia.post(
                                "model-builder.model-blocks.store",
                                {
                                    label: "New Model Block",
                                    simulation_id: simulationId,
                                    weight:
                                        Object.values(modelBlocks)?.length || 0,
                                },
                                {},
                                {
                                    preserveState: true,
                                    preserveScroll: true,
                                },
                            )
                        }
                    />
                )}
            </div>
            <div>
                <div className="text-l mb-2 font-bold">Organize Blocks</div>
                <SortableModelBlockTree
                    modelBlocks={modelBlocks}
                    modelBlockArray={modelBlockArray as unknown as ModelBlock[]}
                    preventMixedLevelSort={interactive}
                />
            </div>
        </div>
    );
};
