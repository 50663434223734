import React from "react";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Authenticated from "@/Layouts/Authenticated";


type Props = Pick<SapienAdminPageProps, "auth" | "errors"> & {
    children: React.ReactNode;
    showDefaultHeader?: boolean;
};
const queryClient = new QueryClient();
export default function CoursePageWrapper({
    auth,
    children,
    showDefaultHeader = true,
}: Props) {


    return (
        <QueryClientProvider client={queryClient}>
            <Authenticated>
                <div className="min-h-screen bg-slate-100">
                    {!!showDefaultHeader && (
                        <header className="bg-white shadow-sm">
                            <div className="mx-auto flex max-w-7xl justify-between px-4 py-4 sm:px-6 lg:px-8">
                                <h1 className="text-lg font-semibold leading-6 text-gray-900">
                                    Courses
                                </h1>
                            </div>
                        </header>
                    )}
                    {children}
                </div>
            </Authenticated>
        </QueryClientProvider>
    );
}
