import React from 'react'
import { WithId } from './WithId'

type Props = {threads: WithId<App.Data.ThreadData>[]}

export default function ThreadList({}: Props) {
  return (
    <div>ThreadList</div>
  )
}
