import React, { useEffect, useMemo, useState } from "react";
import { getComparisonData } from "./getComparisonData";
import { Combobox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
    CohortShape,
    ModelVariable,
    ModelVariableDataType,
    TeamUserRoundPivot,
    VariableValue,
} from "@/models";
import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { sapienRoute } from "@/inertia-utils/hooks";
import { Link } from "@inertiajs/react";

type CohortWithTeamUserRounds = CohortShape & {
    team_user_rounds: (TeamUserRoundPivot & {
        user: { name: string };
        id: string;
    })[];
};
type Props = {
    cohorts: { [index: string]: CohortWithTeamUserRounds };
    simulation: string;
    teamUserRoundId?: string;
};

const getDifferencesMap = (
    variables: ModelVariable[],
    targetValues: {
        [index: string]: VariableValue[];
    },
    pythonValues: {
        [index: string]: VariableValue[];
    },
) => {
    return variables?.reduce(
        (map, variable) => ({
            ...map,
            [variable.id]: !isEqual(
                targetValues[variable.id]?.map((value) => ({
                    numerical_value: value.numerical_value,
                    boolean_value: value.boolean_value,
                    model_variable_id: value.model_variable_id,
                    time_horizon_id: value.time_horizon_id,
                })),
                pythonValues[variable.id]?.map((value) => ({
                    numerical_value: value.numerical_value,
                    boolean_value: value.boolean_value,
                    model_variable_id: value.model_variable_id,
                    time_horizon_id: value.time_horizon_id,
                })),
            ),
        }),
        {},
    );
};

const DisplayVariableRows = ({
    i,
    variable,
    targetValueRow,
    pythonValueRow,
    numberOfTimeHorizons,
    hasDifference,
}: {
    i: number;
    variable: ModelVariable;
    targetValueRow: VariableValue[];
    pythonValueRow: VariableValue[];
    numberOfTimeHorizons: number;
    hasDifference: boolean;
}) => {
    const isEven = !(i % 2);

    const differencesMap = useMemo(() => {
        if (hasDifference && targetValueRow?.length == pythonValueRow?.length) {
            return targetValueRow.reduce(
                (acc, targetValue, i) => {
                    return {
                        ...acc,
                        [i]:
                            variable.data_type === ModelVariableDataType.Number
                                ? targetValue.numerical_value !=
                                  pythonValueRow[i].numerical_value
                                : targetValue.boolean_value !=
                                  pythonValueRow[i].boolean_value,
                    };
                },
                {} as { [index: number]: boolean },
            );
        } else {
            return {};
        }
    }, [hasDifference, targetValueRow, pythonValueRow, variable]);

    return (
        <React.Fragment key={variable.id}>
            <tr
                className={`${
                    hasDifference
                        ? "border-t border-t-red-700 bg-red-200"
                        : isEven
                          ? "bg-gray-200"
                          : "bg-gray-100"
                } border-b border-b-gray-300`}
            >
                <th
                    rowSpan={2}
                    className="max-w-[12rem] border-r border-r-gray-300 p-2 text-left text-xs"
                >
                    {variable.label}
                </th>
                {pythonValueRow?.map((value, i) => (
                    <td
                        key={i}
                        className={`w-4 p-2 text-indigo-800 ${
                            !!differencesMap[i]
                                ? "bg-red-200"
                                : isEven
                                  ? "bg-gray-200"
                                  : "bg-gray-100"
                        }`}
                        colSpan={
                            pythonValueRow?.length > 1
                                ? 1
                                : numberOfTimeHorizons
                        }
                    >
                        {variable.data_type === ModelVariableDataType.Number
                            ? value.numerical_value
                            : value.boolean_value.toString()}
                    </td>
                ))}
            </tr>
            <tr
                className={
                    hasDifference
                        ? "border-b border-b-red-700 bg-red-200"
                        : isEven
                          ? "bg-gray-200"
                          : "bg-gray-100"
                }
            >
                {targetValueRow?.map((value, i) => (
                    <td
                        key={i}
                        className={`w-4 p-2 ${
                            !!differencesMap[i]
                                ? "bg-red-200"
                                : isEven
                                  ? "bg-gray-200"
                                  : "bg-gray-100"
                        }`}
                        colSpan={
                            targetValueRow?.length > 1
                                ? 1
                                : numberOfTimeHorizons
                        }
                    >
                        {variable.data_type === ModelVariableDataType.Number
                            ? value.numerical_value
                            : value.boolean_value.toString()}
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

export default function CompareTables({
    cohorts,
    simulation,
    teamUserRoundId,
}: Props) {
    const [selectedTeamUserRoundId, _] = useState(teamUserRoundId);

    const selectedTeamUserRound = useMemo(() => {
        return Object.values(cohorts)
            ?.map((cohort) => cohort.team_user_rounds)
            ?.flat()
            ?.find((teamUserRound) => teamUserRound.id === teamUserRoundId);
    }, [simulation, cohorts, teamUserRoundId]);
    const [query, setQuery] = useState("");

    const filteredCohorts = useMemo(() => {
        if (!query)
            return Object.values(cohorts)?.filter(
                (cohort) => !!cohort?.team_user_rounds?.length,
            );
        const filtered = Object.values(cohorts)?.filter((cohort) => {
            return cohort.team_user_rounds?.some((teamUserRound) =>
                teamUserRound.user?.name
                    .toLowerCase()
                    .includes(query.toLocaleLowerCase()),
            );
        });

        return !!filtered?.length
            ? filtered
            : Object.values(cohorts)?.filter(
                  (cohort) => !!cohort?.team_user_rounds?.length,
              );
    }, [cohorts, query]);

    const { data, isFetching } = useQuery({
        queryKey: [
            "comparisonData",
            { simulation, teamUserRoundId: selectedTeamUserRoundId },
        ],
        queryFn: async () => {
            return await getComparisonData({
                teamUserRoundId: selectedTeamUserRoundId,
            });
        },
        select: (data) => data,
        initialData: {
            targetValues: {},
            phpData: {},
            pythonTime: 0,
            fullPythonTime: 0,
            phpTime: 0,
            success: true,
            targetVariables: [],
        },
        enabled: !!selectedTeamUserRoundId,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const {
        targetValues,
        phpData,
        pythonTime,
        phpTime,
        success,
        targetVariables,
        fullPythonTime,
    } = data;

    const [selectedVariables, setSelectedVariables] = useState<{
        [index: string]: ModelVariable;
    }>({});

    useEffect(() => {
        if (!data?.targetVariables) return;
        const newSelectedVariables = targetVariables.reduce(
            (acc, curr) => {
                return { ...acc, [curr.id]: curr };
            },
            {} as { [index: string]: ModelVariable },
        );
        setSelectedVariables(newSelectedVariables);
    }, [data]);

    const numberOfTimeHorizons = useMemo(() => {
        if (!data?.targetValues) return 0;
        const valueRowThatUsesTime = Object.values(data?.targetValues)?.find(
            (valueArray) => valueArray?.length > 1,
        );
        return valueRowThatUsesTime?.length || 0;
    }, [data]);

    const differencesMap = useMemo(() => {
        if (!data?.targetVariables || !data?.phpData || !data?.targetValues)
            return {};
        return getDifferencesMap(targetVariables, targetValues, phpData);
    }, [data]);

    return (
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div className="flex flex-col space-y-4 divide-y-0">
                <Combobox
                    value={selectedTeamUserRound?.user?.name || ""}
                    disabled={isFetching}
                >
                    {({ open }) => (
                        <>
                            <div className="relative mt-1">
                                <div className="relative flex w-full max-w-[42rem] items-center">
                                    <Combobox.Input
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                        }}
                                        autoComplete="off"
                                        className="relative block h-10 w-full max-w-[42rem] rounded-md border-gray-300 text-sm
                                            sm:text-xs"
                                    />
                                    <Combobox.Button
                                        className={
                                            "absolute right-4 top-1/2 -translate-y-1/2"
                                        }
                                    >
                                        <ChevronDownIcon className="h-4 w-4" />
                                    </Combobox.Button>
                                </div>
                                <div className="relative w-full max-w-[42rem]">
                                    <Combobox.Options
                                        className="max-h-32 absolute ml-0 mt-1 max-h-[calc(100vh-108px)] w-full max-w-[42rem]
                                            space-y-2 overflow-y-scroll rounded-md bg-white py-1 pl-0 text-base shadow-lg
                                            ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                        {filteredCohorts.map((cohort) => (
                                            <li
                                                className="flex w-full list-none flex-col px-2 py-1"
                                                key={cohort.id}
                                            >
                                                <h3 className="mb-2 font-bold">
                                                    {cohort.title}
                                                </h3>
                                                <ul className="flex w-full flex-col pl-0">
                                                    {cohort.team_user_rounds
                                                        .filter((tur) =>
                                                            tur.user?.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    query.toLowerCase(),
                                                                ),
                                                        )
                                                        .map(
                                                            (teamUserRound) => (
                                                                <li
                                                                    key={
                                                                        teamUserRound.id
                                                                    }
                                                                    className="h-8 w-full list-none px-2 py-1 text-gray-900"
                                                                >
                                                                    <Link
                                                                        key={
                                                                            teamUserRound.id
                                                                        }
                                                                        href={sapienRoute(
                                                                            "model-builder.calculation-comparison.show",
                                                                            {
                                                                                simulation,
                                                                                teamUserRoundId:
                                                                                    teamUserRound.id,
                                                                            },
                                                                        )}
                                                                        className='relative inline-block w-full cursor-pointer select-none list-none p-2 py-2
                                                                            text-gray-900 data-[active="true"]:bg-blue-100
                                                                            data-[active="true"]:text-blue-900'
                                                                        data-active={
                                                                            selectedTeamUserRoundId ===
                                                                            teamUserRound.id
                                                                        }
                                                                    >
                                                                        {
                                                                            teamUserRound
                                                                                ?.user
                                                                                ?.name
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            </li>
                                        ))}
                                    </Combobox.Options>
                                </div>
                            </div>
                        </>
                    )}
                </Combobox>
                <div
                    className={`flex flex-col space-y-4 divide-y ${isFetching ? "animate-pulse" : "opacity-100"}`}
                >
                    {!!data && !success && (
                        <div className="text-red-500">
                            There's no data for this user
                        </div>
                    )}

                    {!data && !isFetching && <div>Select a user</div>}

                    <div className="flex flex-col space-y-2 rounded-md bg-indigo-50 p-3">
                        {!!selectedTeamUserRound && (
                            <h3>{selectedTeamUserRound?.user?.name}</h3>
                        )}
                        {!!pythonTime && (
                            <h3>
                                Python API calculation time (seconds):{" "}
                                {Number(pythonTime).toFixed(6)} (
                                {fullPythonTime} total)
                            </h3>
                        )}
                        {!!phpTime && (
                            <h3>
                                PHP calculation time (seconds):{" "}
                                {Number(phpTime).toFixed(6)}
                            </h3>
                        )}
                    </div>
                    {!!targetVariables?.length && (
                        <div>
                            <label
                                htmlFor="model_variable"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Select a variable
                            </label>
                            <select
                                id="model_variable"
                                name="model_variable"
                                onChange={(e) => {
                                    const variable = targetVariables.find(
                                        (variable) =>
                                            variable.id === e.target.value,
                                    );
                                    if (!variable) return;
                                    setSelectedVariables((variables) => {
                                        const newVariables = { ...variables };

                                        if (
                                            Object.values(newVariables)
                                                .length ===
                                            targetVariables.length
                                        ) {
                                            return { [variable.id]: variable };
                                        }

                                        return {
                                            ...newVariables,
                                            [variable.id]: variable,
                                        };
                                    });
                                }}
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1
                                    ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm
                                    sm:leading-6"
                            >
                                <option>Select a variable to filter</option>
                                {targetVariables.map((variable) => (
                                    <option
                                        key={variable.id}
                                        value={variable.id}
                                    >
                                        {variable.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {!!Object.values(selectedVariables)?.length &&
                        Object.values(selectedVariables)?.length !==
                            targetVariables.length && (
                            <div className="mt-2 flex flex-wrap gap-2 py-2">
                                {Object.values(selectedVariables).map(
                                    (variable) => (
                                        <span
                                            key={variable.id}
                                            className="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs
                                                font-medium text-red-700"
                                        >
                                            {variable.label}
                                            <button
                                                key={variable.id}
                                                type="button"
                                                className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (
                                                        Object.values(
                                                            selectedVariables,
                                                        ).length === 1
                                                    ) {
                                                        setSelectedVariables(
                                                            targetVariables.reduce(
                                                                (
                                                                    acc,
                                                                    curr,
                                                                ) => ({
                                                                    ...acc,
                                                                    [curr.id]:
                                                                        curr,
                                                                }),
                                                                {},
                                                            ),
                                                        );
                                                        return;
                                                    }

                                                    setSelectedVariables(
                                                        (variables) => {
                                                            const newVariables =
                                                                {
                                                                    ...variables,
                                                                };
                                                            delete newVariables[
                                                                variable.id
                                                            ];
                                                            return newVariables;
                                                        },
                                                    );
                                                }}
                                            >
                                                <span className="sr-only">
                                                    Remove {variable.label}
                                                </span>
                                                <svg
                                                    viewBox="0 0 14 14"
                                                    className="h-3.5 w-3.5 stroke-red-700/50 group-hover:stroke-red-700/75"
                                                >
                                                    <path d="M4 4l6 6m0-6l-6 6" />
                                                </svg>
                                                <span className="absolute -inset-1" />
                                            </button>
                                        </span>
                                    ),
                                )}
                                <span
                                    className="inline-flex items-center gap-x-0.5 rounded-md bg-red-100 px-2 py-1 text-xs
                                        font-medium text-red-700"
                                >
                                    Reset
                                    <button
                                        type="button"
                                        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedVariables(
                                                targetVariables.reduce(
                                                    (acc, curr) => ({
                                                        ...acc,
                                                        [curr.id]: curr,
                                                    }),
                                                    {},
                                                ),
                                            );
                                        }}
                                    >
                                        <span className="sr-only">
                                            Reset filters
                                        </span>
                                        <svg
                                            viewBox="0 0 14 14"
                                            className="h-3.5 w-3.5 stroke-red-700/50 group-hover:stroke-red-700/75"
                                        >
                                            <path d="M4 4l6 6m0-6l-6 6" />
                                        </svg>
                                        <span className="absolute -inset-1" />
                                    </button>
                                </span>
                            </div>
                        )}
                    {!data.pythonTime && isFetching && (
                        <table className="table table-auto animate-pulse rounded-md">
                            <tbody>
                                {[...Array(5).keys()].map((i) => (
                                    <tr
                                        className="odd:bg-gray-200 even:bg-gray-400"
                                        key={i}
                                    >
                                        <th className="p-4"></th>
                                        <td className="p-2"></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    <div className="w-full overflow-x-auto">
                        {!!data &&
                            !!differencesMap &&
                            Object.values(differencesMap).filter(
                                (value) => !!value,
                            ).length > 0 && (
                                <div className="my-2 text-xs text-red-700">
                                    {`Variables with differences: ${
                                        Object.values(differencesMap).filter(
                                            (value) => !!value,
                                        ).length
                                    }`}
                                </div>
                            )}
                        <table className="table min-w-full table-fixed rounded-md border border-gray-300">
                            <tbody>
                                {Object.values(selectedVariables)
                                    .filter((variable) => !variable.uses_time)
                                    .map((row, i) => (
                                        <React.Fragment key={row.id}>
                                            <DisplayVariableRows
                                                key={row.id}
                                                i={i}
                                                variable={row}
                                                targetValueRow={phpData[row.id]}
                                                pythonValueRow={
                                                    targetValues[row.id]
                                                }
                                                numberOfTimeHorizons={
                                                    numberOfTimeHorizons
                                                }
                                                hasDifference={
                                                    differencesMap[row.id]
                                                }
                                            />
                                        </React.Fragment>
                                    ))}
                                {!!numberOfTimeHorizons && (
                                    <tr>
                                        <td className="border-r border-r-gray-300 p-2 text-sm">
                                            Time Horizon
                                        </td>
                                        {[
                                            ...Array(
                                                numberOfTimeHorizons,
                                            ).keys(),
                                        ].map((_, i) => (
                                            <td key={i} className="w-4 p-2">
                                                {i}
                                            </td>
                                        ))}
                                    </tr>
                                )}
                                {Object.values(selectedVariables)
                                    .filter((variable) => variable.uses_time)
                                    .map((row, i) => (
                                        <React.Fragment key={row.id}>
                                            <DisplayVariableRows
                                                key={row.id}
                                                i={i}
                                                variable={row}
                                                targetValueRow={phpData[row.id]}
                                                pythonValueRow={
                                                    targetValues[row.id]
                                                }
                                                numberOfTimeHorizons={
                                                    numberOfTimeHorizons
                                                }
                                                hasDifference={
                                                    differencesMap[row.id]
                                                }
                                            />
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        </table>
                        <div className="flex flex-col divide-y"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
