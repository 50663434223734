import React, { useMemo, useState } from "react";
import { Switch } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useFindContentBlockMethods, useModalQueryParams } from "@/hooks";
import {
    ContentBlockConfig,
    bigFlatten,
    useContentBlockMetadata,
    ContentBlockMetadata,
    getContentBlockConfigByType,
} from "@/model-configs";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { SapienInertia, usePage } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import { SapienAdminPage } from "@/inertia-utils/types";
import { useTemplateMap } from "../../content-block-templates/useTemplateMap";
import TemplateMapCategoryDisplay from "../../content-block-templates/TemplateMapCategoryDisplay";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useQuery } from "@tanstack/react-query";
import { getTemplatesToAddContentBlock } from "./getTemplatesToAddContentBlock";
import AddContentBlockButtonSlot from "../../content-block-buttons/AddContentBlockButtonSlot";
import { useAddContentBlockState } from "../../content-block-buttons/useAddContentBlockState";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function removeIdsRecursively(
    contentBlock: ContentBlockShape,
): ContentBlockShape {
    const { id, contentBlocks, ...rest } = contentBlock;

    const updatedContentBlocks = contentBlocks?.map((block) =>
        removeIdsRecursively({
            ...block,
            id: undefined,
            parent_content_block_id: undefined,
        }),
    );

    return {
        ...rest,
        ...(updatedContentBlocks && { contentBlocks: updatedContentBlocks }),
    };
}

function ContentBlockModalContent() {
    const { categories } = useContentBlockMetadata();
    const { queryParams, closeModal } = useModalQueryParams(
        "AddContentBlockModal",
    );
    const { isBehaviorType } = useAddContentBlockState();
    const { contentBlockId, position } = queryParams;
    const { data, isLoading } = useQuery({
        queryKey: ["content-block-templates", contentBlockId],
        queryFn: () => getTemplatesToAddContentBlock(contentBlockId, position),
    });

    const { auth, round } = usePage<SapienAdminPage>()?.props;

    const { getContentBlock, getContentBlockWithChildren } =
        useFindContentBlockMethods();
    const contentBlock = getContentBlock(contentBlockId);
    const parentContentBlock = useMemo(() => {
        if (contentBlock?.parent_content_block_id) {
            return getContentBlockWithChildren(
                contentBlock.parent_content_block_id,
            );
        }
    }, [contentBlock]);

    const parentConfig = useMemo(() => {
        return (
            !!parentContentBlock &&
            getContentBlockConfigByType(parentContentBlock.content_block_type)
        );
    }, [parentContentBlock]);

    const [filterState, setFilterState] = useState({
        Templates: true,
        Layouts: true,
        "Content Blocks": true,
    });

    const flattened = useMemo(() => bigFlatten(categories), []);

    const { templateMap, query, setQuery } = useTemplateMap(
        data?.templates,
        auth?.user,
        parentConfig as ContentBlockConfig,
    );
    const filteredCategories: typeof categories = useMemo(() => {
        if (parentConfig) {
            const filteredMetadatas = filterContentBlockMetadata(
                flattened,
                parentConfig as ContentBlockConfig,
                query,
                filterState,
            );

            const parentKeys = new Set(
                filteredMetadatas.map((meta) => meta.categoryKey),
            );

            const subcategoryKeys = new Set(
                filteredMetadatas.map((meta) => meta.subcategoryKey),
            );

            return Array.from(parentKeys).reduce((carry, parentKey) => {
                return {
                    ...carry,
                    [parentKey]: Array.from(subcategoryKeys)
                        .filter((subCateogryKey) =>
                            filteredMetadatas.some(
                                (contentBlockMetadata) =>
                                    contentBlockMetadata.subcategoryKey ===
                                        subCateogryKey &&
                                    contentBlockMetadata.categoryKey ===
                                        parentKey,
                            ),
                        )
                        .reduce((carry, subcategoryKey) => {
                            return {
                                ...carry,
                                [subcategoryKey]: filteredMetadatas.filter(
                                    (contentBlockMetadata) =>
                                        contentBlockMetadata.subcategoryKey ===
                                        subcategoryKey,
                                ),
                            };
                        }, {}),
                };
            }, {});
        }
        return {};
    }, [parentConfig, flattened, query, filterState]) as typeof categories;

    if (!contentBlockId || !position) return <></>;
    if (isLoading)
        return (
            <div className="flex animate-pulse flex-col gap-2 p-4 py-4">
                <div className="flex justify-between border-b py-8">
                    <div className="h-12 w-full rounded-md bg-white/80" />
                </div>
                <div className="flex gap-2 border-b py-8">
                    <div className="h-8 w-24 rounded-md bg-white/80" />
                    <div className="h-8 w-24 rounded-md bg-white/80" />
                    <div className="h-8 w-24 rounded-md bg-white/80" />
                </div>
                <div className="grid grid-cols-3 flex-wrap gap-4">
                    {[...Array(6).keys()].map((i) => (
                        <div
                            key={i}
                            className="h-64 w-full rounded-md bg-white/80"
                        ></div>
                    ))}
                </div>
                <div className="grid grid-cols-2 justify-between gap-2 border-t py-4">
                    <div className="h-12 rounded-xl bg-white/80" />
                    <div className="h-12 rounded-xl bg-white/80" />
                </div>
            </div>
        );
    return (
        <div
            className="flex flex-col space-y-4 rounded-md p-2 text-white"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            data-testid="add-content-block-modal"
        >
            <div className="px-4 pt-4">
                <h3>Select a content block to add</h3>
            </div>
            <div className="flex flex-col space-y-4 px-4 py-4">
                <h4 className="px-4">Filters</h4>
                <div className="relative mt-1 rounded-md px-4 py-2 shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-7">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        placeholder="Search"
                        type="text"
                        className={
                            "block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 pl-10 text-gray-200 focus:text-white  focus:outline-offset-0 focus:outline-blue-600/50"
                        }
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <fieldset>
                    <div className="flex space-x-4 px-4">
                        {Object.keys(filterState).map((filterKey) => (
                            <Switch.Group
                                as="div"
                                className="flex flex-wrap items-center justify-between space-x-1 rounded-md bg-white/10 px-2 py-1"
                                key={filterKey}
                            >
                                <Switch
                                    checked={
                                        !!filterState[
                                            filterKey as keyof typeof filterState
                                        ]
                                    }
                                    onChange={(checked) => {
                                        setFilterState((previousState) => {
                                            return {
                                                ...previousState,
                                                [filterKey]: checked,
                                            };
                                        });
                                    }}
                                    className={classNames(
                                        !!filterState[
                                            filterKey as keyof typeof filterState
                                        ]
                                            ? "bg-indigo-600"
                                            : "bg-gray-200",
                                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            !!filterState[
                                                filterKey as keyof typeof filterState
                                            ]
                                                ? "translate-x-5"
                                                : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                                        )}
                                    />
                                </Switch>
                                <span className="flex flex-grow flex-col">
                                    <Switch.Label
                                        as="span"
                                        className="text-sm font-medium leading-6 "
                                        passive
                                    >
                                        {filterKey}
                                    </Switch.Label>
                                </span>
                            </Switch.Group>
                        ))}
                    </div>
                </fieldset>
            </div>
            <div className="max-h-[50vh] overflow-y-auto px-4 pt-4 scrollbar scrollbar-track-gray-50/10 scrollbar-thumb-gray-300 scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-2">
                {Object.keys(filteredCategories).map((categoryKey) => (
                    <div
                        className="div flex flex-col gap-2 p-4 text-white"
                        key={categoryKey}
                    >
                        <div className="flex flex-col gap-2">
                            {Object.keys(filteredCategories[categoryKey]).map(
                                (subCategoryKey) => (
                                    <div
                                        className="flex flex-col py-2"
                                        key={subCategoryKey}
                                    >
                                        <h3 className="text-bold py-2 font-bold uppercase">
                                            {subCategoryKey}
                                        </h3>

                                        <div
                                            className={`grid gap-2 ${
                                                categoryKey !== "Layouts"
                                                    ? "grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4"
                                                    : "grid-cols-2 gap-y-6"
                                            }`}
                                        >
                                            {filteredCategories[categoryKey][
                                                subCategoryKey
                                            ].map(
                                                (
                                                    addable: ContentBlockMetadata,
                                                ) => (
                                                    <div
                                                        key={addable.key}
                                                        className={`relative flex cursor-pointer items-center justify-start gap-2 rounded-md bg-white/10 p-2 px-4 transition-all hover:bg-white/20 ${
                                                            categoryKey !==
                                                            "Layouts"
                                                                ? "flex-row"
                                                                : "flex-col"
                                                        }`}
                                                    >
                                                        <>
                                                            <div
                                                                className={`relative flex h-full w-full items-center justify-center`}
                                                            >
                                                                <AddContentBlockButtonSlot
                                                                    contentBlock={
                                                                        parentContentBlock
                                                                    }
                                                                    addableContentBlockMetaData={
                                                                        addable
                                                                    }
                                                                    isAddingModalToExistingContentBlock={
                                                                        false
                                                                    }
                                                                    newModalTriggerType={
                                                                        undefined
                                                                    }
                                                                    isAddingBehaviorBlock={isBehaviorType(
                                                                        getContentBlockConfigByType(
                                                                            addable.blockOrLayoutType,
                                                                        ),
                                                                    )}
                                                                    isAddingQuestion={
                                                                        addable.blockOrLayoutType ===
                                                                        ContentBlockType.Question
                                                                    }
                                                                    depth={0}
                                                                    weight={
                                                                        contentBlock?.weight +
                                                                        1
                                                                    }
                                                                />
                                                                {addable.icon}
                                                            </div>
                                                            {categoryKey ===
                                                                "Layouts" && (
                                                                <div className="w-full text-center capitalize ">
                                                                    {addable.key
                                                                        .charAt(
                                                                            0,
                                                                        )
                                                                        .toUpperCase() +
                                                                        addable.key
                                                                            .substring(
                                                                                1,
                                                                            )
                                                                            .toLowerCase()}
                                                                </div>
                                                            )}
                                                        </>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                ))}
                {filterState?.Templates &&
                    Object.keys(templateMap).map((category) => (
                        <TemplateMapCategoryDisplay
                            key={category}
                            category={category}
                            templates={templateMap[category]}
                            selectedTemplate={null}
                            handleClickAdd={(selectedTemplate) => {
                                const indexWithinParent =
                                    parentContentBlock?.contentBlocks?.findIndex(
                                        (child) => child.id === contentBlockId,
                                    );

                                const block = removeIdsRecursively({
                                    ...selectedTemplate.content_block,
                                    parent_content_block_id:
                                        parentContentBlock?.id,
                                    weight: indexWithinParent
                                        ? indexWithinParent + 1
                                        : 0,
                                    round_id: round.id,
                                });

                                SapienInertia.post(
                                    "creator.design.store.from-template",
                                    {
                                        ...block,
                                        position,
                                        ...{
                                            theme: JSON.stringify(block.theme),
                                        },
                                    } as (typeof LaravelRequestBodyShapes)["creator.design.store.from-template"],
                                    {
                                        templateId: selectedTemplate.id,
                                    },
                                );
                            }}
                            emptyLabel="There aren't any templates in this category."
                        />
                    ))}
            </div>
            <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-1">
                <div className="flex items-center justify-center">
                    <button
                        onClick={closeModal}
                        className="inline-flex w-full items-center justify-center rounded-full bg-[#1f2a378f] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

//TODO: switch to new pattern. Query templates using react-query
export default function AddContentBlockModal() {
    const { isAtModalUrl } = useModalQueryParams("AddContentBlockModal");

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => {}}
            size="l"
            backgroundColor="#111928"
            overlayStyes={{ maxHeight: "100vh", overflowY: "hidden" }}
            modalId="add-content-block-modal"
        >
            {!!isAtModalUrl && <ContentBlockModalContent />}
        </ModalContainer>
    );
}

function filterContentBlockMetadata(
    metaDatas: (ContentBlockMetadata & {
        subcategoryKey: string;
        categoryKey: string;
    })[],
    config: ContentBlockConfig,
    filterText: string = "",
    filterState: {
        Templates: boolean;
        Layouts: boolean;
        "Content Blocks": boolean;
    },
) {
    return metaDatas.filter((metaData) => {
        const filterStateMapping =
            metaData.categoryKey === "Layouts" ? "Layouts" : "Content Blocks";
        if (!filterState[filterStateMapping]) {
            return false;
        }

        // const matchesFilterState =

        const casedFilterText = filterText.toLowerCase();
        const matchesFilterText =
            !filterText ||
            metaData.key.toLowerCase().indexOf(casedFilterText) !== -1 ||
            metaData.subcategoryKey.toLowerCase().indexOf(casedFilterText) !==
                -1 ||
            metaData.categoryKey.toLowerCase().indexOf(casedFilterText) !== -1;

        return (
            matchesFilterText &&
            (config?.addableChildBlocks?.some(
                (addableBlockType) =>
                    addableBlockType === metaData.blockOrLayoutType,
            ) ||
                config?.addableLayouts?.some(
                    (addableBlockType) =>
                        addableBlockType === metaData.blockOrLayoutType,
                ))
        );
    });
}
