import React from "react";
import { AdminPageSection } from "./AdminPageSection";

export const AdminPageHeader = ({
    children,
    pageTitle,
}: {
    children?: React.ReactNode;
    pageTitle?: string;
}) => {
    return (
        <AdminPageSection className="border-b border-slate-200 bg-slate-50">
            <div className="flex min-h-16 flex-row items-center justify-between py-2">
                {!!pageTitle && (
                    <div>
                        <span className="text-xl font-semibold text-slate-900">
                            {pageTitle}
                        </span>
                    </div>
                )}
                {children !== undefined && (
                    <div className={!pageTitle ? "w-full" : ""}>{children}</div>
                )}
            </div>
        </AdminPageSection>
    );
};
