import React, { useState } from "react";
import { Lesson } from "../types";
import { useViewedContentBlockState } from "./viewed-content-block-tracking/useViewedContentBlockState";
import { Link } from "@inertiajs/react";
import { Progress } from "./ProgressTracker";
import { sapienRoute } from "@/inertia-utils/hooks";
import { useAtom } from "jotai";
import { transitionDirectionAtom } from "../shared/page-transitions/usePageTransition";
import { useLessonScore } from "./useLessonScore";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { Popover, PopoverTrigger } from "@/components/popover/Popover";
import { PopoverContent } from "@radix-ui/react-popover";

type Props = {
    lesson: Lesson;
    currentLesson: Lesson;
    route: "e-learning.preview.show" | "e-learning.participant.lesson.show";
    weight: number;
    currentWeight: number;
};

export default function LessonLink({
    lesson,
    currentLesson,
    route,
    weight,
    currentWeight,
}: Props) {
    const { viewedContentBlockPercentage } = useViewedContentBlockState(lesson);
    const [_, setDirection] = useAtom(transitionDirectionAtom);
    const { hasPassedQuiz, lessonScore, blockedLessonIds } = useLessonScore();
    const isCurrentLesson = lesson.id === currentLesson.id;

    const [open, setOpen] = useState(false);

    return (
        <div
            className="w-full data-[is-disabled='false']:cursor-pointer
                data-[is-disabled='true']:cursor-not-allowed"
            key={lesson.id}
            data-is-current-lesson={isCurrentLesson}
            data-is-disabled={!hasPassedQuiz}
        >
            {!blockedLessonIds.includes(lesson.id) ||
            currentLesson.id === lesson.id ? (
                <Link
                    onClick={() => {
                        setDirection(weight > currentWeight ? "up" : "down");
                    }}
                    href={sapienRoute(route, { lesson: lesson.id })}
                    className="flex w-full items-center justify-between border-b border-l-8 border-slate-200
                        bg-slate-200 px-2 py-4 transition-colors last:border-b-0
                        data-[is-current='false']:border-l-transparent
                        data-[is-current='true']:border-l-sapien-blue data-[is-current='true']:bg-white
                        hover:bg-white disabled:cursor-not-allowed"
                    data-is-current={isCurrentLesson ? "true" : "false"}
                >
                    <p className="text-left">{lesson.title}</p>
                    <div className="text-green-500">
                        <Progress
                            percentage={viewedContentBlockPercentage}
                            size={25}
                            strokeWidth={3}
                            strokeColor="text-green-500"
                            trailColor="text-slate-300"
                            textColor="text-green-700"
                            showPercentage={false}
                        />
                    </div>
                </Link>
            ) : (
                <Popover open={open}>
                    <PopoverTrigger
                        data-is-current={isCurrentLesson ? "true" : "false"}
                        className="group/requires-quiz relative flex w-full cursor-not-allowed items-center
                            justify-between overflow-visible border-b border-l-8 border-slate-200
                            bg-slate-200 px-2 py-4 text-gray-600 transition-colors last:border-b-0
                            data-[is-current='false']:border-l-transparent
                            data-[is-current='true']:border-l-sapien-blue data-[is-current='true']:bg-white
                            focus-visible:outline-none"
                        onMouseEnter={() => {
                            setOpen(true);
                        }}
                        onMouseLeave={() => {
                            setOpen(false);
                        }}
                    >
                        <p className="text-left">{lesson.title}</p>
                        <div className="text-green-500">
                            <Progress
                                percentage={viewedContentBlockPercentage}
                                size={25}
                                strokeWidth={3}
                                strokeColor="text-green-500"
                                trailColor="text-slate-300"
                                textColor="text-green-700"
                                showPercentage={false}
                            />
                        </div>
                    </PopoverTrigger>
                    <PopoverContent className="z-[999999] w-72 rounded-md bg-sapien-blue p-4 shadow">
                        {!!lessonScore.required_score ? (
                            <p className="text-sm text-white">
                                You must score{" "}
                                {lessonScore.required_score < 100
                                    ? " at least"
                                    : ""}{" "}
                                {lessonScore.required_score}% on the quiz to
                                access this lesson.
                            </p>
                        ) : (
                            <p className="text-sm text-white">
                                You can't access this lesson until you complete
                                required quizzes.
                            </p>
                        )}
                    </PopoverContent>
                </Popover>
            )}
        </div>
    );
}
