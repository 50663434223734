import React, { useMemo } from "react";
import { ModelBlock } from "@/models";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const ModelBlockCarouselMenuComponent = ({
    selectedModelBlock,
    setSelectedModelBlock,
    modelBlocks,
}: {
    selectedModelBlock: ModelBlock;
    setSelectedModelBlock: (value: React.SetStateAction<ModelBlock>) => void;
    modelBlocks: ModelBlock[];
}) => {
    const selectedBlockIndex = useMemo(() => {
        return modelBlocks
            .map((block) => block.id)
            .indexOf(selectedModelBlock.id);
    }, [selectedModelBlock, modelBlocks]);

    return (
        <div className="flex items-center justify-center space-x-2">
            <button
                type="button"
                className="inline-flex items-center rounded-md bg-transparent p-2 text-center text-sm
                    font-medium text-slate-500 transition-all hover:bg-slate-50 hover:text-slate-800
                    focus:outline-none focus:ring-0 disabled:text-slate-300
                    hover:disabled:bg-transparent dark:text-slate-500 dark:hover:bg-slate-800/25
                    dark:hover:text-slate-300 dark:disabled:text-slate-700
                    dark:hover:disabled:bg-transparent"
                disabled={selectedBlockIndex === 0}
                onClick={() => {
                    setSelectedModelBlock(modelBlocks[selectedBlockIndex - 1]);
                }}
            >
                <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <select
                className="min-w-[50%] rounded-md border-slate-200 bg-slate-50/50 px-4 py-2 text-center
                    text-base text-slate-700 transition-all [text-align-last:center] marker:border
                    focus:border-slate-300 focus:outline-none focus:ring-0 dark:border-slate-800
                    dark:bg-slate-800/20 dark:text-slate-300 dark:focus:border-slate-700"
                value={selectedModelBlock.id}
                onChange={(e) => {
                    setSelectedModelBlock(
                        modelBlocks.find((block) => block.id == e.target.value),
                    );
                }}
            >
                {modelBlocks.map((block) => (
                    <option
                        key={block.id}
                        value={block.id}
                        className="dark:bg-slate-800"
                    >
                        {block.label}
                    </option>
                ))}
            </select>
            <button
                type="button"
                className="inline-flex items-center rounded-md bg-transparent p-2 text-center text-sm
                    font-medium text-slate-500 transition-all hover:bg-slate-50 hover:text-slate-800
                    focus:outline-none focus:ring-0 disabled:text-slate-300
                    hover:disabled:bg-transparent dark:text-slate-500 dark:hover:bg-slate-800/25
                    dark:hover:text-slate-300 dark:disabled:text-slate-700
                    dark:hover:disabled:bg-transparent"
                disabled={selectedBlockIndex === modelBlocks.length - 1}
                onClick={() => {
                    setSelectedModelBlock(modelBlocks[selectedBlockIndex + 1]);
                }}
            >
                <ChevronRightIcon className="h-5 w-5" />
            </button>
        </div>
    );
};

export const ModelBlockCarouselMenu = React.memo(
    ModelBlockCarouselMenuComponent,
);
