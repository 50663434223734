import React, { useMemo, useState } from "react";
import {
    ContentBlockType,
    OptionShape,
    PromptShape,
    PromptType,
} from "@/models";
import { createPortal } from "react-dom";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import TimelineDragAndDropContainerModal from "./TimelineDragAndDropContainerModal";
import {
    OptionContainer,
    SelectionContainer,
} from "@/styles/themes/Question/drag-and-drop/types";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import TimelineOrDragAndDropCreateOptionModal from "./TimelineOrDragAndDropOptionModal";
import { useSelectedRoundId } from "@/hooks";
import { OptionPopover } from "../../OptionPopover";
import DragAndDropOptionModal from "./DragAndDropOptionModal";

type Props = {
    prompt: PromptShape;
};

export type SaveableContainer = (
    | Partial<OptionContainer>
    | Partial<SelectionContainer>
) & { id?: string };

export const TimelineAndDndForms = ({ prompt }: Props) => {
    // selectionContainers, options
    const { optionContainers, selectionContainers } = prompt;

    const optionContainerWrapper = document.querySelector(
        ".activeBlock + div .option-container-wrapper"
    ) as HTMLDivElement;
    const selectionContainerWrapper = document.querySelector(
        ".activeBlock + div .selection-container-wrapper"
    );

    const [editedContainer, setEditedContainer] = useState<
        SaveableContainer | undefined
    >(undefined);

    const [editedOption, setEditedOption] = useState<OptionShape | undefined>(
        undefined
    );

    const { selectedRoundId } = useSelectedRoundId();

    const column_count = useMemo(() => {
        const columnCount = Math.max(
            ...[...optionContainers, ...selectionContainers].map(
                (container) => container.column_count
            )
        );

        return columnCount || 6;
    }, [optionContainers, selectionContainers]);

    const dragItemsByContainer: { [index: string]: OptionShape[] } =
        useMemo(() => {
            return [...optionContainers, ...selectionContainers].reduce(
                (carry, container) => {
                    return {
                        ...carry,
                        [container.id]: prompt.options.filter(
                            (option) =>
                                option.drag_and_drop_prompt_container_id ===
                                container.id
                        ),
                    };
                },
                {}
            );
        }, [optionContainers, selectionContainers, prompt]);

    const deleteContainer = (container: SaveableContainer) => {
        if (
            container.id &&
            confirm(
                `Are you sure you want to delete the container ${container.label}?`
            )
        ) {
            SapienInertia.delete(
                sapienRoute(
                    "creator.design.drag-and-drop-prompt-containers.destroy",
                    {
                        drag_and_drop_prompt_container: container.id,
                    }
                )
            );
        }
    };

    if (optionContainerWrapper)
        optionContainerWrapper.style.paddingBottom = "3rem";

    return (
        <>
            {optionContainerWrapper &&
                createPortal(
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setEditedContainer({
                                label: "Add a label",
                                is_selection_container: false,
                                container_type: "column",
                                prompt_id: prompt.id,
                                column_count,
                                row_count: 1,
                            });
                        }}
                        className="z-100 transition-all bg-white text-gray-600 hover:text-green-600 opacity-90 hover:opacity-100 p-2 rounded-full shadow-lg absolute right-2 -bottom-2 group flex items-center cursor-pointer"
                    >
                        <PlusIcon className="h-4 w-4" />
                        <span className="transition-all w-full max-w-sm inline-flex items-center justify-center text-xs font-bold">
                            Add Option Category
                        </span>
                    </button>,
                    optionContainerWrapper
                )}

            {selectionContainerWrapper &&
                createPortal(
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setEditedContainer(() => ({
                                label: "Add a label",
                                is_selection_container: true,
                                container_type: "column",
                                prompt_id: prompt.id,
                                column_count,
                                row_count: 1,
                            }));
                        }}
                        className="z-100 transition-all bg-white text-gray-600 hover:text-green-600 opacity-90 hover:opacity-100 p-2 rounded-full shadow-lg absolute right-2 -bottom-5 group flex items-center cursor-pointer"
                    >
                        <PlusIcon className="h-4 w-4" />
                        <span className="transition-all w-full max-w-sm inline-flex items-center justify-center text-xs font-bold">
                            Add Selection Category
                        </span>
                    </button>,
                    selectionContainerWrapper
                )}

            {optionContainers.map((container) => {
                const options = dragItemsByContainer[container.id];

                return options.map((option) => {
                    const optionElement = document.querySelector(
                        `[data-option-id="${option.id}"]`
                    );
                    if (!optionElement) return null;

                    return createPortal(
                        <div
                            className={
                                "absolute -top-2 -left-2 flex gap-2 opacity-50 hover:opacity-100 justify-end items-end group z-100"
                            }
                        >
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditedOption(option);
                                }}
                                className="transition-all bg-white shadow-2xl  hover:shadow-lg rounded-full p-2 text-blue-600 gap-2 group flex items-center cursor-pointer"
                            >
                                <PencilIcon className="h-4 w-4" />
                                {!!option.selectionDataModelVariable && (
                                    <div className="relative flex items-center">
                                        <OptionPopover />
                                    </div>
                                )}
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        confirm(
                                            "Are you sure you want to delete this option?"
                                        )
                                    ) {
                                        SapienInertia.delete(
                                            sapienRoute(
                                                "creator.design.drag-and-drop-prompt-containers.options.destroy",
                                                { option: option.id }
                                            ),
                                            {
                                                onError: (page) => {
                                                    console.log(page);
                                                },
                                                onSuccess: () => {
                                                    console.log("success");
                                                },
                                            }
                                        );
                                    }
                                }}
                                className="transition-all bg-white shadow-2xl  hover:shadow-lg rounded-full p-2 text-red-600 gap-2 group flex items-center cursor-pointer"
                            >
                                <TrashIcon className="h-4 w-4" />
                                {!!option.selectionDataModelVariable && (
                                    <div className="relative flex items-center">
                                        <OptionPopover />
                                    </div>
                                )}
                            </button>
                        </div>,
                        optionElement
                    );
                });
            })}
            {[...optionContainers, ...selectionContainers].map((container) => {
                const containerElement = document.getElementById(
                    `${container.id}`
                );

                if (!containerElement)
                    return <React.Fragment key={container.id}></React.Fragment>;

                containerElement.style.paddingBottom = "3em";

                return (
                    <React.Fragment key={container.id}>
                        {createPortal(
                            <div
                                key={container.id}
                                className={`absolute pointer-events-none right-2 flex gap-2 opacity-80 hover:opacity-100 w-full  items-start ${
                                    prompt.prompt_type === PromptType.Timeline
                                        ? "-top-2  h-full right-2 justify-end"
                                        : "bottom-3 left-2 justify-start"
                                }`}
                            >
                                <button
                                    className="transition-all pointer-events-auto bg-white shadow-2xl hover:shadow-lg rounded-full p-2  text-gray-600 hover:text-green-600 gap-2 group flex items-center cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditedContainer(container);
                                    }}
                                >
                                    <PencilIcon className="h-4 w-4" />
                                    <span className="transition-all w-full max-w-sm inline-flex items-center justify-center text-xs font-bold">
                                        Edit Container
                                    </span>
                                </button>
                                <button
                                    className="bg-white shadow-2xl pointer-events-auto hover:shadow-lg transition-all rounded-full p-2  text-red-300 hover:text-red-600 gap-2 group flex items-center cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteContainer(container);
                                    }}
                                >
                                    <TrashIcon className="h-4 w-4" />
                                </button>
                                <button className="shadow-2xl hover:shadow-lg pointer-events-auto transition-all bg-white text-gray-600 hover:text-green-600 gap-2 p-2 rounded-full group flex items-center cursor-pointer">
                                    <PlusIcon className="h-4 w-4" />
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEditedOption({
                                                content: "",
                                                prompt_id: prompt.id,
                                                drag_and_drop_prompt_container_id:
                                                    container.id,
                                                contentBlock: {
                                                    content: "",
                                                    content_block_type:
                                                        ContentBlockType.Card,
                                                    round_id: selectedRoundId,
                                                },
                                                numerical_value: 0,
                                                weight:
                                                    dragItemsByContainer[
                                                        container.id
                                                    ]?.length + 1 || 1,
                                            });
                                        }}
                                        className="transition-all w-full max-w-sm inline-flex items-center justify-center text-xs font-bold"
                                    >
                                        Add Option
                                    </span>
                                </button>
                            </div>,
                            containerElement
                        )}
                        {prompt.prompt_type === PromptType.Timeline && (
                            <TimelineOrDragAndDropCreateOptionModal
                                option={
                                    editedOption?.drag_and_drop_prompt_container_id ===
                                    container.id
                                        ? editedOption
                                        : null
                                }
                                setOption={setEditedOption}
                                parentContentBlockId={prompt.content_block_id}
                                weight={
                                    dragItemsByContainer[container.id]?.length +
                                        1 || 1
                                }
                                container={container}
                            />
                        )}
                        {prompt.prompt_type === PromptType["Drag and Drop"] && (
                            <DragAndDropOptionModal
                                option={
                                    editedOption?.drag_and_drop_prompt_container_id ===
                                    container.id
                                        ? editedOption
                                        : null
                                }
                                setOption={setEditedOption}
                                parentContentBlockId={prompt.content_block_id}
                                weight={
                                    dragItemsByContainer[container.id]?.length +
                                        1 || 1
                                }
                                container={container}
                            />
                        )}
                    </React.Fragment>
                );
            })}
            <TimelineDragAndDropContainerModal
                container={editedContainer}
                setContainer={setEditedContainer}
            />
        </>
    );
};
