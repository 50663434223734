import React, { useMemo } from "react";
import { ModelBlock, ModelVariable, ModelVariableDataType } from "@/models";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { getIsModelTimeVariable } from "./utils";

const InteractiveBlockLinkDisplayComponent = ({
    simulationSlug,
    modelBlock,
}: {
    simulationSlug: string;
    modelBlock: ModelBlock;
}) => {
    const modelVariables: ModelVariable[] = useMemo(() => {
        return modelBlock?.modelVariables?.length > 0 ||
            modelBlock?.modelBlocks?.length > 0
            ? [
                  ...modelBlock.modelVariables.filter(
                      (variable) => !getIsModelTimeVariable(variable.label),
                  ),
                  ...modelBlock.modelBlocks.reduce(
                      (variableArray, block) => [
                          ...variableArray,
                          ...block.modelVariables,
                      ],
                      [],
                  ),
              ]
            : [];
    }, [modelBlock]);

    const keyMetricVariables = useMemo(() => {
        return (
            modelVariables?.filter(
                (variable) =>
                    variable.expose_to_facilitator &&
                    variable.data_type === ModelVariableDataType.Number,
            ) ?? []
        );
    }, [modelVariables]);

    return (
        <InertiaLink
            key={modelBlock.id}
            href={sapienRoute("interactive.show", {
                simulationSlug: simulationSlug,
                moduleId: modelBlock.id,
            })}
        >
            <div
                key={modelBlock.id}
                className="rounded-md bg-slate-100 p-4 text-slate-700 transition-all hover:bg-slate-200
                    hover:shadow-md dark:bg-slate-800 dark:text-slate-300 dark:hover:bg-slate-700"
            >
                <div className="flex flex-col space-y-2">
                    <h2 className="text-lg font-bold">{modelBlock.label}</h2>
                    {keyMetricVariables?.length > 0 ? (
                        <div className="w-full text-slate-600 dark:text-slate-400">
                            <div className="mx-3 divide-y divide-slate-400 dark:divide-slate-500">
                                <div className="text-md pb-1 font-semibold">
                                    {"Key Variables"}
                                </div>
                                <div className="flex flex-col pt-1 text-sm tracking-tight">
                                    {keyMetricVariables?.map((variable) => (
                                        <div
                                            key={variable.id}
                                            className={`${variable.is_key_metric ? "font-semibold" : "font-normal"}`}
                                        >
                                            {variable.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 text-slate-600 sm:grid-cols-2 dark:text-slate-400">
                            <div className="mx-3 divide-y divide-slate-400 dark:divide-slate-500">
                                <div className="text-md pb-1 font-semibold">
                                    {"Inputs"}
                                </div>
                                <div className="flex flex-col pt-1 text-sm tracking-tight">
                                    {modelVariables
                                        ?.filter(
                                            (variable) =>
                                                variable.expose_to_designer,
                                        )
                                        .map((variable) => (
                                            <div
                                                key={variable.id}
                                                className={`${variable.is_key_metric ? "font-semibold" : "font-normal"}`}
                                            >
                                                {variable.label}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="mx-3 mt-3 divide-y divide-slate-400 sm:mt-0 dark:divide-slate-500">
                                <div className="text-md pb-1 first-line:font-semibold">
                                    {"Outputs"}
                                </div>
                                <div className="flex flex-col pt-1 text-sm tracking-tight">
                                    {modelVariables
                                        ?.filter(
                                            (variable) =>
                                                variable.is_key_metric,
                                        )
                                        .map((variable) => (
                                            <div
                                                key={variable.id}
                                                className={`${variable.is_key_metric ? "font-semibold" : "font-normal"}`}
                                            >
                                                {variable.label}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </InertiaLink>
    );
};

export const InteractiveBlockLinkDisplay = React.memo(
    InteractiveBlockLinkDisplayComponent,
);
