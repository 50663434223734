import { CurrencyType, ModelVariableUnit } from "@/models";
import { variableValueFormatter } from "@/util";

const variableUnitMap: Record<string, ModelVariableUnit> = {
    Doctors: ModelVariableUnit.None,
    Associates: ModelVariableUnit.None,
    "Net Production": ModelVariableUnit.Dollars,
    "Net Collection": ModelVariableUnit.Dollars,
    "New Patient Calls": ModelVariableUnit.None,
    "Total Exams": ModelVariableUnit.None,
    "Total Starts": ModelVariableUnit.None,
    "Aligner SOC": ModelVariableUnit.Percent,
    "Brackets Starts": ModelVariableUnit.None,
    "Aligner Starts": ModelVariableUnit.None,
    "Brackets VPV": ModelVariableUnit.Dollars,
    "Aligner VPV": ModelVariableUnit.Dollars,
    "Brackets Average Visits": ModelVariableUnit.None,
    "Aligner Average Visits": ModelVariableUnit.None,
    "Total OH % of Net Collect": ModelVariableUnit.Percent,
    "Total OH Expenses": ModelVariableUnit.Dollars,
    "Staff Compensation": ModelVariableUnit.Dollars,
    "Staff Benefits": ModelVariableUnit.Dollars,
    "Staff Taxes": ModelVariableUnit.Dollars,
    "Staff Other": ModelVariableUnit.Dollars,
    "Total Staff Expenses": ModelVariableUnit.Dollars,
    "Staff % of Net Collect": ModelVariableUnit.Percent,
    "Supplies Invisalign": ModelVariableUnit.Dollars,
    "Supplies In-House Aligner": ModelVariableUnit.Dollars,
    "Supplies Other Aligner": ModelVariableUnit.Dollars,
    "Total Aligner Expenses": ModelVariableUnit.Dollars,
    "Aligner % of Net Collect": ModelVariableUnit.Percent,
    "Supplies Lab 1": ModelVariableUnit.Dollars,
    "Supplies Lab 2": ModelVariableUnit.Dollars,
    "Supplies Lab 3": ModelVariableUnit.Dollars,
    "Total Lab Expenses": ModelVariableUnit.Dollars,
    "Supplies Clinical": ModelVariableUnit.Dollars,
    "Supplies Other": ModelVariableUnit.Dollars,
    "Supplies COVID": ModelVariableUnit.Dollars,
    "Total Clinical Expenses": ModelVariableUnit.Dollars,
    "Total Clinical and Lab": ModelVariableUnit.Dollars,
    "Clinical and Lab % of Net Collect": ModelVariableUnit.Percent,
    "Marketing Internal": ModelVariableUnit.Dollars,
    "Marketing External": ModelVariableUnit.Dollars,
    "Marketing Digital": ModelVariableUnit.Dollars,
    "Marketing Wages": ModelVariableUnit.Dollars,
    "Total Marketing": ModelVariableUnit.Dollars,
    "Marketing % of Net Collect": ModelVariableUnit.Percent,
    "Facility Equipment": ModelVariableUnit.Dollars,
    "Facility Computer": ModelVariableUnit.Dollars,
    "Facility COVID": ModelVariableUnit.Dollars,
    "Facility Depreciation": ModelVariableUnit.Dollars,
    "Facility Rent": ModelVariableUnit.Dollars,
    "Facility Utilities": ModelVariableUnit.Dollars,
    "Facility Repairs": ModelVariableUnit.Dollars,
    "Facility Other": ModelVariableUnit.Dollars,
    "Total Equipment and Facility": ModelVariableUnit.Dollars,
    "Equip & Facility % of Net Collect": ModelVariableUnit.Percent,
    "Expenses Office Supplies": ModelVariableUnit.Dollars,
    "Expenses Other": ModelVariableUnit.Dollars,
    "Expenses Professional Fees": ModelVariableUnit.Dollars,
    "Expenses Insurance": ModelVariableUnit.Dollars,
    "Expenses Professional Taxes": ModelVariableUnit.Dollars,
    "Expenses Professional Meals": ModelVariableUnit.Dollars,
    "Total Other Practice Expenses": ModelVariableUnit.Dollars,
    "Other Practice % of Net Collect": ModelVariableUnit.Percent,
    "Compensation Associates Benefits": ModelVariableUnit.Dollars,
    "Compensation Associates": ModelVariableUnit.Dollars,
    "Total Associate Compensation": ModelVariableUnit.Dollars,
    "Associate % of Net Collect": ModelVariableUnit.Percent,
};

export const formatSandboxValue = (variable: string, value: number) =>
    variableValueFormatter(
        CurrencyType.USD,
        variableUnitMap[variable] || ModelVariableUnit.None,
        value,
        variableUnitMap[variable] === ModelVariableUnit.Dollars,
    );
