import React, { useMemo, useState } from "react";
import { H5Span, H6Span } from "../../components";
import { Cohort, Participant } from "../../models";
import { plus } from "react-icons-kit/feather/plus";
import {
    useSelectedCohort,
    useSelectedSimulation,
    useTeamsOfSelectedCohort,
} from "../../hooks";
import { PrimaryButtonOutline } from "../../components/admin-components";
import { Link as InertiaLink } from "@inertiajs/react";
import { TeamDisplay } from "./TeamDisplay";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { useRoundStore } from "@/hooks/store";
import { CohortProgressDisplay } from "./CohortProgressDisplay";
import {
    AllowTeamOrCohortAdvance,
    AdvanceAllowedStatus,
} from "./AllowAdvanceControls";
import { TeamWithProgressData } from "@/Pages/Admin/FacilitationContainer";
import MoveParticipantModal from "./MoveParticipantModal";
import {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid";
import QueryClient from "@/Pages/ELearning/QueryClient/QueryClient";

function getCohortStatus(cohort: Cohort): keyof typeof AdvanceAllowedStatus {
    const participants: Participant[] = cohort.teams.reduce(
        (participants, team) => {
            return [...participants, ...team.participants];
        },
        [],
    );

    if (
        participants.every(
            (participant) =>
                participant?.pivot["team_user_round.advance_allowed"],
        )
    ) {
        return "ALL_ALLOWED";
    }

    if (
        participants.some(
            (participant) =>
                participant?.pivot["team_user_round.advance_allowed"],
        )
    ) {
        return "SOME_ALLOWED";
    }

    return "NONE_ALLOWED";
}

export const CohortDetail = () => {
    const { selectedSimulation } = useSelectedSimulation();
    const { selectedCohort } = useSelectedCohort();
    const { teams } = useTeamsOfSelectedCohort();
    const { rounds } = useRoundStore();

    const [expandedTeamMap, setExpandedTeamMap] = useState<{
        [index: string]: boolean;
    }>({});

    const allAreExpanded = useMemo(() => {
        return (
            !!teams?.length && teams.every((team) => expandedTeamMap[team.id])
        );
    }, [teams?.length, expandedTeamMap]);

    const cohortStatus = useMemo(() => {
        if (!selectedCohort) return "NONE_ALLOWED";
        return getCohortStatus(selectedCohort);
    }, [selectedCohort]);

    const cohortRoundId = useMemo(() => {
        let roundIdMap = {};
        if (!!teams?.length) {
            roundIdMap = teams.reduce((outerMap, team) => {
                if (!!team.participants) {
                    return {
                        ...outerMap,
                        ...team.participants.reduce((innerMap, participant) => {
                            if (participant.pivot?.round_id) {
                                return {
                                    ...innerMap,
                                    [participant.pivot.round_id]: true,
                                };
                            } else {
                                return innerMap;
                            }
                        }, {}),
                    };
                } else {
                    return outerMap;
                }
            }, {});
        }
        return Object.keys(roundIdMap).length === 1
            ? Object.keys(roundIdMap)[0]
            : "";
    }, [teams]);

    const [participantToMove, setParticipantToMove] = useState<Participant>();

    const [showAdvanceControls, setShowAdvanceControls] =
        useState<boolean>(false);

    const isCourse = selectedSimulation?.is_course;

    return (
        <QueryClient>
            {!!selectedCohort && (
                <div className="space-y-4">
                    <div className="flex min-h-9 flex-row items-center justify-between">
                        <span className="text-xl font-normal">
                            {selectedCohort.title}
                        </span>
                    </div>
                    <div className="mb-6 rounded-sm bg-slate-300 p-4">
                        <div className="flex flex-row items-center justify-between space-x-2">
                            <span className="flex items-center">
                                <label
                                    htmlFor="location"
                                    className="whitespace-nowrap font-medium"
                                >
                                    Cohort Placement
                                </label>
                                <select
                                    id="location"
                                    name="location"
                                    className="ml-4 block w-full min-w-[200px] rounded-md border-gray-300 py-2 pl-3 pr-10
                                        text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500
                                        sm:text-sm"
                                    value={cohortRoundId}
                                    onChange={(e) => {
                                        SapienInertia.post(
                                            "facilitation.set-cohort-round",
                                            {
                                                round_id: e.target.value,
                                                cohort_id: selectedCohort.id,
                                            },
                                            {
                                                round_id: e.target.value,
                                                cohort_id: selectedCohort.id,
                                            },
                                            {
                                                preserveScroll: true,
                                            },
                                        );
                                    }}
                                >
                                    <option key="" value="" disabled />
                                    {rounds.map((round) => (
                                        <option key={round.id} value={round.id}>
                                            {round.title}
                                        </option>
                                    ))}
                                </select>
                            </span>
                            {!!showAdvanceControls && (
                                <span className="flex items-center">
                                    <span className="pr-2">Allow Advance</span>
                                    <AllowTeamOrCohortAdvance
                                        status={cohortStatus}
                                        targetType={"Cohort"}
                                        cohort_id={selectedCohort.id}
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mb-2 flex flex-row items-center justify-between">
                        <H6Span className="items center flex space-x-2">
                            Teams
                        </H6Span>
                        <span className="flex space-x-2">
                            <button
                                className="m-1 inline-flex items-center rounded-full border border-blue-700 px-3.5 py-2
                                    text-center text-sm font-medium text-blue-700 transition-all hover:bg-blue-800
                                    hover:bg-opacity-5 focus:outline-none focus:ring-0 focus:ring-blue-300"
                                onClick={() => {
                                    setExpandedTeamMap(
                                        teams.reduce((acc, team) => {
                                            return {
                                                ...acc,
                                                [team.id]: !allAreExpanded,
                                            };
                                        }, {}),
                                    );
                                }}
                            >
                                {allAreExpanded ? (
                                    <>
                                        <ArrowsPointingInIcon className="mr-1 h-4 w-4" />{" "}
                                        Collapse All
                                    </>
                                ) : (
                                    <>
                                        <ArrowsPointingOutIcon className="mr-1 h-4 w-4" />{" "}
                                        Expand All
                                    </>
                                )}
                            </button>
                            <PrimaryButtonOutline
                                text={"Create Team"}
                                icon={plus}
                                handler={() => {
                                    SapienInertia.post(
                                        "facilitation.save-team",
                                        {
                                            name: `Team ${(teams?.length || 0) + 1}`,
                                            cohort_id: selectedCohort.id,
                                            team_number:
                                                (teams?.length || 0) + 1,
                                        },
                                        {},
                                        {
                                            preserveScroll: true,
                                        },
                                    );
                                }}
                            />
                        </span>
                    </div>
                    <div className="relative mb-4 overflow-x-auto rounded-sm">
                        <table
                            className="w-full table-auto overflow-hidden rounded-sm text-left text-xs text-slate-100
                                rtl:text-right"
                        >
                            <thead className="bg-slate-800 text-xs uppercase text-slate-200">
                                <tr>
                                    {!isCourse && (
                                        <>
                                            <th className="px-2 py-3">Team</th>

                                            <th className="px-2 py-3">
                                                Participant
                                            </th>
                                        </>
                                    )}
                                    <th className="px-2 py-3">Email</th>
                                    {!isCourse && (
                                        <th className="px-2 py-3">Link</th>
                                    )}
                                    {isCourse && (
                                        <th className="px-2 py-3">
                                            Allow unpublished access
                                        </th>
                                    )}
                                    <th className="px-2 py-3">Round</th>
                                    {!!showAdvanceControls && (
                                        <th className="px-2 py-3">Advance</th>
                                    )}
                                    {/* <th className="px-2 py-3">Move</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {!!teams &&
                                    teams.map((team) => (
                                        <TeamDisplay
                                            key={team.id}
                                            team={team as TeamWithProgressData}
                                            setParticipantToMove={
                                                setParticipantToMove
                                            }
                                            showAdvanceControls={
                                                showAdvanceControls
                                            }
                                            isExpanded={
                                                expandedTeamMap[team.id]
                                            }
                                            setIsExpanded={(isExpanded) => {
                                                setExpandedTeamMap({
                                                    ...expandedTeamMap,
                                                    [team.id]: isExpanded,
                                                });
                                            }}
                                            isCourse={isCourse}
                                        />
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pt-4">
                        <div className="mb-4">
                            <H6Span>Progress</H6Span>
                        </div>
                        <div className="mb-4">
                            <CohortProgressDisplay
                                teams={teams as TeamWithProgressData[]}
                                rounds={rounds}
                            />
                        </div>
                    </div>
                    <div className="mt-4 flex flex-col justify-center">
                        <H5Span>Links</H5Span>
                        <InertiaLink
                            href={sapienRoute("discovery.show-data", {
                                simulationSlug: selectedSimulation?.slug,
                                cohortId: selectedCohort.id,
                            })}
                            className="text-blue-500 transition-all hover:text-blue-800"
                        >
                            Discovery Layer
                        </InertiaLink>
                        <InertiaLink
                            href={sapienRoute(
                                "facilitation.reflection-journal",
                                {
                                    simulationSlug: selectedSimulation.slug,
                                    cohortId: selectedCohort.id,
                                },
                            )}
                            className="text-blue-500 transition-all hover:text-blue-800"
                        >
                            Reflection Journal Entries
                        </InertiaLink>
                    </div>
                    <MoveParticipantModal
                        participant={participantToMove}
                        teams={teams.filter(
                            (team) =>
                                team.id !== participantToMove?.pivot?.team_id &&
                                (
                                    team as TeamWithProgressData
                                ).team_user_rounds?.every(
                                    (teamUserRound) =>
                                        !teamUserRound?.teamSelections?.length,
                                ),
                        )}
                        cancel={() => {
                            setParticipantToMove(undefined);
                        }}
                    />
                </div>
            )}
        </QueryClient>
    );
};
