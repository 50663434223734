import { SapienPageProps } from "@/inertia-utils/types";
import { Tenant } from "@/models";
import { useForm } from "@inertiajs/react";
import React, { useCallback, useState } from "react";
import { CentralAdminWrapper } from "./CentralAdminWrapper";
import { SapienInertia, sapienAxios, sapienRoute } from "@/inertia-utils/hooks";
import Checkbox from "@/components/Checkbox";

type Props = {
    tenant?: Tenant;
    domain?: string;
    baseUrl: string;
} & SapienPageProps;

const TenantForm = (props: Props) => {
    const { tenant, domain, baseUrl } = props;
    const formValues = {
        tenant_name: tenant?.tenant_name || "",
        domain: domain || "",
        enable_e_learning: false,
    };
    const { setData, data } = useForm(formValues);
    const [processing, setProcessing] = useState(false);

    function redirectToTenant(tenant: Tenant) {
        SapienInertia.visit(
            sapienRoute("tenant.impersonate", {
                id: tenant.id,
            }),
            {
                onSuccess(page) {
                    const typedPage = page as {
                        props: {
                            redirectLocation?: string;
                        };
                    };

                    if (typedPage.props.redirectLocation)
                        window.location.href = typedPage.props.redirectLocation;
                },
            },
        );
    }

    const submit = useCallback(
        async (e) => {
            e.preventDefault();
            setProcessing(true);
            if (!!tenant) {
                const { data: savedData } = await sapienAxios.put<
                    { tenant: Tenant },
                    "tenant.update"
                >("tenant.update", data);
                const savedTenant = savedData?.tenant;
                if (savedTenant) redirectToTenant(savedTenant);
            } else {
                const { data: savedData } = await sapienAxios.post<
                    { tenant: Tenant },
                    "tenant.store"
                >("tenant.store", data);
                const tenant = savedData?.tenant;
                if (tenant) redirectToTenant(tenant);
            }
        },
        [data],
    );

    return (
        <CentralAdminWrapper {...props}>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={submit}>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    {" "}
                                    Tenant Name{" "}
                                </label>
                                <div className="mt-1">
                                    <input
                                        data-testid="tenant_name"
                                        id="tenant_name"
                                        type="tenant_name"
                                        className="@error('tenant_name') is-invalid @enderror block w-full appearance-none
                                            rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm
                                            focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        name="tenant_name"
                                        value={data.tenant_name}
                                        required
                                        autoFocus
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                tenant_name: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Domain
                                </label>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                        value={data.domain}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                domain: e.target.value,
                                            });
                                        }}
                                        type="text"
                                        name="domain"
                                        id="domain"
                                        className="block w-full appearance-none rounded-md border border-gray-300 py-3 pl-7 pr-12
                                            shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Domain"
                                        aria-describedby="price-currency"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span
                                            className="text-gray-500 sm:text-sm"
                                            id="price-currency"
                                        >
                                            {" "}
                                            .{baseUrl}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 block">
                                <label className="flex items-center">
                                    <Checkbox
                                        name="remember"
                                        value={data.enable_e_learning}
                                        handleChange={() => {
                                            setData(
                                                "enable_e_learning",
                                                !data.enable_e_learning,
                                            );
                                        }}
                                    />

                                    <span className="ml-2 text-sm text-gray-600">
                                        Enable eLearning Features
                                    </span>
                                </label>
                            </div>
                            <div>
                                {processing && (
                                    <>
                                        <button
                                            type="button"
                                            className="inline-flex w-full cursor-not-allowed items-center justify-center rounded-md
                                                bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow
                                                transition duration-150 ease-in-out hover:bg-indigo-400"
                                            disabled={processing}
                                        >
                                            <>
                                                <svg
                                                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                Processing...
                                            </>
                                        </button>

                                        <p className="mt-2 text-sm text-slate-800">
                                            Creating your sim. This may take a
                                            while
                                        </p>
                                    </>
                                )}
                                {!processing && (
                                    <button
                                        data-testid="create_tenant_button"
                                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600
                                            px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700
                                            focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </CentralAdminWrapper>
    );
};

export default TenantForm;
