import React, { useEffect, useMemo, useState } from "react";
import {
    ModelVariable,
    ModelVariableDataType,
    ModelVariableScope,
    ModelVariableType,
    TimeHorizon,
} from "../../models";
import { groupBy } from "lodash";
import { useFormatVariableValue } from "@/hooks";
import {
    CheckIcon,
    UserIcon,
    UserGroupIcon,
    UsersIcon,
} from "@heroicons/react/24/solid";
import { FormInputSmall } from "@/components";
import { SapienInertia } from "@/inertia-utils/hooks";

export const DesignVariableTableRowEditable = ({
    modelVariable,
    timeHorizons,
}: {
    modelVariable: ModelVariable;
    timeHorizons: TimeHorizon[];
}) => {
    const formatVariableValue = useFormatVariableValue();

    const variableValuesGroupedByTimeHorizonId = useMemo(() => {
        return !!modelVariable.uses_time
            ? groupBy(modelVariable.baseVariableValues, "time_horizon_id")
            : undefined;
    }, [modelVariable]);

    const isRangeVariable = useMemo(() => {
        return (
            modelVariable.minimum !== null &&
            modelVariable.maximum !== null &&
            Number(modelVariable.minimum) < Number(modelVariable.maximum)
        );
    }, [modelVariable]);

    const [useRangeDisplay, setUseRangeDisplay] = useState(isRangeVariable);

    const [editedModelVariable, setEditedModelVariable] =
        useState<ModelVariable>();

    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (!!modelVariable && !!editedModelVariable) {
            setEditedModelVariable(undefined);
            setUseRangeDisplay(isRangeVariable);
            setIsBusy(false);
        }
    }, [modelVariable]);

    return (
        <tr
            key={modelVariable.id}
            className={`table-row border odd:bg-slate-50 odd:bg-opacity-75 even:bg-slate-50
                even:bg-opacity-10`}
        >
            <th className={"table-col px-1 py-1"}>
                <span
                    className="text-xs"
                    title={
                        modelVariable.variable_type ===
                        ModelVariableType["Selection Data"]
                            ? "SELECTION DATA"
                            : modelVariable.variable_type
                    }
                >
                    {modelVariable.label}
                </span>
            </th>
            <td className="px-1 py-1">
                <div
                    className="flex justify-center"
                    title={modelVariable.scope}
                >
                    {modelVariable.scope === ModelVariableScope.User ? (
                        <UserIcon className="h-4 w-4 text-neutral-500" />
                    ) : modelVariable.scope === ModelVariableScope.Team ? (
                        <UsersIcon className="h-4 w-4 text-neutral-500" />
                    ) : (
                        <UserGroupIcon className="h-4 w-4 text-neutral-500" />
                    )}
                </div>
            </td>
            {!!modelVariable.uses_time &&
                timeHorizons.map((timeHorizon) => (
                    <td
                        className="border-l px-1 py-1 text-right"
                        key={timeHorizon.id}
                    >
                        {!!variableValuesGroupedByTimeHorizonId &&
                            !!variableValuesGroupedByTimeHorizonId[
                                timeHorizon.id
                            ] && (
                                <span>
                                    {modelVariable.data_type ===
                                    ModelVariableDataType.Number
                                        ? formatVariableValue(
                                              modelVariable.unit,
                                              variableValuesGroupedByTimeHorizonId[
                                                  timeHorizon.id
                                              ][0].numerical_value,
                                              modelVariable.is_integer,
                                          )
                                        : variableValuesGroupedByTimeHorizonId[
                                              timeHorizon.id
                                          ][0].boolean_value.toString()}
                                </span>
                            )}
                    </td>
                ))}
            {!modelVariable.uses_time &&
                modelVariable.data_type === ModelVariableDataType.Boolean && (
                    <td
                        className={"border-l px-1 py-1"}
                        colSpan={timeHorizons?.length || 1}
                    >
                        {modelVariable.baseVariableValues[0].boolean_value.toString()}
                    </td>
                )}
            {!modelVariable.uses_time &&
                modelVariable.data_type === ModelVariableDataType.Number &&
                modelVariable.variable_type !==
                    ModelVariableType.Independent && (
                    <td
                        className={"border-l px-1 py-1"}
                        colSpan={timeHorizons?.length || 1}
                    >
                        {formatVariableValue(
                            modelVariable.unit,
                            modelVariable.baseVariableValues[0].numerical_value,
                            modelVariable.is_integer,
                        )}
                    </td>
                )}
            {!modelVariable.uses_time &&
                modelVariable.data_type === ModelVariableDataType.Number &&
                modelVariable.variable_type === ModelVariableType.Independent &&
                !!isRangeVariable &&
                !!useRangeDisplay && (
                    <td
                        className={"border-l px-1 py-1"}
                        colSpan={timeHorizons?.length || 1}
                    >
                        <div className="flex items-center justify-between">
                            <span
                                className={!isBusy ? "cursor-pointer" : ""}
                                onClick={() => {
                                    if (!isBusy) {
                                        setUseRangeDisplay(false);
                                        if (!editedModelVariable) {
                                            setEditedModelVariable(
                                                modelVariable,
                                            );
                                        }
                                    }
                                }}
                            >
                                {formatVariableValue(
                                    modelVariable.unit,
                                    !!editedModelVariable &&
                                        !!editedModelVariable
                                            .baseVariableValues[0]
                                        ? editedModelVariable
                                              .baseVariableValues[0]
                                              .numerical_value
                                        : modelVariable.baseVariableValues[0]
                                              .numerical_value,
                                    modelVariable.is_integer,
                                )}
                            </span>
                            <span className="ml-6 inline-flex items-center">
                                <span className="text-neutral-400">
                                    {Number(modelVariable.minimum)}
                                </span>
                                <input
                                    className="mx-2 min-w-[200px]"
                                    type="range"
                                    min={modelVariable.minimum}
                                    max={modelVariable.maximum}
                                    step={
                                        Number(
                                            modelVariable.baseVariableValues[0]
                                                .numerical_value,
                                        ) -
                                            Math.floor(
                                                Number(
                                                    modelVariable
                                                        .baseVariableValues[0]
                                                        .numerical_value,
                                                ),
                                            ) !==
                                        0
                                            ? 0.1
                                            : 1
                                    }
                                    disabled={isBusy}
                                    value={
                                        !!editedModelVariable &&
                                        !!editedModelVariable
                                            .baseVariableValues[0]
                                            ? editedModelVariable
                                                  .baseVariableValues[0]
                                                  .numerical_value
                                            : modelVariable
                                                  .baseVariableValues[0]
                                                  .numerical_value
                                    }
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setEditedModelVariable({
                                            ...modelVariable,
                                            baseVariableValues: [
                                                {
                                                    ...modelVariable
                                                        .baseVariableValues[0],
                                                    numerical_value: Number(
                                                        e.target.value,
                                                    ),
                                                },
                                            ],
                                        });
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("click");
                                        if (!!editedModelVariable) {
                                            setIsBusy(true);
                                            SapienInertia.put(
                                                "model-builder.model-variables.update",
                                                editedModelVariable,
                                                {
                                                    modelVariableId:
                                                        editedModelVariable.id,
                                                },
                                                {
                                                    preserveState: true,
                                                    preserveScroll: true,
                                                },
                                            );
                                            // setEditedModelVariable(undefined);
                                        }
                                    }}
                                />
                                <span className="text-neutral-400">
                                    {Number(modelVariable.maximum)}
                                </span>
                            </span>
                        </div>
                    </td>
                )}
            {!modelVariable.uses_time &&
                modelVariable.data_type === ModelVariableDataType.Number &&
                modelVariable.variable_type === ModelVariableType.Independent &&
                (!isRangeVariable || !useRangeDisplay) && (
                    <td
                        className={`border-l px-1 py-1 ${!editedModelVariable && "cursor-pointer"}`}
                        colSpan={timeHorizons?.length || 1}
                        onClick={() => {
                            if (!editedModelVariable) {
                                setEditedModelVariable(modelVariable);
                            }
                        }}
                    >
                        {!!editedModelVariable &&
                        !!editedModelVariable.baseVariableValues[0] ? (
                            <div className="flex items-center">
                                <FormInputSmall
                                    type="number"
                                    placeholder=""
                                    disabled={isBusy}
                                    value={
                                        editedModelVariable
                                            .baseVariableValues[0]
                                            .numerical_value
                                    }
                                    onChange={(e) => {
                                        setEditedModelVariable({
                                            ...editedModelVariable,
                                            baseVariableValues: [
                                                {
                                                    ...editedModelVariable
                                                        .baseVariableValues[0],
                                                    numerical_value: Number(
                                                        e.target.value,
                                                    ),
                                                },
                                            ],
                                        });
                                    }}
                                />
                                <button
                                    type="button"
                                    className="ml-1.5 inline-flex items-center rounded bg-slate-50 p-2 text-center text-sm
                                        font-medium text-neutral-900 hover:bg-slate-100 focus:outline-none focus:ring-0
                                        disabled:bg-slate-50"
                                    disabled={
                                        isBusy ||
                                        (isRangeVariable &&
                                            (editedModelVariable
                                                .baseVariableValues[0]
                                                .numerical_value <
                                                Number(modelVariable.minimum) ||
                                                editedModelVariable
                                                    .baseVariableValues[0]
                                                    .numerical_value >
                                                    Number(
                                                        modelVariable.maximum,
                                                    )))
                                    }
                                    onClick={() => {
                                        setIsBusy(true);
                                        SapienInertia.put(
                                            "model-builder.model-variables.update",
                                            editedModelVariable,
                                            {
                                                modelVariableId:
                                                    editedModelVariable.id,
                                            },
                                            {
                                                preserveState: true,
                                                preserveScroll: true,
                                            },
                                        );
                                    }}
                                >
                                    <CheckIcon className="h-4 w-4" />
                                </button>
                            </div>
                        ) : (
                            <>
                                {formatVariableValue(
                                    modelVariable.unit,
                                    modelVariable.baseVariableValues[0]
                                        .numerical_value,
                                    modelVariable.is_integer,
                                )}
                            </>
                        )}
                    </td>
                )}
        </tr>
    );
};
