import React, { useState } from "react";
import ApplicationLogo from "@/components/ApplicationLogo";
import NavLink from "@/components/NavLink";
import { Link } from "@inertiajs/react";
import { User, UserShape } from "@/models";
import DropdownAvatar from "./DropdownAvatar";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import ResponsiveNavLink from "@/components/ResponsiveNavLink";
import { useAuthStore } from "@/hooks/store";

type Props = {
    auth?: {
        user?: User | UserShape;
        isAdmin: boolean;
    };
    header?: string | JSX.Element;
    children?: JSX.Element;
    errors?: any;
    impersonatingUser?: User;
    links?: React.ReactNode;
    eLearningContext?: boolean;
};

export default function Authenticated({
    header,
    children,
    impersonatingUser,
    links,
    auth: authProp,
    eLearningContext,
}: Props) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false);

    const { props } = usePage();
    const { auth, tenant, isNotParticipant } = useAuthStore(authProp);

    return (
        <div className="min-h-[100vh]">
            <nav className="fixed z-[9999] w-full border-b border-slate-200 bg-white px-8 shadow-sm">
                <div className="w-full">
                    <div className="flex h-16 justify-between">
                        <div className="flex">
                            <div className="flex shrink-0 items-center">
                                <Link
                                    href={
                                        !!isNotParticipant
                                            ? sapienRoute("admin.index")
                                            : sapienRoute("dashboard")
                                    }
                                >
                                    <ApplicationLogo className="block h-9 w-auto text-slate-500" />
                                </Link>
                            </div>

                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                {!!auth?.user && isNotParticipant && (
                                    <>
                                        <NavLink
                                            href={sapienRoute("admin.index")}
                                            active={isCurrent(
                                                "admin.index",
                                                props.route,
                                                [
                                                    "admin.users.index",
                                                    "templates.index",
                                                    "templates.edit",
                                                ],
                                            )}
                                        >
                                            Admin
                                        </NavLink>
                                        <NavLink
                                            href={sapienRoute(
                                                "simulation.projects.index",
                                            )}
                                            active={isCurrent(
                                                "simulation.projects.index",
                                                props.route,
                                                !eLearningContext
                                                    ? [
                                                          "simulation.projects.show",
                                                          "simulation.projects.detail",
                                                          "facilitation.index",
                                                          "facilitation.show",
                                                          "discovery.index",
                                                          "discovery.show-data",
                                                          "discovery.show-statistics",
                                                          "model-builder.structure.index",
                                                          "model-builder.wizard.index",
                                                      ]
                                                    : [],
                                            )}
                                        >
                                            Simulations
                                        </NavLink>
                                        {tenant?.has_e_learning_feature && (
                                            <NavLink
                                                href={sapienRoute(
                                                    "e-learning.courses.index",
                                                )}
                                                active={isCurrent(
                                                    "e-learning.courses.index",
                                                    props.route,
                                                    !eLearningContext
                                                        ? [
                                                              "e-learning.courses.edit",
                                                          ]
                                                        : [
                                                              "e-learning.courses.edit",
                                                              "facilitation.index",
                                                              "facilitation.show",
                                                          ],
                                                )}
                                            >
                                                E-learning
                                            </NavLink>
                                        )}
                                        {/* <NavLink
                                            href={sapienRoute(
                                                "admin.users.index",
                                            )}
                                            active={isCurrent(
                                                "admin.users.index",
                                                props.route,
                                            )}
                                        >
                                            Users
                                        </NavLink>
                                        <NavLink
                                            href={sapienRoute(
                                                "templates.index",
                                            )}
                                            active={isCurrent(
                                                "templates.index",
                                                props.route,
                                            )}
                                        >
                                            Templates
                                        </NavLink> */}
                                    </>
                                )}
                                <NavLink
                                    href={sapienRoute("dashboard")}
                                    active={isCurrent("dashboard", props.route)}
                                >
                                    Dashboard
                                </NavLink>
                                {links}
                            </div>
                        </div>

                        <DropdownAvatar
                            auth={auth}
                            impersonatingUser={impersonatingUser}
                        />

                        <div className="-mr-2 flex items-center sm:hidden">
                            <button
                                onClick={() =>
                                    setShowingNavigationDropdown(
                                        (previousState) => !previousState,
                                    )
                                }
                                className="inline-flex items-center justify-center rounded-md p-2 text-slate-500 transition
                                    duration-150 ease-in-out hover:bg-slate-100 hover:text-slate-600
                                    focus:outline-none"
                            >
                                <svg
                                    className="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        className={
                                            !showingNavigationDropdown
                                                ? "inline-flex"
                                                : "hidden"
                                        }
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={
                                            showingNavigationDropdown
                                                ? "inline-flex"
                                                : "hidden"
                                        }
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        (showingNavigationDropdown ? "block" : "hidden") +
                        " sm:hidden"
                    }
                >
                    <div className="space-y-1 pb-3 pt-2">
                        <ResponsiveNavLink
                            href={sapienRoute("dashboard")}
                            active={isCurrent("dashboard", props.route)}
                        >
                            Dashboard
                        </ResponsiveNavLink>
                    </div>

                    <div className="border-t border-slate-200 pb-1 pt-4">
                        <div className="px-4">
                            <div className="text-base font-medium text-slate-800">
                                {auth.user?.name}
                            </div>
                            <div className="text-sm font-medium text-slate-500">
                                {auth.user?.email}
                            </div>
                        </div>

                        <div className="mt-3 space-y-1">
                            <ResponsiveNavLink
                                method={"post"}
                                href={sapienRoute("logout")}
                                as="button"
                            >
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </div>
            </nav>

            {header && (
                <header className="bg-white shadow">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        {header}
                    </div>
                </header>
            )}
            <div className="h-16 w-full"></div>
            <main>{children}</main>
        </div>
    );
}
