import { SapienPageProps } from "@/inertia-utils/types";
import React, { useCallback } from "react";
import { WithId } from "./WithId";
import Authenticated from "@/Layouts/Authenticated";
import Input from "@/components/Input";
import { useForm } from "@inertiajs/react";
import Label from "@/components/Label";
import ForumRichText from "./ForumRichText";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";

type Props = {
    step: number;
    category:
        | WithId<App.Data.Forum.CategoryData>
        | {
              title: string;
              rich_text: App.Data.RichTextData;
              updated_at: string;
          };
} & SapienPageProps;

export default function CreateCategory({
    auth,
    step,
    category = {
        title: "",
        rich_text: {} as App.Data.RichTextData,
        updated_at: "",
    },
}: Props) {
    const { submit, data, setData } = useForm(category);

    const updateRichText = useCallback(
        (rich_text) => {
            setData("rich_text", rich_text as App.Data.RichTextData);
        },
        [category.updated_at],
    );

    return (
        <Authenticated auth={auth}>
            <div className="container mx-auto max-w-2xl">
                <h1 className="text-2xl font-bold">Create a category</h1>
                <div className="mt-4">
                    <p>Step {step}</p>
                    <p>Category: {category?.title}</p>
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        const existingCategory =
                            category as WithId<App.Data.Forum.CategoryData>;

                        if (!!existingCategory.id) {
                            SapienInertia.put(
                                "forum.categories.update",
                                {
                                    ...data,
                                    rich_text: JSON.stringify(data.rich_text),
                                },
                                { category: existingCategory.id },
                            );
                        } else {
                            SapienInertia.post("forum.categories.store", {
                                ...data,
                                rich_text: JSON.stringify(data.rich_text),
                            });
                        }
                    }}
                    className="mt-4 flex flex-col space-y-4 rounded-md bg-white p-6 shadow"
                >
                    <div>
                        <Label forInput="title" value="Title" />
                        <Input
                            required
                            name="title"
                            value={data.title}
                            handleChange={(e) => {
                                setData("title", e.target.value);
                            }}
                            placeHolder="Enter a title"
                        />
                    </div>
                    <div>
                        <Label forInput="title" value="Description" />
                        <ForumRichText
                            content={data.rich_text}
                            onUpdate={updateRichText}
                            editable={true}
                            parentObject={category}
                            hideButtons={true}
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            className="rounded-md bg-red-100 px-4 py-2 text-gray-800"
                            onClick={() => {}}
                        >
                            Cancel
                        </button>
                        <button
                            className="rounded-md bg-sapien-blue px-4 py-2 text-white"
                            type="submit"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </Authenticated>
    );
}
