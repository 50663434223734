import React, { useState } from "react";
import { Course, Lesson } from "../types";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { useSetupContentBlockState } from "./useContentBlockState";
import LessonSideNav from "../LessonDisplay/lesson-sidenav/LessonSideNav";
import LessonDisplay from "../LessonDisplay/LessonDisplay";
import { useViewedContentBlockState } from "../LessonDisplay/viewed-content-block-tracking/useViewedContentBlockState";
import QueryClient from "../QueryClient/QueryClient";
import LessonDesignHeader from "./LessonDesignHeader";
import { Link } from "@inertiajs/react";
import ApplicationLogo from "@/components/ApplicationLogo";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ChevronLeft, ChevronRight } from "lucide-react";

type Props = { lesson: Lesson; course: Course } & SapienAdminPageProps;

export default function LessonPreview({ lesson, course, auth }: Props) {
    const {} = useSetupContentBlockState(lesson);
    const { viewContentBlock } = useViewedContentBlockState(lesson);

    const [sidebarState, setSidebarState] = useState<"open" | "closed">(
        "closed",
    );
    return (
        <QueryClient>
            <div className="flex">
                <LessonDesignHeader lesson={lesson} course={course} auth={auth}>
                    <div className="items-between flex w-full flex-1">
                        <div className="flex w-full flex-1 items-center gap-7">
                            <Link
                                href={sapienRoute("e-learning.courses.index")}
                                className="flex items-center underline"
                                as="a"
                            >
                                <ApplicationLogo className="block h-9 w-auto" />
                            </Link>

                            <h3 className="flex items-center text-xl font-bold ">
                                {course.courseMetadata?.course_title ||
                                    course.title}{" "}
                            </h3>
                            <h3 className="flex items-center text-xl font-bold ">
                                {lesson.title}
                            </h3>
                        </div>
                        <div className="flex items-center space-x-7">
                            <Link
                                className="flex items-center underline"
                                href={sapienRoute("e-learning.design", {
                                    lesson: lesson.id,
                                })}
                            >
                                <ChevronLeft className="h-4 w-4" />
                                <span>Design</span>
                            </Link>
                        </div>
                    </div>
                </LessonDesignHeader>
                <LessonSideNav
                    course={course}
                    currentLesson={lesson}
                    isInAdminContext={true}
                    sidebarState={sidebarState}
                    setSidebarState={setSidebarState}
                />
                <main className="lesson-designer e-learning flex w-full flex-col items-center pt-16">
                    <LessonDisplay
                        lesson={lesson}
                        scrollCallback={viewContentBlock}
                        lessonCount={course.lessons.length}
                        isInAdminContext={false}
                    />
                </main>
            </div>
        </QueryClient>
    );
}
