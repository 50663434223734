import React, { useEffect } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Cohort, CohortShape, Simulation, TeamShape } from "@/models";
import {
    useSelectedSimulation,
    useInitAuthFromInertiaProps,
    useSelectedCohort,
    useAllCohorts,
} from "@/hooks";
import { useRoundStore } from "@/hooks/store";
import { CohortDetail, CohortList } from "@/Layouts/facilitation";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import ParticipantLinkModal from "./ParticipantLinkModal";
import { useModalComponentStore } from "@/hooks/useModalComponentStore";
import { AdminContainer } from "@/Layouts/admin";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { SimBuilderTopNav } from "@/Layouts/admin/SimBuilderTopNav";

export type TeamWithProgressData = TeamShape & {
    progress_map: {
        [round_id: string]: {
            participantsInRound: number;
            percent_complete: number;
        };
    };
    team_user_rounds?: {
        teamSelections: any[];
    }[];
};

export type CohortWithProgressData = Omit<CohortShape, "teams"> & {
    teams: TeamWithProgressData[];
};

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    cohort: CohortWithProgressData | null;
};

export default function FacilitationContainer(props: Props) {
    const { simulation, cohort, auth, tenant } = props;
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { gotCohort } = useSelectedCohort();
    const { gotCohorts } = useAllCohorts();
    const { gotRounds } = useRoundStore();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
        gotRounds(simulation.rounds || []);
        gotCohorts(simulation.cohorts);
    }, [simulation]);

    function reloadOnNotify(_: any) {
        console.log("doing reload");
        SapienInertia.reload({
            preserveScroll: true,
            preserveState: true,
        });
    }

    useEffect(() => {
        gotCohort(cohort as Cohort);
    }, [cohort]);

    const { setUpFacilitatorSockets } = useWebSockets();

    useEffect(() => {
        if (cohort) {
            setUpFacilitatorSockets(cohort.id, {
                "facilitator.notify": reloadOnNotify,
            });
        }
    }, [cohort?.id]);

    const { setModalProps } = useModalComponentStore();
    setModalProps(props);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <>
                <AdminContainer
                    headTitle={"Facilitation"}
                    auth={auth}
                    eLearningContext={selectedSimulation.is_course}
                >
                    <AdminPageHeader>
                        <SimBuilderTopNav
                            title={selectedSimulation.title}
                            slug={selectedSimulation.slug}
                            id={selectedSimulation.id}
                            isCourse={selectedSimulation.is_course}
                        />
                    </AdminPageHeader>
                    <AdminPageSection>
                        <div className="">
                            {!cohort ? <CohortList /> : <CohortDetail />}
                        </div>
                    </AdminPageSection>
                </AdminContainer>
                <ParticipantLinkModal />
            </>
        )
    );
}
