import { sapienAxios } from "@/inertia-utils/hooks";
import { ModelVariable, VariableValue } from "@/models";

export async function getComparisonData({
    teamUserRoundId,
}: {
    teamUserRoundId: string;
}) {

    const { data } = await sapienAxios.get<
        {
            targetValues: { [index: string]: VariableValue[] };
            phpData: { [index: string]: VariableValue[] };
            phpTime: number;
            pythonTime: number;
            fullPythonTime: number;
            success: boolean;
            targetVariables: ModelVariable[];
        },
        "model-builder.calculation-comparison.values.show"
    >("model-builder.calculation-comparison.values.show", {
        teamUserRoundId,
    });

    return data;
}
