import { SapienInertia, usePage } from "@/inertia-utils/hooks";
import { Participant, TeamShape } from "@/models";
import { ModalContainer } from "@/modules/shared";
import React, { useEffect, useState } from "react";

type Props = {
    participant?: Participant;
    teams?: TeamShape[];
    cancel: () => void;
};

export default function MoveParticipantModal({
    participant,
    teams,
    cancel,
}: Props) {
    const [selectedTeamId, setSelectedTeamId] = useState(
        participant?.pivot?.team_id
    );

    useEffect(() => {
        setSelectedTeamId(teams[0]?.id);
    }, [participant, teams]);

    const { errors } = usePage()?.props;

    return (
        <ModalContainer
            isModalOpen={!!participant && teams?.length > 0}
            setIsModalOpen={() => cancel()}
            size="s"
        >
            {!!participant && (
                <div className="p-6 pb-2">
                    <div className="flex flex-col m-0">
                        <div className="w-full py-4">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Move {participant?.name} to a new team
                            </h3>
                        </div>
                        <div className="w-full py-4 border-t border-[#374151]/20">
                            <label
                                htmlFor="location"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Select Team
                            </label>
                            <select
                                id="location"
                                name="location"
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={selectedTeamId}
                                onChange={(e) => {
                                    setSelectedTeamId(e.target.value);
                                }}
                            >
                                {teams?.map((team) => (
                                    <option key={team.id} value={team.id}>
                                        {team.name}
                                    </option>
                                ))}
                            </select>
                            {errors && (
                                <div className="w-full space-y-1 flex flex-col">
                                    {Object.values(errors).map((error) => (
                                        <p
                                            key={error}
                                            className="text-red-500 text-sm"
                                        >
                                            {error}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="border-t border-[#374151]/20 w-full py-4 flex justify-end space-x-2">
                            <button
                                onClick={() => {
                                    SapienInertia.post(
                                        "facilitation.move-participant",
                                        {
                                            team_user_round_id:
                                                participant?.pivot?.[
                                                    "team_user_round.id"
                                                ],
                                            team_id: selectedTeamId,
                                        },
                                        {
                                            teamUserRoundId:
                                                participant?.pivot?.[
                                                    "team_user_round.id"
                                                ],
                                        },
                                        {
                                            onSuccess: () => {
                                                cancel();
                                            },
                                            preserveScroll: true,
                                            only: ['cohort']
                                        }
                                    );
                                }}
                                disabled={
                                    !selectedTeamId ||
                                    selectedTeamId ===
                                        participant?.pivot?.team_id
                                }
                                className="inline-flex items-center disabled:cursor-not-allowed disabled:bg-blue-400 bg-blue-600 px-4 py-2 rounded text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none 2bluefocus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Move Participant
                            </button>
                            <button
                                onClick={() => {
                                    cancel();
                                }}
                                className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </ModalContainer>
    );
}
