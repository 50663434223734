import Authenticated from "@/Layouts/Authenticated";
import NavLink from "@/components/NavLink";
import { sapienRoute } from "@/inertia-utils/hooks";
import { SapienPageProps } from "@/inertia-utils/types";
import { Head } from "@inertiajs/react";
import React from "react";
import { WithId } from "./WithId";
import ThreadPreviewCard from "./ThreadPreviewCard";

type Props = SapienPageProps & {
    threads: WithId<App.Data.ThreadData>[];
    categories: App.Data.ForumParentData[];
};

export default function Index({
    threads,
    auth,
    impersonating_user,
    categories,
}: Props) {
    return (
        <Authenticated
            auth={auth}
            errors={[]}
            impersonatingUser={impersonating_user}
            links={
                <NavLink active={false} href={sapienRoute("resources")}>
                    Resources
                </NavLink>
            }
        >
            <>
                <Head title="Threads" />
                <div>
                    {categories?.map((category) => <div>{category.title}</div>)}
                </div>
                <div className="flex w-full justify-center">
                    <div className="w-full max-w-7xl">
                        {threads.map((thread) => (
                            <ThreadPreviewCard thread={thread} />
                        ))}
                    </div>
                </div>
            </>
        </Authenticated>
    );
}
