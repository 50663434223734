import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Head } from "@inertiajs/react";
import * as Tabs from "@radix-ui/react-tabs";
import { SapienPageProps } from "@/inertia-utils/types";
import { useInitAuthFromInertiaProps } from "@/hooks";
import { DataSandboxProps } from "./types";
import { DataSandboxDetail } from "./DataSandboxDetail";
import { SandboxWrapper } from "./SandboxWrapper";

type Props = SapienPageProps & DataSandboxProps;

const queryClient = new QueryClient();

export default function DataSandbox({
    data,
    years,
    variables,
    auth,
    tenant,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);

    const tabKeys = [
        "Data Overview",
        "Regression",
        "Cluster Analysis",
        "Forecasting",
    ];

    const [activeTab, setActiveTab] = useState(tabKeys[0]);

    return (
        <QueryClientProvider client={queryClient}>
            <Head title={"Data Sandbox"} />
            <SandboxWrapper>
                <div className="flex w-full justify-center">
                    <Tabs.Root
                        onValueChange={setActiveTab}
                        value={activeTab}
                        className="w-full"
                    >
                        <Tabs.List
                            className="flex w-full justify-center border-b border-slate-300/75 bg-slate-200
                                bg-opacity-25 dark:border-slate-800 dark:bg-slate-950 dark:bg-opacity-50"
                        >
                            {tabKeys.map((tabKey, i) => (
                                <Tabs.Trigger
                                    key={tabKey}
                                    value={tabKey}
                                    className={`text-slate-900 data-[state="active"]:border-b
                                    data-[state="active"]:border-blue-600 data-[state="active"]:text-blue-700
                                    dark:text-slate-300 dark:data-[state="active"]:border-blue-600
                                    dark:data-[state="active"]:text-blue-500`}
                                >
                                    <div
                                        className="px-4 py-2 text-base font-light tracking-wide hover:bg-slate-300/25
                                            dark:hover:bg-slate-800/25"
                                    >
                                        {tabKey}
                                    </div>
                                </Tabs.Trigger>
                            ))}
                        </Tabs.List>
                        <DataSandboxDetail
                            tabKeys={tabKeys}
                            data={data}
                            years={years}
                            variables={variables}
                        />
                    </Tabs.Root>
                </div>
            </SandboxWrapper>
        </QueryClientProvider>
    );
}
