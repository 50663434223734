import React from "react";
import { Participant } from "../../models";
import { useRoundStore } from "@/hooks/store";
import { SapienInertia } from "@/inertia-utils/hooks";

export const ParticipantRoundMenu = (props: { participant: Participant }) => {
    const { participant } = props;

    const { rounds } = useRoundStore();

    return (
        !!rounds && (
            <div>
                <select
                    className="block w-full min-w-[200px] rounded-md border border-neutral-300 bg-white bg-opacity-75 p-1.5 text-xs text-neutral-900 focus:border-blue-500 focus:ring-blue-500"
                    value={participant.pivot?.round_id}
                    onChange={(e) => {
                        SapienInertia.post(
                            "facilitation.set-participant-round",
                            {
                                user_id: participant.pivot?.user_id,
                                team_id: participant.pivot.team_id,
                                round_id: e.target.value,
                            },
                            {
                                user_id: participant.pivot.user_id,
                                team_id: participant.pivot.team_id,
                                round_id: e.target.value,
                            },
                            {
                                preserveScroll: true,
                            },
                        );
                    }}
                >
                    {rounds.map((round) => (
                        <option key={round.id} value={round.id}>
                            {round.title}
                        </option>
                    ))}
                </select>
            </div>
        )
    );
};
