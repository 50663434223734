import React, { useEffect, useState } from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import LogoImage from "@/components/LogoImage";
import { sapienRoute } from "@/inertia-utils/hooks";
import { InteractiveColorModeButton } from "../InteractiveModule/InteractiveColorModeButton";
import { TopNavLinkDiv } from "@/components";
import { useSetColorModeClass } from "./useDataSandbox";
import { useAuthStore } from "@/hooks/store";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";

const setThemeInStorage = (theme: "" | "dark") => {
    localStorage.setItem("sandbox-theme", theme);
};
const getThemeInStorage = () => {
    return localStorage.getItem("sandbox-theme") as "" | "dark";
};

const SandboxWrapperComponent = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const { user } = useAuthStore();

    const setColorModeClassAtom = useSetColorModeClass();

    const [colorModeClass, setColorModeClass] = useState<"" | "dark">(
        getThemeInStorage() ?? "dark",
    );

    useEffect(() => {
        setColorModeClassAtom(colorModeClass);
    }, []);

    useEffect(() => {
        if (colorModeClass !== getThemeInStorage()) {
            setThemeInStorage(colorModeClass);
            setColorModeClassAtom(colorModeClass);
        }
    }, [colorModeClass]);

    return (
        <div
            className={`interactive-container ${colorModeClass} relative flex h-[100vh] flex-col
            overflow-hidden dark:bg-slate-900`}
        >
            <div className="interactive-top flex h-11 items-center">
                <div
                    className="interactive-top-logo flex h-11 w-11 min-w-11 items-center justify-center
                        bg-slate-950"
                >
                    <TopNavLinkDiv style={{ padding: 0 }}>
                        <InertiaLink href={sapienRoute("admin.index")}>
                            <LogoImage size={"22"} opacity={"0.9"} />
                        </InertiaLink>
                    </TopNavLinkDiv>
                </div>
                <div
                    className="interactive-top-center flex h-11 w-[calc(100%-44px)] min-w-[calc(100%-44px)]
                        items-center justify-between bg-slate-950 text-white transition-all"
                >
                    <div className="flex flex-row flex-wrap items-center">
                        <div className={"flex items-center"}>
                            <div className="px-1 text-sm font-bold text-white opacity-80 sm:px-3">
                                Data Sandbox
                            </div>
                        </div>
                    </div>
                    <div className="discovery-top-right-links flex h-full items-center bg-slate-950 text-white">
                        <InteractiveColorModeButton
                            colorModeClass={colorModeClass}
                            setColorModeClass={setColorModeClass}
                        />
                        {!!user && <UserNavDropdown userName={user.name} />}
                    </div>
                </div>
            </div>
            <div className="interactive-bottom flex h-[calc(100vh-44px)] text-slate-700 dark:text-slate-300">
                <div
                    className="interactive-widget relative h-full w-full overflow-auto scrollbar-thin
                        scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                        scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                        scrollbar-corner-rounded-full dark:scrollbar-track-slate-800
                        dark:scrollbar-thumb-slate-700/75"
                >
                    <div className="interactive-center-panel h-full w-full">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SandboxWrapper = React.memo(SandboxWrapperComponent);
