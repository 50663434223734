import { SapienPageProps } from "@/inertia-utils/types";
import { Tenant } from "@/models";
import React from "react";
import { Link } from "@inertiajs/react";
import { useInitAuthFromInertiaProps } from "@/hooks";
import { CentralAdminWrapper } from "./CentralAdminWrapper";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { TrashIcon } from "@heroicons/react/24/solid";
import DeleteTenantModal from "./DeleteTenantModal";
import { Switch } from "@headlessui/react";
import { router } from "@inertiajs/react";

type Props = {
    tenants: Tenant[];
    testTenantId?: string;
} & SapienPageProps;

export default function TenantList(props: Props) {
    const { tenants, auth, testTenantId } = props;

    const {} = useInitAuthFromInertiaProps(auth);

    const [tenantToDelete, setTenantToDelete] = React.useState<Tenant | null>(
        null,
    );
    // function routeWithSubdomain(route: string, domain: string) {
    //     console.log("route is", route, route.split("://"));
    //     const subdomain = domain.split(".")[0];
    //     const routeParts = route.split("://");
    //     const withSubDomain = `${subdomain}.${routeParts[1]}`;
    //     return `${routeParts[0]}://${withSubDomain}`;
    // }

    function redirectToTenant(tenant: Tenant) {
        SapienInertia.visit(
            sapienRoute("tenant.impersonate", {
                id: tenant.id,
            }),
            {
                onSuccess(page) {
                    const typedPage = page as {
                        props: {
                            redirectLocation?: string;
                        };
                    };

                    if (typedPage.props.redirectLocation)
                        window.location.href = typedPage.props.redirectLocation;
                },
            },
        );
    }

    function enableELearning(tenant: Tenant) {
        SapienInertia.post("tenants.e-learning.store", {
            tenant_id: tenant.id,
        });
    }

    function disableELearning(tenant: Tenant) {
        router.delete(
            sapienRoute("tenants.e-learning.delete", { tenant: tenant.id }),
        );
    }

    return (
        <CentralAdminWrapper {...props}>
            <div className="mt-6 flow-root h-full w-full max-w-3xl items-center self-center">
                <ul role="list" className="list-none divide-y divide-gray-200">
                    <li className="list-none py-4">
                        <div className="flex items-center justify-end space-x-4">
                            <div>
                                {!!testTenantId && (
                                    <button
                                    data-testid="reset-test-tenant"
                                        onClick={() =>
                                            SapienInertia.post(
                                                "reset.test-tenant",
                                                {
                                                    test_tenant_id:
                                                        testTenantId,
                                                },
                                            )
                                        }
                                        className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5
                                            py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                    >
                                        Reset Test Tenant
                                    </button>
                                )}
                            </div>
                            <div>
                                <Link
                                    href={sapienRoute("tenant.create")}
                                    className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5
                                        py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                >
                                    <svg
                                        className="h-6 w-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    Create
                                </Link>
                            </div>
                        </div>
                    </li>
                    {tenants.map((tenant) => (
                        <li className="list-none py-4" key={tenant.id}>
                            <div className="flex items-center space-x-4">
                                <div className="min-w-0 flex-1">
                                    <Link
                                        as={"button"}
                                        href={""}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToTenant(tenant);
                                        }}
                                        data-testid={`tenant_link_${tenant.tenant_name}`}
                                        className="truncate text-sm text-blue-700 underline-offset-4 hover:text-blue-900
                                            hover:underline"
                                    >
                                        {tenant.tenant_name}
                                    </Link>
                                </div>
                                <div className="flex space-x-2">
                                    <div
                                        className="flex space-x-2"
                                        data-testid={`e-learning-${tenant.tenant_name}`}
                                    >
                                        <label className="text-slate-600">
                                            eLearning
                                        </label>
                                        <Switch.Group>
                                            <Switch
                                                checked={
                                                    tenant.has_e_learning_feature
                                                }
                                                onChange={(checked) => {
                                                    if (checked) {
                                                        enableELearning(tenant);
                                                    } else
                                                        disableELearning(
                                                            tenant,
                                                        );
                                                }}
                                                className={`${tenant.has_e_learning_feature ? "bg-blue-600" : "bg-gray-200"} relative
                                                inline-flex h-6 w-11 items-center rounded-full`}
                                            >
                                                <span className="sr-only">
                                                    Enable e-learning
                                                </span>
                                                <span
                                                    className={`${tenant.has_e_learning_feature ? "translate-x-6" : "translate-x-1"}
                                                    inline-block h-4 w-4 rounded-full bg-white transform`}
                                                />
                                            </Switch>
                                        </Switch.Group>{" "}
                                    </div>

                                    <Link
                                        href={sapienRoute("tenant.edit", {
                                            tenant: tenant.id,
                                        })}
                                        className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5
                                            py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                    >
                                        <svg
                                            className="h-6 w-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                            ></path>
                                        </svg>
                                        Edit
                                    </Link>
                                    {tenant.can_be_deleted && (
                                        <button
                                            onClick={() =>
                                                setTenantToDelete(tenant)
                                            }
                                            data-testid={`delete-${tenant.tenant_name}`}
                                            className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5
                                                py-0.5 text-sm font-medium leading-5 text-red-700 shadow-sm hover:bg-gray-50"
                                        >
                                            <TrashIcon className="h-6 w-6" />
                                            Delete
                                        </button>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <DeleteTenantModal
                tenant={tenantToDelete}
                cancel={() => setTenantToDelete(null)}
            />
        </CentralAdminWrapper>
    );
}
