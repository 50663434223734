import React from "react";
import { StatisticsTable } from "./StatisticsTable";
import { DataSandboxProps } from "./types";
import { SubsectionWrapper } from "./SharedComponents";

const OverviewDisplayComponent = ({
    data,
    years,
    variables,
}: DataSandboxProps) => {
    return (
        <>
            <SubsectionWrapper title={"Properties"}>
                <div className="space-y-4">
                    <div className="text-sm text-slate-500">{`YEARS: ${Math.min(...years)} to ${Math.max(...years)}`}</div>
                    <div className="text-sm text-slate-500">{`VARIABLES: ${variables.map((variable) => ` ${variable}`)}`}</div>
                </div>
            </SubsectionWrapper>
            <SubsectionWrapper title={"Statistical Summary"}>
                <div className="space-y-8 pt-2">
                    <div className="w-full">
                        {data !== undefined && Object.keys(data).length > 1 && (
                            <StatisticsTable
                                data={data}
                                outerKeysAsRows={true}
                            />
                        )}
                    </div>
                    <div className="w-full">
                        {data !== undefined && Object.keys(data).length > 1 && (
                            <StatisticsTable data={data} />
                        )}
                    </div>
                </div>
            </SubsectionWrapper>
        </>
    );
};

export const OverviewDisplay = React.memo(OverviewDisplayComponent);
