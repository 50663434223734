import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation } from "@/models";
import { AdminContainer } from "@/Layouts/admin";
import { useInitAuthFromInertiaProps } from "@/hooks";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { TopNavContainer } from "@/Layouts/TopNavContainer";
import { Course } from "../ELearning/types";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = SapienPageProps & {
    simulations: Simulation[];
    courses: Course[];
};

export default function Dashboard({
    simulations,
    courses,
    auth,
    tenant,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth, simulations, null, tenant);

    return (
        <AdminContainer headTitle={"Admin"} auth={auth}>
            <AdminPageHeader>
                <TopNavContainer header={"Home"} />
            </AdminPageHeader>
            <AdminPageSection>
                <div className="space-y-4 text-slate-900">
                    <div className="flex min-h-9 flex-row items-center justify-between">
                        <span className="text-xl font-normal">
                            {"Simulations"}
                        </span>
                    </div>
                    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                        {!!simulations?.length &&
                            simulations.map((simulation) => (
                                <Link
                                    key={simulation.id}
                                    href={sapienRoute(
                                        "simulation.projects.show",
                                        {
                                            project: simulation.slug,
                                        },
                                    )}
                                >
                                    <div
                                        key={simulation.id}
                                        className="rounded-md bg-white/75 p-4 hover:bg-white/100 hover:shadow-sm"
                                    >
                                        <div className="flex flex-col space-y-px">
                                            <span className="text-right text-xs tracking-wide text-slate-500">
                                                {simulation.slug}
                                            </span>
                                            <span className="text-base font-semibold">
                                                {simulation.title}
                                            </span>
                                            <span className="pt-0.5 text-sm text-slate-700">
                                                {`Rounds: ${simulation.rounds_count}`}
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                    </div>
                    {!!tenant?.has_e_learning_feature && (
                        <>
                            <div className="flex min-h-9 flex-row items-center justify-between">
                                <span className="text-xl font-normal">
                                    {"Courses"}
                                </span>
                            </div>
                            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                {!!courses?.length &&
                                    courses.map((course) => (
                                        <Link
                                            key={course.id}
                                            href={sapienRoute(
                                                "e-learning.courses.edit",
                                                {
                                                    course: course.id,
                                                },
                                            )}
                                        >
                                            <div
                                                key={course.id}
                                                className="rounded-md bg-white/75 p-4 hover:bg-white/100 hover:shadow-sm"
                                            >
                                                <div className="flex flex-col space-y-px">
                                                    <span className="text-right text-xs tracking-wide text-slate-500">
                                                        {course.slug}
                                                    </span>
                                                    <span className="text-base font-semibold">
                                                        {course.title}
                                                    </span>
                                                    <span className="pt-0.5 text-sm text-slate-700">
                                                        {`Lessons: ${course.lessons_count}`}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            </AdminPageSection>
        </AdminContainer>
    );
}
