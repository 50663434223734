import React from "react";
import { VariableTableRowProps } from "./props";
import { ModelVariableDataType } from "@/models";
import { FavoriteToggleIcon } from "../shared";

const CompactVariableDataTableRowComponent = ({
    modelVariable,
    variableValues,
    index,
    showResampleFunction,
    formatVariableValue,
}: VariableTableRowProps) => {
    return (
        <tr
            key={modelVariable.id}
            className={`table-row border border-slate-300 bg-slate-50 transition-all
            dark:border-slate-600 dark:bg-slate-900 ${
                index % 2 == 0
                    ? modelVariable.expose_to_designer
                        ? "bg-slate-50 bg-opacity-10 dark:bg-opacity-30"
                        : "bg-slate-100 bg-opacity-20 dark:bg-opacity-40"
                    : "bg-slate-50 bg-opacity-80 dark:bg-opacity-10"
            }
            ${modelVariable.expose_to_designer ? "text-slate-400" : ""}`}
        >
            <th
                className={`table-col px-1 py-1.5 ${showResampleFunction ? "flex justify-between" : ""}`}
            >
                <span
                    className={`flex items-center text-nowrap text-xs ${
                        modelVariable.expose_to_designer
                            ? "font-normal"
                            : "font-semibold"
                    }`}
                >
                    <span className="flex pr-2">
                        <FavoriteToggleIcon
                            modelVariableId={modelVariable.id}
                        />
                    </span>
                    {modelVariable.label}
                </span>
                {!!showResampleFunction && (
                    <span className="pl-2 text-xs font-thin text-slate-400">{`${modelVariable.resample_function}`}</span>
                )}
            </th>
            {variableValues?.map((value, index) => (
                <td
                    key={index}
                    className={`border-l border-slate-300 px-1 py-1 text-right transition-all
                        dark:border-slate-600`}
                    colSpan={1}
                >
                    {modelVariable.data_type === ModelVariableDataType.Number
                        ? formatVariableValue(
                              modelVariable.unit,
                              value.numerical_value,
                              modelVariable.is_integer,
                          )
                        : value.boolean_value.toString()}
                </td>
            ))}
        </tr>
    );
};

export const CompactVariableDataTableRow = React.memo(
    CompactVariableDataTableRowComponent,
);
