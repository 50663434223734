import React, { Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, Bars3Icon, HomeIcon } from "@heroicons/react/24/outline";
import ApplicationLogo from "@/components/ApplicationLogo";
import { Link } from "@inertiajs/react";
import { route } from "ziggy-js";
import { sapienRoute } from "@/inertia-utils/hooks";
import DropdownAvatar from "@/Layouts/DropdownAvatar";
import { useSidebarState } from "@/hooks/store/useSidebarState";
import { Auth } from "@/inertia-utils/types";
import { Team, Tenant, User } from "@/models";

type Props = {
    children: JSX.Element | JSX.Element[];
    auth: Auth;
    impersonating_user?: User;
    tenant: Tenant;
    team?: Team;
    isInEditorContext?: boolean;
};

export const WidthSideNav: React.FC<Props> = ({
    auth,
    impersonating_user,
    tenant,
    children,
    team,
    isInEditorContext,
}: Props) => {
    const {
        isSidebarOpen,
        setIsSidebarOpen,
        setIsWrappingElementIsPresent,
        isWrappingElementPresent,
    } = useSidebarState();

    const handleWrappingElementPresenceChange = useCallback(
        (isWrappingElementPresent) => {
            setIsWrappingElementIsPresent(!isWrappingElementPresent);
        },
        [],
    );

    return (
        <>
            <div
                className="navigation flex h-full w-full @container/with-side-nav"
                // id={isInEditorContext ? "headlessui-portal-root" : "--"}
            >
                <Transition.Root show={isSidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-40"
                        onClose={() => setIsSidebarOpen(false)}
                        open={true}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel
                                    className="@4xl:show relative flex w-full max-w-xs flex-1 flex-col bg-white
                                        focus:outline-none"
                                >
                                    <Transition.Child
                                        as={"div"}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100 absolute top-0 right-0 -mr-12 pt-2"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none
                                                focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() =>
                                                setIsSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </Transition.Child>
                                    <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                                        <div className="flex flex-shrink-0 items-center px-4">
                                            <ApplicationLogo
                                                className="h-10 w-10"
                                                logoPath={tenant?.logo_url}
                                            />
                                        </div>
                                        <div className="mt-5 flex flex-grow">
                                            <nav
                                                className="flex-1 space-y-4 bg-white px-2"
                                                aria-label="Sidebar"
                                            >
                                                <div className="space-y-1 px-2">
                                                    <Link
                                                        href={
                                                            !isInEditorContext &&
                                                            sapienRoute("dashboard")
                                                        }
                                                        className="group flex items-center rounded-md px-2 py-2 text-base font-medium
                                                            text-slate-500 hover:bg-slate-200"
                                                    >
                                                        <HomeIcon className="mr-4 h-6 w-6 flex-shrink-0" />
                                                        Dashboard
                                                    </Link>
                                                </div>
                                                <div
                                                    className="space-y-1 px-2"
                                                    id="participant-sim-nav-links"
                                                    ref={(element) => {
                                                        if (
                                                            (element &&
                                                                !isWrappingElementPresent) ||
                                                            (!element &&
                                                                isWrappingElementPresent)
                                                        ) {
                                                            handleWrappingElementPresenceChange(
                                                                isWrappingElementPresent,
                                                            );
                                                        }
                                                    }}
                                                ></div>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                                        <a
                                            href="#"
                                            className="group block flex-shrink-0"
                                        >
                                            <div className="flex items-center">
                                                <div>
                                                    {/* <img
                                                        className="inline-block h-10 w-10 rounded-full"
                                                        src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                                        alt=""
                                                    /> */}
                                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-600 text-white">
                                                        <span>
                                                            {auth.user.name.substring(
                                                                0,
                                                                1,
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="ml-3">
                                                    {impersonating_user && (
                                                        <p className="text-xs text-gray-400">
                                                            {" "}
                                                            {
                                                                impersonating_user.name
                                                            }{" "}
                                                            impersonating
                                                        </p>
                                                    )}
                                                    <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                                                        {auth.user.name}
                                                    </p>
                                                    {!impersonating_user &&
                                                        !isInEditorContext && (
                                                            <Link
                                                                method={"post"}
                                                                href={route(
                                                                    "logout",
                                                                )}
                                                                as="button"
                                                                className="text-sm font-medium text-gray-500 group-hover:text-gray-700"
                                                            >
                                                                Log Out
                                                            </Link>
                                                        )}
                                                    {impersonating_user && (
                                                        <Link
                                                            method={"post"}
                                                            href={sapienRoute(
                                                                "leave-impersonation",
                                                                {
                                                                    team_id:
                                                                        team.id,
                                                                },
                                                            )}
                                                            as="button"
                                                            className="text-sm font-medium text-gray-500 group-hover:text-gray-700"
                                                        >
                                                            Facilitate
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div
                                className="w-14 flex-shrink-0"
                                aria-hidden="true"
                            >
                                {/* Force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <div className="flex min-w-0 flex-1 flex-col overflow-hidden @4xl:flex @4xl:flex-row">
                    <div
                        className={`z-10 hidden h-full w-12 @4xl:block ${
                            isInEditorContext
                                ? "max-h-[calc(100vh-68px)]"
                                : "max-h-[100vh]"
                        }`}
                        onClick={() => {
                            if (!isSidebarOpen) setIsSidebarOpen(true);
                        }}
                    >
                        <div
                            className="flex h-full flex-col items-center justify-between border-r border-gray-200
                                bg-gray-50 p-0"
                        >
                            <div className="flex flex-col items-center justify-center">
                                <div className="flex h-12 w-12 items-center justify-center border-b border-gray-200">
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md text-gray-500
                                            hover:text-gray-900"
                                        onClick={() => setIsSidebarOpen(true)}
                                    >
                                        <span className="sr-only">
                                            Open sidebar
                                        </span>
                                        <ApplicationLogo
                                            className="w-8"
                                            logoPath={tenant?.logo_url}
                                        />
                                    </button>
                                </div>
                                <nav className="mt-2 flex w-full flex-col items-center justify-start">
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        href={
                                            !isInEditorContext &&
                                            sapienRoute("dashboard")
                                        }
                                        className="mb-4 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md
                                            text-slate-500 hover:bg-slate-200"
                                    >
                                        <HomeIcon className="h-6 w-6 flex-shrink-0" />
                                    </Link>
                                    <div
                                        id="sidebar-notification-icon"
                                        className="relative"
                                    ></div>
                                </nav>
                            </div>
                            {auth?.user && (
                                <div
                                    className="flex h-16 w-full flex-col items-center justify-center border-t border-gray-200
                                        py-4"
                                >
                                    {isSidebarOpen ? (
                                        <></>
                                    ) : (
                                        <>
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 text-white">
                                                <span>
                                                    {auth.user.name.substring(
                                                        0,
                                                        1,
                                                    )}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`participant-top-nav flex w-full justify-between border-b border-gray-200
                        bg-gray-50 px-4 py-2.5 ${
                            isInEditorContext
                                ? "@4xl:h-0 @4xl:w-0 @4xl:p-0 @4xl:opacity-0"
                                : "@4xl:hidden  "
                        }`}
                    >
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md text-gray-500
                                hover:text-gray-900"
                            onClick={() => setIsSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <ApplicationLogo
                                className="w-8"
                                logoPath={tenant?.logo_url}
                            />
                        </button>
                        <div className="flex gap-4">
                            <div
                                id="notification-icon"
                                className="flex cursor-pointer items-center"
                            ></div>
                            <DropdownAvatar
                                auth={auth}
                                impersonatingUser={impersonating_user}
                            >
                                <div className="flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 text-white">
                                    <span>
                                        {auth.user.name.substring(0, 1)}
                                    </span>
                                </div>
                            </DropdownAvatar>
                            <button onClick={() => setIsSidebarOpen(true)}>
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                    <main
                        id="main"
                        className={`scroll-bar-track-gray-50 relative z-0 flex-1 overflow-y-auto scrollbar
                        scrollbar-thumb-gray-300 scrollbar-thumb-rounded-md scrollbar-w-2
                        focus:outline-none ${
                            isInEditorContext
                                ? "max-h-[calc(100vh-123px)] @4xl:max-h-[calc(100vh-72px)]"
                                : "max-h-[calc(100vh-42px)] @4xl:max-h-[100vh]"
                        }`}
                    >
                        {children}
                    </main>
                    <div
                        id="content-block-modal-wrapper"
                        className="pointer-events-none absolute z-[10000] h-0 w-0
                            has-[.pointer-events-auto.modal-container]:pointer-events-auto"
                    ></div>
                </div>
            </div>
        </>
    );
};
