import React from "react";
import Checkbox from "@/components/Checkbox";
import Guest from "@/Layouts/Guest";
import Input from "@/components/Input";
import Label from "@/components/Label";
import ValidationErrors from "@/components/ValidationErrors";
import { Head, Link, useForm } from "@inertiajs/react";
import { SapienPageProps } from "@/inertia-utils/types";
import { sapienRoute } from "@/inertia-utils/hooks";
import { Cohort, Tenant } from "@/models";
import { Course } from "../types";
import ApplicationLogo from "@/components/ApplicationLogo";

type Props = {
    status: boolean;
    cohort: Cohort;
    course: Course;
    tenant:Tenant
};

export default function Login({
    status,
    course,
    cohort,
    tenant,
}: Props) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        remember: "",
        course_id: course?.id,
        cohort_id: cohort?.id,
    });

    const onHandleChange = (event) => {
        setData(
            event.target.name,
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value,
        );
    };

    const submit = (e) => {
        e.preventDefault();
        let route: string = "login";
        // if (!!course?.id) {
        route = sapienRoute("e-learning.email.login");
        post(route);
        // }
    };

    return (
        <div
            className="flex min-h-screen flex-col items-center bg-gray-100 pt-6 sm:justify-center
                sm:pt-0"
        >
            <div
                className="flex w-full flex-col items-center justify-center gap-4 overflow-hidden
                    rounded-lg bg-white px-6 py-6 shadow-md sm:max-w-md"
            >
                <ApplicationLogo
                    logoPath={tenant?.logo_url}
                    className="h-20 w-20 fill-current text-gray-500"
                />
                <div className="flex w-full items-center justify-center px-4 ">
                    {!!course && (
                        <div className="mt-2 w-full sm:max-w-md">
                            <p className="text-sm text-gray-500">
                                Enter your email address to login and join{" "}
                                {course.courseMetadata?.course_title ||
                                    course.title}
                                . Sapien won't share your data or contact you.
                                You can return here to login at any time.
                            </p>
                        </div>
                    )}
                    {!course && !cohort && (
                        <div className="mt-2 w-full sm:max-w-md">
                            <p className="text-sm text-gray-500">
                                Enter your email address to login and join your
                                course. Sapien won't share your data or contact
                                you. You can return here to login at any time.
                            </p>
                        </div>
                    )}
                </div>
                <div className="mt-2 w-full px-4 sm:max-w-md">
                    <ValidationErrors errors={errors} />

                    <form onSubmit={submit} className="w-full">
                        <div>
                            <Label forInput="email" value="Email" />

                            <Input
                                type="text"
                                name="email"
                                value={data.email}
                                className="mt-1 block w-full"
                                autoComplete="username"
                                isFocused={true}
                                handleChange={onHandleChange}
                            />
                        </div>

                        <div className="mt-4 flex items-center justify-end">
                            <button
                                className="ml-4"
                                data-testid="login-button"
                                // disabled={processing}
                            >
                                Log in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
