import React from "react";
import Authenticated from "../../Layouts/Authenticated";
import { Head, Link } from "@inertiajs/react";
import { SapienParticipantPageProps } from "../../inertia-utils/types";
import DashboardCard from "./DashboardCard";
import { useInitAuthFromInertiaProps } from "@/hooks";
import InteractiveModelBlockDashboardCardContents from "./InteractiveModelBlockDashboardCardContents";
import NavLink from "@/components/NavLink";
import { sapienRoute } from "@/inertia-utils/hooks";
import { InfoIcon } from "lucide-react";

type Props = {
    projectionTools: App.Data.InertactiveModelBlockData[];
} & SapienParticipantPageProps;

export default function Dashboard({
    auth,
    tenant,
    errors,
    impersonating_user,
    projectionTools,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);

    return (
        <Authenticated
            auth={auth}
            errors={errors}
            impersonatingUser={impersonating_user}
            links={
                <NavLink active={true} href={sapienRoute("resources")}>
                    Resources
                </NavLink>
            }
        >
            <>
                <Head title="Dashboard" />
                <div className="flex flex-col items-center justify-center bg-gradient-to-b from-black to-sapien-blue py-16">
                    <h2
                        className="text-gray-100 flex w-full items-center justify-center space-x-4
                            text-center text-4xl font-semibold leading-tight"
                    >
                        <span>Resources</span>
                    </h2>
                </div>
                <div className="mx-auto my-0 w-full max-w-5xl text-gray-800">
                    <div className="flex flex-col space-y-16 p-8">
                        {!!projectionTools?.length && (
                            <>
                                {
                                    <ul
                                        role="list"
                                        className="grid grid-cols-1 gap-6 p-0"
                                    >
                                        {projectionTools.map((modelBlock) => {
                                            const simulation =
                                                modelBlock.simulation;
                                            return (
                                                <div className="flex flex-col space-y-4">
                                                    <div className="flex flex-col space-y-px">
                                                        <h2 className="bold">
                                                            {simulation.title}
                                                        </h2>
                                                        <h3 className="text-gray-500">
                                                            {modelBlock.label}
                                                        </h3>
                                                    </div>
                                                    <video
                                                        src={
                                                            modelBlock
                                                                .instructionalVideo
                                                                .video_url
                                                        }
                                                        controls
                                                    />
                                                </div>
                                            );
                                        })}
                                    </ul>
                                }
                            </>
                        )}
                    </div>
                </div>
            </>
        </Authenticated>
    );
}
// {!!modelBlock.instructionalVideo && (
//     <>
//         <div
//             className="absolute left-1/2 top-1/2 z-[1000] flex rounded-full bg-white/50 p-0
//                 text-sapien-blue shadow transition-all -translate-x-1/2 -translate-y-1/2
//                 hover:bg-white hover:scale-125"
//         >
//             <button onClick={() => setModalOpen(true)}>
//                 <PlayCircle className="h-12 w-12" />
//             </button>
//         </div>
//         <ModalContainer isModalOpen={modalOpen}>
//             <div className="relative">
//                 <div
//                     className="absolute right-2 top-2 z-[1001] rounded-full bg-white p-2 text-sapien-blue
//                         opacity-25 transition-opacity hover:opacity-100"
//                 >
//                     <button
//                         className="flex"
//                         onClick={() => setModalOpen(false)}
//                     >
//                         <XIcon className="h-8 w-8" />
//                     </button>
//                 </div>
//                 <video
//                     src={modelBlock.instructionalVideo.video_url}
//                     controls
//                     autoPlay
//                 />
//             </div>
//         </ModalContainer>

//     </>
// )}
