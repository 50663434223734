import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Simulation } from "@/models";
import { SapienPageProps } from "@/inertia-utils/types";
import { useInitAuthFromInertiaProps } from "@/hooks";
import { InteractiveBlockLinkDisplay } from "./InteractiveBlockLinkDisplay";
import { CenterPanelPageWrapper } from "./shared";
import { InteractiveModuleWrapper } from "./InteractiveModuleWrapper";
import { useResetInteractiveModuleState } from "./useInteractiveModule";

type Props = SapienPageProps & {
    simulation: Simulation;
};

const queryClient = new QueryClient();

export default function InteractiveModuleContainer({
    simulation,
    auth,
    tenant,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);
    useResetInteractiveModuleState();

    return (
        <QueryClientProvider client={queryClient}>
            <InteractiveModuleWrapper
                simulationTitle={simulation?.title}
                simulationSlug={simulation?.slug}
            >
                <CenterPanelPageWrapper fullWidth={true}>
                    <div className="grid w-full grid-cols-1 gap-4 p-6">
                        {simulation?.interactiveBlocks?.length > 0 ? (
                            simulation.interactiveBlocks.map(
                                (interactiveBlock) => (
                                    <InteractiveBlockLinkDisplay
                                        key={interactiveBlock.id}
                                        simulationSlug={simulation?.slug}
                                        modelBlock={interactiveBlock}
                                    />
                                ),
                            )
                        ) : (
                            <div className="py-8 text-center text-lg text-slate-700 dark:text-slate-300">
                                No interactive models available.
                            </div>
                        )}
                    </div>
                </CenterPanelPageWrapper>
            </InteractiveModuleWrapper>
        </QueryClientProvider>
    );
}
