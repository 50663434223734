import { BaseModel, IBaseModel } from "./BaseModel";
import { Team, TeamShape, TeamUserRoundPivot } from "./Team";

export type UserShape = IBaseModel & {
    name: string;
    email: string;
    password?: string;
    roles: {
        name: string;
        id: string | number;
    }[];
    current_team_user_round_id?: string;
};

export type ParticipantShape = UserShape & {
    teams: TeamShape[];
    team_id?: string;

    pivot?: TeamUserRoundPivot;
};

class User extends BaseModel implements UserShape {
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "User";
    public static END_POINT: string = "/api/user/";

    //TODO: specify configuration shape
    name = "";
    email = "";
    password = "";
    roles = [];
    current_team_user_round_id = "";
}

class Participant extends User {
    teams: Team[] = [];
    team_id?: string;

    pivot?: TeamUserRoundPivot;
}

export { User, Participant };
