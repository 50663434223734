import React from "react";

export const AdminPageSection = ({
    children,
    className,
}: {
    children?: React.ReactNode;
    className?: string;
}) => {
    return (
        <div className={`${!!className ? className : "bg-slate-100 py-4"}`}>
            <div className="mx-auto my-0 w-full max-w-7xl px-4 sm:px-6 lg:px-8">
                <div>{children}</div>
            </div>
        </div>
    );
};
