import React, { useCallback, useEffect } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { PromptType } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { H6Span } from "@/components";
import PromptTypeTag from "./PromptTypeTag";
import QuestionModalFooter from "./QuestionModalFooter";
import { useForm } from "@inertiajs/react";
import { useModalQueryParams } from "@/hooks";
import { useGetContentBlockById } from "../modals/shared-queries/useGetContentBlockById";
import { useDesignLayerProps } from "../shared-props";

function TimelineBuilderContents() {
    const { queryParams, closeModal } = useModalQueryParams(
        "TimelineQuestionModal",
    );
    const { contentBlockId } = queryParams;

    const { data: contentBlock } = useGetContentBlockById(contentBlockId);
    const { prompt } = contentBlock || { prompt: null };

    const scopes = ["Participant", "Team"];
    const time_units = ["day", "d", "week", "w", "month", "m", "year", "y"];

    const leftButtonHandler = useCallback(closeModal, []);

    const { data, setData } = useForm({
        id: prompt?.id || "",
        content: prompt?.content || "",
        scope: prompt?.scope || scopes[0],
        prompt_type: PromptType.Timeline,
        content_block_id: contentBlockId || "content_block_id",
        time_unit: prompt?.selectionContainers?.length
            ? prompt.selectionContainers[0].column_label
            : time_units[3],
        default_size: prompt?.selectionContainers?.length
            ? prompt.selectionContainers[0].column_count
            : 6,
    });

    useEffect(() => {
        setData({
            id: prompt?.id || "",
            content: prompt?.content || "",
            scope: prompt?.scope || scopes[0],
            prompt_type: PromptType.Timeline,
            content_block_id: contentBlockId || "content_block_id",
            time_unit: prompt?.selectionContainers?.length
                ? prompt.selectionContainers[0].column_label
                : time_units[3],
            default_size: prompt?.selectionContainers?.length
                ? prompt.selectionContainers[0].column_count
                : 6,
        });
    }, [prompt?.id]);

    const saveTimeline = useCallback(() => {
        SapienInertia.put("creator.design.questions.timeline.update", data, {
            promptId: prompt?.id,
        });
    }, [data, prompt]);

    const { modalPageErrors } = useDesignLayerProps()?.designLayerProps;

    if (!prompt) return <></>;
    return (
        <div className="py-6 text-white">
            <div
                className={`flex px-6 ${
                    !prompt.id ? "justify-between" : "justify-end"
                }`}
            >
                <PromptTypeTag promptType={prompt.prompt_type} />
            </div>

            <div className="flex flex-row items-center justify-between p-6 text-white">
                <H6Span>{!!prompt?.id ? "Edit " : "Create "} Timeline</H6Span>
            </div>

            <form
                className="flex flex-col space-y-4 border-t border-[#374151] p-6 text-white"
                onSubmit={() => {
                    saveTimeline();
                }}
            >
                <div className="flex justify-between">
                    <label>Scope</label>
                    <select
                        value={data.scope}
                        onChange={(e) => setData("scope", e.target.value)}
                        className="block w-full max-w-xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm"
                    >
                        {scopes.map((scope) => (
                            <option
                                className="bg-[#29303e]"
                                key={scope}
                                value={scope}
                            >
                                {scope}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-between">
                    <label>Prompt</label>
                    <div className="w-full max-w-3xl">
                        <textarea
                            value={data.content}
                            onChange={(e) => {
                                setData("content", e.target.value);
                            }}
                            className={`block w-full max-w-3xl  border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm
                        ${
                            modalPageErrors?.content
                                ? " rounded-t-md border-pink-500"
                                : "rounded-md"
                        }`}
                        ></textarea>
                        {!!modalPageErrors?.content && (
                            <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                                {modalPageErrors?.content}
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex justify-between">
                    <label>Time Unit</label>
                    <select
                        className="block w-full max-w-xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm"
                        value={data.time_unit}
                        onChange={(e) => {
                            setData("time_unit", e.target.value);
                        }}
                    >
                        {time_units.map((unit) => (
                            <option
                                value={unit}
                                key={unit}
                                className="bg-[#29303e]"
                            >
                                {unit}
                            </option>
                        ))}
                    </select>
                    {!!modalPageErrors?.time_unit && (
                        <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                            {modalPageErrors?.time_unit}
                        </p>
                    )}
                </div>
                <div className="flex justify-between">
                    <label>Length of Time</label>
                    <input
                        className="block w-full max-w-xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-sm"
                        type="number"
                        value={data.default_size}
                        onChange={(e) => {
                            setData("default_size", Number(e.target.value));
                        }}
                    />
                    {!!modalPageErrors?.default_size && (
                        <p className="rounded-b-md bg-pink-600 p-2 text-xs">
                            {modalPageErrors?.default_size}
                        </p>
                    )}
                </div>
            </form>
            <QuestionModalFooter
                isLastStep={true}
                isFirstStep={true}
                rightButtonHandler={saveTimeline}
                leftButtonHandler={leftButtonHandler}
            />
        </div>
    );
}

export default function TimelineBuilder() {
    const { isAtModalUrl, closeModal } = useModalQueryParams(
        "TimelineQuestionModal",
    );

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={closeModal}
            size="l"
            backgroundColor="#111928"
        >
            {isAtModalUrl && <TimelineBuilderContents />}
        </ModalContainer>
    );
}
