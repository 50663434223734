import React, { useMemo } from "react";
import { formatSandboxValue } from "./utils";

const StatisticsTableComponent = ({
    data,
    label,
    outerKeysAsRows,
    formatKey,
}: {
    data: Record<string, Record<string, number>>;
    label?: string;
    outerKeysAsRows?: boolean;
    formatKey?: string;
}) => {
    const columns = useMemo(
        () =>
            !!data && Object.keys(data)?.length > 0
                ? outerKeysAsRows
                    ? Object.keys(data[Object.keys(data)[0]])
                    : Object.keys(data)?.filter((key) => key !== "Year")
                : [],
        [data, outerKeysAsRows],
    );
    const rows = useMemo(
        () =>
            !!data && Object.keys(data)?.length > 0
                ? outerKeysAsRows
                    ? Object.keys(data)?.filter((key) => key !== "Year")
                    : Object.keys(data[Object.keys(data)[0]])
                : [],
        [data, outerKeysAsRows],
    );

    return (
        <div className="bg-slate-100/50 text-slate-600 dark:bg-slate-800/20 dark:text-slate-400">
            <div
                className="interactive-widget relative h-full w-full overflow-auto scrollbar-thin
                    scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                    scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                    scrollbar-corner-rounded-full dark:scrollbar-track-slate-800
                    dark:scrollbar-thumb-slate-700/75"
            >
                <div className="table-container">
                    <table
                        border={1}
                        className="w-full table-auto text-left text-sm"
                    >
                        <thead>
                            <tr>
                                <th className="px-3 py-2 font-semibold">
                                    {label || "Variable"}
                                </th>
                                {columns?.map((colHeader) => (
                                    <th
                                        key={colHeader}
                                        className="text-nowrap border-l border-slate-200 px-3 py-2 text-right font-semibold
                                            dark:border-slate-800"
                                    >
                                        {colHeader}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((rowHeader, index) => (
                                <tr
                                    key={rowHeader}
                                    className={`table-row border-t border-slate-300/50 text-slate-600 dark:border-slate-700/50
                                    dark:text-slate-400 ${
                                        index % 2 == 0
                                            ? "bg-slate-200/50 dark:bg-slate-900/75"
                                            : ""
                                    }`}
                                >
                                    <th className="text-nowrap px-3 py-1 font-semibold">
                                        {rowHeader}
                                    </th>
                                    {columns.map((colHeader) => (
                                        <td
                                            key={colHeader + rowHeader}
                                            className="border-l border-slate-200 px-3 py-1 text-right dark:border-slate-800"
                                        >
                                            {!!outerKeysAsRows ? (
                                                <>
                                                    {data[rowHeader][
                                                        colHeader
                                                    ] !== undefined
                                                        ? colHeader == "count"
                                                            ? data[rowHeader][
                                                                  colHeader
                                                              ]
                                                            : formatSandboxValue(
                                                                  formatKey ||
                                                                      rowHeader,
                                                                  data[
                                                                      rowHeader
                                                                  ][colHeader],
                                                              )
                                                        : ""}
                                                </>
                                            ) : (
                                                <>
                                                    {data[colHeader][
                                                        rowHeader
                                                    ] !== undefined
                                                        ? rowHeader == "count"
                                                            ? data[colHeader][
                                                                  rowHeader
                                                              ]
                                                            : formatSandboxValue(
                                                                  formatKey ||
                                                                      colHeader,
                                                                  data[
                                                                      colHeader
                                                                  ][rowHeader],
                                                              )
                                                        : ""}
                                                </>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export const StatisticsTable = React.memo(StatisticsTableComponent);
