import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Head, Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";

const CentralAdminWrapper = ({
    auth,
    children,
}: SapienPageProps & { children: React.ReactNode }) => {
    return (
        <>
            <Head title={"Tenants"} />
            <div>
                <nav className="bg-white shadow">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        className="block h-8 w-auto lg:hidden"
                                        src="https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/sapien_experience_logo.png"
                                        alt="Workflow"
                                    />
                                    <img
                                        className="hidden h-8 w-auto lg:block"
                                        src="https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/sapien_experience_logo.png"
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        <Link
                                            href={sapienRoute("tenant.index")}
                                            className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm
                                                font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                        >
                                            Tenants
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-4">
                                    <a
                                        className="block px-4 py-2 text-sm text-gray-700"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="user-menu-item-2"
                                        href="#"
                                    >
                                        {auth?.user?.name}
                                    </a>
                                    <Link
                                        className="block px-4 py-2 text-sm text-gray-700"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="user-menu-item-2"
                                        href={sapienRoute("central.logout")}
                                    >
                                        Sign out
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <main className="flex justify-center">{children}</main>
            </div>
        </>
    );
};

export { CentralAdminWrapper };
