import { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { sapienAxios } from "@/inertia-utils/hooks";
import {
    ClusterAnalysisPayload,
    ClusterAnalysisResponse,
    ForecastPayload,
    ForecastResponse,
    RegressionPayload,
    RegressionResponse,
    RegressionType,
} from "./types";

async function regressionFunction({
    body,
}: {
    body: Partial<RegressionPayload>;
}) {
    const { data } = await sapienAxios.post<
        RegressionResponse,
        "data-sandbox.regression"
    >("data-sandbox.regression", body as Partial<RegressionPayload>, {});

    return data;
}

const handleRegression = (regressionParameters: RegressionPayload) => {
    const { data } = useQuery({
        queryKey: ["regression", regressionParameters],
        queryFn: async () => {
            const data = await regressionFunction({
                body: regressionParameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled:
            !!regressionParameters?.regression_type &&
            !!regressionParameters?.x_variables &&
            regressionParameters?.x_variables?.length > 0 &&
            !!regressionParameters?.y_variables &&
            regressionParameters?.y_variables?.length > 0 &&
            regressionParameters.x_variables.every(
                (variable) =>
                    !regressionParameters.y_variables.includes(variable),
            ),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

export const useHandleRegression = (
    regressionParameters: RegressionPayload,
) => {
    const regressionResponse = handleRegression(regressionParameters);
    return regressionResponse;
};

async function clusterAnalysisFunction({
    body,
}: {
    body: Partial<ClusterAnalysisPayload>;
}) {
    const { data } = await sapienAxios.post<
        ClusterAnalysisResponse,
        "data-sandbox.cluster-analysis"
    >(
        "data-sandbox.cluster-analysis",
        body as Partial<ClusterAnalysisPayload>,
        {},
    );

    return data;
}

const handleClusterAnalysis = (
    clusterAnalysisParameters: ClusterAnalysisPayload,
) => {
    const { data } = useQuery({
        queryKey: ["clusterAnalysis", clusterAnalysisParameters],
        queryFn: async () => {
            const data = await clusterAnalysisFunction({
                body: clusterAnalysisParameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled:
            !!clusterAnalysisParameters?.variables &&
            clusterAnalysisParameters?.variables?.length > 0 &&
            !!clusterAnalysisParameters?.clusters,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

export const useHandleClusterAnalysis = (
    clusterAnalysisParameters: ClusterAnalysisPayload,
) => {
    const clusterAnalysisResponse = handleClusterAnalysis(
        clusterAnalysisParameters,
    );
    return clusterAnalysisResponse;
};

async function forecastFunction({ body }: { body: Partial<ForecastPayload> }) {
    const { data } = await sapienAxios.post<
        ForecastResponse,
        "data-sandbox.forecast"
    >("data-sandbox.forecast", body as Partial<ForecastPayload>, {});

    return data;
}

const handleForecast = (forecastParameters: ForecastPayload) => {
    const { data } = useQuery({
        queryKey: ["forecast", forecastParameters],
        queryFn: async () => {
            const data = await forecastFunction({
                body: forecastParameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled:
            !!forecastParameters?.variable &&
            !!forecastParameters?.forecast_length,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

export const useHandleForecast = (forecastParameters: ForecastPayload) => {
    const forecastResponse = handleForecast(forecastParameters);
    return forecastResponse;
};

export const useDataSandbox = () => {
    const [regressionParameters, setRegressionParameters] =
        useState<RegressionPayload>({
            regression_type: RegressionType["simple linear"],
            x_variables: ["Total Starts"],
            y_variables: ["Net Production"],
            degree: 2,
        });

    const regressionResponse = useHandleRegression(regressionParameters);

    const [clusterAnalysisParameters, setClusterAnalysisParameters] =
        useState<ClusterAnalysisPayload>({
            variables: [
                "Net Production",
                "Total Starts",
                "Aligner SOC",
                "Total OH Expenses",
            ],
            clusters: 4,
        });

    const clusterAnalysisResponse = useHandleClusterAnalysis(
        clusterAnalysisParameters,
    );

    const [forecastParameters, setForecastParameters] =
        useState<ForecastPayload>({
            variable: "Net Production",
            forecast_length: 5,
            example_count: 6,
        });

    const forecastResponse = useHandleForecast(forecastParameters);

    return {
        regressionProps: {
            regressionParameters,
            setRegressionParameters,
            regressionResponse:
                regressionResponse ||
                ({
                    regressionData: {},
                    regressionSummary: {},
                    regressionDataPoints: [],
                } as RegressionResponse),
        },
        clusterAnalysisProps: {
            clusterAnalysisParameters,
            setClusterAnalysisParameters,
            clusterAnalysisResponse:
                clusterAnalysisResponse ||
                ({
                    clusterAnalysisData: {},
                    clusterAnalysisSummary: {},
                    clusterDescriptions: {},
                } as ClusterAnalysisResponse),
        },
        forecastProps: {
            forecastParameters,
            setForecastParameters,
            forecastResponse:
                forecastResponse ||
                ({
                    forecastData: {},
                    forecastSummary: {},
                    forecastExamples: {},
                    originalData: {},
                } as ForecastResponse),
        },
    };
};

const colorModeClassAtom = atom<"" | "dark">("");

export const useColorModeClass = () => {
    const colorModeClass = useAtomValue(colorModeClassAtom);
    return colorModeClass;
};

export const useSetColorModeClass = () => {
    const setColorModeClass = useSetAtom(colorModeClassAtom);
    return setColorModeClass;
};
