import React, { useEffect, useMemo, useState } from "react";
import { SapienModelDesignPageProps } from "@/inertia-utils/types";
import {
    DesignModelBlockDisplay,
    DesignVariableTableDisplay,
    ModelBlockExpandCollapseMenu,
} from "@/Layouts/model-design";
import { groupBy } from "lodash";
import { getLineageIds, flatten, createNestedTree } from "@/util";
import { ModelBlock, SimulationShape } from "@/models";
import { useInitAuthFromInertiaProps, useSelectedSimulation } from "@/hooks";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { AdminContainer } from "@/Layouts/admin";
import { SimBuilderTopNav } from "@/Layouts/admin/SimBuilderTopNav";

export default function ModelDesignContainer(
    props: SapienModelDesignPageProps,
) {
    const {
        simulation,
        timeHorizons,
        // rounds,
        modelBlocks,
        modelVariables,
        // variableRelationships,
        auth,
        tenant,
    } = props;
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(
                simulation as SimulationShape & { is_course: boolean },
            );
        }
    }, [simulation]);

    const designerVariablesGroupedByModelBlockId = useMemo(() => {
        return groupBy(
            modelVariables?.filter(
                (modelVariable) => modelVariable.expose_to_designer,
            ) || [],
            "model_block_id",
        );
    }, [modelVariables]);

    const flattenedModelBlocks = useMemo<ModelBlock[]>(() => {
        return flatten(modelBlocks || [], "modelBlocks", "id");
    }, [modelBlocks]);

    const modelBlockLineageIds = useMemo<{
        [modelBlockId: string]: boolean;
    }>(() => {
        let filtered = {};
        const flatObject = flattenedModelBlocks.reduce(
            (map, item) => ({
                ...map,
                [item.id]: item,
            }),
            {},
        );
        Object.keys(designerVariablesGroupedByModelBlockId).forEach(
            (blockId) => {
                let lineageIds =
                    getLineageIds(
                        blockId,
                        flatObject,
                        "parent_model_block_id",
                    ) || [];
                filtered = {
                    ...filtered,
                    [blockId]: true,
                    ...lineageIds.reduce(
                        (map, id) => ({ ...map, [id]: true }),
                        {},
                    ),
                };
            },
        );
        return filtered;
    }, [flattenedModelBlocks, designerVariablesGroupedByModelBlockId]);

    const filteredNestedModelBlocks = useMemo<ModelBlock[]>(() => {
        return createNestedTree<ModelBlock>(
            "parent_model_block_id",
            "modelBlocks",
            flattenedModelBlocks
                .filter((modelBlock) => !!modelBlockLineageIds[modelBlock.id])
                .map(
                    (modelBlock) =>
                        ({ ...modelBlock, modelBlocks: [] }) as ModelBlock,
                ),
        );
    }, [flattenedModelBlocks, modelBlockLineageIds]);

    const [modelBlockAccordionMap, setModelBlockAccordionMap] = useState<{
        [modelBlockId: string]: boolean;
    }>({
        ...modelBlockLineageIds,
        ...filteredNestedModelBlocks.reduce(
            (map, modelBlock) => ({
                ...map,
                ...modelBlock.modelBlocks?.reduce(
                    (map, modelBlock) => ({
                        ...map,
                        [modelBlock.id]: false,
                    }),
                    {},
                ),
            }),
            {},
        ),
    });

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <>
                <AdminContainer headTitle={"Structure"} auth={auth}>
                    <AdminPageHeader>
                        <SimBuilderTopNav
                            title={selectedSimulation.title}
                            slug={selectedSimulation.slug}
                            id={selectedSimulation.id}
                            isCourse={selectedSimulation.is_course}
                        />
                    </AdminPageHeader>
                    <AdminPageSection>
                        <div className="space-y-4">
                            <div className="flex min-h-9 flex-row items-center justify-between">
                                <span className="text-xl font-normal">
                                    {"Model Structure"}
                                </span>
                            </div>
                            {!!modelVariables?.filter(
                                (modelVariable) => modelVariable.is_key_metric,
                            ).length && (
                                <div className="mb-6 rounded-md bg-slate-50 p-4">
                                    <div className="mb-3 text-lg">
                                        Key Metrics
                                    </div>
                                    <DesignVariableTableDisplay
                                        modelVariables={modelVariables.filter(
                                            (modelVariable) =>
                                                modelVariable.is_key_metric,
                                        )}
                                        timeHorizons={timeHorizons}
                                    />
                                </div>
                            )}
                            {!!modelVariables?.filter(
                                (modelVariable) =>
                                    modelVariable.expose_to_designer,
                            ).length && (
                                <div className="mb-6 rounded-md bg-slate-50 p-4">
                                    <div className="mb-3 flex items-baseline justify-between">
                                        <div className="text-lg">
                                            Designer Settings
                                        </div>
                                        <ModelBlockExpandCollapseMenu
                                            filteredNestedModelBlocks={
                                                filteredNestedModelBlocks
                                            }
                                            modelBlockAccordionMap={
                                                modelBlockAccordionMap
                                            }
                                            setModelBlockAccordionMap={
                                                setModelBlockAccordionMap
                                            }
                                        />
                                    </div>
                                    {!!filteredNestedModelBlocks?.length &&
                                        filteredNestedModelBlocks.map(
                                            (modelBlock) => (
                                                <DesignModelBlockDisplay
                                                    key={modelBlock.id}
                                                    modelBlock={modelBlock}
                                                    ancestry={[]}
                                                    modelVariables={
                                                        designerVariablesGroupedByModelBlockId
                                                    }
                                                    timeHorizons={timeHorizons}
                                                    modelBlockAccordionMap={
                                                        modelBlockAccordionMap
                                                    }
                                                    setModelBlockAccordionMap={
                                                        setModelBlockAccordionMap
                                                    }
                                                />
                                            ),
                                        )}
                                </div>
                            )}
                        </div>
                    </AdminPageSection>
                </AdminContainer>
            </>
        )
    );
}
