import React, { useState } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation } from "@/models";
import { AdminContainer, SimulationList } from "@/Layouts/admin";
import { useInitAuthFromInertiaProps } from "@/hooks";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/feather/plus";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import SimVariantModal from "./sim-variants/SimVariantModal";

type Props = SapienPageProps & {
    simulations: Simulation[];
};

export default function ProjectDashboard({ simulations, auth, tenant }: Props) {
    const {} = useInitAuthFromInertiaProps(auth, simulations, null, tenant);

    const [editedSimulation, setEditedSimulation] =
        useState<Simulation | null>();

    return (
        <>
            <AdminContainer headTitle={"Simulations"} auth={auth}>
                <AdminPageHeader pageTitle="Simulations">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-full bg-indigo-600 px-5 py-2.5 text-center
                            text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none
                            focus:ring-4 focus:ring-indigo-500"
                        onClick={() => {
                            setEditedSimulation(
                                new Simulation({
                                    weight: !!simulations?.length
                                        ? Math.max(
                                              ...simulations.map(
                                                  (simulation) =>
                                                      simulation.weight,
                                              ),
                                          ) + 1
                                        : 0,
                                }),
                            );
                        }}
                        disabled={false}
                    >
                        <span
                            style={{
                                marginRight: "0.5rem",
                            }}
                        >
                            <Icon
                                icon={plus}
                                size={16}
                                style={{
                                    display: "flex",
                                }}
                            />
                        </span>
                        <span>Create Project</span>
                    </button>
                </AdminPageHeader>
                <AdminPageSection>
                    <SimulationList
                        editedSimulation={editedSimulation}
                        setEditedSimulation={setEditedSimulation}
                        domain={
                            !!tenant?.domains?.length
                                ? tenant.domains[0].domain
                                : ""
                        }
                    />
                </AdminPageSection>
            </AdminContainer>
            <SimVariantModal />
        </>
    );
}
