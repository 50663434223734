import React from "react";

type LogoProps = { className?: string; logoPath?: string };
const defaultSapienLogoPath =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/sapien_experience_logo.png";

export default function ApplicationLogo({ className, logoPath }: LogoProps) {
    return (
        <div className={className}>
            <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={defaultSapienLogoPath}
            />
        </div>
    );
}
