import React from "react";
import { ChevronRight } from "lucide-react";
import { CardWidgetWrapper } from "./WidgetWrappers";

const AccordionCardWrapperComponent = ({
    modelBlockLabel,
    handleSetModelBlockDisplayMap,
    isExpanded,
    children,
}: {
    modelBlockLabel: string;
    handleSetModelBlockDisplayMap: () => void;
    isExpanded: boolean;
    children: React.ReactNode;
}) => {
    return (
        <CardWidgetWrapper>
            <div>
                <div
                    className="group"
                    data-is-shown={isExpanded}
                    onClick={() => handleSetModelBlockDisplayMap()}
                >
                    <div
                        className={`flex items-center bg-slate-50 p-4 font-semibold transition-all
                        group-data-[is-shown='false']:rounded-md
                        group-data-[is-shown='true']:rounded-t-md
                        group-data-[is-shown='false']:border-b-0 group-data-[is-shown='true']:border-b
                        hover:cursor-pointer hover:bg-slate-100/75 dark:bg-slate-800/25
                        dark:hover:bg-slate-800/50 ${
                            isExpanded
                                ? "border-slate-200 dark:border-slate-800"
                                : "border-transparent dark:border-transparent"
                        }`}
                    >
                        <ChevronRight className="h-4 w-4 transition-all group-data-[is-shown='true']:rotate-90" />
                        <span className="pl-3 text-lg">{modelBlockLabel}</span>
                    </div>
                </div>
                {children}
            </div>
        </CardWidgetWrapper>
    );
};

export const AccordionCardWrapper = React.memo(AccordionCardWrapperComponent);
