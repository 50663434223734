import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { sapienRoute } from "@/inertia-utils/hooks";
import {
    ContentBlockShape,
    ContentBlockTemplateShape,
    Tag,
    Tenant,
    VariableValue,
} from "@/models";

export type VariableValueWithLabel = VariableValue & {
    label: string;
};

export async function saveTemplate(
    data: {
        label: string;
        content_block: string | null;
        description: string;
        simulation_id: string;
        content_block_id: string;
        tags: Tag[];
        html_string: string;
    },
    contentBlock: ContentBlockShape,
    simulation_id: string
) {
    data.content_block = JSON.stringify(contentBlock);
    data.simulation_id = simulation_id;
    data.content_block_id = contentBlock.id;

    const route = sapienRoute("creator.design.templates.store");
    const {
        data: { template },
    } = await axios.post<{ template: ContentBlockTemplateShape }>(route, data);
    console.log("returned", template);
    return { template };
}

export function useTemplateCreationData(template: ContentBlockTemplateShape) {
    const getTemplatePublishData = async (
        template: ContentBlockTemplateShape
    ) => {
        const route = sapienRoute("creator.design.publish.from-template", {
            templateId: template.id,
        });
        const { data } = await axios.get<{
            // templateToPublish: ContentBlockTemplateShape;
            tenant: Tenant;
            parentContentBlockId?: string;
        }>(route);

        return data;
    };

    return useQuery({
        queryKey: ["templatePublishData", template?.id],
        queryFn: () => getTemplatePublishData(template),

        enabled: !!template,
        initialData: {
            tenant: null,
            parentContentBlockId: null,
        },
    });
}
export function useTemplateCreationQueries() {
    const getTemplatePublishData = async (
        template: ContentBlockTemplateShape
    ) => {
        const route = sapienRoute("creator.design.publish.from-template", {
            templateId: template.id,
        });
        const { data } = await axios.get<{
            // templateToPublish: ContentBlockTemplateShape;
            tenant: Tenant;
            parentContentBlockId?: string;
        }>(route);

        return data;
    };

    return {
        getTemplatePublishData,
    };
}
