import ValidationErrors from "@/components/ValidationErrors";
import Input from "@/components/Input";
import Label from "@/components/Label";
import { Head, Link, useForm } from "@inertiajs/react";
import React from "react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { SapienPageProps } from "@/inertia-utils/types";
import Guest from "@/Layouts/Guest";
import { MailOpenIcon } from "lucide-react";

export default function Login({
    message,
    tenant,
}: { message?: "success" | "error" } & SapienPageProps) {
    const { errors, post, processing, data, setData } = useForm({
        email: "",
        remember: false,
    });

    const login = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const route = sapienRoute("passwordless-login.store");
        post(route);
    };

    if (message === "error") {
        errors.remember = "Expired or invalid magic link";
    }

    return (
        <Guest tenant={tenant}>
            <Head title="Log in using magic link" />
            <div className="mt-2 w-full px-4 sm:max-w-md">
                <ValidationErrors errors={errors} />

                <form onSubmit={login} method="post" className="w-full">
                    <div className="relative">
                        <Label forInput="email" value="Email" />
                        <div className="relative">
                            <MailOpenIcon className="absolute ml-2 top-1/2 h-5 w-5 text-slate-300 -translate-y-1/2" />
                            <Input
                                type="text"
                                name="email"
                                value={data.email}
                                className="mt-1 block w-full pl-8 pb-3"
                                autoComplete="username"
                                isFocused={true}
                                handleChange={(e) =>
                                    setData("email", e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="mt-4 flex items-center justify-end">
                        <button
                            className="hover:bg-sapien-blue-dark w-full rounded-md bg-sapien-blue px-4 py-2 text-center
                                text-white"
                            data-testid="login-button"
                            disabled={processing}
                        >
                            Send magic link
                        </button>
                    </div>
                    <div className="mt-4 flex flex-col items-center space-y-1 text-xs text-gray-600">
                        <span className="text-center">
                            We'll send a magic link to your email address to log
                            in.
                        </span>
                        <Link
                            href={sapienRoute("login")}
                            className="text-center text-indigo-600 underline"
                        >
                            I know my password and I want to log in that way.
                        </Link>
                    </div>
                </form>
            </div>
        </Guest>
    );
}
