import React from "react";
import {
    SapienInertia,
    sapienRoute
} from "@/inertia-utils/hooks";
import { RoundShape } from "@/models";

export const RoundSelectionMenu = (props: {
    isDisabled: boolean;
    selectedRoundId: string;
    handleDiscard: () => boolean;
    rounds: RoundShape[];
}) => {
    const {
        isDisabled,
        selectedRoundId,
        handleDiscard,
        rounds
    } = props;

    return (
        rounds !== undefined &&
        rounds.length > 0 && (
            <div className="hidden py-px sm:flex">
                <select
                    className="flex w-full items-center rounded-sm
                        border-gray-600 bg-white/5 py-1 pl-2 pr-8 text-sm
                        text-white focus:outline-none focus:ring-0"
                    value={selectedRoundId || ""}
                    onChange={(e) => {
                        handleDiscard();
                        if (e.target.value !== "")
                            SapienInertia.visit(
                                sapienRoute(
                                    "simulation.design.show",
                                    {
                                        roundId:
                                            e
                                                .target
                                                .value
                                    }
                                )
                            );
                    }}
                    disabled={isDisabled}
                >
                    <option
                        key={"none"}
                        value={""}
                        className="bg-[#29303e]"
                    >
                        {""}
                    </option>
                    {rounds &&
                        rounds.map((round) => (
                            <option
                                key={round.id}
                                value={round.id}
                                className="bg-[#29303e]"
                            >
                                {round.title}
                            </option>
                        ))}
                </select>
            </div>
        )
    );
};
