import { atom, useAtom } from "jotai";
import { useMemo } from "react";

const lessonScoreAtom = atom({
    required_score: 0,
    current_score: 0,
});

const blockedLessonIdsAtom = atom<string[]>([])

export function useSetupLessonScore({ required_score = 0, current_score = 0, blockedLessonIds = []}) {
    const [lessonScore, setLessonScore] = useAtom(lessonScoreAtom);
    const [_,setBlockedLessonIds] = useAtom(blockedLessonIdsAtom)

    if(blockedLessonIds) setBlockedLessonIds(blockedLessonIds);
    if (
        required_score !== lessonScore.required_score ||
        current_score !== lessonScore.current_score
    ) {
        setLessonScore({ required_score, current_score });
    }
}

export function useLessonScore() {
    const [lessonScore, _setLessonScore] = useAtom(lessonScoreAtom);
    const [blockedLessonIds] = useAtom(blockedLessonIdsAtom);

    const setLessonScore = (newScore: {
        required_score: number;
        current_score: number;
    }) => {
        _setLessonScore(newScore);
    };
    const hasPassedQuiz = useMemo(() => {
        return (
            !lessonScore.required_score ||
            lessonScore.current_score >= lessonScore.required_score
        );
    }, [lessonScore]);

    return {
        lessonScore,
        setLessonScore,
        hasPassedQuiz,
        blockedLessonIds
    };
}
