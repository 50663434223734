import React from "react";
import { ChartBlock, FormattedChartValue } from "../types";
import { ChartDisplay } from "../shared";

const InteractiveDashboardChartComponent = ({
    modelTimespan,
    setChartTimeIndex,
    chartBlock,
    chartValues,
}: {
    modelTimespan: number;
    setChartTimeIndex: (chartTimeIndex: number) => void;
    chartBlock: ChartBlock;
    chartValues: Record<string, FormattedChartValue[]>;
}) => {
    return (
        <ChartDisplay
            modelTimespan={modelTimespan}
            chartBlock={chartBlock}
            setChartTimeIndex={setChartTimeIndex}
            chartValues={chartValues}
        />
    );
};

export const InteractiveDashboardChart = React.memo(
    InteractiveDashboardChartComponent,
);
