import React, { useCallback } from "react";
import { WithId } from "./WithId";
import { useAuthStore } from "@/hooks/store";
import ForumRichText from "./ForumRichText";
import { content } from "./Thread";
import { SapienInertia } from "@/inertia-utils/hooks";
import { MessageSquareIcon, PencilIcon } from "lucide-react";
import { RichText } from "@/models";

type Props = {
    post: WithId<App.Data.PostData>;
};
// data-[is-top-level=true]:pl-4
export default function Post({ post }: Props) {
    const { user } = useAuthStore();

    const [isReplying, setIsReplying] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);

    const createReply = useCallback(
        (rich_text: RichText | App.Data.RichTextData) => {
            SapienInertia.post(
                "forum.posts.store",
                {
                    parent_post_id: post.id,
                    rich_text: JSON.stringify(rich_text),
                    parent_thread_id: post.parent_thread_id,
                },
                {},
                {
                    onSuccess: () => setIsReplying(false),
                    preserveState: true,
                    preserveScroll: true,
                },
            );
        },
        [post],
    );

    return (
        <div
            className="mt-4 flex w-full flex-col space-y-2 border-gray-200
                data-[is-top-level=true]:border-l"
            data-is-top-level={post.children?.length > 0}
        >
            <div className="w-full pl-3">
                <div className="mb-2 flex space-x-2 text-sm">
                    <h4 className="font-bold">{post.author?.name}</h4>
                    <span className="text-gray-500">{post.age}</span>
                </div>
                <div className="flex w-full text-gray-700">
                    <ForumRichText
                        parentObject={post}
                        content={post.rich_text}
                        editable={isEditing}
                        placeHolder="Add a comment"
                        saveOverride={(rich_text) => {
                            SapienInertia.put(
                                "forum.posts.update",
                                {
                                    rich_text: JSON.stringify(rich_text),
                                    parent_thread_id: post.parent_thread_id,
                                },
                                {
                                    post: post.id,
                                },
                                {
                                    onSuccess: () => {
                                        setIsReplying(false);
                                        setIsEditing(false);
                                    },
                                    preserveState: false,
                                    preserveScroll: true,
                                    only: ["posts"],
                                },
                            );
                        }}
                    />
                </div>
                <div className="mt-2 flex w-full space-x-2 pb-2">
                    <button
                        className="flex items-center space-x-1 py-1 text-sm text-gray-500 transition-colors
                            hover:text-gray-900 hover:underline"
                        onClick={() => setIsReplying(!isReplying)}
                    >
                        <MessageSquareIcon className="h-4 w-4 text-current" />
                        <span>Reply</span>
                    </button>
                    {user.id === post.author?.id && (
                        <button
                            className="flex items-center space-x-1 py-1 text-sm text-gray-500 transition-colors
                                hover:text-gray-900 hover:underline"
                            onClick={() => setIsEditing(!isEditing)}
                        >
                            <PencilIcon className="h-4 w-4 text-current" />
                            <span>Edit</span>
                        </button>
                    )}
                </div>
                {!!isReplying && (
                    <div className="w-full pl-2">
                        <ForumRichText
                            editable={true}
                            content={content}
                            parentObject={{
                                id: "",
                                rich_text: content,
                                updated_at: "",
                            }}
                            placeHolder="Add a reply..."
                            saveOverride={createReply}
                        />
                    </div>
                )}
                {!!post.children?.length && (
                    <div className="-ml-3 pt-2">
                        {post.children?.map((child, i) => (
                            <div
                                className="relative mt-2 flex w-full"
                                key={child.id}
                            >
                                <div
                                    className="-ml-[1px] mt-[6px] box-border h-6 w-11 rounded-bl-[1.5rem] border-0
                                        border-b-[1px] border-l-[1px] border-solid border-gray-200 -translate-y-1"
                                ></div>
                                <Post
                                    key={child.id}
                                    post={child as WithId<App.Data.PostData>}
                                />
                                {i === post.children.length - 1 && (
                                    <div className="absolute -left-[2px] top-2 h-[calc(100%-0.5rem)] w-[2px] bg-gray-50"></div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
