import React, { useEffect, useState } from "react";
import { Simulation, SubmoduleTree } from "@/models";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import Icon from "react-icons-kit";
import { home } from "react-icons-kit/feather/home";
import { map } from "react-icons-kit/feather/map";
import { sliders } from "react-icons-kit/feather/sliders";
import { grid } from "react-icons-kit/feather/grid";
import { ic_lightbulb_outline } from "react-icons-kit/md/ic_lightbulb_outline";
import { plus } from "react-icons-kit/feather/plus";
import { SapienPageProps } from "@/inertia-utils/types";
import { useInitAuthFromInertiaProps, useSelectedSimulation } from "@/hooks";
import {
    AdminPageHeader,
    AdminPageSection,
    PrimaryButtonOutline,
} from "@/components/admin-components";
import { ArchetypeModelForm } from "./ArchetypeModelForm";
import { ArchetypeModelTimeline } from "./ArchetypeModelTimeline";
import { ArchetypeModelDemo } from "./ArchetypeModelDemo";
import { AdminContainer } from "@/Layouts/admin";
import { SimBuilderTopNav } from "@/Layouts/admin/SimBuilderTopNav";

const sizes = {
    topNav: "40px",
    toolbar: "80px",
    leftMenu: "210px",
};
type PageSection = "Dashboard" | "Design" | "Levers" | "Structure" | "Guides";
const menuItemObject: Record<PageSection, any> = {
    Dashboard: home,
    Design: grid,
    Levers: sliders,
    Structure: map,
    Guides: ic_lightbulb_outline,
};

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    submoduleTree?: SubmoduleTree;
};

const queryClient = new QueryClient();

export default function ArchetypeModelBuilderContainer({
    simulation,
    submoduleTree, //: tree,
    auth,
    tenant,
}: Props) {
    const {} = useInitAuthFromInertiaProps(auth, null, null, tenant);

    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const [showForm, setShowForm] = useState(true);
    const [menuSection, setMenuSection] = useState<PageSection>("Dashboard");

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    // const modelBlock: ModelBlock & {
    //     interActiveModuleArchetypes: InteractiveModuleArchetype[];
    //     interActiveModuleCharts: ChartBlock[];
    // } = useMemo(() => {
    //     if (!block) return;
    //     const modelVariables =
    //         block.modelVariables?.length > 0 || block.modelBlocks?.length > 0
    //             ? [
    //                   ...block.modelVariables,
    //                   ...block.modelBlocks.reduce(
    //                       (variableArray, block) => [
    //                           ...variableArray,
    //                           ...block.modelVariables,
    //                       ],
    //                       [],
    //                   ),
    //               ]
    //             : [];
    //     return { ...block, modelVariables: modelVariables } as ModelBlock & {
    //         interActiveModuleArchetypes: InteractiveModuleArchetype[];
    //         interActiveModuleCharts: ChartBlock[];
    //     };
    // }, [block]);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <>
                <QueryClientProvider client={queryClient}>
                    <AdminContainer headTitle={"Wizard"} auth={auth}>
                        <AdminPageHeader>
                            <SimBuilderTopNav
                                title={selectedSimulation.title}
                                slug={selectedSimulation.slug}
                                id={selectedSimulation.id}
                                isCourse={selectedSimulation.is_course}
                            />
                        </AdminPageHeader>
                        <AdminPageSection>
                            <div className="builder-bottom -my-4 flex h-[calc(100vh-130px)] flex-row">
                                <div
                                    className="builder-left-detail-menu flex w-20 flex-col justify-between border-r
                                        border-neutral-200 bg-indigo-50 bg-opacity-75 text-neutral-800"
                                    style={{
                                        height: "100%",
                                        overflow: "auto",
                                        transition: "all .25s ease",
                                    }}
                                >
                                    <div className={"w-full"}>
                                        {Object.keys(menuItemObject).map(
                                            (key) => (
                                                <div
                                                    key={key}
                                                    className={`flex w-full cursor-pointer flex-col items-center justify-center py-2
                                                    hover:bg-indigo-100 hover:bg-opacity-50 ${
                                                        menuSection == key
                                                            ? "bg-indigo-100 bg-opacity-80"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        setMenuSection(
                                                            key as PageSection,
                                                        );
                                                    }}
                                                >
                                                    <Icon
                                                        icon={
                                                            menuItemObject[key]
                                                        }
                                                        size={24}
                                                        className="flex h-8 items-center justify-center text-neutral-500"
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    />
                                                    <span className="text-xs font-light">
                                                        {key}
                                                    </span>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="builder-center-panel w-full"
                                    style={{
                                        transition: "all .25s ease",
                                        // height: "100%",
                                        overflow: "auto",
                                        left: sizes.toolbar,
                                    }}
                                >
                                    <div
                                        className="builder-breadcrumb-menu border-b border-neutral-200 bg-indigo-50 bg-opacity-30
                                            px-2 text-neutral-800"
                                        style={{
                                            height: sizes.topNav,
                                            minHeight: sizes.topNav,
                                            position: "sticky",
                                            width: "100%",
                                            bottom: 0,
                                        }}
                                    >
                                        <div className="flex h-full items-center">
                                            <div className="flex h-full items-center justify-start px-3 text-lg font-semibold">
                                                <div>{menuSection}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            // height: `calc(100vh - ${sizes.topNav} - ${sizes.topNav})`,
                                            // minHeight: `calc(100vh - ${sizes.topNav} - ${sizes.topNav})`,
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            transition: "all .25s ease",
                                            overflow: "auto",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                maxWidth: "1290px",
                                                margin: "0 auto",
                                                minWidth: "300px",
                                            }}
                                        >
                                            <div className="p-6">
                                                {menuSection ===
                                                    "Dashboard" && (
                                                    <>
                                                        <div className="pb-3 text-lg">
                                                            {"Demo"}
                                                        </div>
                                                        <ArchetypeModelDemo
                                                            rounds={
                                                                simulation?.rounds ??
                                                                []
                                                            }
                                                            timeHorizons={
                                                                simulation?.timeHorizons ??
                                                                []
                                                            }
                                                        />
                                                    </>
                                                )}
                                                {menuSection ===
                                                    "Structure" && (
                                                    <>
                                                        <div className="pb-3 text-lg">
                                                            {
                                                                "Rounds and Questions"
                                                            }
                                                        </div>
                                                        <ArchetypeModelTimeline
                                                            rounds={
                                                                simulation?.rounds ??
                                                                []
                                                            }
                                                            timeHorizons={
                                                                simulation?.timeHorizons ??
                                                                []
                                                            }
                                                        />
                                                    </>
                                                )}
                                                {menuSection === "Design" && (
                                                    <>
                                                        {!submoduleTree ? (
                                                            <div>
                                                                <div className="mb-4 flex min-h-[46px] flex-row items-center justify-between">
                                                                    <div className="pb-3 text-lg">
                                                                        Model
                                                                        Blocks
                                                                    </div>
                                                                    <PrimaryButtonOutline
                                                                        text={
                                                                            "Create Block"
                                                                        }
                                                                        icon={
                                                                            plus
                                                                        }
                                                                        handler={() =>
                                                                            setShowForm(
                                                                                !showForm,
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="w-full">
                                                                    {showForm && (
                                                                        <ArchetypeModelForm
                                                                            rounds={
                                                                                simulation?.rounds ??
                                                                                []
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="grid w-full grid-cols-1 gap-4">
                                                                    {simulation?.submoduleTrees?.map(
                                                                        (
                                                                            tree,
                                                                        ) => (
                                                                            <InertiaLink
                                                                                key={
                                                                                    tree.id
                                                                                }
                                                                                href={sapienRoute(
                                                                                    "model-builder.wizard.show",
                                                                                    {
                                                                                        simulationSlug:
                                                                                            simulation.slug,
                                                                                        submoduleTreeId:
                                                                                            tree.id,
                                                                                    },
                                                                                )}
                                                                            >
                                                                                <div className="rounded-md bg-indigo-50 p-4 hover:shadow-md">
                                                                                    <div className="flex flex-row justify-between space-x-1">
                                                                                        <h2 className="text-lg font-bold">
                                                                                            {
                                                                                                tree.label
                                                                                            }
                                                                                        </h2>
                                                                                    </div>
                                                                                </div>
                                                                            </InertiaLink>
                                                                        ),
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {
                                                                    submoduleTree.label
                                                                }
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AdminPageSection>
                    </AdminContainer>
                </QueryClientProvider>
            </>
        )
    );
}
