import ApplicationLogo from "@/components/ApplicationLogo";
import ValidationErrors from "@/components/ValidationErrors";
import Input from "@/components/Input";
import Label from "@/components/Label";
import { Link, useForm } from "@inertiajs/react";
import React from "react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { MailOpen } from "lucide-react";

export default function Login({ email }: { email: string }) {
    const { errors, post, processing, data, setData } = useForm({
        email,
        remember: true,
    });

    const login = (e: React.MouseEvent) => {
        e.preventDefault();
        const route = sapienRoute("passwordless-login.store");
        post(route);
    };

    return (
        <div
            className="flex min-h-screen flex-col items-center justify-center space-y-4 bg-gray-100
                pt-6 sm:justify-center sm:pt-0"
        >
            <ApplicationLogo className="h-16 w-16 fill-current text-gray-500" />
            <div
                className="flex w-full flex-col items-center justify-center gap-4 overflow-hidden
                    rounded-lg bg-white px-6 py-6 shadow-md sm:max-w-md"
            >
                <div className="flex w-80 flex-col items-center justify-center space-y-6 p-6 text-center">
                    <MailOpen className="h-20 w-20 text-sapien-blue" />
                    <div className="flex flex-col space-y-2 ">
                        <h1 className="text-2xl font-bold">Check your email</h1>
                        <p>We emailed a magic link to {email}.</p>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <p>
                            Didn't get an email?
                            <br />
                            <button
                                className=" text-indigo-600 underline"
                                onClick={login}
                            >
                                Resend
                            </button>{" "}
                            or{" "}
                            <Link
                                className="text-indigo-600 underline"
                                href={sapienRoute("passwordless-login.show")}
                            >
                                try a different address
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
