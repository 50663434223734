import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
    useFlatContentBlock,
    useEditorActiveLineage,
    useChartContentBlock,
    useModalQueryParams,
    useEditorMenuSections,
} from "@/hooks";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { contentBlockHasChildren, titleCase } from "@/util";
import {
    PencilIcon,
    CheckIcon,
    XMarkIcon,
    EyeIcon,
    VariableIcon,
    TableCellsIcon,
    FolderIcon,
    ChartBarIcon,
    IdentificationIcon,
    DocumentTextIcon,
    ArrowDownOnSquareIcon,
    LinkIcon,
    QuestionMarkCircleIcon,
    WindowIcon,
} from "@heroicons/react/24/solid";
import {
    DocumentIcon,
    PhotoIcon,
    RectangleStackIcon,
} from "@heroicons/react/24/outline";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { useEffect } from "react";
import { useDisplayRules } from "@/hooks/useDisplayRules";
import { Icon } from "react-icons-kit";
import { move } from "react-icons-kit/feather/move";
import {
    useDiscardActiveContentBlock,
    useGetActiveContentBlock,
    useGetActiveContentBlockId,
    useSelectActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { useUpdateTopLevelContentBlockLayer } from "./useUpdateTopLevelContentBlockLayers";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

type DNDKitSortableItemProps = {
    attributes?: DraggableAttributes;
    listeners?: SyntheticListenerMap;
    isDragging?: boolean;
};
const DragHandle = ({ listeners, attributes }: DNDKitSortableItemProps) => (
    <div
        className="drag-handle flex items-center"
        {...listeners}
        {...attributes}
    >
        <Icon
            icon={move}
            size={14}
            style={{
                display: "flex",
            }}
        />
    </div>
);

const NestedLayerIcon = ({
    contentbLockType,
}: {
    contentbLockType: ContentBlockType;
}) => {
    switch (contentbLockType) {
        case ContentBlockType["Page"]:
            return (
                <DocumentIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType.Card:
        case ContentBlockType["Card Deck"]:
            return (
                <IdentificationIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType["Image"]:
            return (
                <PhotoIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType.Button:
            return (
                <ArrowDownOnSquareIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType.Link:
            return (
                <LinkIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType.Typography:
        case ContentBlockType["Rich Text"]:
            return (
                <DocumentTextIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType["Chart"]:
        case ContentBlockType["Chart Axis"]:
        case ContentBlockType["Area Series"]:
        case ContentBlockType["LineSeries"]:
            return (
                <ChartBarIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType["Table"]:
        case ContentBlockType["Table Header"]:
        case ContentBlockType["Table Body"]:
        case ContentBlockType["Table Cell"]:
            return (
                <TableCellsIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType["Modal"]:
            return (
                <EyeIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType.Question:
        case ContentBlockType.Form:
            return (
                <QuestionMarkCircleIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
        case ContentBlockType.Tabs:
            return (
                <FolderIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );

        default:
            return (
                <RectangleStackIcon className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white" />
            );
    }
};
const modalTypes = [ContentBlockType.Modal, ContentBlockType["Inbox Message"]];

export const NestedContentBlockLayerDisplay = (
    props: {
        contentBlockId: string;
        contentBlockNested: ContentBlockShape;
        ancestorId: string;
        nestingLevel: number;
        currentPageId: string;
        children?: React.ReactElement;
    } & DNDKitSortableItemProps,
) => {
    const {
        contentBlockId,
        contentBlockNested,
        ancestorId,
        nestingLevel,
        currentPageId,
        listeners,
        attributes,
    } = props;
    const { flatContentBlock } = useFlatContentBlock(contentBlockId);
    const { activeLineage } = useEditorActiveLineage();
    const [shouldShowChildLayers, setShouldShowChildLayers] = useState(false);
    const { openModal } = useModalQueryParams();

    const isSelected = contentBlockId === useGetActiveContentBlockId();
    useLayoutEffect(() => {
        let newShouldShowChildLayers =
            activeLineage[contentBlockId] !== undefined ||
            isSelected ||
            (ancestorId === currentPageId && nestingLevel < 1);
        setShouldShowChildLayers(newShouldShowChildLayers);
    }, [
        isSelected,
        activeLineage,
        contentBlockId,
        ancestorId,
        currentPageId,
        nestingLevel,
    ]);

    const { setActiveContentBlock } = useSelectActiveContentBlock();
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { discardActiveContentBlock } = useDiscardActiveContentBlock();
    const { toggleLeftMenu } = useEditorMenuSections();

    const handleLayerClick = useCallback(() => {
        // clicked block is the active block, so handle discard to deselect it
        if (activeContentBlock?.id === contentBlockId) {
            discardActiveContentBlock();
            return;
        }
        // clicked block is not the active block
        if (
            contentBlockNested?.content_block_type === ContentBlockType.Page &&
            contentBlockId !== currentPageId
        ) {
            // clicked block is a page but not the current page, so handle discard before nav
            const hasDiscarded = discardActiveContentBlock();
            return hasDiscarded;
        } else {
            setActiveContentBlock(contentBlockId);
        }
    }, [
        flatContentBlock,
        ancestorId,
        contentBlockId,
        activeContentBlock,
        currentPageId,
        contentBlockNested?.content_block_type,
    ]);

    const [editedBlockId, setEditedBlockId] = useState("");
    const [title, setTitle] = useState("");
    useEffect(() => {
        setTitle(contentBlockNested.title);
    }, [editedBlockId]);

    const { displayRulesByContentBlockId } = useDisplayRules();
    const { setChartContentBlock } = useChartContentBlock();

    // to do: we need to handle discarding active blocks before using route
    const route = useMemo(() => {
        if (contentBlockNested.content_block_type === ContentBlockType.Modal) {
            return sapienRoute("sim-content.modal-content-block", {
                modalContentBlockId: contentBlockNested.id,
            });
        }
        return sapienRoute("simulation.design.show.page", {
            roundId: contentBlockNested.round_id,
            currentPageId: contentBlockNested.id,
        });
    }, [contentBlockNested]);

    const mutation = useUpdateTopLevelContentBlockLayer();
    return (
        contentBlockNested !== undefined && (
            <div
                data-testid={`nested-layer-display-${contentBlockId}`}
                key={contentBlockId}
                style={{
                    borderLeft: `10px solid ${
                        contentBlockId === currentPageId ||
                        (contentBlockNested.content_block_type ===
                            ContentBlockType.Modal &&
                            !!activeLineage[contentBlockId]) ||
                        (activeContentBlock.content_block_type ===
                            ContentBlockType.Modal &&
                            activeContentBlock.id === contentBlockId)
                            ? "rgba(255,255,255,0.15)"
                            : "rgba(255,255,255,0)"
                    }`,
                }}
                className={`selector w-full text-sm text-white ${
                    activeContentBlock?.id === contentBlockId
                        ? "active-layer"
                        : ""
                }`}
            >
                <div
                    className="p-1"
                    style={{
                        backgroundColor: isSelected && "rgba(255,255,255,0.15)",
                    }}
                >
                    <div className="ml-2 flex items-center space-x-1">
                        {!!listeners &&
                            !!attributes &&
                            contentBlockNested?.content_block_type ===
                                ContentBlockType.Page && (
                                <DragHandle
                                    listeners={listeners}
                                    attributes={attributes}
                                />
                            )}
                        {editedBlockId !== contentBlockId ? (
                            <>
                                <NestedLayerIcon
                                    contentbLockType={
                                        contentBlockNested.content_block_type
                                    }
                                />
                                {(contentBlockNested.content_block_type !==
                                    ContentBlockType.Page ||
                                    contentBlockNested.id === currentPageId) &&
                                !modalTypes.includes(
                                    contentBlockNested.content_block_type,
                                ) ? (
                                    <button
                                        onClick={() => {
                                            handleLayerClick();
                                        }}
                                        className={
                                            "ml-8 truncate text-xs leading-[1.1]"
                                        }
                                        data-content-block-selection-id={
                                            contentBlockNested?.id
                                        }
                                        data-testid={`content-block-selection-id-${contentBlockId}`}
                                    >
                                        {(
                                            contentBlockNested.title ||
                                            titleCase(
                                                contentBlockNested.content_block_type,
                                            )
                                        ).slice(0, 50)}
                                    </button>
                                ) : modalTypes.includes(
                                      contentBlockNested.content_block_type,
                                  ) ? (
                                    <button
                                        onClick={() => {
                                            handleLayerClick();
                                        }}
                                        data-testid={`modal-link-${contentBlockNested.id}`}
                                        className="ml-8 truncate text-xs leading-[1.1]"
                                        data-content-block-selection-id={
                                            contentBlockNested?.id
                                        }
                                    >
                                        {(
                                            contentBlockNested.title ||
                                            titleCase(
                                                contentBlockNested.content_block_type,
                                            )
                                        ).slice(0, 50)}
                                    </button>
                                ) : (
                                    // content block is a page but not the current page
                                    <button
                                        className="text-xs leading-[1.2]"
                                        data-content-block-selection-id={
                                            contentBlockNested?.id
                                        }
                                        onClick={() => {
                                            const hasDiscarded =
                                                handleLayerClick();
                                            if (hasDiscarded) {
                                                SapienInertia.visit(route, {
                                                    only: [
                                                        "contentBlocks",
                                                        "currentPageId",
                                                        "topLevelContentBlockLayers",
                                                        "modalPageProps",
                                                        "modalComponent",
                                                    ],
                                                });
                                            }
                                        }}
                                    >
                                        {contentBlockNested.title ||
                                            titleCase(
                                                contentBlockNested.content_block_type,
                                            )}
                                        <div className="sr-only">
                                            select{" "}
                                            {
                                                contentBlockNested.content_block_type
                                            }
                                        </div>
                                    </button>
                                )}
                                <PencilIcon
                                    data-testid={`edit-title-${contentBlockNested.id}`}
                                    className="ml-3 h-3 w-3 text-gray-100"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setEditedBlockId(contentBlockId);
                                    }}
                                />
                                {!!contentBlockNested.behaviors?.click
                                    ?.ShowModalContentBlock && (
                                    <WindowIcon
                                        role="button"
                                        className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white"
                                        onClick={() => {
                                            openModal({
                                                modalComponent:
                                                    "ContentBlockModal",
                                                modalContentBlockId:
                                                    contentBlockNested.behaviors
                                                        .click
                                                        .ShowModalContentBlock
                                                        .modalContentBlockId,
                                            });
                                        }}
                                    />
                                )}
                                {contentBlockNested.content_block_type ===
                                    ContentBlockType["Table Body"] && (
                                    <VariableIcon
                                        className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white"
                                        onClick={(e) => {
                                            setChartContentBlock(
                                                contentBlockNested,
                                            );
                                        }}
                                    />
                                )}
                                {!!displayRulesByContentBlockId[
                                    contentBlockId
                                ] && (
                                    <EyeIcon
                                        className="h-4 w-4 cursor-pointer text-gray-200 transition-colors hover:text-white"
                                        onClick={() => {
                                            openModal({
                                                modalComponent:
                                                    "DynamicContentModal",
                                                contentBlockId,
                                            });
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <span>
                                    <input
                                        data-testid={`edit-title-input-${contentBlockNested.id}`}
                                        disabled={mutation.isPending}
                                        type="text"
                                        name="title"
                                        id="title"
                                        className="block w-full rounded-md border-gray-300 px-1.5 py-1 text-gray-800 shadow-sm
                                            focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Title"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                </span>
                                <span>
                                    <button
                                        data-testid={`edit-title-check-${contentBlockNested.id}`}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            await mutation.mutateAsync({
                                                title,
                                                contentBlockId: editedBlockId,
                                            });
                                            setEditedBlockId("");
                                        }}
                                        disabled={mutation.isPending}
                                    >
                                        <CheckIcon className="h-3 w-3 text-green-100" />
                                    </button>
                                    <XMarkIcon
                                        className="h-3 w-3 text-red-100"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setEditedBlockId("");
                                        }}
                                    />
                                </span>
                            </>
                        )}
                    </div>
                    {contentBlockHasChildren(contentBlockNested) &&
                        shouldShowChildLayers && (
                            <div
                                style={{
                                    paddingLeft:
                                        contentBlockNested.content_block_type ===
                                        ContentBlockType.Page
                                            ? 5
                                            : 3,
                                }}
                            >
                                {contentBlockNested.contentBlocks.map(
                                    (child) => (
                                        <NestedContentBlockLayerDisplay
                                            key={child.id}
                                            contentBlockId={child.id}
                                            contentBlockNested={child}
                                            ancestorId={ancestorId}
                                            nestingLevel={nestingLevel + 1}
                                            currentPageId={currentPageId}
                                        />
                                    ),
                                )}
                            </div>
                        )}
                </div>
                {props.children}
            </div>
        )
    );
};
