import React, { useEffect, useMemo } from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@radix-ui/react-popover";
import Icon from "react-icons-kit";
import { info } from "react-icons-kit/feather/info";
import { CompactSettingsTable } from "./CompactSettingsTable";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { AccordionCardSettingsDisplay } from "./AccordionCardSettingsDisplay";
import {
    ButtonGroupMenu,
    CollapseAllControlButton,
    ModelBlockCarouselMenu,
    ShowHideButton,
} from "../shared";
import { CarouselCardSettingsDisplay } from "./CarouselCardSettingsDisplay";
import {
    useInputDataObject,
    useInputModelVariableMap,
    useModelTimespan,
    useModelTimespanArray,
    useSettingsModelBlocks,
} from "../state";
import {
    useHandleInputDataReset,
    useHandleSetInputDataItem,
    useHandleSetInputDataObjectFull,
} from "../useInteractiveModule";
import { useInterfaceStateSettings } from "../useInterfaceState";
import { getNewInputDataObjectOnTimespanChange } from "../utils";

const InteractiveSectionSettingsComponent = ({}: {}) => {
    const {
        settingsTabIndex,
        setSettingsTabIndex,
        showSettingsCharts,
        setShowSettingsCharts,
        selectedSettingsModelBlock,
        setSelectedSettingsModelBlock,
        modelBlockDisplayMapSettings,
        setModelBlockDisplayMapSettings,
        hasOpenSettingsBlocks,
    } = useInterfaceStateSettings();

    const modelTimespanArray = useModelTimespanArray();
    const modelTimespan = useModelTimespan();
    const inputDataObject = useInputDataObject();
    const settingsModelBlocks = useSettingsModelBlocks();
    const inputModelVariableMap = useInputModelVariableMap();
    const { handleInputDataReset, isPending: isResetting } =
        useHandleInputDataReset();
    const { handleSetInputDataObjectFull, isPending: isSettingFull } =
        useHandleSetInputDataObjectFull();
    const { handleSetInputDataItem, isPending: isSettingItem } =
        useHandleSetInputDataItem();

    const isPending = useMemo(
        () => isResetting || isSettingFull || isSettingItem,
        [isResetting, isSettingFull, isSettingItem],
    );

    useEffect(() => {
        if (settingsModelBlocks?.length > 0 && !selectedSettingsModelBlock) {
            setSelectedSettingsModelBlock(settingsModelBlocks[0]);
        }
        if (!Object.keys(modelBlockDisplayMapSettings)?.length) {
            setModelBlockDisplayMapSettings(
                settingsModelBlocks?.reduce(
                    (map, block) => ({ ...map, [block.id]: true }),
                    {},
                ) || {},
            );
        }
    }, [settingsModelBlocks]);

    return (
        <div className="flex w-full flex-col space-y-6">
            <div className="flex items-center justify-between space-x-2">
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Inputs"}
                </h1>
                <ButtonGroupMenu
                    selectedIndex={settingsTabIndex}
                    setSelectedIndex={setSettingsTabIndex}
                    buttonItems={["Default View", "List View", "Compact View"]}
                    isPrimary={false}
                />
            </div>

            <div
                className="flex flex-col items-start justify-between space-x-0 space-y-2 text-lg
                    font-semibold sm:flex-row sm:flex-wrap sm:items-center sm:space-x-2 sm:space-y-2
                    md:space-y-0"
            >
                <span className="flex items-center space-x-2">
                    {modelTimespanArray?.length > 0 && (
                        <select
                            className="min-w-[200px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                                text-blue-700 transition-all marker:border focus:border-slate-300
                                focus:outline-none focus:ring-0 dark:border-slate-800 dark:bg-slate-800/20
                                dark:text-blue-700 dark:focus:border-slate-700"
                            value={modelTimespan}
                            disabled={isPending}
                            onChange={(e) => {
                                e.stopPropagation();
                                handleSetInputDataObjectFull(
                                    getNewInputDataObjectOnTimespanChange(
                                        inputDataObject,
                                        Number(e.target.value),
                                    ),
                                );
                            }}
                        >
                            {modelTimespanArray.map((val) => (
                                <option
                                    key={val}
                                    value={val}
                                    className="dark:bg-slate-800"
                                >
                                    {`Model Timespan: ${val}`}
                                </option>
                            ))}
                        </select>
                    )}
                    <Popover>
                        <PopoverTrigger
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div
                                title={"Model Timespan"}
                                className="relative inline-flex items-center text-sm font-medium"
                            >
                                <span className="sr-only">Model Timespan</span>
                                <Icon
                                    icon={info}
                                    size={20}
                                    className="flex h-8 items-center justify-center text-slate-400 transition-all
                                        hover:text-slate-600 focus:outline-none focus:ring-0 dark:text-slate-500
                                        dark:hover:text-slate-300"
                                    style={{ display: "flex" }}
                                />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent
                            align={"center"}
                            sideOffset={0}
                            className="mt-2 min-w-[340px] max-w-[340px] rounded-md border border-slate-200 bg-slate-100
                                p-4 text-sm font-normal text-slate-600 dark:border-slate-700 dark:bg-slate-800
                                dark:text-slate-400"
                            style={{
                                zIndex: 9999,
                            }}
                        >
                            <div className="">
                                <span className="font-semibold text-blue-700 dark:text-blue-700">{` Model Timespan `}</span>
                                refers to the time in years for model
                                calculations.
                                <br />
                                Select lower values to generate short-term
                                projections and higher values to generate
                                long-term projections.
                            </div>
                        </PopoverContent>
                    </Popover>
                </span>
                <span className="flex items-center space-x-2">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-slate-200 bg-transparent p-1
                            text-center text-xs font-normal text-slate-500 transition-all hover:bg-slate-50
                            hover:text-slate-600 focus:outline-none focus:ring-0 dark:border-slate-800
                            dark:text-slate-500 dark:hover:bg-slate-800/25 dark:hover:text-slate-400"
                        disabled={isPending}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (window.confirm("Reset all model inputs?")) {
                                handleInputDataReset();
                            }
                        }}
                    >
                        <ArrowPathIcon className="mr-1 h-4 w-4" />
                        {`Restore Defaults`}
                    </button>
                    {settingsTabIndex > 0 && (
                        <CollapseAllControlButton
                            allAreCollapsed={!hasOpenSettingsBlocks}
                            setAllAreCollapsed={(hasOpenSettings) => {
                                setModelBlockDisplayMapSettings(
                                    Object.keys(
                                        modelBlockDisplayMapSettings,
                                    ).reduce(
                                        (map, key) => ({
                                            ...map,
                                            [key]: !hasOpenSettings,
                                        }),
                                        {},
                                    ),
                                );
                            }}
                        />
                    )}
                    <ShowHideButton
                        show={showSettingsCharts}
                        setShow={setShowSettingsCharts}
                        label={"Input Charts"}
                    />
                </span>
            </div>

            {settingsTabIndex === 0 &&
                !!selectedSettingsModelBlock &&
                settingsModelBlocks?.length > 0 && (
                    <div className="text-slate-700 transition-all dark:text-slate-300">
                        <ModelBlockCarouselMenu
                            selectedModelBlock={selectedSettingsModelBlock}
                            setSelectedModelBlock={
                                setSelectedSettingsModelBlock
                            }
                            modelBlocks={settingsModelBlocks}
                        />
                    </div>
                )}

            {settingsTabIndex === 0 &&
                Object.keys(inputDataObject)?.length > 0 &&
                Object.keys(inputModelVariableMap)?.length > 0 &&
                !!selectedSettingsModelBlock && (
                    <div className="text-slate-700 dark:text-slate-300">
                        <CarouselCardSettingsDisplay
                            modelBlock={selectedSettingsModelBlock}
                            modelVariables={{
                                [selectedSettingsModelBlock.id]:
                                    inputModelVariableMap[
                                        selectedSettingsModelBlock.id
                                    ],
                            }}
                            inputDataObject={inputDataObject}
                            setInputDataItem={handleSetInputDataItem}
                            showSettingsCharts={showSettingsCharts}
                        />
                    </div>
                )}

            {settingsTabIndex === 1 &&
                Object.keys(inputDataObject)?.length > 0 && (
                    <div className="text-slate-700 dark:text-slate-300">
                        <AccordionCardSettingsDisplay
                            modelBlocks={settingsModelBlocks}
                            modelVariables={inputModelVariableMap}
                            inputDataObject={inputDataObject}
                            setInputDataItem={handleSetInputDataItem}
                            showSettingsCharts={showSettingsCharts}
                            modelBlockDisplayMap={modelBlockDisplayMapSettings}
                            setModelBlockDisplayMap={
                                setModelBlockDisplayMapSettings
                            }
                        />
                    </div>
                )}

            {settingsTabIndex === 2 &&
                Object.keys(inputDataObject)?.length > 0 && (
                    <div className="">
                        <CompactSettingsTable
                            modelBlocks={settingsModelBlocks}
                            modelVariables={inputModelVariableMap}
                            inputDataObject={inputDataObject}
                            setInputDataItem={handleSetInputDataItem}
                            showSettingsCharts={showSettingsCharts}
                            modelBlockDisplayMap={modelBlockDisplayMapSettings}
                            setModelBlockDisplayMap={
                                setModelBlockDisplayMapSettings
                            }
                        />
                    </div>
                )}
        </div>
    );
};

export const InteractiveSectionSettings = React.memo(
    InteractiveSectionSettingsComponent,
);
