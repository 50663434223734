import React from "react";
import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import { useSetVariableWatchlist, useVariableWatchlist } from "../state";

const FavoriteToggleIconComponent = ({
    modelVariableId,
}: {
    modelVariableId: string;
}) => {
    const watchlist = useVariableWatchlist();
    const setWatchlist = useSetVariableWatchlist();

    return (
        <button
            type="button"
            title={"Watchlist"}
            className={`focus:outline-none focus:ring-0 ${
                !!watchlist[modelVariableId]
                    ? "text-blue-600 hover:text-blue-500 dark:text-blue-700 dark:hover:text-blue-600"
                    : "text-slate-500 opacity-40 hover:opacity-100 dark:text-slate-500"
            }`}
            onClick={(e) => {
                e.stopPropagation();
                setWatchlist({
                    ...watchlist,
                    [modelVariableId]: !watchlist[modelVariableId],
                });
            }}
        >
            {!!watchlist[modelVariableId] ? (
                <StarIconSolid className="h-4 w-4" />
            ) : (
                <StarIconOutline className="h-4 w-4" />
            )}
        </button>
    );
};

export const FavoriteToggleIcon = React.memo(FavoriteToggleIconComponent);
