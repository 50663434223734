import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "@inertiajs/react";
import {
    ContentBlockShape,
    ContentBlockType,
    OptionShape,
    RichText,
} from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared/ModalContainer";
import { buildDragAndDropCard } from "@/model-configs/content-block-layouts/CardLayouts";
import { useFindContentBlockMethods, useSelectedSimulation } from "@/hooks";
import {
    OptionContainer,
    SelectionContainer,
} from "@/styles/themes/Question/drag-and-drop/types";
import OptionModalRichTextEditor from "./OptionModalRichTextEditor";
import {
    CreateContentBlockBody,
    createContentBlock,
} from "../../../content-block-buttons/create-content-block-button/createContentBlock";

type Props = {
    parentContentBlockId: string;
    weight: number;
    option: OptionShape;
    setOption: (option: OptionShape | undefined) => void;
    container: OptionContainer | SelectionContainer;
};

export default function DragAndDropOptionModal({
    option,
    setOption,
    parentContentBlockId,
    weight,
    container,
}: Props) {
    const { setData, data } = useForm({
        numerical_value: 0,
        size: 0,
        subtitle: "",
        richText: {},
        content: "",
        ...option,
    });

    const { selectedSimulation } = useSelectedSimulation();
    const { getContentBlockWithChildren } = useFindContentBlockMethods();

    const [richText, setRichText] = useState({});
    useEffect(() => {
        if (richText !== data?.richText) setData("richText", richText);
    }, [richText, data]);

    useEffect(() => {
        setData({
            numerical_value: 0,
            size: 0,
            subtitle: "",
            richText: {},
            content: "",
            ...option,
        });
    }, [option]);

    const recursivelyUpdateContentBlock = (
        oldBlock: ContentBlockShape,
        newBlock: ContentBlockShape,
    ) => {
        return {
            ...newBlock,
            ...oldBlock,
            contentBlocks: oldBlock.contentBlocks?.map((block, i) => {
                let updatedBlock = {
                    ...block,
                    rich_text: newBlock.contentBlocks[i].rich_text,
                };
                if (
                    newBlock.contentBlocks[i].content_block_type ===
                    ContentBlockType["Rich Text"]
                )
                    console.log("updated", updatedBlock.rich_text, block.id);
                recursivelyUpdateContentBlock(
                    updatedBlock,
                    newBlock.contentBlocks[i],
                );
                return updatedBlock;
            }),
        };
    };

    const saveOption = useCallback(async () => {
        //first, make a content block for the option
        let block;

        if (option?.contentBlock?.id) {
            block = recursivelyUpdateContentBlock(
                getContentBlockWithChildren(option?.contentBlock.id),
                buildDragAndDropCard(
                    data?.content,
                    parentContentBlockId,
                    weight,
                    data?.numerical_value,
                    data?.subtitle,
                    data?.richText,
                ),
            );
            SapienInertia.put(
                "creator.design.drag-and-drop-prompt-containers.options.update",
                {
                    ...data,
                    prompt_id: container.prompt_id,
                    drag_and_drop_prompt_container_id: container.id,
                    content_block_id: option.content_block_id,
                },
                {
                    option: option.id,
                },
            );
            return;
        } else {
            block = {
                ...option?.contentBlock,
                ...buildDragAndDropCard(
                    data?.content,
                    parentContentBlockId,
                    weight,
                    data?.numerical_value,
                    data?.subtitle,
                    data?.richText,
                ),
            };
        }
        const { contentBlock: savedBlock } = await createContentBlock({
            body: {
                ...block,
                theme: JSON.stringify(block.theme),
                position: "after",
            } as CreateContentBlockBody,
            selectedSimulation,
        });

        SapienInertia.post(
            "creator.design.drag-and-drop-prompt-containers.options.store",
            {
                ...data,
                prompt_id: container.prompt_id,
                drag_and_drop_prompt_container_id: container.id,
                content_block_id: savedBlock?.id,
                weight,
            },
        );
    }, [data]);

    return (
        <ModalContainer
            isModalOpen={!!option}
            setIsModalOpen={() => {
                setOption(undefined);
            }}
            size="m"
            backgroundColor="#111928"
            styles={{ margin: "4em auto" }}
        >
            {!!data && (
                <div className="py-6 text-white">
                    <div className="flex justify-between p-6">
                        <label>{data.id ? "Update" : "Create"} Option</label>
                    </div>
                    <form
                        className="flex flex-col space-y-4 border-t border-[#374151] p-6 text-white"
                        onSubmit={(e) => {
                            e.preventDefault();
                            saveOption();
                        }}
                    >
                        <div className="flex flex-col space-y-2">
                            <label>Label</label>
                            <input
                                autoFocus={true}
                                type="text"
                                className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-xs`}
                                value={data.content}
                                onChange={(e) => {
                                    setData("content", e.target.value);
                                }}
                            />
                        </div>

                        <div className="flex flex-col space-y-2">
                            <label>Numeric Value</label>
                            <input
                                type="number"
                                className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-xs`}
                                value={data.numerical_value}
                                onChange={(e) =>
                                    setData(
                                        "numerical_value",
                                        Number(e.target.value),
                                    )
                                }
                            />
                        </div>
                        {!data?.id && (
                            <>
                                <div className="flex flex-col space-y-2">
                                    <label>Subtitle</label>
                                    <input
                                        type="text"
                                        className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200 focus:text-white focus:outline-offset-0  focus:outline-[#1d4ed8] sm:text-xs`}
                                        value={data.subtitle}
                                        onChange={(e) => {
                                            setData("subtitle", e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 text-gray-900">
                                    <label>Content</label>
                                    <OptionModalRichTextEditor
                                        richText={data.richText}
                                        onChange={(richText: RichText) => {
                                            setRichText(richText);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                            <div className="flex items-center justify-center">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOption(undefined);
                                    }}
                                    className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none focus:ring-2 focus:ring-blue-300"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    onClick={() => {
                                        saveOption();
                                    }}
                                    disabled={!data.content}
                                    className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-75"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </ModalContainer>
    );
}
